
import React, { useState, useEffect, useRef } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import transactionObject, { objectMergeUpdate, viewObjectKeys } from "../../../Errors/Transactions/Transaction";
import moment from "moment/moment";
import helpers from "../../../assets/js/helper";
import Cookies from "universal-cookie";
import { Box, Button, Grid } from "@mui/material";
import CustomTextField from "../../../Components/CustomTextField";
import CustomeSelectField from "../../../Components/CustomeSelectField";
import geofenceAPI from "../../../Network/Geofence";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import transactionAPI from "../../../Network/Transaction";
const cookies = new Cookies();



export default function UpdateFormContainer(props) {
  const [t] = useTranslation("common");
  const navigate = useNavigate();

  const [transaction, setTransaction] = useState(transactionObject);

  const timerDestinationGeofences = useRef(null)
  const [destinationGeofences, setDestinationGeofences] = useState([])
  const [loadingDestinationGeofences, setLoadingDestinationGeofences] = useState(false)
  const [searchTermDestinationGeofence, setSearchTermDestinationGeofence] = useState("");

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }


  const handleSearchTermDestinationGeofenceChange = (newValue) => {

    setSearchTermDestinationGeofence(newValue);
    if (timerDestinationGeofences.current) {
      clearTimeout(timerDestinationGeofences?.current)
    }
    timerDestinationGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingDestinationGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [2, 3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingDestinationGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setDestinationGeofences(result?.data?.data?.data)
        }
      }
      else {
        setDestinationGeofences([])
      }
    }, 1000)
  };

  useEffect(() => {


    setTransaction(objectMergeUpdate(props?.object))

  }, [props?.openForm, props?.object]);



  const submit = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {
        let readyToSendObject = {}
        // console.log("transaction?.formatted_datetime",transaction?.formatted_datetime);

        if (!helpers.durationFromNow(transaction?.formatted_datetime, 30)) {
          readyToSendObject['transaction_update_exceeded_time'] = 1
          // toast.error(t('TRANSACTIONS.transaction_update_exceeded_time'))
          // props.setOpenForm(false);
          // return 
        }

        let checkErrorObject = {
          ...transaction
        }
        let hasError = false






        if (!transaction?.destination_geofence_id?.value?.id) {
          hasError = true
          checkErrorObject['destination_geofence_id']['error'] = true
          checkErrorObject['destination_geofence_id']['message'] = [t('GLOBAL.mandatory_field')]

        }

        if (hasError) {
          setTransaction({
            ...checkErrorObject
          })
          return
        }

        readyToSendObject['has_pending_transaction_details'] = 1
        readyToSendObject['from_transaction_id'] = transaction?.id
        readyToSendObject['pending_transaction_details'] = {}
        readyToSendObject['pending_transaction_details']['sourceGps'] = transaction?.sourceGps?.value
        readyToSendObject['pending_transaction_details']['destinationGps'] = transaction?.destination_geofence_id?.value?.code
        readyToSendObject['pending_transaction_details']['regNumber'] = transaction?.regNumber?.value
        let shiftedDateTime = transaction?.fDate?.value
        if (transaction?.fDate?.value) {
          try {
            const date = new Date(transaction?.fDate?.value);
            date?.setHours(date?.getHours() + 3);
            shiftedDateTime = date
          } catch (error) {
            shiftedDateTime = transaction?.fDate?.value
          }
        }
        readyToSendObject['pending_transaction_details']['fDate'] = shiftedDateTime
        readyToSendObject['pending_transaction_details']['regType'] = transaction?.regType?.value
        readyToSendObject['pending_transaction_details']['province'] = transaction?.province?.value
        readyToSendObject['pending_transaction_details']['voucherNo'] = transaction?.voucherNo?.value
        readyToSendObject['pending_transaction_details']['voucherType'] = transaction?.voucherType?.value
        readyToSendObject['pending_transaction_details']['driverName'] = transaction?.driverName?.value
        readyToSendObject['pending_transaction_details']['sourceWHouse'] = transaction?.sourceWHouse?.value
        readyToSendObject['pending_transaction_details']['sourceProvince'] = transaction?.sourceProvince?.value
        readyToSendObject['pending_transaction_details']['destinationWHouse'] = transaction?.destinationWHouse?.value
        readyToSendObject['pending_transaction_details']['destinationProvince'] = transaction?.destinationProvince?.value
        readyToSendObject['pending_transaction_details']['carrier'] = transaction?.carrier?.value
        readyToSendObject['pending_transaction_details']['carrierName'] = transaction?.carrierName?.value

        readyToSendObject['pending_transaction_details']['productType'] = transaction?.productType?.value
        readyToSendObject['pending_transaction_details']['liters'] = transaction?.liters?.value
        readyToSendObject['pending_transaction_details']['voucherType2'] = transaction?.voucherType2?.value




        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
          id: cookies.get("id"),
        };

        let result = await transactionAPI.addTransaction({
          data: readyToSendObject,
          params: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });


        if (result?.status && result?.data?.status) {



          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));

          props.loadData();
          props.setOpenForm(false);
        } else {



          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }
      } catch (error) {

        toast.error(error.message);
      }
    } else {

      // navigate('/redirect')
    }
  };

  const renderField = (viewObjectKey) => {
    if (viewObjectKey == "destination_geofence_name") {
      return <CustomeSelectField
        label={t("TRANSACTIONS.destination_geofence")}
        haswidth={true}
        onInputChange={handleSearchTermDestinationGeofenceChange}
        inputValue={searchTermDestinationGeofence}
        loading={loadingDestinationGeofences}
        loadingText={t('GLOBAL.searching')}
        value={transaction?.destination_geofence_id?.value}
        list={destinationGeofences}
        error={transaction?.destination_geofence_id?.error}
        message={transaction?.destination_geofence_id?.message}
        readOnly={false}
        onChange={(e, newValue) => {

          setTransaction({
            ...transaction,
            destination_geofence_id: {
              ...transaction?.destination_geofence_id,
              value: newValue,
              error: false,
              message: []
            },
            destinationGps: {
              value: newValue?.code,
              error: false,
              message: "",
              required: false
            },
            destination_geofence_code: newValue?.code
          })
        }}
        onClearClick={() => {

          setTransaction({
            ...transaction,
            destination_geofence_id: {
              ...transaction?.destination_geofence_id,
              value: "",
              error: false,
              message: []
            }
          })
        }}
      />
    }
    else if (viewObjectKey == "carrier" || viewObjectKey == "liters") {
      return <CustomTextField
        label={t("TRANSACTIONS." + viewObjectKey)}
        hasMultipleLine={true}
        value={transaction?.hasOwnProperty(viewObjectKey) ? transaction[viewObjectKey]?.value : ""}
        haswidth={true}
        readOnly={true}
      />
    }
    else {
      return <CustomTextField
        label={t("TRANSACTIONS." + viewObjectKey)}
        hasMultipleLine={true}
        value={transaction?.hasOwnProperty(viewObjectKey) ? transaction[viewObjectKey] : ""}
        haswidth={true}
        readOnly={true}
      />
    }
  }

  return (
    <PopupForm
      open={props?.openForm}
      setOpen={props?.setOpenForm}
      title={t("TRANSACTIONS.view_title")}
      isFullScreen={false}

      content={<Box className="Container-fluid position-relative" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{
          marginTop: '10px'
        }}>
          {viewObjectKeys?.length ? viewObjectKeys?.map(viewObjectKey => {
            return <Grid xs={6} sm={6} md={6} lg={6} sx={{
              padding: '0px'
            }}>
              {renderField(viewObjectKey)}

            </Grid>
          }) : null}

        </Grid>
      </Box>

      }
      footer={
        <Box>
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#d1d0d0",
              borderColor: "#ffffff",
              color: "#000000",
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#e7a52a",
                borderColor: "#ffffff",
                color: "#fff",
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {

              props?.setOpenForm(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button

            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              backgroundColor: "#e7a52a",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>

      }
    />
  );
}
