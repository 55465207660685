
import React, { useState, useEffect, useRef } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import { transactionAuditObject, objectMergeUpdate, viewObjectKeys } from "../../../Errors/Transactions/Transaction";
import moment from "moment/moment";
import helpers from "../../../assets/js/helper";
import Cookies from "universal-cookie";
import { Box, Button, Grid } from "@mui/material";
import CustomTextField from "../../../Components/CustomTextField";
import CustomeSelectField from "../../../Components/CustomeSelectField";
import geofenceAPI from "../../../Network/Geofence";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import transactionAPI from "../../../Network/Transaction";
import transactionStatusAPI from "../../../Network/TransactionStatus";
import transactionAuditAPI from "../../../Network/TransactionAudit";
import TransactionAudits from "./TransactionAudits";
const cookies = new Cookies();



export default function AuditFormContainer(props) {
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const [reload, setReload] = useState(false)
  const [transactionAudit, setTransactionAudit] = useState(transactionAuditObject);

  const [loadingTransactionStatuses, setLoadingTransactionStatuses] = useState(true)
  const [transactionStatuses, setTransactionStatuses] = useState([])

  const getResources = async () => {

    try {

      const headerObject = {
        username: cookies.get("user_name") ? cookies.get("user_name") : null,
        user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
        account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
        account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
        authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
        sid: cookies.get("sid") ? cookies.get("sid") : null,
        baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
        id: cookies.get("id") ? cookies.get("id") : null,
      }
      const transactionStatusesResult = await transactionStatusAPI.getTransactionStatusList({
        params: {
          ...headerObject,
          with_out_status_not_audited: true
        }

      })
      if (transactionStatusesResult.status && transactionStatusesResult?.data?.data && Array.isArray(transactionStatusesResult?.data?.data)) {
        setTransactionStatuses(transactionStatusesResult?.data?.data)
      }
      setLoadingTransactionStatuses(false);
    } catch (error) {
      console.log(error);
      setLoadingTransactionStatuses(false);
    }

  }


  useEffect(() => {
    getResources()
    return () => {

    }
  }, [])

  useEffect(() => {
    //TODO load transactionAudit grid data

  }, [props?.openForm, props?.object]);



  const submit = async (closeForm) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {



        let checkErrorObject = {
          ...transactionAudit
        }
        let hasError = false
        let readyToSendObject = {}

        if (!transactionAudit?.transaction_status_id?.value?.id) {
      
          hasError = true
          checkErrorObject['transaction_status_id']['error'] = true
          checkErrorObject['transaction_status_id']['message'] = [t('GLOBAL.mandatory_field')]


        }
        if ( !transactionAudit?.note?.value) {
         
          hasError = true
          

          checkErrorObject['note']['error'] = true
          checkErrorObject['note']['message'] = [t('GLOBAL.mandatory_field')]

        }
        if (props?.object?.transaction_id !== transactionAudit?.transaction_status_id?.value?.id && !transactionAudit?.note?.value) {
          console.log("hasError", 2);
          hasError = true
          checkErrorObject['note']['error'] = true
          checkErrorObject['note']['message'] = [t('TRANSACTIONS.mandatory_field_if_status_changed')]
        }

        if (hasError) {
          setTransactionAudit({
            ...checkErrorObject
          })
          return
        }

        readyToSendObject['transaction_status_id'] = transactionAudit?.transaction_status_id?.value?.code
        readyToSendObject['note'] = transactionAudit?.note?.value
        readyToSendObject['transaction_id'] = props?.object?.id


        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
          id: cookies.get("id"),
        };

        let result = await transactionAuditAPI.addTransactionAudit({
          data: readyToSendObject,
          params: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });


        if (result?.status && result?.data?.status) {
          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));
          props.loadData();
          setReload(!reload)
          if(closeForm)
          {
            props.setOpenForm(false);
          }
          
        } else {



          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }
      } catch (error) {

        toast.error(error.message);
      }
    } else {

      // navigate('/redirect')
    }
  };



  return (
    <PopupForm
      open={props?.openForm}
      setOpen={props?.setOpenForm}
      title={t("TRANSACTIONS.view_title")}
      isFullScreen={false}

      content={<Box className="Container-fluid position-relative" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{
          marginTop: '10px'
        }}>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            {loadingTransactionStatuses ? null :
              <CustomeSelectField
                label={t("TRANSACTIONS.transaction_status")}
                haswidth={true}

                loading={loadingTransactionStatuses}
                loadingText={t('GLOBAL.searching')}
                value={transactionAudit?.transaction_status_id?.value}
                list={Array.isArray(transactionStatuses)?transactionStatuses:[]}
                error={transactionAudit?.transaction_status_id?.error}
                message={transactionAudit?.transaction_status_id?.message}
                readOnly={false}
                onChange={(e, newValue) => {

                  setTransactionAudit({
                    ...transactionAudit,
                    transaction_status_id: {
                      ...transactionAudit?.transaction_status_id,
                      value: newValue,
                      error: false,
                      message: []
                    }
                  })
                }}
                onClearClick={() => {

                  setTransactionAudit({
                    ...transactionAudit,
                    transaction_status_id: {
                      ...transactionAudit?.transaction_status_id,
                      value: "",
                      error: false,
                      message: []
                    }
                  })
                }}
              />}

          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("TRANSACTIONS.note")}
              hasMultipleLine={true}
              error={transactionAudit?.note?.error}
              message={transactionAudit?.note?.message}
              onChange={(e) => {
                setTransactionAudit({
                  ...transactionAudit,
                  note: {
                    ...transactionAudit?.note,
                    value: e.target.value,
                    error: false,
                    message: []
                  }
                })
              }}
              value={transactionAudit?.note?.value}
              haswidth={true}
              readOnly={false}
              onClearClick={() => {

                setTransactionAudit({
                  ...transactionAudit,
                  note: {
                    ...transactionAudit?.note,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} sx={{
            padding: '0px',

          }}>
            <Box sx={{
              paddingTop: '10px'
            }}>
              <TransactionAudits
                reload={reload}
                transactionId={props?.object?.id}
              />
            </Box>

          </Grid>
        </Grid>

      </Box>

      }
      footer={
        <Box>
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#d1d0d0",
              borderColor: "#ffffff",
              color: "#000000",
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#e7a52a",
                borderColor: "#ffffff",
                color: "#fff",
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {

              props?.setOpenForm(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button

            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "120px !important",
              minWidth: "120px !important",
              maxWidth: "120px !important",
              backgroundColor: "#e7a52a",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              const closeForm = true
              submit(closeForm);
            }}
          >
            {t("GLOBAL.Save_and_close")}
          </Button>
          <Button

            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              backgroundColor: "#e7a52a",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>

      }
    />
  );
}
