
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next"
import unitObject, {
  checkErrors,
  objectMerge,
  resetErrors,
  resetObject,
} from "../../../Errors/Units/Unit";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import unitAPI from "../../../Network/Unit";
import RenderFooter from "./RenderFooter";
import RenderContent from "./RenderContent";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};


export default function FormContainer(props) {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [unit, setUnit] = useState(unitObject);
  const navigate = useNavigate();

  const submit = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {
        

        let checkErrorObject = {
          ...unit
        }
        let hasError = false
        let readyToSendObject = {}
        if (unit?.name?.value  && unit?.name?.value?.trim()?.length>0) {
          let name = unit?.name?.value?.trim()
          readyToSendObject["name"] = name?.replace(/\\|\/|\'/g, "");
        }
        else{
          hasError=true
          checkErrorObject['name']['error']=true
          checkErrorObject['name']['message']=[t('GLOBAL.mandatory_field')]
        }

        if (unit?.plate_number?.value  && unit?.plate_number?.value?.trim()?.length>0) {
          let plate_number = unit?.plate_number?.value?.trim()
          readyToSendObject["plate_number"] = plate_number?.replace(/\\|\/|\'/g, "");
        }
        else{
          hasError=true
          checkErrorObject['plate_number']['error']=true
          checkErrorObject['plate_number']['message']=[t('GLOBAL.mandatory_field')]
        }


        // if (unit?.plate_letter_id?.value?.id !== null  ) {
          readyToSendObject["plate_letter_id"] = unit?.plate_letter_id?.value?.id;
        // }
        if (unit?.plate_province_id?.value?.id !== null  ) {
          readyToSendObject["plate_province_id"] = unit?.plate_province_id?.value?.id;
        }
        if (unit?.plate_type_id?.value?.id !== null  ) {
          readyToSendObject["plate_type_id"] = unit?.plate_type_id?.value?.id;
        }
        if (unit?.monitored_by_id?.value?.id !== null  ) {
          readyToSendObject["monitored_by_id"] = unit?.monitored_by_id?.value?.id;
        }

        if (unit?.driver_name?.value  && unit?.driver_name?.value?.trim()?.length>0) {
          let driver_name = unit?.driver_name?.value?.trim()
          readyToSendObject["driver_name"] = driver_name?.replace(/\\|\/|\'/g, "");
        }
        if (unit?.unit_type_id?.value?.id !== null  ) {
          readyToSendObject["unit_type_id"] = unit?.unit_type_id?.value?.id;
        }

        if (unit?.unit_section_id?.value?.id !== null  ) {
          readyToSendObject["unit_section_id"] = unit?.unit_section_id?.value?.id;
        }
       

        if(hasError)
        {
          setUnit({
            ...checkErrorObject
          })
          return
        }



        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
          id: cookies.get("id"),
        };

        let result = null;
        if (props?.object && props?.object?.id) {
          readyToSendObject["id"] = props?.object?.id;

          result = await unitAPI.editUnit({
            data: readyToSendObject,
            params: params,
            id: props?.object?.id,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        } else {

          result = await unitAPI.addUnit({
            data: readyToSendObject,
            params: params,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        }
        
        if (result?.status && result?.data?.status) {
         
          setLoading(false);

          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));
          clearForm();
          props.loadData();
          props.setOpenForm(false);
        } else {
         
          setLoading(false);

          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {
            
            toast.error(result?.data?.data?.message?.text?result?.data?.data?.message?.text:result?.data?.data?.message)
          }
        }
        setLoading(false);
      } catch (error) {
    
        setLoading(false);
        toast.error(error.message);
      }
    } else {
      
      // navigate('/redirect')
    }
  };

  const clearForm = () => {
    setUnit(unitObject)
  };
  useEffect(() => {
    if (!props?.object) {
      clearForm();

    } else {
     
      setUnit(objectMerge(props?.object))
    }
  }, [props?.openForm, props?.object]);


  return (
    <PopupForm
      open={props.openForm}
      setOpen={props.setOpenForm}
      title={
        props?.object?.id
          ? t("UNITS.update_title")
          : t("UNITS.add_title")
      }
      isFullScreen={false}
      content={
        <RenderContent
          object={unit}
          setObject={(value) => {
            setUnit(value)
          }}
          open={props.openForm}
          setOpen={props.setOpenForm}
          isFullScreen={false}
          loadingPlateTypes={props?.loadingPlateTypes}
          loadingUsers={props?.loadingUsers}
          loadingPlateLetters={props?.loadingPlateLetters}
          loadingPlateProvinces={props?.loadingPlateProvinces}
          plateTypes={props?.plateTypes}
          plateProvinces={props?.plateProvinces}
          plateLetters={props?.plateLetters}
          loadingUnitSections={props?.loadingUnitSections}
          loadingUnitTypes={props?.loadingUnitTypes}
          unitSections={props?.unitSections}
          unitTypes={props?.unitTypes}
          users={props?.users}
        />
      }
      footer={
        <RenderFooter
          open={props.openForm}
          setOpen={props.setOpenForm}
          submit={submit}
          clearForm={clearForm}
        />
      }
    />
  );
}
