import { createSlice } from '@reduxjs/toolkit';


const initialState={
    maintheme:{
      background:'#FAFAFA',
      paperColor:'#ffffff',
      primaryColor:'#707070',
      secondaryColor:'#e7a52a',
      colorblack:'#000000',
      lightblack:'#333333',
      colorWhite:'#ffffff',
      extrBold:'#3D3D3D',
      extraLight:'#B3B8BD',
      normalblack:'#52575D',
      semilight:'#B3B8BD',
      iconColor:'#17681b',
      grid_clear_icon:'#C8D0D6',
      grid_clear_icon_text:'black',
      grid_search_icon:'#ee9d3'
    },
    logintheme:{
       platte_background_color:'#17681b',
       paperColor:'#ffffff',
       primaryColor:'#707070',
       secondaryColor:'#e7a52a',
       colorblack:'#000000',
       lightblack:'#333333',
       colorWhite:'#ffffff',
       iconsColor:'#17681b'
    },
    sidemenutheme:{
      paperColor:'#ffffff',
      primaryColor:'#707070',
      secondaryColor:'#17681b',
      backgroundColor:'#FAFAFA',
      colorblack:'#000000',
      colorWhite:'#ffffff',
      lightblack:'#333333',
      iconColor:'#C8D0D6 ',
      itmeshover:'#17681b29 ',
      textitemcolor:'#808080',
      dropdwoncolor:'rgb(200,208,214)'

    },
    dashboardtheme:{
      background:'#17681b',
      paperColor:'#ffffff',
      primaryColor:'#707070',
      secondaryColor:'#51D9F0',
      colorblack:'#000000',
      lightblack:'#333333',
      colorWhite:'#ffffff',
      extrBold:'#3D3D3D',
      extraLight:'#B3B8BD',
      normalblack:'#52575D',
      semilight:'#B3B8BD',
      axisLableColor:'#43425D',
      chartOneSeriesOne:'#A4A1FB',
      chartOneSeriesTwo:'#56D9FE',
      chartOneSeriesThree:'#5FE3A1',

    },
    gridtheme:{
      gridHeader:'#17681b',
      paperColor:'#ffffff',
      primaryColor:'#707070',
      secondaryColor:'#51D9F0',
      colorblack:'#000000',
      lightblack:'#333333',
      colorWhite:'#ffffff',
      extrBold:'#3D3D3D',
      extraLight:'#B3B8BD',
      normalblack:'#52575D',
      semilight:'#B3B8BD',
      tooltip_create:'#17681b',
      tooltip_create_text:'#fff',
      tooltip_export:'green',
      tooltip_export_text:'#fff',
      tooltip_import:'#02ab2f',
      tooltip_import_text:'#fff',
      tooltip_map:'#fcc705',
      tooltip_map_text:'#fff',
      tooltip_telegram:'#32affc',
      tooltip_telegram_text:'#fff'

    },
    appbar:{
      background:'#17681b',
      paperColor:'#ffffff',
      primaryColor:'#707070',
      secondaryColor:'#e7a52a',
      colorblack:'#000000',
      lightblack:'#333333',
      colorWhite:'#ffffff',
      extrBold:'#3D3D3D',
      extraLight:'#B3B8BD',
      normalblack:'#52575D',
      semilight:'#B3B8BD'
    },
}
export const themesSlice = createSlice({
  name: 'themeData',
  initialState:initialState,
  reducers: {
    setlogintheme:(state,action)=>{
      state.logintheme=action.payload
    },
    setSidemenutheme:(state,action)=>{
      state.sidemenutheme=action.payload
    },
    setMaintheme:(state,action)=>{
      state.maintheme=action.payload
    },
    setDashboardtheme:(state,action)=>{
      state.dashboardtheme=action.payload
    },
    setAppBarTheme:(state,action)=>{
      state.appbar=action.payload
    }

  
  }
});

export const {
  setlogintheme,
  setSidemenutheme,
  setAppBarTheme
} = themesSlice.actions;



export default themesSlice.reducer;
