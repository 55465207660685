import React, { useState, useEffect } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DropDownGrid from "../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Typography
} from "@mui/material";
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import mainUnitApi from "../../Network/MainUnit";
import FormContainer from "./Form/FormContainer";
import Title from "./Title";
import { selectRoles } from "../../reduxStore/RolesReducer";
import plateLetterAPI from "../../Network/PlateLetter";
import plateTypeAPI from "../../Network/PlateType";
import plateProvinceAPI from "../../Network/PlateProvince";
import userAPI from "../../Network/User";
import ImportFormContainer from "./ImportForm/ImportFormContainer";

const cookies = new Cookies();

export default function MainUnits() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const [openImportForm, setOpenImportForm] = useState(false)

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);

  const [plateTypes, setPlateTypes] = useState([])
  const [loadingPlateTypes, setLoadingPlateTypes] = useState(true)

  const [plateProvinces, setPlateProvinces] = useState([])
  const [loadingPlateProvinces, setLoadingPlateProvinces] = useState(true)

  const [plateLetters, setPlateLetters] = useState([])
  const [loadingPlateLetters, setLoadingPlateLetters] = useState(true)

  const [users, setUsers] = useState([])
  const [loadingUsers, setLoadingUsers] = useState(true)

  const [filters, setFilters] = useState({
    name: "",
    code: "",
    type_id: null,
    wialon_id: null,
    export_excel: false
  })
  const [reload, setReload] = useState(false)

  const [openForm, setOpenForm] = useState(false);
  const [selectedObject, setselectedObject] = useState(null);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const rolesRedux = useSelector(selectRoles)

  const columns = [
    { field: "index_column", headerName: "ت", minWidth: 50, maxWidth: 60 },
    { field: "id", headerName: "#", minWidth: 50, maxWidth: 60 },
    { field: "name", headerName: t("UNITS.name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "main_account_name", headerName: t("UNITS.account_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "tag_name", headerName: t("UNITS.tag_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "plate_number", headerName: t("UNITS.plate_number"), minWidth: 110, maxWidth: 150, flex: 1 },
    { field: "plate_letter_name", headerName: t("UNITS.plate_letter"), minWidth: 110, maxWidth: 150, flex: 1 },
    { field: "plate_province_name", headerName: t("UNITS.plate_province"), minWidth: 110, maxWidth: 150, flex: 1 },
    { field: "plate_type_name", headerName: t("UNITS.plate_type"), minWidth: 110, maxWidth: 150, flex: 1 },
    {
      field: "is_subscribed", headerName: t("UNITS.is_subscribed"), minWidth: 110, maxWidth: 150, flex: 1,
      renderCell: (rows) => (
        <>
          {rows?.row?.is_subscribed ? (
            <MenuItem
              onClick={() => {

              }}
              className="ActionIcons"
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px 7px 0 0",
                }}
              >
                <CheckCircleIcon style={{ fill: "#388e3c" }} />
              </ListItemIcon>

            </MenuItem>
          ) : <MenuItem
            onClick={() => {

            }}
            className="ActionIcons"
          >
            <ListItemIcon
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "0px 7px 0 0",
              }}
            >
              <DoDisturbOnIcon style={{ fill: "#d32f2f" }} />
            </ListItemIcon>


          </MenuItem>}
        </>
      )
    },
    {
      headerName: t("UNITS.actions"),
      field: "Actions",
      flex: 0.4,
      minWidth: 100,
      renderHeader: () => (
        <Typography>
          {rolesRedux?.update_unit?.value ? t("UNITS.actions") : null}
        </Typography>
      ),
      renderCell: (rows) => (
        rolesRedux?.update_unit?.value ?
          <DropDownGrid>
            <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
              <ListItemIcon sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px 7px 0 0'
              }}>
                <EditIcon style={{ fill: "#17681b" }} />
              </ListItemIcon>
              {t('UNITS.update_title')}
            </MenuItem>


          </DropDownGrid> : null
      ),
    },
  ]

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }

  const getResources = () => {



    plateLetterAPI.getPlateLetterList({
      params: {
        ...headerObject,
      }

    }).then(plateLettersResult => {
      if (plateLettersResult.status && plateLettersResult?.data?.data) {
        setPlateLetters(plateLettersResult?.data?.data)
      }
      setLoadingPlateLetters(false);
    }).catch(error => {
      console.log(error);
      setLoadingPlateLetters(false);
    }).finally(() => {
      setLoadingPlateLetters(false);
    })

    plateTypeAPI.getPlateTypeList({
      params: {
        ...headerObject,
      }

    }).then(plateTypesResult => {
      if (plateTypesResult.status && plateTypesResult?.data?.data) {
        setPlateTypes(plateTypesResult?.data?.data)
      }
      setLoadingPlateTypes(false);
    }).catch(error => {
      console.log(error);
      setLoadingPlateTypes(false);
    }).finally(() => {
      setLoadingPlateTypes(false);
    })

    plateProvinceAPI.getPlateProvinceList({
      params: {
        ...headerObject,
      }

    }).then(plateProvincesResult => {
      if (plateProvincesResult.status && plateProvincesResult?.data?.data) {
        setPlateProvinces(plateProvincesResult?.data?.data)
      }
      setLoadingPlateProvinces(false);
    }).catch(error => {
      console.log(error);
      setLoadingPlateProvinces(false);
    }).finally(() => {
      setLoadingPlateProvinces(false);
    })

    userAPI.getUserList({
      params: {
        ...headerObject,
      }

    }).then(usersResult => {
      if (usersResult.status && usersResult?.data?.data) {
        setUsers(usersResult?.data?.data?.map(user => {

          let formattedName = 'لايوجد'
          if (user?.name && user?.wialon_user) {
            formattedName = user?.name + "(" + user?.wialon_user + ")";
          }
          else if (user?.name) {
            formattedName = user?.name
          }

          return {
            ...user,
            name: formattedName
          }
        }))
      }
      setLoadingUsers(false);
    }).catch(error => {
      console.log(error);
      setLoadingUsers(false);
    }).finally(() => {
      setLoadingUsers(false);
    })


  }

  useEffect(() => {
    getResources()
    return () => {

    }
  }, [])

  useEffect(() => {
    loadData()
  }, [pageNumber, pageSize, reload]);

  const loadData = async (searchParams) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        ...headerObject,
        ...(filters?.export_excel ? {} :
          {
            page: pageNumber,
            page_size: pageSize,
          }),
        search: "",
        ...searchParams,
        ...filters
      };

      setLoading(true);

      const result = await mainUnitApi.mainUnits({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data?.data) {


        if (filters?.export_excel) {
          setFilters({
            ...filters,
            export_excel: false
          })
          downloadExcel(result?.data?.data)
        }
        else {
          if (result?.data?.data?.data?.length > 0) {
            setRows(result?.data?.data?.data);
            setRowsTotal(result?.data?.data?.total_records);
            setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
          }
          else {
            setRows([]);
            setRowsTotal(0);
            setPageCount(0);
          }
        }
      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };


  const refreshFromWialon = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        username: cookies.get("user_name") ? cookies.get("user_name") : null,
        user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
        account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
        account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
        authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
        sid: cookies.get("sid") ? cookies.get("sid") : null,
        baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
        id: cookies.get("id") ? cookies.get("id") : null,

      };

      setLoading(true);

      const result = await mainUnitApi.refreshDataFromWialon({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data) {
        loadData()
      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };


  const createFunction = (type) => {
    if (type == "refresh") {
      refreshFromWialon()
    }
    else {
      setselectedObject(null);
      setOpenForm(true);
    }
  };
  const updateFunction = (obj) => {
    setselectedObject(obj)
    setOpenForm(true)
  }

  const clearFilterFunction = () => {
    setFilters({
      name: "",
      code: "",
      type_id: null,
      wialon_id: null,
      export_excel: false
    })
  }
  const searchFunction = () => {
    setReload(!reload)
    setPageNumber(1)
  };
  const importFunction = () => {
    setOpenImportForm(true)
    console.log("openImportForm", openImportForm ? "true" : "false");
  }
  const exportFunction = () => {

    setFilters({
      ...filters,
      export_excel: true
    })
    setReload(!reload)
  }
  const downloadExcel = async (fileName) => {
    const result = await mainUnitApi?.downloadExcel({
      params: {
        ...headerObject,
        fileName: fileName
      }
    })
    if (result.status && result?.data) {
      var _url = window.URL.createObjectURL(result?.data);
      window.open(_url, "_blank").focus(); // window.open + focus
    }
  }
  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >

      {openImportForm ? (
        <ImportFormContainer
          object={selectedObject}
          openForm={openImportForm}
          setOpenForm={setOpenImportForm}
          loadData={() => { }}
        />
      ) : null}
      {openForm ? (
        <FormContainer
          object={selectedObject}
          openForm={openForm}
          setOpenForm={setOpenForm}
          loadData={loadData}
          loadingPlateLetters={loadingPlateLetters}
          loadingUsers={loadingUsers}
          loadingPlateProvinces={loadingPlateProvinces}
          loadingPlateTypes={loadingPlateTypes}
          plateLetters={plateLetters}
          plateProvinces={plateProvinces}
          plateTypes={plateTypes}
          users={users}
        />
      ) : null}

      <Title
        createFunction={createFunction}
        openFilterColumn={openFilterColumn}
      />
      <Grid
        rows={rows}
        hasExport={rolesRedux?.export_unit?.value}
        exportFunction={exportFunction}

        hasImport={rolesRedux?.add_geofence?.value}
        importFunction={importFunction}

        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={false}
        creatFunction={createFunction}


        filterChilds={
          <Search
            filters={filters}
            setFilters={setFilters}
            loadingPlateLetters={loadingPlateLetters}
            loadingUsers={loadingUsers}
            loadingPlateProvinces={loadingPlateProvinces}
            loadingPlateTypes={loadingPlateTypes}
            plateLetters={plateLetters}
            plateProvinces={plateProvinces}
            plateTypes={plateTypes}
            users={users}
          />
        }
      />

    </Box>
  );
}
