import { Box, Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import './style.css'

import React, { useEffect, useMemo } from "react";
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useState } from "react";
import CustomDataGrid from "./CustomDataGrid";
import CustomeFilter from "../../Pages/Grid/Components/CustomeFilter";
import FilterColumn from "../../Pages/Grid/FilterColumn";

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  position: 'relative !important',
  // top:'120px !important',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  const directions = useSelector(state => state.settingsData.directions)
  const [t, i18n] = useTranslation('common');

  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1, fontFamily: 'Cairo-Medium' }}>
        {
          t('GLOBAL.GRID_NO_DATA')
        }
      </Box>
    </StyledGridOverlay>
  );
}

function CustomNoResultOverlay() {
  const directions = useSelector(state => state.settingsData.directions)
  const [t, i18n] = useTranslation('common');

  return (
    <StyledGridOverlay
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'relative !important',
        // top:'100px !important',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',


        }}
      >
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

          }}
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1, fontFamily: 'Cairo-Medium' }}>
          {
            t('GLOBAL.GRID_NO_RESULT_DATA')
          }
        </Box>
      </Box>
    </StyledGridOverlay>
  );
}




export default function CustomDataGridWithSearch(props) {
  const {
    rows,
    columns,
    pageSize,
    setPageSize,
    handlePageChange,
    rowsTotal,
    pageNumber,
    setPageNumber,
    checkScreenSize,
    loading,
    pageCount,
    creatFunction,
    clearFunction,
    searchFunction,
    hasCreate,
    hasImport,
    hasMap,
    hasExport,
    hasTelegram,
    exportFunction,
    importFunction,
    telegramFunction,
    mapFunction,
    notHaveAdvancSearch,
    hasSelectOption,
    handleCheckBoxChange,
    listOfSelectedRows,
    handleRowCellChange,
    paginationMode,
    filterHasSelectCounterValue,
    filterHasSelectCounter,
    hasCustomeID,
    getRowClassName,
    customHeight,
    customContianerSX,
    fixHeight,
    setColumns,
    hasColumnsHidShow,
    multipleExports,
    viewFunction,
    exportReport,
    createReport,
    showUpdate,
    UpdateFunction,
    auditFunction,
    createRouteFunction,
    setClickedCollapsed,
    setRows,
    showRoutesFunction,
    showStopsFunction

  } = props;
  const directions = useSelector(state => state.settingsData.directions)
  const isopensidemenu = useSelector(state => state.settingsData.isopensidemenu)

  const catchedElement = React.useRef(null)
  const screenwidth = useSelector(state => state.settingsData.screenwidth)

  var customeColumn = [];
  try {
    if (directions == 'rtl') {
      customeColumn = [...columns].reverse();
    }
    else
      customeColumn = columns
  } catch (err) {
    console.log(err?.message)
  }

  useMemo(() => {
    if (rows && rows?.length > 0) {
      catchedElement.current = document.querySelector('.ContainerForGrid .MuiDataGrid-virtualScroller')
      if (catchedElement.current && directions == 'rtl') {
        catchedElement.current.scrollLeft += 1000;
      }
      else if (catchedElement.current)
        catchedElement.current.scrollLeft -= 1000;
    } else {

    }
  }, [isopensidemenu, checkScreenSize, rows])

  const ComponentList = {
    Footer: () => null,
    NoRowsOverlay: CustomNoRowsOverlay,
    NoResultsOverlay: CustomNoResultOverlay,
    Header: ({ children }) => (
      <div style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      }}>{children}</div>
    ),
    // Toolbar: GridToolbar,
  }
  const pageSizes = [5, 10, 20]
  const onPageSizeChanged = (newPageSize) => {
    setPageSize(newPageSize);
  }
  const styledList = {
    height: (rows?.length == 0 ? (customHeight ? customHeight : '440px') : (fixHeight ? fixHeight : 'max-content')),
  }
  const onpageChangehandle = (params) => {
    handlePageChange(params);
  }
  const [cellModesModel, setCellModesModel] = React.useState({});
  const handleCellClick = React.useCallback((params) => {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: params.View }
              }),
              {}
            )
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: params.View } }),
            {}
          ),
          [params.field]: { mode: params.Edit }
        }
      };
    });
  }, []);
  

  const [openFilterColumn, setOpenFilterColumn] = useState(false)
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'start',
          height: 'fit-content',
          padding: '0px',
          paddingTop: '0',
          width: '100%',
          marginTop: '0px',
          ...customContianerSX
        }}>



        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'fit-content',
            padding: '0px 0px 0px 0px',
          }}>
          <div
            style={{
              backgroundColor: "#000",
              boxShadow: " 0px 2px 7px -4px rgb(0 0 0 / 64%)",
              direction: 'ltr',
              width: "100%",
              display: "block",
              flexGrow: 1
            }}
            className="ContainerForGrid"
          >
            <CustomDataGrid
              setRows={setRows}
              viewFunction={viewFunction}
              exportReport={exportReport}
              createReport={createReport}
              showUpdate={showUpdate}
              UpdateFunction={UpdateFunction}
              auditFunction={auditFunction}
              createRouteFunction={createRouteFunction}
              setClickedCollapsed={setClickedCollapsed}
              showRoutesFunction={showRoutesFunction}
              showStopsFunction={showStopsFunction}
              rows={rows}
              getRowClassName={getRowClassName}
              columns={customeColumn}
              rowCount={rowsTotal ? rowsTotal : 0}
              pageSize={pageSize}
              page={pageNumber - 1}
              checkboxSelection={hasSelectOption}
              onPageSizeChange={onPageSizeChanged}
              components={ComponentList}
              autoHeight
              rowsPerPageOptions={pageSizes}
              pagination
              loading={loading}
              scrollbarSize={7}
              GridColDef={false}
              paginationMode={paginationMode ? paginationMode : "server"}
              onPageChange={onpageChangehandle}
              style={styledList}
              virtualScrollName={!rowsTotal ? '& .MuiDataGrid-virtualScrollerContent' : 'adasdasdasdsadas'}
              onSelectionModelChange={handleCheckBoxChange}
              selectionModel={listOfSelectedRows}
              onEditRowsModelChange={handleRowCellChange}
              onCellModesModelChange
              editMode="row"
              getRowHeight={() => screenwidth <= 900 ? "400px" : 'auto'}
              keepNonExistentRowsSelected
              getRowId={hasCustomeID ? (row) => row?.task_id : null}
              disableSelectionOnClick
              id="task_id"
            />
          </div>

          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              '& .MuiPaginationItem-previousNext ': {
                backgroundColor: 'lightgrey !important'
              },
              '& .MuiPaginationItem-previousNext .MuiSvgIcon-root ': {
                color: 'black !important'
              }
            }}
          >
            <Box className="paginationTabsBottom" sx={{ display: 'flex', justifyContent: 'space-between', alignItemd: 'center' }}>
              <Box className="paginationTabsBottom_sub">
                <Box className="d-flex align-items-center"
                  sx={{
                    '& label,& input': {
                      fontFamily: "Cairo-Medium !important",
                      color: 'grey'
                    }
                  }}
                >
                  <label htmlFor="pageNumberSet">الصفحة</label>
                  <input
                    className="pageNumberSet"
                    id="pageNumberSet"
                    min={1}
                    max={pageCount}
                    type="number"
                    value={pageNumber}
                    focused
                    onChange={(e) => {
                      setPageNumber(parseInt(e.target.value));
                    }}
                  />
                </Box>
                <Box className="boxOfPagination1" >
                  <Pagination
                    sx={{
                      // backgroundColor:"#17681b"
                      ".MuiPaginationItem-root": {
                        color: "#17681b"
                      },
                      ".MuiPaginationItem-root.Mui-selected": {
                        color: "#FFFFFF",
                        backgroundColor: "#17681b"
                      }
                    }}
                    //   size={
                    //     checkScreenSize
                    //       ? "small"
                    //       : checkScreenSize == 580
                    //       ? "30px"
                    //       : "large"
                    //   }
                    count={pageCount}
                    page={pageNumber}
                    //   showFirstButton={checkScreenSize ? false : true}
                    //   showLastButton={checkScreenSize ? false : true}
                    onChange={(e, p) => {
                      setPageNumber(p);
                    }}
                  />
                </Box>
              </Box>

              <Box sx={{ flex: 1 }}>
                <Box className="d-flex align-items-center"
                  sx={{
                    '& label,& input': {
                      fontFamily: "Cairo-Medium !important",
                      color: 'grey'
                    }
                  }}
                >
                  <label htmlFor="pageNumberSet">الصفحة</label>
                  <Box sx={{
                    minWidth: 90, "& .MuiInputBase-root": {
                      height: 40, margin: '0 10px'
                    }
                  }}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={pageSize}
                        // label="Age"
                        onChange={(e) => {
                          setPageSize(e.target.value)
                        }}
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                </Box>
              </Box>

            </Box>

          </Box>
        </Box>
        {!notHaveAdvancSearch ?

          <CustomeFilter
            multipleExports={multipleExports}
            exportFunction={exportFunction}
            createFunction={creatFunction}
            importFunction={importFunction}
            mapFunction={mapFunction}
            telegramFunction={telegramFunction}
            hasExport={hasExport}
            hasCreate={hasCreate}
            hasMaps={hasMap}
            hasImport={hasImport}
            hasTelegram={hasTelegram}
            resultLength={rowsTotal}
            searchFunction={searchFunction}
            clearFunction={clearFunction}
            filterHasSelectCounter={filterHasSelectCounter}
            filterHasSelectCounterValue={filterHasSelectCounterValue}
            openFilterColumn={openFilterColumn}
            setOpenFilterColumn={setOpenFilterColumn}
            hasColumnsHidShow={false}
          //    customeHeight={screenWidth<=1100?'460px':"350px"}
          >



            {
              props?.filterChilds
            }

          </CustomeFilter>
          : null}

      </Box>
      {/* {openFilterColumn ? */}
      <FilterColumn
        columns={columns}
        setColumns={typeof setColumns === 'function' ? setColumns : () => { }}
        open={openFilterColumn}
        setOpen={setOpenFilterColumn}
      />
      {/* : null} */}
    </>
  );
}
