import React, { useState, useEffect } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDownGrid from "../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Typography,
  IconButton,
  Alert,
  AlertTitle
} from "@mui/material";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import transactionAPI from "../../Network/Transaction";
import Title from "./Title";
import { selectRoles } from "../../reduxStore/RolesReducer";
import CustomToggleStatus from "../../Components/CustomToggleStatus";
import CustomLargeTextView from "../../Components/CustomLargeTextView";
import DownloadIcon from '@mui/icons-material/Download';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import RouteIcon from '@mui/icons-material/Route';

import moment from "moment";
import branchAPI from "../../Network/Branch";
import CancelIcon from '@mui/icons-material/Cancel';
import FlagIcon from '@mui/icons-material/Flag';
import EditIcon from "@mui/icons-material/Edit";
import { selectTransactionFilter } from "../../reduxStore/SettingsReducer";
import transactionStatusAPI from "../../Network/TransactionStatus";
import VerifiedIcon from '@mui/icons-material/Verified';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import LocationOnIcon from '@mui/icons-material/LocationOn';



import { SiMicrosoftexcel } from 'react-icons/si'
import { BsFilePdfFill } from 'react-icons/bs'
import TransactionStops from "../Transactions/TransactionStops/TransactionStops";
import TransactionRoutes from "../Transactions/TransactionRoutes/TransactionRoutes";
import ViewFormContainer from "../Transactions/ViewForm/ViewFormContainer";

const cookies = new Cookies();

const todayStart = new Date();
const yesterday = new Date(todayStart);
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(0, 0, 0, 0);

const todayEnd = new Date();
todayEnd.setHours(23, 59, 59, 999);
export default function ReportByAuditedPerUser() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const transactionFilters = useSelector(selectTransactionFilter)
  const [branches, setBranches] = useState([])
  const [loadingBranches, setLoadingBranches] = useState(true)

  const [transactionProductTypes, setTransactionProductTypes] = useState([])
  const [loadingTransactionProductTypes, setLoadingTransactionProductTypes] = useState(true)

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);

  const [filters, setFilters] = useState({
    unit: null,
    unit_id: "",
    sourceGeofence: null,
    source_geofence_id: "",
    destinationGeofence: null,
    destination_geofence_id: "",
    route: null,
    route_id: "",
    creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
    creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
    creationDateFrom: yesterday,
    creationDateTo: todayEnd,
    export_excel: false,
    export_pdf: false,
    note: "",
    destination_geofence_name: "",
    source_geofence_name: "",
    all_time: 0
  })
  const [reload, setReload] = useState(false)

  

  const [openForm, setOpenForm] = useState(false);
  const [openViewForm, setOpenViewForm] = useState(false);
  const [selectedObject, setselectedObject] = useState(null);
  const [showStops, setShowStops] = useState(false)
  const [showRoutes, setShowRoutes] = useState(false)

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const rolesRedux = useSelector(selectRoles)

  const [columns, setColumns] = useState([
    { field: "index_column", headerName: "ت", minWidth: 50, maxWidth: 60 },
   
    { field: "audited_by_name", headerName: t("TRANSACTIONS.audited_by_name"), minWidth: 180, maxWidth: 200, flex: 2 },
    // { field: "status_1_name_total", headerName: t("TRANSACTIONS.status_1_name_total"), minWidth: 180, maxWidth: 200, flex: 2 },
    { field: "status_2_name_total", headerName: t("TRANSACTIONS.status_2_name_total"), minWidth: 180, maxWidth: 200, flex: 2 },
    { field: "status_3_name_total", headerName: t("TRANSACTIONS.status_3_name_total"), minWidth: 180, maxWidth: 200, flex: 2 },
    { field: "status_4_name_total", headerName: t("TRANSACTIONS.status_4_name_total"), minWidth: 180,  flex: 2 },
    
  ])

  
  const getResources = () => {


    branchAPI.getBranchList({
      params: {
        ...headerObject,
      }

    }).then(branchesResult => {
      if (branchesResult.status && branchesResult?.data?.data) {
        setBranches(branchesResult?.data?.data)
      }
      setLoadingBranches(false);
    }).catch(error => {
      console.log(error);
      setLoadingBranches(false);
    }).finally(() => {
      setLoadingBranches(false);
    })
  }


  useEffect(() => {
    getResources()
    return () => {

    }
  }, [])
  useEffect(() => {
   
      loadData()
    

  }, [pageNumber, pageSize, reload]);


  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }
  const loadData = async (searchParams) => {

    

    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        ...headerObject,
        ...(filters?.export_excel || filters?.export_pdf ? {} :
          {
            page: pageNumber,
            page_size: pageSize,
          }),

        search: "",
        ...searchParams,
        ...filters,
        
      };



      setLoading(true);

      const result = await transactionAPI.auditedTransactionByUsersReport({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data?.data) {


        if (filters?.export_excel) {
          setFilters({
            ...filters,
            export_excel: false
          })
          downloadExcel(result?.data?.data)
        }
        else if (filters?.export_pdf) {
          setFilters({
            ...filters,
            export_pdf: false
          })
          downloadPDF(result?.data?.data)
        }
        else {
          if (result?.data?.data?.data?.length > 0) {
            let data = []
            
            if(result?.data?.data?.data?.length>1)
            {
              let dataResult = result?.data?.data?.data
              let rowStyleClassNumber = true
              
              data.push({
                ...dataResult[0],
                rowStyleClassNumber:rowStyleClassNumber
              })
              for (let index = 1; index < dataResult?.length; index++) 
              {
                const currentRow = dataResult[index];
                const previousRow = dataResult[index-1];
                if(currentRow?.destination_geofence_id != previousRow?.destination_geofence_id)
                {
                  rowStyleClassNumber = !rowStyleClassNumber
                }
                data.push({
                  ...currentRow,
                  rowStyleClassNumber:rowStyleClassNumber
                })
              }
            }
            else{
              data = result?.data?.data?.data
            }
            console.log(data);
            setRows(data);
            setRowsTotal(result?.data?.data?.total_records);
            setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
          }
          else {
            setRows([]);
            setRowsTotal(0);
            setPageCount(0);
          }
        }
      } else {
        setFilters({
          ...filters,
          export_pdf: false,
          export_excel: false
        })

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };

  const createFunction = () => {
    setselectedObject(null);
    setOpenForm(true);
  };
  


  const downloadPDF = async (data, transaction) => {
    const result = await transactionAPI.downloadPdf({
      params: {
        ...headerObject,
        fileName: data?.result
      }
    })
    setLoading(false)
    if (result.status && result?.data) {
      const url = window.URL.createObjectURL(new Blob([result?.data]));
      const link = document.createElement('a');
      link.href = url;
      let reportName = t('TRANSACTIONS.reportByProductType')
      if (filters?.destinationGeofence?.name) {
        reportName += (" : " + filters?.destinationGeofence?.name)
      }
      link.setAttribute('download', reportName + '.'+data?.type);
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    }

  }
  const exportFunction = () => {

    setFilters({
      ...filters,
      export_excel: true
    })
    setReload(!reload)
  }
  const exportPDF = () => {
    setFilters({
      ...filters,
      export_pdf: true
    })
    setReload(!reload)
  }

  const clearFilterFunction = () => {
    setFilters({
      name: "",
      code: "",
      transaction_id: "",
      transaction_number: "",
      type_id: null,
      wialon_id: null,
      export_excel: false,
      export_pdf: false,
      creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
      creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
      creationDateFrom: yesterday,
      creationDateTo: todayEnd,
      export_excel: false,
      note: "",
      destination_geofence_name: "",
      source_geofence_name: "",
      all_time: 1
    })
  }
  const searchFunction = () => {
    setReload(!reload)
    setPageNumber(1)
  };

  const downloadExcel = async (fileName) => {
    const result = await transactionAPI?.downloadExcel({
      params: {
        ...headerObject,
        fileName: fileName
      }
    })
    if (result.status && result?.data) {
      var _url = window.URL.createObjectURL(result?.data);
      window.open(_url, "_blank").focus(); // window.open + focus
    }
  }

  const getTitleAndSecondaryTitle = () => {
    let title = t('TRANSACTIONS.reportByAuditedPerUser')
    if (filters?.destinationGeofence?.name) {
      title += (" : " + filters?.destinationGeofence?.name)
    }
   

    let secondaryTitle = ""
    if (!filters?.all_time && filters?.creation_date_from) {
      secondaryTitle = (t('GLOBAL.from') + ":" + filters?.creation_date_from + "  " + t('GLOBAL.to') + ":" + filters?.creation_date_to)
    }
    return {
      title: title,
      secondaryTitle: secondaryTitle
    }
  }

  
  const getRowClassName = (params) => {
    
    
    if(params?.row?.rowStyleClassNumber)
    {
      
      return 'highlighted-gray-row'
    }
    else{
      return null
    }
  }; 
  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >
     

      <Title
        title={getTitleAndSecondaryTitle()?.title}
        secondaryTitle={getTitleAndSecondaryTitle()?.secondaryTitle}
      />
      
      <Grid
        rows={rows}
        multipleExports={rolesRedux?.export_transaction?.value ?
          [
            { icon: <SiMicrosoftexcel color="green" size={"25"} />, name: t('TRANSACTIONS.exportExcel'), key: 'report1', onClick: () => { exportFunction() } },
            { icon: <BsFilePdfFill color="red" size={"25"} />, name: t('TRANSACTIONS.exportPDF'), key: 'report4', onClick: () => { exportPDF() } },
          ] : []
        }
        fixHeight={'calc(100vh - 300px)'}
        getRowClassName={getRowClassName}
        hasExport={false}
        exportFunction={exportFunction}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={false}
        creatFunction={createFunction}

        hasColumnsHidShow={false}
        setColumns={setColumns}

        filterChilds={
          <Search
            filters={filters}
            setFilters={setFilters}
            loadingBranches={loadingBranches}
            branches={branches}
            transactionProductTypes={transactionProductTypes}
            loadingTransactionProductTypes={loadingTransactionProductTypes}
            showBySequence={
              {
                branches:1,
                dateRange:2
              }
            }


          />
        }
      />

    </Box>
  );
}
