
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import masterObject, {
  objectMerge
} from "../../../Errors/Masters/Master";

import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import tagAPI from "../../../Network/Tag";
import RenderFooter from "./RenderFooter";
import RenderContent from "./RenderContent";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};


export default function FormContainer(props) {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [tag, setTag] = useState(masterObject);
  const navigate = useNavigate();

  const submit = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {

        let checkErrorObject = {
          ...tag
        }
        let hasError = false
        let readyToSendObject = {}
        if (tag?.name?.value && tag?.name?.value?.trim()?.length > 0) {
          let name = tag?.name?.value?.trim()
          readyToSendObject["name"] = name?.replace(/\\|\/|\'/g, "");
        }
        else {
          hasError = true
          checkErrorObject['name']['error'] = true
          checkErrorObject['name']['message'] = [t('GLOBAL.mandatory_field')]
        }
        if (tag?.code?.value && tag?.code?.value?.trim()?.length > 0) {
          let code = tag?.code?.value?.trim()
          readyToSendObject["code"] = code?.replace(/\\|\/|\'/g, "");
        }
        else {
          hasError = true
          checkErrorObject['code']['error'] = true
          checkErrorObject['code']['message'] = [t('GLOBAL.mandatory_field')]
        }
      

        let enabled = tag?.enabled?.value ? 1 : 0
        readyToSendObject["enabled"] = enabled;



        if (hasError) {
          setTag({
            ...checkErrorObject
          })
          return
        }



        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
          id: cookies.get("id"),
        };

        let result = null;
        if (props?.object && props?.object?.id) {
          readyToSendObject["id"] = props?.object?.id;

          result = await tagAPI.editTag({
            data: readyToSendObject,
            params: params,
            id: props?.object?.id,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        } else {

          result = await tagAPI.addTag({
            data: readyToSendObject,
            params: params,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        }

        if (result?.status && result?.data?.status) {

          setLoading(false);

          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));
          clearForm();
          props.loadData();
          props.setOpenForm(false);
        } else {

          setLoading(false);

          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text)
          }
        }
        setLoading(false);
      } catch (error) {

        setLoading(false);
        toast.error(error.message);
      }
    } else {

      // navigate('/redirect')
    }
  };

  const clearForm = () => {
    setTag(masterObject)
  };
  useEffect(() => {
    if (!props?.object) {
      clearForm();

    } else {
      
      setTag(objectMerge(props?.object))
    }
  }, [props?.openForm, props?.object]);


  return (
    <PopupForm
      open={props.openForm}
      setOpen={props.setOpenForm}
      title={
        props?.object?.id
          ? t("TAGS.update_title")
          : t("TAGS.add_title")
      }
      isFullScreen={false}
      content={
        <RenderContent
          object={tag}
          setObject={(value) => {
            setTag(value)
          }}
          open={props.openForm}
          setOpen={props.setOpenForm}
          isFullScreen={false}
        />
      }
      footer={
        <RenderFooter
          open={props.openForm}
          setOpen={props.setOpenForm}
          submit={submit}
          clearForm={clearForm}
        />
      }
    />
  );
}
