
import axios from "axios";
import store from '../app/store';
import Cookies from "universal-cookie";
import { setUserToken } from "../reduxStore/UserReducer";
import { setGlobalLoading } from "../reduxStore/SettingsReducer";

const cookies = new Cookies();

const APP_CONSTANTS = {
    API_URL: `${process.env.REACT_APP_API_URL}`,
}


const callAPI = async (parameters) => {

    const storeState = store.getState();

    try {

        store.dispatch(setGlobalLoading(true))

        const result = await axios({
            url: APP_CONSTANTS.API_URL + parameters?.route,
            method: parameters?.method,
            signal: parameters.signal,
            headers: {
                Accept: "application/json",
                'X-localization': storeState?.SettingReducer?.value?.language?.code ? storeState?.SettingReducer?.value?.language?.code : 'ar',
                ...parameters?.headers
            },
            responseType: parameters?.responseType,
            params: parameters?.params,
            data: parameters?.data

        })
        store.dispatch(setGlobalLoading(false))

        if (result && result.status == 200) {
            if (result.data.status === false && result?.data?.data?.message?.code == 1) {
                console.log("result?.data?.data",result?.data?.data);
                cookies.remove("user")
                // cookies.remove("token")
                store.dispatch(setUserToken(null))
                // window.location.href = '/';
                return { fromAPI: true, status: false, data: null, error: true, errorCode: 401, errorMessage:  result?.data?.data?.message?.text };

            }

            if (result && result.data) {
                return { fromAPI: true, status: true, data: result.data, error: false, errorCode: 0, errorMessage: null };
            }
            return { fromAPI: true, status: false, data: null, error: true, errorCode: 1, errorMessage: "" };

        } else if (result.data.status === false) {
            return { fromAPI: true, status: false, data: null, error: true, errorCode: 2, errorMessage: "" };
        }
    } catch (error) {
        store.dispatch(setGlobalLoading(false))

        const responseResult = error.response

        if (responseResult && responseResult.status === 400) {
            return { fromAPI: true, status: false, data: null, error: true, errorCode: 4, errorMessage: responseResult.data.data };
        }
        else if (responseResult && responseResult.status === 401) {
            cookies.remove("user")
            // cookies.remove("token")
            store.dispatch(setUserToken(null))
            return { fromAPI: true, status: false, data: null, error: true, errorCode: 401, errorMessage: responseResult.data.message };

        }
        else if (responseResult && responseResult.status === 500) {

            return { fromAPI: true, status: false, data: null, error: true, errorCode: 500, errorMessage: responseResult.data?.message };
        }
        else {
            return { fromAPI: true, status: false, data: null, error: true, errorCode: 6, errorMessage: "" };

        }
    }

}

export default callAPI;