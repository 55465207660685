import callAPI from '../ApiConfig'

const activities = async (props) => {
    return await callAPI({
        route:"activities/",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            'Content-Type': 'application/json',
            Accept:'application/json',
        }
    })
}

const getUsers = async (props) => {
    return await callAPI({
        route:"users/",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            'Content-Type': 'application/json',
            Accept:'application/json',
        }
    })
}


const getLogs = async (props) => {
    return await callAPI({
        route:"logs/",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            'Content-Type': 'application/json',
            Accept:'application/json',
        }
    })
}

const activitiesAPI={
    activities:activities,
    getUsers: getUsers,
    getLogs: getLogs
}
export default activitiesAPI