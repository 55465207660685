import React, { useState, useContext, useReducer, useEffect } from "react";
import Cookies from "universal-cookie";
import Host from "../src/assets/js/Host";
import { toast } from "react-toastify";
import axios from "axios";
import reducer from "./reducer";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

const cookies = new Cookies();
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkIjoxLCJ1c2VybmFtZSI6IndhZWwubSIsInJvbGVfaWRzIjpbMSwyLDMsNCw1LDYsNyw4LDksMTBdfSwiaWF0IjoxNjgwNjc0MjUwfQ.Wql_L6AAZ4Ub08L2btkgOetT0IwO6hMCQSPa7O4Fyjs";
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar";

const AppContext = React.createContext();

let now = new Date();

const initialState = {};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (csvData && csvData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        fileName + "_" + Math.floor(Date.now() / 1000) + fileExtension
      );
    }
  };
  const exportAttendanceToCSV = (ws_data, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    var workbook = XLSX.utils.book_new();

    var ws = XLSX.utils.aoa_to_sheet(ws_data);

    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      fileName + "_" + Math.floor(Date.now() / 1000) + fileExtension
    );
  };
  return (
    <AppContext.Provider
      value={{
        ...state,
        exportToCSV,
        exportAttendanceToCSV,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
