
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next"
import mainUnitObject, {
  checkErrors,
  objectMerge,
  resetErrors,
  resetObject,
} from "../../../Errors/MainUnits/MainUnit";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import mainUnitApi from "../../../Network/MainUnit";
import RenderFooter from "./RenderFooter";
import RenderContent from "./RenderContent";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};


export default function WialonLinksFormContainer(props) {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [unit, setUnit] = useState({
    wialon_id: {
      value: null,
      error: false,
      message: []
    }
  });
  const navigate = useNavigate();
  const timestampZeroGMTToDatetimePlusThree = (timestamp) => {
    // Assume you have a timestamp in milliseconds
    // const timestamp = 1622898600000;

    // Create a new Date object using the timestamp
    try {

      const timestampInMiliseconds = parseInt(timestamp) * 1000

      const date = new Date(timestampInMiliseconds);
      if (!isNaN(date)) {
        // Add 3 hours to the UTC time
        date.setUTCHours(date.getUTCHours() + 3);
        // Extract the various components of the updated date and time
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // months are zero-based
        const day = date.getUTCDate();
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();

        // Construct the formatted date and time string
        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return formattedDateTime
      }
      else {
        return ""
      }
    } catch (error) {

    }
    return ""

  }
  const submit = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {


        let checkErrorObject = {
          ...unit
        }
        let hasError = false
        let readyToSendObject = {}



        if (unit?.wialon_id?.value?.id !== null) {
          readyToSendObject["wialon_id"] = unit?.wialon_id?.value?.id;
          readyToSendObject["wialon_name"] = unit?.wialon_id?.value?.wialon_name;
          readyToSendObject["is_installed"] = 1
          readyToSendObject["epc"] = unit?.wialon_id?.value?.epc;
          readyToSendObject["update_note"] = ""
          readyToSendObject["main_account_id"] = unit?.wialon_id?.value?.main_account_id;
          readyToSendObject["installation_date"] = timestampZeroGMTToDatetimePlusThree(unit?.wialon_id?.value?.installation_date);

        }
        else {
          checkErrorObject['wialon_id']['error'] = true
          checkErrorObject['wialon_id']['message'] = [t('GLOBAL.mandatory_field')]
          hasError = true
        }


        if (hasError) {
          setUnit({
            ...checkErrorObject
          })
          return
        }



        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
          id: cookies.get("id"),
        };

        let result = null;

        readyToSendObject["id"] = props?.object?.id;

        result = await mainUnitApi.linktMainUnitTemp({
          data: readyToSendObject,
          params: params,
          id: props?.object?.id,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });


        if (result?.status && result?.data?.status) {

          setLoading(false);

          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));
          clearForm();
          props.loadData();
          props.setOpenForm(false);
        } else {

          setLoading(false);

          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }
        setLoading(false);
      } catch (error) {

        setLoading(false);
        toast.error(error.message);
      }
    } else {

      // navigate('/redirect')
    }
  };

  
  const clearForm = () => {
    setUnit({
      wialon_id: {
        value: null,
        error: false,
        message: []
      }
    })
  };
  useEffect(() => {
    clearForm()
  }, [props?.openForm, props?.object]);


  return (
    <PopupForm
      open={props.openForm}
      setOpen={props.setOpenForm}
      title={
        props?.object?.id
          ? t("UNITS.update_title")
          : t("UNITS.add_title")
      }
      isFullScreen={false}
      customWidth="45%"
      customeHeight="45%"
      content={
        <RenderContent
          object={unit}
          title={(props?.object?.name_and_type?props?.object?.name_and_type:"")+" "+(props?.object?.plate_number?props?.object?.plate_number:"")+" "+(props?.object?.plate_letter?props?.object?.plate_letter:"")+" "+(props?.object?.plate_province?props?.object?.plate_province:"")+" "+(props?.object?.company_name?props?.object?.company_name:"")+" "}
          setObject={(value) => {
            setUnit(value)
          }}
          open={props?.openForm}
          setOpen={props?.setOpenForm}
          wialonUnits={props?.wialonUnits}
          isFullScreen={false}
        />
      }
      footer={
        <RenderFooter
          open={props.openForm}
          setOpen={props.setOpenForm}
          submit={submit}
          clearForm={clearForm}
        />
      }
    />
  );
}
