import { Box, Divider, Tooltip, Typography } from '@mui/material'
import React from 'react'
import AddIcon from "@mui/icons-material/Add";
import * as BiIcons from "react-icons/bi";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectRoles } from '../../reduxStore/RolesReducer';

function Title({ createFunction, openFilterColumn }) {
    const [t] = useTranslation("common");
    const rolesRedux = useSelector(selectRoles)
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "stretch",
                    position: "relative",
                }}
            >
                {rolesRedux?.add_user?.value?
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0 10px",
                    }}
                >
                    <Box
                        sx={{
                            marginRight: "10px",
                            backgroundColor: "#17681b",
                            color: "#FFFFFF",
                            width: "45px",
                            height: "45px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={createFunction}
                        id="demo-customized-button"
                        aria-controls={
                            openFilterColumn ? "demo-customized-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openFilterColumn ? "true" : undefined}
                        variant="contained"
                    >

                        <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("USERS.add")}</span>}>
                            <AddIcon
                                sx={{
                                    color: "#fff",
                                    fontSize: "40px",
                                }}
                            />
                        </Tooltip>
                    </Box>

                </Box>:null}

                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            marginLeft: "10px",
                            marginBottom: "10px",
                            fontFamily: "Cairo-Bold",
                            fontSize: "23px",
                            letterSpacing: "-1px",
                        }}
                    >
                        {t("USERS.title_mobile")}
                    </Typography>
                </Box>

                
            </Box>
            <Divider
                sx={{
                    backgroundColor: "#EBF2F7",
                    margin: "30px",
                    marginTop: "10px",
                    width: "100%",
                }}
                style={{
                    height: "3px",
                }}
            />
        </>
    )
}

export default Title