import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import MainUnitCard from './InstallationCheckCard';




export default function InstallationCheckGrid({ mainUnits }) {
    const navigate = useNavigate();
    return (
        <div style={{
            flexGrow: 1,
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
            <Grid container spacing={3} wrap="wrap">
                {mainUnits?.map((mainUnit, index) => {
                    return (
                        <Grid item key={index + "_favourite_page"}>
                            <MainUnitCard
                               
                                mainUnit={mainUnit}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    );
}
