import callAPI from '../ApiConfig'

const unitTypes = async (props) => {


    return await callAPI({
        route: "unit_types/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addUnitType = async (props) => {


    return await callAPI({
        route: "unit_types/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editUnitType = async (props) => {

    return await callAPI({
        route: "unit_types/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteUnitType = async (props) => {


    return await callAPI({
        route: "unit_types/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}



const getUnitTypeList = async (props) => {

    return await callAPI({
        route: "unit_types/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const upload = async (props) => {

    let formdata = new FormData();

    formdata.append("files", props?.data?.file)

    return await callAPI({
        route: "unit_types/import",
        method: "post",
        signal: props?.signal,
        data: formdata,
        params: props?.params,
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            ...props?.headers
        }
    })
}


const downloadExcel = async (props) => {


    return await callAPI({
        route: "unit_types/download",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params
        },
        headers: {

        },
        responseType: "blob"

    })
}
const unitTypeAPI = {
    unitTypes: unitTypes,
    addUnitType: addUnitType,
    editUnitType: editUnitType,
    deleteUnitType: deleteUnitType,
    getUnitTypeList: getUnitTypeList,
    upload: upload,
    downloadExcel: downloadExcel
}
export default unitTypeAPI