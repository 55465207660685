import callAPI from '../ApiConfig'
import $ from "jquery";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const branches = async (props) => {

    return await callAPI({
        route: "branches/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addBranch = async (props) => {


    return await callAPI({
        route: "branches/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editBranch = async (props) => {

    return await callAPI({
        route: "branches/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteBranch = async (props) => {


    return await callAPI({
        route: "branches/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getBranchList = async (props) => {

    return await callAPI({
        route: "branches/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const branchAPI = {
    branches: branches,
    addBranch: addBranch,
    editBranch: editBranch,
    deleteBranch: deleteBranch,
    getBranchList: getBranchList
}
export default branchAPI