import React, { useEffect, useRef, useState } from 'react'
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'
import { Collapse } from 'react-collapse';
import * as RiIcons from 'react-icons/ri'
import { setCurrentlyOpendMenuItem, setIsOpenSideMenu, setcurrentOpenMenuCollapse } from '../reduxStore/SettingsReducer';
import { selectRoles } from '../reduxStore/RolesReducer';


export default function SideMenuItemArray({
  open,
  setOpen,
  pathsList,
  setPathList,
  MainContainerRef,
}) {
  const listRef = useRef(null);
  const location = useLocation();
  const [t] = useTranslation("common");
  const sidemenutheme = useSelector(state => state.themeData.sidemenutheme)
  const dispatch = useDispatch()
  // const [openCollapse,setOpenCollapse]=useState(false)
  const rolesRedux = useSelector(selectRoles)
  const [paths, setPaths] = useState(pathsList?.length ? pathsList : [])
  const isopensidemenu = useSelector(
    (state) => state.settingsData.isopensidemenu
  );
  const currentOpenMenuCollapse = useSelector(
    (state) => state.settingsData.currentOpenMenuCollapse
  );
  const currentlyOpenedMenuItem = useSelector(
    (state) => state.settingsData.currentlyOpenedMenuItem
  );
  useEffect(() => {

    setPaths(pathsList?.length ? pathsList : [])

    return () => {

    }
  }, [pathsList])


  useEffect(() => {

    if (currentOpenMenuCollapse && pathsList?.length) {


      let data = handleOpenOrClose(currentOpenMenuCollapse, pathsList);
      if (data && data?.length > 0) {
        setPathList(data);
      }
    }
  }, [currentOpenMenuCollapse]);
  const openCollapseToggle = (path, event) => {

    dispatch(setIsOpenSideMenu(true));


    if (!isopensidemenu) {


      dispatch(setCurrentlyOpendMenuItem(path));

      if (!path?.openCollapse) {
        dispatch(setcurrentOpenMenuCollapse(path?.id));

      }
    } else dispatch(setcurrentOpenMenuCollapse(path?.id));

    handleClick(event)


  }
  const handleOpenOrClose = (id, dataList) => {
    var arr = [];
    dataList &&
      dataList?.length > 0 &&
      dataList?.map((itm) => {
        if (itm?.subLink?.length > 0) {
          if (itm?.id == id) {
            arr.push({
              ...itm,
              openCollapse: itm?.openCollapse == true ? false : true,
            });
          } else {
            let object = itm;
            let subArr = [];
            itm?.subLink &&
              itm?.subLink?.length > 0 &&
              itm?.subLink?.map((itm2) => {
                if (itm2?.subLink?.length > 0) {
                  if (itm2?.id == id) {
                    subArr.push({
                      ...itm2,
                      openCollapse: itm2?.openCollapse == true ? false : true,
                    });
                  } else {
                    subArr.push(itm2);
                  }
                } else {
                  subArr.push(itm2);
                }
              });
            if (subArr?.length) {
              object = {
                ...itm,
                subLink: subArr,
              };
              arr.push(object);
            }
            //  handleOpenOrClose(id,itm?.subLink,itm?.id)
          }
        } else {
          arr.push(itm);
        }
      });

    dispatch(setcurrentOpenMenuCollapse(null));
    return arr;
  };
  const getCurrentHeightInList = (pathsList, id, pathName) => {
    //  var Hvalue=5,lock=false;
    //  pathsList&&pathsList?.map(item=>{
    //   if(id&&id!=item?.id&&!lock&&!pathName){
    //     if(item?.subLink?.length){
    //       if(item?.openCollapse){
    //       if(item?.subLink?.length>10)
    //         Hvalue=-150
    //       }
    //     }
    //   }else if(pathName&&pathName!='/system/'+item?.to&&!lock&&!id){
    //     if(item?.subLink?.length){
    //       if(item?.openCollapse){
    //       if(item?.subLink?.length>10)
    //         Hvalue=-150
    //       }
    //     }
    //   }else{
    //     lock=true;
    //   }
    //  })
    return 5;
  }
  useEffect(
    () => {
      // Scroll to the selected menu item when it changes

      if (
        currentlyOpenedMenuItem &&
        currentlyOpenedMenuItem?.ref &&
        isopensidemenu &&
        MainContainerRef?.current
      ) {

        let countH = getCurrentHeightInList(pathsList, currentlyOpenedMenuItem?.id, null)
        setTimeout(() => {
          const container =
            MainContainerRef && MainContainerRef?.current
              ? MainContainerRef?.current
              : null;
          const selectedItemElement =
            currentlyOpenedMenuItem &&
              currentlyOpenedMenuItem?.ref &&
              currentlyOpenedMenuItem?.ref?.current
              ? currentlyOpenedMenuItem?.ref?.current
              : null;
          const scrollOffset =
            selectedItemElement && selectedItemElement?.offsetTop
              ? selectedItemElement?.offsetTop
              : null;
          if (container && scrollOffset != null)
            container.scrollTop = scrollOffset - countH;

        }, 50);
      } else if (!currentlyOpenedMenuItem && isopensidemenu) {

        let countH = getCurrentHeightInList(pathsList, null, location.pathname)

        pathsList &&
          pathsList?.map((item) => {
            if (item?.subLink && item?.subLink?.length) {


              item?.subLink?.map((subItem) => {
                if (
                  `/system/${subItem?.to}` == location.pathname &&
                  subItem?.ref
                ) {
                  setTimeout(() => {
                    dispatch(setcurrentOpenMenuCollapse(subItem?.id));
                    dispatch(setCurrentlyOpendMenuItem(subItem));
                    // dispatch(setCopyOfcurrentOpenMenuItem(subItem));
                    const container =
                      MainContainerRef && MainContainerRef?.current
                        ? MainContainerRef?.current
                        : null;
                    const selectedItemElement =
                      subItem && subItem?.ref && subItem?.ref?.current
                        ? subItem?.ref?.current
                        : null;
                    const scrollOffset =
                      selectedItemElement && selectedItemElement?.offsetTop
                        ? selectedItemElement?.offsetTop
                        : null;


                    if (container && scrollOffset != null)
                      container.scrollTop = scrollOffset - countH;
                  }, 50);
                }
              });
            } else {

              let countH = getCurrentHeightInList(pathsList, null, location.pathname)

              if (
                `/system/${item?.to}` == location.pathname &&
                item?.ref
              ) {
                setTimeout(() => {
                  dispatch(setcurrentOpenMenuCollapse(item?.id));
                  dispatch(setCurrentlyOpendMenuItem(item));
                  // dispatch(setCopyOfcurrentOpenMenuItem(item));
                  const container =
                    MainContainerRef && MainContainerRef?.current
                      ? MainContainerRef?.current
                      : null;
                  const selectedItemElement =
                    item && item?.ref && item?.ref?.current
                      ? item?.ref?.current
                      : null;
                  const scrollOffset =
                    selectedItemElement && selectedItemElement?.offsetTop
                      ? selectedItemElement?.offsetTop
                      : null;


                  if (container && scrollOffset != null)
                    container.scrollTop = scrollOffset - countH;
                }, 50);
              }
            }
          });
      }
    },
    !currentlyOpenedMenuItem
      ? [currentlyOpenedMenuItem, isopensidemenu, pathsList]
      : [currentlyOpenedMenuItem, isopensidemenu]
  );

  const openCollapseToggle2 = (obj) => {
    setOpen(true)
    dispatch(setIsOpenSideMenu(true));
    dispatch(setcurrentOpenMenuCollapse(obj?.id));
    dispatch(setCurrentlyOpendMenuItem(obj));
  };
  const handleClick = (event) => {
    if (event && event?.target) {
      const listItem = event?.target
      if (listItem) {
        const offsetTop = listItem?.offsetTop;
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        });
      }
    }
  };
  return (
    <List
      sx={{
        height: "fit-content !important",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: open ? "center" : "center",
        justifyContent: "start",
        transition: "all 0.3s ease-in-out !important",
        paddingLeft: !open ? '13px' : '0',
        // paddingBottom:'150px',
        '& .iconSideMenu': {
          color: `${sidemenutheme?.iconColor} `,

          fontSize: '28px'
        },
        '& .MuiListItemText-root .MuiTypography-root': {
          textAlign: "left",
          font: "normal normal 15px/30px Cairo-Medium !important",
          letterSpacing: "0px",
          color: sidemenutheme?.textitemcolor,
          opacity: "1",
        },
        '& .MuiButtonBase-root': {
          backgroundColor: 'transparent !important',
          padding: '20px 20px !important',
        },
        '& .MuiListItem-root': {
          borderRadius: open ? '0px' : '50%',
          width: open ? '100%' : '40px',
          height: open ? '48px' : '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',

        },
        '& .MuiListItem-root:hover': {
          backgroundColor: `${sidemenutheme.itmeshover} !important`,
        },
        '& .MuiListItem-root:hover .iconSideMenu': {
          color: `${sidemenutheme?.secondaryColor} !important`
        },
        '& .MuiListItem-root:hover .MuiListItemText-root .MuiTypography-root': {
          color: `${sidemenutheme?.secondaryColor} !important`
        },
        '& .active .MuiListItem-root': {
          backgroundColor: `${sidemenutheme.itmeshover} !important`
        },
        '& .active .iconSideMenu': {
          color: `${sidemenutheme?.secondaryColor} !important`,
        },
        '& .active .MuiListItemText-root .MuiTypography-root': {
          color: sidemenutheme?.secondaryColor + ' !important',
        },
        "& .activeDropDown": {
          backgroundColor: `${sidemenutheme?.dropdwoncolor} !important`,
          borderRadius: open ? '0px' : '50%',
          width: open ? '100%' : '40px',
          height: open ? '48px' : '40px',
        },
        "& .activeDropDown .itmdropdown svg,& .activeDropDown .itmdropdown .MuiTypography-root ": {
          color: sidemenutheme?.colorWhite + ' !important',
          cursor: 'pointer !important',
        },
        "& .itmContainerdropdown  .itmdropdown svg": {

          color: sidemenutheme?.secondaryColor + ' !important',
        }
      }}
      ref={listRef}

    >
      {pathsList && pathsList?.length > 0 && pathsList?.filter(item => item?.hasRole(rolesRedux))?.map((obj, index) => (
        <>
          {obj && obj?.subLink?.length === 0 ?
            <NavLink
              to={obj?.to} key={obj?.name + "_" + obj?.id}
              onClick={() => {
                setOpen(false)
                dispatch(setCurrentlyOpendMenuItem(obj));
              }}
              style={{
                width: '100%',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

              }}
              ref={obj?.ref}
            >
              <ListItem disablePadding sx={{ display: 'block', mt: '10px' }} disableRipple>
                <ListItemButton
                  disableRipple
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  {
                    obj?.id === 7 && open ?
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            {obj?.icon && obj?.icon(open)}
                          </ListItemIcon>
                          <ListItemText primary={obj?.name} sx={{ opacity: open ? 1 : 0 }} />
                        </Box>

                      </Box> : <>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                          }}
                        >
                          {obj?.icon && obj?.icon(open)}
                        </ListItemIcon>
                        <ListItemText primary={obj?.name} sx={{ opacity: open ? 1 : 0 }} />
                      </>
                  }
                </ListItemButton>
              </ListItem>
            </NavLink>
            : obj ?
              <Box
                sx={{
                  display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mt: '10px', cursor: 'pointer'
                }}
                className="activeDropDown" onClick={(e) => { openCollapseToggle(obj, e) }}
                ref={obj?.ref}
              >
                <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 6 : 'auto',
                      justifyContent: 'center',
                      position: 'relative',
                      left: open ? '20px' : '6.5px'
                    }}
                    className="itmdropdown"
                  >
                    {obj?.icon && obj?.icon(open)}
                  </ListItemIcon>
                  <ListItemText className='itmdropdown' primary={obj?.name} sx={{ opacity: open ? 1 : 0 }} />
                </Box>
                {
                  !obj?.openCollapse ?
                    <RiIcons.RiArrowDropDownLine style={{ fontSize: '30px', color: '#17681b' }} className='itmdropdown' /> :
                    <RiIcons.RiArrowDropUpLine style={{ fontSize: '30px', color: '#17681b' }} className='itmdropdown' />
                }
              </Box> : null
          }
          {
            obj && obj?.subLink?.length > 0 ?
              <Collapse isOpened={obj?.openCollapse && open} className="activeDropDown">
                {/* <List> */}
                {obj?.subLink?.filter(item => item?.hasRole(rolesRedux))?.map((sub) => (
                  <NavLink
                    to={sub?.to} key={sub?.name + "_" + sub?.id}
                    className='itmContainerdropdown itmdropdown'
                    ref={sub?.ref}
                    onClick={() => openCollapseToggle2(sub)}
                    style={{
                      width: '100%',
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ListItem disablePadding sx={{ display: 'block', mt: '10px' }} disableRipple className='itmContainerdropdown itmdropdown '>
                      <ListItemButton
                        disableRipple
                        sx={{
                          marginLeft: '20px',
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                          }}
                        >
                          {sub?.icon && sub?.icon(open)}
                        </ListItemIcon>
                        <ListItemText primary={sub?.name} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                ))}
              </Collapse> : null
          }
        </>
      ))}
    </List>
  )
}
