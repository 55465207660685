import { Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import Preloading from "./Preload/Preloading";
const cookies = new Cookies();
export default function PageNotFound({ loading }) {
  const [t] = useTranslation("common");
  useEffect(() => {

  }, []);


  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#17681b",
        zIndex: 2147483647,
      }}
    >
      {loading ?
        <Preloading />
        :
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
            height: "auto",
            flexDirection: "column",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontFamily: "Cairo-Medium",
                color: "#fff",
                fontSize: "30px",
              }}
            >
              {t("GLOBAL.pageNotFoundMessage")}
            </span>



          </div>
        </Box>
      }
    </div>
  );
}
