import { Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default function SorryPage() {
  const [t] = useTranslation("common");
  const [value, setValue] = useState(5);
  var CounterDown = null;
  useEffect(() => {
    
  }, []);

 
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#17681b",
        zIndex: 2147483647,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          height: "auto",
          flexDirection: "column",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontFamily: "Cairo-Medium",
              color: "#fff",
              fontSize: "30px",
            }}
          >
            {t("GLOBAL.sorryMessage")}
          </span>
          
          
          
        </div>
      </Box>
    </div>
  );
}
