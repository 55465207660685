import { Autocomplete, Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import SearchInput from '../../Components/SearchInput';
import unitAPI from '../../Network/Unit';
import geofenceAPI from '../../Network/Geofence';
import routeAPI from '../../Network/Route';
import Cookies from "universal-cookie";
import moment from 'moment/moment';
import CustomDateRange from '../../Components/CustomDateRange';
import CustomDateTimeField from '../../Components/CustomDateTimeField';
const cookies = new Cookies();

/**
 * Search function:
 * @param {Object} options - Options object containing various parameters.
 * @param {Object} options.filters - Filters object.
 * @param {Function} options.setFilters - Function to set filters.
 * @param {boolean} options.loadingBranches - Loading state for branches (array).
 * @param {Array} options.branches - data for branches.
 * @param {boolean} options.loadingTransactionStatuses - Loading state for transaction statuses.
 * @param {Array} options.transactionStatuses - data for transaction statuses (array).
 * @param {Object} options.showBySequence - Object containing keys mapped to numeric values.
 * Expected keys and their corresponding numeric values:
 *  - transactionId: 1
 *  - transactionNumber: 2
 *  - destinationGeofenceName: 3
 *  ... and so on for other keys
 * @param {boolean} options.loadingTransactionProductTypes - Loading state for transaction product types.
 * @param {Array} options.transactionProductTypes - Array of transaction product types.
 */
const Search = ({
  filters,
  setFilters,
  loadingBranches,
  branches,
  loadingTransactionStatuses,
  transactionStatuses,
  showBySequence,
  loadingTransactionProductTypes,
  transactionProductTypes,
  loadingAccounts,
  accounts,
  loadingUsers,
  users,
}) => {
  const [t] = useTranslation("common");
  const timerUnits = useRef(null)
  const timerSourceGeofences = useRef(null)
  const timerDestinationGeofences = useRef(null)
  const timerRoutes = useRef(null)

  const [units, setUnits] = useState([])
  const [sourceGeofences, setSourceGeofences] = useState([])
  const [destinationGeofences, setDestinationGeofences] = useState([])
  const [routes, setRoutes] = useState([])

  const [loadingUnits, setLoadingUnits] = useState(false)
  const [loadingSourceGeofences, setLoadingSourceGeofences] = useState(false)
  const [loadingDestinationGeofences, setLoadingDestinationGeofences] = useState(false)
  const [loadingRoutes, setLoadingRoutes] = useState(false)


  const [searchTermUnit, setSearchTermUnit] = useState("");
  const [searchTermSourceGeofence, setSearchTermSourceGeofence] = useState("");
  const [searchTermDestinationGeofence, setSearchTermDestinationGeofence] = useState("");
  const [searchTermRoute, setSearchTermRoute] = useState("");

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }

  const handleSearchTermUnitChange = (newValue) => {


    setSearchTermUnit(newValue);
    if (timerUnits.current) {
      clearTimeout(timerUnits?.current)
    }
    timerUnits.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingUnits(true);

        const result = await unitAPI.getUnitList({
          params: {
            ...headerObject,
            name: newValue,
            page_size: 20,
            page: 1
          }

        })
        setLoadingUnits(false);

        if (result.status && result?.data?.data?.data) {
          setUnits(result?.data?.data?.data)
        }
      }
      else {
        setUnits([])
      }
    }, 1000)
  };

  const handleSearchTermSourceGeofenceChange = (newValue) => {

    setSearchTermSourceGeofence(newValue);
    if (timerSourceGeofences.current) {
      clearTimeout(timerSourceGeofences?.current)
    }
    timerSourceGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingSourceGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [1, 3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingSourceGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setSourceGeofences(result?.data?.data?.data)
        }
      }
      else {
        setSourceGeofences([])
      }
    }, 1000)
  };
  const handleSearchTermDestinationGeofenceChange = (newValue) => {

    setSearchTermDestinationGeofence(newValue);
    if (timerDestinationGeofences.current) {
      clearTimeout(timerDestinationGeofences?.current)
    }
    timerDestinationGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingDestinationGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [2, 3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingDestinationGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setDestinationGeofences(result?.data?.data?.data)
        }
      }
      else {
        setDestinationGeofences([])
      }
    }, 1000)
  };


  const getFilteredSearchFields = () => {
    let searchFields = {
      transactionId: <SearchInput
        value={filters?.transaction_id}
        setValue={(value) => {
          if (!isNaN(value)) {
            setFilters({
              ...filters,
              transaction_id: value
            })
          }

        }}
        title={"#"}
      />,
      transactionNumber: <SearchInput
        value={filters?.transaction_number}
        setValue={(value) => {
          if (!isNaN(value)) {
            setFilters({
              ...filters,
              transaction_number: value
            })
          }

        }}
        title={t('TRANSACTIONS.transaction_number')}
      />,
      note: <SearchInput
        value={filters?.note}
        setValue={(value) => {
          setFilters({
            ...filters,
            note: value
          })
        }}
        title={t('TRANSACTIONS.search_in_notes')}
      />,
      destinationGeofenceName: <SearchInput
        value={filters?.destination_warehouse}
        setValue={(value) => {
          setFilters({
            ...filters,
            destination_warehouse: value
          })
        }}
        title={t('TRANSACTIONS.search_destination_geofence_name')}
      />,
      transactionStatuses: <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.transaction_status_selected ? filters?.transaction_status_selected : []}
        options={transactionStatuses}
        multiple={true}
        loading={loadingTransactionStatuses}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            transaction_status_ids: v?.length > 0 ? v?.map(transaction_status => transaction_status?.id) : [],
            transaction_status_selected: v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.transaction_statuses')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />,
      unit: <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        onInputChange={(event, newInputValue) => {

          handleSearchTermUnitChange(newInputValue);
        }}
        inputValue={searchTermUnit}
        loading={loadingUnits}
        loadingText={t('GLOBAL.searching')}
        value={filters?.unit}
        options={units}
        getOptionLabel={(option) => {

          return option && option?.name || ""
        }}
        onChange={(e, v, r) => {

          setFilters({
            ...filters,
            unit: v,
            unit_id: v ? v?.id : ""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.unit')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />,
      branches: <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.branches_selected ? filters?.branches_selected : []}
        options={branches}
        multiple={true}
        loading={loadingBranches}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            branch_ids: v?.length > 0 ? v?.map(branch => branch?.id) : [],
            branches_selected: v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.branches_general')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />,
      sourceGeofence: <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        onInputChange={(event, newInputValue) => {

          handleSearchTermSourceGeofenceChange(newInputValue);
        }}
        inputValue={searchTermSourceGeofence}
        loading={loadingSourceGeofences}
        loadingText={t('GLOBAL.searching')}
        value={filters?.sourceGeofence}
        options={sourceGeofences}
        getOptionLabel={(option) => {

          return option && option?.name || ""
        }}
        onChange={(e, v, r) => {

          setFilters({
            ...filters,
            sourceGeofence: v,
            source_geofence_id: v ? v?.id : ""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.source_geofence')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />,
      destinationGeofence: <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        onInputChange={(event, newInputValue) => {

          handleSearchTermDestinationGeofenceChange(newInputValue);
        }}
        inputValue={searchTermDestinationGeofence}
        loading={loadingDestinationGeofences}
        loadingText={t('GLOBAL.searching')}
        value={filters?.destinationGeofence}
        options={destinationGeofences}

        getOptionLabel={(option) => {

          return option && option?.name || ""
        }}



        onChange={(e, v, r) => {

          setFilters({
            ...filters,
            destinationGeofence: v,
            destination_geofence_id: v ? v?.id : ""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.destination_geofence')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />,
      dateRange: <CustomDateRange
        dateRange={filters}
        setDateRange={(value) => {
          setFilters({
            ...filters,
            ...value
          })
        }}
      />,
      report_date: <CustomDateTimeField
        label={t('TRANSACTIONS.report_date')}
        value={filters?.report_date?.value}
        haswidth={true}
        readOnly={false}
        minDate={new Date()}
        maxDate={null}
        onChange={(e) => {
          setFilters({
            ...filters,
            report_date: e
          })

        }}
        onClearClick={() => {
          setFilters({
            ...filters,
            report_date: null
          })

        }}
      />,
      isMonitored: <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TRANSACTIONS.is_monitored')}
          </Typography>

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.is_monitored}
            onChange={(e) => {
              setFilters({
                ...filters,
                is_monitored: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.monitored")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.not_monitored")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>,
      hasViolation: <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TRANSACTIONS.has_violation')}
          </Typography>

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.has_violation}
            onChange={(e) => {
              setFilters({
                ...filters,
                has_violation: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.has_violation")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.doesnt_has_violation")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>,
      isComplete: <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TRANSACTIONS.is_complete')}
          </Typography>

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.is_complete}
            onChange={(e) => {
              setFilters({
                ...filters,
                is_complete: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.is_complete")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.not_complete")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>,
      exceededAllowedTime: <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TRANSACTIONS.exceeded_allowed_time')}
          </Typography>

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.exceeded_allowed_time}
            onChange={(e) => {
              setFilters({
                ...filters,
                exceeded_allowed_time: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.exceeded_allowed_time")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.not_exceeded_allowed_time")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>,
      hasSTopViolation: <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TRANSACTIONS.has_stop_violation')}
          </Typography>

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.has_stop_violation}
            onChange={(e) => {
              setFilters({
                ...filters,
                has_stop_violation: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.has_stop_violation")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.doesnt_have_stop_violation")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>,
      isValid: <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TRANSACTIONS.valid')}
          </Typography>

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.valid}
            onChange={(e) => {
              setFilters({
                ...filters,
                valid: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.valid")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.not_valid")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>,
      productType: <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}

        loading={loadingTransactionProductTypes}
        loadingText={t('GLOBAL.searching')}
        value={filters?.productType}
        options={transactionProductTypes}
        getOptionLabel={(option) => {

          return option && option?.name || ""
        }}
        onChange={(e, v, r) => {

          setFilters({
            ...filters,
            productType: v,
            product_type_id: v ? v?.id : ""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.product_type')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />,
      users: <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.users_selected ? filters?.users_selected : []}
        options={users}
        multiple={true}
        loading={loadingUsers}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            user_ids: v?.length > 0 ? v?.map(user => user?.id) : [],
            users_selected: v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.users')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />,
      accounts: <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.accounts_selected ? filters?.accounts_selected : []}
        options={accounts}
        multiple={true}
        loading={loadingAccounts}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            account_ids: v?.length > 0 ? v?.map(user => user?.id) : [],
            accounts_selected: v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.accounts')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />,
      receipt_number: <SearchInput
        value={filters?.receipt_number}
        setValue={(value) => {
          if (!isNaN(value)) {
            setFilters({
              ...filters,
              receipt_number: value
            })
          }

        }}
        title={t('UNITS.receipt_number')}
      />,
      company_name: <SearchInput
        value={filters?.company_name}
        setValue={(value) => {

          setFilters({
            ...filters,
            company_name: value
          })


        }}
        title={t('UNITS.company_name')}
      />,
    }


    const sortedKeys = Object.keys(searchFields)
      .filter(key => showBySequence.hasOwnProperty(key))
      .sort((a, b) => showBySequence[a] - showBySequence[b]);

    // Create a new object using sorted keys
    const filteredSearchFields = sortedKeys.reduce((acc, key) => {
      acc[key] = searchFields[key];
      return acc;
    }, {});

    const filteredSearchFieldsArray = Object.values(filteredSearchFields);

    return filteredSearchFieldsArray

  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      {
        // getSearchFields()
        getFilteredSearchFields()
      }
    </Box>
  );
};

export default Search