import callAPI from '../ApiConfig'

const users = async (props) => {


    return await callAPI({
        route: "users/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addUser = async (props) => {


    return await callAPI({
        route: "users/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editUser = async (props) => {

    return await callAPI({
        route: "users/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteUser = async (props) => {


    return await callAPI({
        route: "users/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getAccountList = async (props) => {

    return await callAPI({
        route: "users/accounts",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const getUserList = async (props) => {

    return await callAPI({
        route: "users/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}
const getUserRoles = async (props) => {

    return await callAPI({
        route: "users/roles",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}


const updateRoles = async (props) => {

    return await callAPI({
        route: "users/roles/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {

        }

    })

}

const updateUnits = async (props) => {

    return await callAPI({
        route: "users/units/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {

        }

    })

}

const updateDestinationGeofences = async (props) => {

    return await callAPI({
        route: "users/destination_geofences/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {

        }

    })

}

const login = async (props) => {


    return await callAPI({
        route: "login/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}

const updateGeofenceGroups = async (props) => {

    return await callAPI({
        route: "users/geofence_groups/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {

        }

    })

}
const userAPI = {
    users: users,
    addUser: addUser,
    editUser: editUser,
    deleteUser: deleteUser,
    getUserList: getUserList,
    getUserRoles: getUserRoles,
    updateRoles: updateRoles,
    updateUnits: updateUnits,
    getAccountList: getAccountList,
    updateDestinationGeofences: updateDestinationGeofences,
    login: login,
    updateGeofenceGroups: updateGeofenceGroups
}
export default userAPI