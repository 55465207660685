import { Box, Tooltip } from '@mui/material'
import React from 'react'


function CustomMultiStatusIcon({ multiStatusArray }) {
    
    const renderMultiStatus = () => {
        return multiStatusArray?.length && multiStatusArray?.map(statusItem => {
            if (statusItem?.statusIsTrue) {
                return (
                    <Tooltip
                        title={
                            <span style={{ fontFamily: "Cairo-Medium" }}>
                                {statusItem?.label}
                            </span>
                        }
                    >
                        {statusItem?.icon}
                    </Tooltip>
                )
            }
        })
    }
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width:'24px'
            }}
        >
            {renderMultiStatus()}


        </Box >
    )
}

export default CustomMultiStatusIcon