import {
  Box,
  Button,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Grid from '../../Grid/Grid'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import Search from "./Search";
import userObject from "../../../Errors/Users/User";
import userAPI from "../../../Network/User";
import unitAPI from "../../../Network/Unit";
import PopupForm from "../../../Components/PopupForm";
import Cookies from "universal-cookie";
import CustomTextField from "../../../Components/CustomTextField";
import CustomeSelectField from "../../../Components/CustomeSelectField";
const cookies = new Cookies();





const RenderContent = (props) => {
  const [t] = useTranslation("common");

  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [columns, setColumns] = useState([
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
    },
    { headerName: t("USERS.unit_id"), field: "id", flex: 0.5 },
    { headerName: t("USERS.unit_name"), field: "name", flex: 2 },
    { headerName: t("USERS.wialon_group_name"), field: "wialon_group_name", flex: 2 },
    { headerName: t("USERS.account_name"), field: "account_name", flex: 2 }

  ]);
  const handleCheckBoxChange = useCallback((rows) => {


    props?.setListOfSelectedRows(rows);
    let arr = [];
    props?.units &&
      props?.units?.length &&
      props?.units?.map((itm) => {
        if (rows?.find((e) => e == itm?.id)) {
          arr.push(itm);
        }
      });

    props?.setUnit(arr);
  }, []);

  return (
    <Box className="Container-fluid">
      <Box className="row d-flex justify-content-center align-items-start">
        <Box className="col-12 col-sm-12 col-md-12 my-3 px-0"
          sx={{
            '& .MuiCheckbox-root': {
              color: '#1e6a99 !important',
              fill: '#1e6a99 !important'

            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CustomTextField
              label={t("UNITS.search_by_name_or_id_or_account")}

              value={props?.unitName}

              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setUnitName(e?.target?.value)

                props?.handleSearchInAdvancedSearch(e?.target?.value, props?.unitGroup)



              }}
              onClearClick={() => {
                props?.setUnitName("")
                props?.handleSearchInAdvancedSearch(null, props?.unitGroup)

              }}
            />
            <CustomeSelectField
              label={t("USERS.wialon_group_name")}
              haswidth={true}
              value={props?.unitGroup}
              list={props?.unitGroups?.length ? props?.unitGroups : []}
              error={false}
              message={""}
              readOnly={false}
              onChange={(e, newValue) => {
                props?.setUnitGroup(newValue)
                props?.handleSearchInAdvancedSearch(props?.unitName, newValue)
              }}
              onClearClick={() => {
                props?.setUnitGroup(null)
                props?.handleSearchInAdvancedSearch(props?.unitName, null)
              }}
            />
          </Box>

          <Grid
            rows={props?.units}
            columns={columns}
            setColumns={setColumns}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            loading={loading}
            handlePageChange={handlePageChange}
            rowsTotal={props?.units?.length}
            checkScreenSize={screenwidth}
            pageCount={Math.ceil(props?.units?.length / pageSize)}
            clearFunction={props?.handleClearInAddvancSearch}
            searchFunction={props?.handleSearchInAdvancedSearch}
            filterChilds={
              <Search
                setUnitName={props?.setUnitName}

                unitName={props?.unitName}

                setCheckAllselected={props?.setCheckAllselected}
                checkAllselected={props?.checkAllselected}
                setCheckAllNotSelected={props?.setCheckAllNotSelected}
                checkAllNotSelected={props?.checkAllNotSelected}
              />
            }
            filterHasSelectCounter={true}
            filterHasSelectCounterValue={props?.listOfSelectedRows?.length}
            notHaveAdvancSearch={true}
            hasSelectOption={true}
            handleCheckBoxChange={handleCheckBoxChange}
            listOfSelectedRows={props?.listOfSelectedRows}
            // handleRowCellChange={handleRowCellChange}
            paginationMode={"client"}
          />
        </Box>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props?.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              backgroundColor: "#e7a52a",
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props?.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function UserUnits(props) {
  const [t] = useTranslation("common");
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorsObject] = useState(userObject);
  const dispatch = useDispatch();
  const [units, setUnits] = useState([])
  const [listOfSelectedRows, setListOfSelectedRows] = useState([])
  const [filterdUnits, setFilterdUnits] = useState([])
  const [unitName, setUnitName] = useState()
  const [unitGroup, setUnitGroup] = useState(null)
  const [unitGroups, setUnitGroups] = useState([])

  const [checkAllselected, setCheckAllselected] = useState(false)
  const [checkAllNotSelected, setCheckAllNotSelected] = useState(false)
  const [isUploadingTime, setIsUploadingTime] = useState(false)
  const [unit, setUnit] = useState('')

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,

  };

  const submit = async () => {
    if (!props?.object || !props?.object?.id) {
      toast.warn('USERS.please_select_a_user')
      return
    }

    try {
      let result = null;
      setLoading(true)
      if (props?.object && props?.object?.id) {
        result = await userAPI.updateUnits({
          id: props?.object?.id,
          data: {
            unit_ids: listOfSelectedRows
          },
          params: {
            ...headerObject
          }
        });
      }

      if (result.status && result?.data?.status && result?.data?.data?.message) {
        toast.success(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        clearForm()
        props?.loadData()
        props?.setOpenForm(false)
      } else {


        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }

      }


      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }

  }

  const clearForm = () => {
    setListOfSelectedRows([])
  };

  useEffect(() => {
    if (!props?.object) clearForm();
    loadUnitsData()
    loadUnitsGroups()

    getUserUnits(props?.object)
  }, [props?.openForm, props?.object]);

  const loadUnitsData = async () => {
    let data = null;
    try {
      data = await unitAPI.getUnitList({
        params: {
          ...headerObject,
          monitored_by_id_or_null: props?.object?.id,
          carrier: 1

        }
      })
      if (data && data?.status) {
        setUnits(data?.data?.data)


      } else {
        toast.warn(t('NETWORK_MESSAGES.messageError'))
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const loadUnitsGroups = async () => {
    let data = null;
    try {
      data = await unitAPI.groups({
        params: {
          ...headerObject,
        }
      })
      if (data && data?.status && data?.data?.data?.length) {
        setUnitGroups(data?.data?.data?.map(item => {
          return {
            ...item,
            name: item?.nm
          }
        }))

      } else {
        toast.warn(t('NETWORK_MESSAGES.messageError'))
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const handleSearchInAdvancedSearch = (unitNameIdOrAccountName, unitGroupSelected) => {
    let arr = []

    if (unitNameIdOrAccountName && unitGroupSelected) {
      units && units?.length && units?.map((itm) => {
        if (
          (itm?.wialon_id && unitGroupSelected && unitGroupSelected?.u?.includes(itm?.wialon_id))
          &&
          (
            (itm?.name && itm?.name?.toLowerCase().includes(unitNameIdOrAccountName && unitNameIdOrAccountName?.toLowerCase()))
            ||
            (itm?.account_name && itm?.account_name?.toLowerCase().includes(unitNameIdOrAccountName && unitNameIdOrAccountName?.toLowerCase()))
            ||
            (itm?.id && itm?.id?.toString().includes(unitNameIdOrAccountName && unitNameIdOrAccountName?.toLowerCase()))
          )
        ) {
          
          
            itm = {
              ...itm,
              wialon_group_name: unitGroupSelected?.nm?unitGroupSelected?.nm:""
            }
          
          
          arr.push(itm)
        }
      })
      setFilterdUnits(arr)
    } 
    else if (unitGroupSelected) {
      units && units?.length && units?.map((itm) => {
        if (
          (itm?.wialon_id && unitGroupSelected && unitGroupSelected?.u?.includes(itm?.wialon_id))
         
        ) {
          
          
            itm = {
              ...itm,
              wialon_group_name: unitGroupSelected?.nm?unitGroupSelected?.nm:""
            }
          
          
          arr.push(itm)
        }
      })
      setFilterdUnits(arr)
    } 
    else if (unitNameIdOrAccountName ) {
      units && units?.length && units?.map((itm) => {
        if (
          
          (
            (itm?.name && itm?.name?.toLowerCase().includes(unitNameIdOrAccountName && unitNameIdOrAccountName?.toLowerCase()))
            ||
            (itm?.account_name && itm?.account_name?.toLowerCase().includes(unitNameIdOrAccountName && unitNameIdOrAccountName?.toLowerCase()))
            ||
            (itm?.id && itm?.id?.toString().includes(unitNameIdOrAccountName && unitNameIdOrAccountName?.toLowerCase()))
          )
        ) {
          
          
            itm = {
              ...itm,
              wialon_group_name: unitGroupSelected?.nm?unitGroupSelected?.nm:""
            }
          
          
          arr.push(itm)
        }
      })
      setFilterdUnits(arr)
    } 
    else {
      arr = [...units];
      setFilterdUnits(units)
    }


    if (checkAllselected) {
      setFilterdUnits(arr.filter(itm => listOfSelectedRows?.includes(itm?.id)))
    }
    if (checkAllNotSelected) {
      setFilterdUnits(arr.filter(itm => !listOfSelectedRows?.includes(itm?.id)))
    }

  }
  const handleClearInAddvancSearch = () => {

    setUnitName('')
    setFilterdUnits(units)
    setCheckAllselected(false)
  }

  useEffect(() => {
    if (units) {
      setFilterdUnits(units)
    }
  }, [units])


  const getUserUnits = async (user) => {

    if (user?.units?.length) {

      setListOfSelectedRows(user?.units)//get only ids 
    } else {
      setListOfSelectedRows([])
    }

  }

  return (
    <PopupForm
      isFullScreen={false}
      open={props?.openForm}
      setOpen={props?.setOpenForm}
      title={t("USERS.monitor_units")}
      content={
        <RenderContent
          setUnits={setUnits}
          units={filterdUnits}
          unitGroup={unitGroup}
          setUnitGroup={setUnitGroup}
          unitGroups={unitGroups}
          unit={unit}
          setUnit={setUnit}
          allUnits={units}

          unitName={unitName}
          setUnitName={setUnitName}
          handleSearchInAdvancedSearch={handleSearchInAdvancedSearch}
          handleClearInAddvancSearch={handleClearInAddvancSearch}
          setListOfSelectedRows={setListOfSelectedRows}
          listOfSelectedRows={listOfSelectedRows}
          checkAllselected={checkAllselected}
          setCheckAllselected={setCheckAllselected}
          setCheckAllNotSelected={setCheckAllNotSelected}
          checkAllNotSelected={checkAllNotSelected}
          open={props?.openForm} setOpen={props?.setOpenForm}
        />
      }
      footer={
        <RenderFooter
          open={props?.openForm}
          setOpen={props?.setOpenForm}
          submit={submit}
        />
      }
    />
  );
}
