import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Grid from '../../Grid/Grid'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'

import branchAPI from '../../../Network/Branch';
import portTypeAPI from '../../../Network/PortType';
import portCategoryAPI from '../../../Network/PortCategory';
import portReturnAPI from '../../../Network/PortReturn';
import userObject from "../../../Errors/Users/User";
import userAPI from "../../../Network/User";
import PopupForm from "../../../Components/PopupForm";
import Cookies from "universal-cookie";
import CustomTextField from "../../../Components/CustomTextField";
import geofenceAPI from "../../../Network/Geofence";
import CustomeSelectField from "../../../Components/CustomeSelectField";
const cookies = new Cookies();





const RenderContent = (props) => {
  const [t] = useTranslation("common");

  const [branches, setBranches] = useState([])
  const [loadingBranches, setLoadingBranches] = useState(true)

  const [portTypes, setPortTypes] = useState([])
  const [loadingPortTypes, setLoadingPortTypes] = useState(true)

  const [portCategories, setPortCategories] = useState([])
  const [loadingPortCategories, setLoadingPortCategories] = useState(true)

  const [portReturns, setPortReturns] = useState([])
  const [loadingPortReturns, setLoadingPortReturns] = useState(true)



  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [columns, setColumns] = useState([
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
    },
    { headerName: t("USERS.destination_geofence_id"), field: "id", flex: 0.5 },
    { headerName: t("USERS.destination_geofence_name"), field: "name", flex: 2 },
    { headerName: t("GEOFENCES.code"), field: "code", flex: 2 }

  ]);
  const handleCheckBoxChange = useCallback((rows) => {


    props?.setListOfSelectedRows(rows);
    let arr = [];
    props?.destinationGeofences &&
      props?.destinationGeofences?.length &&
      props?.destinationGeofences?.map((itm) => {
        if (rows?.find((e) => e == itm?.id)) {
          arr.push(itm);
        }
      });

    props?.setDestinationGeofence(arr);
  }, []);


  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }

  const getResources = () => {


    branchAPI.getBranchList({
      params: {
        ...headerObject,
      }

    }).then(branchesResult => {
      if (branchesResult.status && branchesResult?.data?.data) {
        setBranches(branchesResult?.data?.data)
      }
      setLoadingBranches(false);
    }).catch(error => {
      console.log(error);
      setLoadingBranches(false);
    }).finally(() => {
      setLoadingBranches(false);
    })

    portTypeAPI.getPortTypeList({
      params: {
        ...headerObject,
      }

    }).then(portTypesResult => {
      if (portTypesResult.status && portTypesResult?.data?.data) {
        setPortTypes(portTypesResult?.data?.data)
      }
      setLoadingPortTypes(false);
    }).catch(error => {
      console.log(error);
      setLoadingPortTypes(false);
    }).finally(() => {
      setLoadingPortTypes(false);
    })

    portCategoryAPI.getPortCategoryList({
      params: {
        ...headerObject,
      }

    }).then(portCategoriesResult => {
      if (portCategoriesResult.status && portCategoriesResult?.data?.data) {
        setPortCategories(portCategoriesResult?.data?.data)
      }
      setLoadingPortCategories(false);
    }).catch(error => {
      console.log(error);
      setLoadingPortCategories(false);
    }).finally(() => {
      setLoadingPortCategories(false);
    })

    portReturnAPI.getPortReturnList({
      params: {
        ...headerObject,
      }

    }).then(portReturnsResult => {
      if (portReturnsResult.status && portReturnsResult?.data?.data) {
        setPortReturns(portReturnsResult?.data?.data)
      }
      setLoadingPortReturns(false);
    }).catch(error => {
      console.log(error);
      setLoadingPortReturns(false);
    }).finally(() => {
      setLoadingPortReturns(false);
    })



  }

  useEffect(() => {
    getResources()
    return () => {

    }
  }, [])

  return (
    <Box className="Container-fluid">
      <Box className="row d-flex justify-content-center align-items-start">
        <Box className="col-12 col-sm-12 col-md-12 my-3 px-0"
          sx={{
            '& .MuiCheckbox-root': {
              color: '#1e6a99 !important',
              fill: '#1e6a99 !important'

            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography sx={{
              fontFamily: 'Cairo',
              marginLeft: '10px',
              backgroundColor: "#edf4fb",
              padding: "4px",
              borderRadius: '5px'
            }}>{t('GLOBAL.total') + " : " + props?.allDestinationGeofences?.length}</Typography>

            <Typography sx={{
              fontFamily: 'Cairo',
              marginLeft: '10px',
              backgroundColor: "#e2f079",
              borderRadius: '5px',
              padding: "4px",
            }}>{t('GLOBAL.total_filtered') + " : " + props?.destinationGeofences?.length}</Typography>
            <Typography sx={{
              fontFamily: 'Cairo',
              marginLeft: '10px',
              backgroundColor: "#bdf4c6",
              borderRadius: '5px',
              padding: "4px",
            }}>{t('GLOBAL.SEARCH_RESULT_OF_selected') + " : " + props?.listOfSelectedRows?.length}</Typography>
          </Box>
          <CustomTextField
            label={t("USERS.destination_name_search")}

            value={props?.filterObject?.name}

            haswidth={true}
            readOnly={false}
            focused={true}
            onChange={(e) => {

              props?.setFilterObject({
                ...props?.filterObject,
                name: e?.target?.value
              })





            }}
            onClearClick={() => {
              props?.setFilterObject({
                ...props?.filterObject,
                name: ""
              })


            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            {/* <CustomeSelectField
              label={t("GEOFENCES.port_type")}
              haswidth={true}
              value={props?.filterObject?.port_type_id?.value}
              list={portTypes?.length ? portTypes : []}
              error={props?.filterObject?.port_type_id?.error}
              message={props?.filterObject?.port_type_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {

                props?.setFilterObject({
                  ...props?.filterObject,
                  port_type_id: {
                    ...props?.filterObject?.port_type_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {

                props?.setFilterObject({
                  ...props?.filterObject,
                  port_type_id: {
                    ...props?.filterObject?.port_type_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            /> */}
            <CustomeSelectField
              label={t("GLOBAL.selected_status")}
              haswidth={true}
              value={props?.filterObject?.selected_status?.value}
              list={[
                {
                  name: t("GLOBAL.selected"),
                  id: 1
                },
                {
                  name: t("GLOBAL.not_selected"),
                  id: 2
                }



              ]}
              error={props?.filterObject?.selected_status?.error}
              message={props?.filterObject?.selected_status?.message}
              readOnly={false}
              onChange={(e, newValue) => {


                props?.setFilterObject({
                  ...props?.filterObject,
                  selected_status: {
                    ...props?.filterObject?.selected_status,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {


                props?.setFilterObject({
                  ...props?.filterObject,
                  selected_status: {
                    ...props?.filterObject?.selected_status,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />

            <CustomeSelectField
              label={t("GEOFENCES.port_return")}
              haswidth={true}
              value={props?.filterObject?.port_return_id?.value}
              list={portReturns?.length ? portReturns : []}
              error={props?.filterObject?.port_return_id?.error}
              message={props?.filterObject?.port_return_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {


                props?.setFilterObject({
                  ...props?.filterObject,
                  port_return_id: {
                    ...props?.filterObject?.port_return_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {


                props?.setFilterObject({
                  ...props?.filterObject,
                  port_return_id: {
                    ...props?.filterObject?.port_return_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />

            <CustomeSelectField
              label={t("GEOFENCES.port_category")}
              haswidth={true}
              value={props?.filterObject?.port_category_id?.value}
              list={portCategories?.length ? portCategories : []}
              error={props?.filterObject?.port_category_id?.error}
              message={props?.filterObject?.port_category_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {

                props?.setFilterObject({
                  ...props?.filterObject,
                  port_category_id: {
                    ...props?.filterObject?.port_category_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {

                props?.setFilterObject({
                  ...props?.filterObject,
                  port_category_id: {
                    ...props?.filterObject?.port_category_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />

            <CustomeSelectField
              label={t("GEOFENCES.branch")}
              haswidth={true}
              value={props?.filterObject?.branch_id?.value}
              list={branches?.length ? branches : []}
              error={props?.filterObject?.branch_id?.error}
              message={props?.filterObject?.branch_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {


                props?.setFilterObject({
                  ...props?.filterObject,
                  branch_id: {
                    ...props?.filterObject?.branch_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {

                props?.setFilterObject({
                  ...props?.filterObject,
                  branch_id: {
                    ...props?.filterObject?.branch_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Box>
          <Grid
            rows={props?.destinationGeofences}
            columns={columns}
            setColumns={setColumns}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            loading={loading}
            handlePageChange={handlePageChange}
            rowsTotal={props?.destinationGeofences?.length}
            checkScreenSize={screenwidth}
            pageCount={Math.ceil(props?.destinationGeofences?.length / pageSize)}
            clearFunction={props?.handleClearInAddvancSearch}
            searchFunction={props?.handleSearchInAdvancedSearch}
            filterChilds={
              null
            }
            filterHasSelectCounter={true}
            filterHasSelectCounterValue={props?.listOfSelectedRows?.length}
            notHaveAdvancSearch={true}
            hasSelectOption={true}
            handleCheckBoxChange={handleCheckBoxChange}
            listOfSelectedRows={props?.listOfSelectedRows}
            // handleRowCellChange={handleRowCellChange}
            paginationMode={"client"}
          />
        </Box>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props?.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              backgroundColor: "#e7a52a",
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props?.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function GeofencesForGroup(props) {
  const [t] = useTranslation("common");

  const [loading, setLoading] = useState(false);

  const [destinationGeofences, setDestinationGeofences] = useState([])

  const [filterdDestinationGeofences, setFilterdDestinationGeofences] = useState([])
  const [destinationGeofenceName, setDestinationGeofenceName] = useState()

  const [checkAllselected, setCheckAllselected] = useState(false)
  const [checkAllNotSelected, setCheckAllNotSelected] = useState(false)

  const [destinationGeofence, setDestinationGeofence] = useState('')
  const [filterObject, setFilterObject] = useState({})

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,

  };



  const clearForm = () => {
    props?.setListOfSelectedRows([])
  };

  useEffect(() => {
    if (!props?.object) clearForm();
    loadDestinationGeofences()

    getUserDestinationGeofences(props?.object)
  }, [props?.openForm, props?.object]);

  const loadDestinationGeofences = async () => {
    let data = null;
    try {
      data = await geofenceAPI.getGeofenceList({
        params: {
          ...headerObject,
          port_type_ids: [2, 3],

        }
      })
      if (data && data?.status) {
        setDestinationGeofences(data?.data?.data)
      } else {
        toast.warn(t('NETWORK_MESSAGES.messageError'))
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const handleSearchInAdvancedSearch = () => {
    let arr = [...destinationGeofences];
    let destinationGeofenceNameIdOrAccountName = filterObject?.name

    if (destinationGeofenceNameIdOrAccountName) {
      arr = arr?.filter((itm) => {
        if (
          (itm?.name && itm?.name?.toLowerCase().includes(destinationGeofenceNameIdOrAccountName && destinationGeofenceNameIdOrAccountName?.toLowerCase()))
          ||
          (itm?.account_name && itm?.account_name?.toLowerCase().includes(destinationGeofenceNameIdOrAccountName && destinationGeofenceNameIdOrAccountName?.toLowerCase()))
          ||
          (itm?.id && itm?.id?.toString().includes(destinationGeofenceNameIdOrAccountName && destinationGeofenceNameIdOrAccountName?.toLowerCase()))
        ) {
          return true
        }

        return false
      })

    }
    if (filterObject?.branch_id?.value?.code) {
      arr = arr.filter(item => item.code.substring(0, 2) === filterObject?.branch_id?.value?.code)
    }
    if (filterObject?.port_category_id?.value?.code) {
      arr = arr.filter(item => item.code.substring(2, 4) === filterObject?.port_category_id?.value?.code)
    }
    if (filterObject?.port_return_id?.value?.code) {
      arr = arr.filter(item => item.code.substring(4, 5) === filterObject?.port_return_id?.value?.code)
    }
    if (filterObject?.port_type_id?.value?.code) {
      arr = arr.filter(item => item.code.substring(5, 6) === filterObject?.port_type_id?.value?.code)
    }
    if (filterObject?.selected_status?.value?.id) {
      if (filterObject?.selected_status?.value?.id == 1) {
        arr = arr.filter(item => props?.listOfSelectedRows?.includes(item.id))
      }
      else {
        arr = arr.filter(item => !props?.listOfSelectedRows?.includes(item.id))
      }

    }

    setFilterdDestinationGeofences(arr)

    if (checkAllselected) {
      setFilterdDestinationGeofences(arr.filter(itm => props?.listOfSelectedRows?.includes(itm?.id)))
    }
    if (checkAllNotSelected) {
      setFilterdDestinationGeofences(arr.filter(itm => !props?.listOfSelectedRows?.includes(itm?.id)))
    }

  }
  const handleClearInAddvancSearch = () => {

    setDestinationGeofenceName('')
    setFilterdDestinationGeofences(destinationGeofences)
    setCheckAllselected(false)
  }

  useEffect(() => {
    if (destinationGeofences) {
      setFilterdDestinationGeofences(destinationGeofences)
    }

  }, [destinationGeofences])

  const getUserDestinationGeofences = async (user) => {

    if (user?.destination_geofences?.length) {

      props?.setListOfSelectedRows(user?.destination_geofences)//get only ids 
    } else {
      props?.setListOfSelectedRows([])
    }

  }
  useEffect(() => {
    handleSearchInAdvancedSearch()


    return () => {

    }
  }, [filterObject])


  useEffect(() => {
    console.log("props?.importedSelectedData?.length", props?.importedSelectedData);
    if (props?.importedSelectedData?.length) {
      let selectedArray = []
      destinationGeofences?.map(item => {
        
        props?.importedSelectedData?.map(selectedItem=>{
          if(selectedItem == item?.code)
            {
              console.log(selectedItem +":"+item?.id,item);
              selectedArray?.push(item?.id)
            }
        })
       
      })
      props?.setListOfSelectedRows(selectedArray)
    }

    return () => {

    }
  }, [props?.importedSelectedData])


  return (

    <RenderContent
      filterObject={filterObject}
      setFilterObject={setFilterObject}
      setDestinationGeofences={setDestinationGeofences}
      destinationGeofences={filterdDestinationGeofences}
      destinationGeofence={destinationGeofence}
      setDestinationGeofence={setDestinationGeofence}
      allDestinationGeofences={destinationGeofences}

      destinationGeofenceName={destinationGeofenceName}
      setDestinationGeofenceName={setDestinationGeofenceName}
      handleSearchInAdvancedSearch={handleSearchInAdvancedSearch}
      handleClearInAddvancSearch={handleClearInAddvancSearch}
      setListOfSelectedRows={props?.setListOfSelectedRows}
      listOfSelectedRows={props?.listOfSelectedRows}
      fileData={props?.fileData}
      checkAllselected={checkAllselected}
      setCheckAllselected={setCheckAllselected}
      setCheckAllNotSelected={setCheckAllNotSelected}
      checkAllNotSelected={checkAllNotSelected}
      open={props?.openForm} setOpen={props?.setOpenForm}
    />

  );
}
