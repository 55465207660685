import { Box, Typography } from '@mui/material'
import React from 'react'
import RefreshIcon from "@mui/icons-material/Refresh";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import swal from "sweetalert";

const InstallationCheckCard = ({ mainUnit, onClick }) => {
    const getUnitPlate = () => {
        
        let unitPlate = (mainUnit?.plate_province?mainUnit?.plate_province?.toString()+"-":"") 
        + (mainUnit?.plate_letter?mainUnit?.plate_letter?.toString()+"-":"")
        + (mainUnit?.plate_number?mainUnit?.plate_number?.toString():"")
        
        return unitPlate
    }
    const getUnitType = () => {
        let unitName = "";
        
        if (mainUnit?.plate_type) {
            unitName += mainUnit?.plate_type + " "
        }
        
        if (mainUnit?.name_and_type) {
            unitName += mainUnit?.name_and_type + " "
        }
        return unitName
    }
    return (
        <Box
            onClick={() => {
                mainUnit?.is_subscribed == 1 ?
                    swal({
                        title: "المركبة مخولة",
                        text: getUnitPlate(),
                        icon: "success",
                        button: {
                            text: "حسنا",
                            closeModal: true,
                        },
                        dangerMode: false,
                    }).then(async (ok) => {
                        if (ok) {
                            try {

                            } catch (error) {

                            }
                        } else {
                            // alert('canceled')
                        }
                    })
                    :
                    swal({
                        title: "المركبة غير مخولة",
                        text: getUnitPlate(),
                        icon: "error",
                        button: {
                            text: "حسنا",
                            closeModal: true,
                        },
                        dangerMode: true,
                    }).then(async (ok) => {
                        if (ok) {
                            try {

                            } catch (error) {

                            }
                        } else {
                            // alert('canceled')
                        }
                    })
            }}
            sx={{
                width: '500px',
                height: '180px',
                borderRadius: '10px',
                cursor: 'pointer',
                backgroundColor: mainUnit?.is_subscribed == 1 ? '#d4edda' : '#8f1c24',

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                ":hover": {
                    cursor: 'pointer',
                    transform: 'translateY(-10px)',
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
                    backgroundColor: mainUnit?.is_subscribed == 1 ? '#d4eddaA0' : '#8f1c24A0',
                }
            }}

        >
            <Box
                sx={{
                    cursor: 'pointer',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: '10px'
                }}
            >



                <Typography
                    sx={{

                        fontFamily: 'Cairo',

                        color: mainUnit?.is_subscribed == 1 ? '#000000' : '#FFFFFF',
                        fontSize: '14px',
                        marginTop: '10px',
                        textAlign: 'center',
                        direction:'rtl'
                    }}
                >
                    {getUnitPlate()}
                </Typography>
                <Typography
                    sx={{

                        fontFamily: 'Cairo',

                        color: mainUnit?.is_subscribed == 1 ? '#000000' : '#FFFFFF',
                        fontSize: '14px',
                        marginTop: '10px',
                        textAlign: 'center'
                    }}
                >
                    {getUnitType()}
                </Typography>
                <Typography
                    sx={{

                        fontFamily: 'Cairo',

                        color: mainUnit?.is_subscribed == 1 ? '#000000' : '#FFFFFF',
                        fontSize: '14px',
                        marginTop: '10px',
                        textAlign: 'center'
                    }}
                >
                    {mainUnit?.driver_name ? mainUnit?.driver_name : ""}
                </Typography>
                <Typography
                    sx={{

                        fontFamily: 'Cairo',

                        color: mainUnit?.is_subscribed == 1 ? '#000000' : '#FFFFFF',
                        fontSize: '14px',
                        marginTop: '10px',
                        textAlign: 'center'
                    }}
                >
                    {mainUnit?.company_name ? mainUnit?.company_name : ""}
                </Typography>
                <Typography
                    sx={{

                        fontFamily: 'Cairo',

                        color: mainUnit?.is_subscribed == 1 ? '#000000' : '#FFFFFF',
                        fontSize: '14px',
                        marginTop: '10px',
                        textAlign: 'center'
                    }}
                >
                    {mainUnit?.note ? mainUnit?.note : ""}
                </Typography>
            </Box>
            <Box
                sx={{
                    cursor: 'pointer',
                    width: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {mainUnit?.is_subscribed == 1 ?
                    <CheckCircleIcon
                        style={{
                            color: '#266713',
                            fontSize: '25px'
                        }}
                    />
                    :
                    <CancelIcon
                        style={{
                            color: '#FFFFFF',
                            fontSize: '25px'
                        }}
                    />}
            </Box>
        </Box>

    )
}

export default InstallationCheckCard