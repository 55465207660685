import callAPI from '../ApiConfig'
import $ from "jquery";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const plateTypes = async (props) => {

    return await callAPI({
        route: "plate_types/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addPlateType = async (props) => {


    return await callAPI({
        route: "plate_types/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editPlateType = async (props) => {

    return await callAPI({
        route: "plate_types/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deletePlateType = async (props) => {


    return await callAPI({
        route: "plate_types/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getPlateTypeList = async (props) => {

    return await callAPI({
        route: "plate_types/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const plateTypeAPI = {
    plateTypes: plateTypes,
    addPlateType: addPlateType,
    editPlateType: editPlateType,
    deletePlateType: deletePlateType,
    getPlateTypeList: getPlateTypeList
}
export default plateTypeAPI