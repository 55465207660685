import React, { useState, useEffect } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDownGrid from "../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import unitAPI from "../../Network/Unit";
import Title from "./Title";
import { selectRoles } from "../../reduxStore/RolesReducer";
import plateLetterAPI from "../../Network/PlateLetter";
import plateTypeAPI from "../../Network/PlateType";
import plateProvinceAPI from "../../Network/PlateProvince";
import moment from "moment";
import ViewListIcon from '@mui/icons-material/ViewList';
import userAPI from "../../Network/User";
import unitSectionAPI from "../../Network/UnitSection";
import unitTypeAPI from "../../Network/UnitType";

const cookies = new Cookies();

const todayStart = new Date();
const yesterday = new Date(todayStart);
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(0, 0, 0, 0);

const todayEnd = new Date();
todayEnd.setHours(23, 59, 59, 999);

export default function UnitsTransactions() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);

  const [plateTypes, setPlateTypes] = useState([])
  const [loadingPlateTypes, setLoadingPlateTypes] = useState(true)

  const [plateProvinces, setPlateProvinces] = useState([])
  const [loadingPlateProvinces, setLoadingPlateProvinces] = useState(true)

  const [plateLetters, setPlateLetters] = useState([])
  const [loadingPlateLetters, setLoadingPlateLetters] = useState(true)

  const [filters, setFilters] = useState({
    name: "",
    code: "",
    type_id: null,
    wialon_id: null,
    export_excel: false,
    transactions_total_greater_than_from: "",
    transactions_total_less_than_from: "",
    creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
    creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
    creationDateFrom: yesterday,
    creationDateTo: todayEnd,
    all_time: 0
  })
  const [reload, setReload] = useState(false)

  const [openForm, setOpenForm] = useState(false);
  const [selectedObject, setselectedObject] = useState(null);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [loadingAccounts, setLoadingAccounts] = useState(true)
  const [accounts, setAccounts] = useState([])

  const [loadingUnitSections, setLoadingUnitSections] = useState(true)
  const [unitSections, setUnitSections] = useState([])

  const [loadingUnitTypes, setLoadingUnitTypes] = useState(true)
  const [unitTypes, setUnitTypes] = useState([])


  const rolesRedux = useSelector(selectRoles)

  const columns = [
    { field: "index_column", headerName: "ت", minWidth: 50, maxWidth: 60 },
    // { field: "id", headerName: "#", minWidth: 50, maxWidth: 60 },
    { field: "unit_name", headerName: t("UNITS.name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "unit_type_name", headerName: t("UNITS.unit_type_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "unit_section_name", headerName: t("UNITS.unit_section_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "account_name", headerName: t("UNITS.account_name"), minWidth: 200, maxWidth: 220, flex: 2 },
    {
      field: "is_governmental", headerName: t("UNITS.is_governmental"), minWidth: 110, maxWidth: 150, flex: 2,
      renderCell: (rows) => {
        return (
          <Typography
            sx={{
              color: '#000000',
              fontSize: '14px'
            }}
          >
            {rows?.row?.is_governmental == 1 ? t("UNITS.governmental") : t("UNITS.private")}
          </Typography>
        )
      }
    },
    { field: "driver_name", headerName: t("UNITS.driver_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    // { field: "number_and_letter", headerName: t("UNITS.number_and_letter"), minWidth: 110, maxWidth: 150, flex: 1 },
    // { field: "province", headerName: t("UNITS.plate_province"), minWidth: 110, maxWidth: 150, flex: 1 },
    // { field: "type", headerName: t("UNITS.plate_type"), minWidth: 110, maxWidth: 150, flex: 1 },
    { field: "transactions_total", headerName: t("UNITS.transactions_total"), minWidth: 110, maxWidth: 150, flex: 1 },
    { field: "inside_baghdad", headerName: t("UNITS.inside_baghdad"), minWidth: 110, maxWidth: 150, flex: 1 },
    {
      field: "outside_baghdad", headerName: t("UNITS.outside_baghdad"), minWidth: 110, maxWidth: 150, flex: 2,
      renderCell: (rows) => {
        return (
          <Typography
            sx={{
              color: '#000000',
              fontSize: '14px'
            }}

          >
            {rows?.row?.transactions_total - rows?.row?.inside_baghdad - rows?.row?.unknown}
          </Typography>
        )
      }
    },
    { field: "unknown", headerName: t("UNITS.unknown"), minWidth: 110, maxWidth: 150, flex: 1 },
    {
      headerName: t("UNITS.actions"),
      field: "Actions",
      flex: 0.4,

      minWidth: 100,
      renderHeader: () => (
        <Typography>
          {t("UNITS.actions")}
        </Typography>
      ),
      renderCell: (rows) => (

        <DropDownGrid>
          <MenuItem onClick={() => { showDetails(rows?.row) }} className="ActionIcons">
            <ListItemIcon sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0px 7px 0 0'
            }}>
              <ViewListIcon style={{ fill: "#17681b" }} />
            </ListItemIcon>
            {t('UNITS.transactions_detail')}
          </MenuItem>


        </DropDownGrid>
      ),
    },
  ]

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }

  const getResources = () => {

    userAPI.getAccountList({
      params: {
        ...headerObject,
      }

    }).then(accountsResult => {
      if (accountsResult.status && accountsResult?.data?.data) {
        setAccounts(accountsResult?.data?.data)
      }
      setLoadingAccounts(false);
    }).catch(error => {
      console.log(error);
      setLoadingAccounts(false);
    }).finally(() => {
      setLoadingAccounts(false);
    })

    unitSectionAPI.getUnitSectionList({
      params: {
        ...headerObject,
      }

    }).then(sectionsResult => {
      if (sectionsResult.status && sectionsResult?.data?.data) {
        setUnitSections(sectionsResult?.data?.data)
      }
      setLoadingUnitSections(false);
    }).catch(error => {
      console.log(error);
      setLoadingUnitSections(false);
    }).finally(() => {
      setLoadingUnitSections(false);
    })

    unitTypeAPI.getUnitTypeList({
      params: {
        ...headerObject,
      }

    }).then(typesResult => {
      if (typesResult.status && typesResult?.data?.data) {
        setUnitTypes(typesResult?.data?.data)
      }
      setLoadingUnitTypes(false);
    }).catch(error => {
      console.log(error);
      setLoadingUnitTypes(false);
    }).finally(() => {
      setLoadingUnitTypes(false);
    })




  }

  useEffect(() => {
    getResources()
    return () => {

    }
  }, [])

  useEffect(() => {
    loadData()
  }, [pageNumber, pageSize, reload]);

  const loadData = async (searchParams) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        ...headerObject,
        ...(filters?.export_excel ? {} :
          {
            page: pageNumber,
            page_size: pageSize,
          }),
        search: "",
        ...searchParams,
        ...filters
      };

      setLoading(true);

      const result = await unitAPI.report({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data?.data) {


        if (filters?.export_excel) {
          setFilters({
            ...filters,
            export_excel: false
          })
          downloadExcel(result?.data?.data)
        }
        else {
          if (result?.data?.data?.data?.length > 0) {
            setRows(result?.data?.data?.data);
            setRowsTotal(result?.data?.data?.total_records);
            setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
          }
          else {
            setRows([]);
            setRowsTotal(0);
            setPageCount(0);
          }
        }
      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };


  const refreshFromWialon = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        username: cookies.get("user_name") ? cookies.get("user_name") : null,
        user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
        account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
        account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
        authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
        sid: cookies.get("sid") ? cookies.get("sid") : null,
        baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
        id: cookies.get("id") ? cookies.get("id") : null,

      };

      setLoading(true);

      const result = await unitAPI.refreshDataFromWialon({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data) {
        loadData()
      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };

  const showDetails = (unit) => {
    // const unitTransactionFilters = {
    //   creation_date_from: filters?.creation_date_from,
    //   creation_date_to: filters?.creation_date_to,
    //   creationDateFrom: filters?.creationDateFrom,
    //   creationDateTo: filters?.creationDateTo,
    //   all_time: filters?.all_time,
    //   unit_id: unit?.id,
    //   unit: {
    //     id: unit?.id,
    //     name: unit?.unit_name
    //   },
    // }
    // localStorage.setItem('unitTransactionFilters', JSON.stringify(unitTransactionFilters));
    // setLoading(true)
    // Get the current root URL
    const currentRootURL = window.location.origin;

    // Append "system/transactions" to the root URL
    let newURL = `${currentRootURL}/system/unit_transactions_detail`;
    newURL += `?unit_name=${unit?.unit_name}&all_time=${filters?.all_time}&unit_id=${unit?.id}&creationDateFrom=${filters?.creation_date_from}&creationDateTo=${filters?.creation_date_to}`

    // Open the new tab with the constructed URL
    const newTab = window.open(newURL, '_blank');
  }

  const createFunction = () => {
    refreshFromWialon()
    // setselectedObject(null);
    // setOpenForm(true);
  };


  const clearFilterFunction = () => {
    setFilters({
      name: "",
      code: "",
      type_id: null,
      wialon_id: null,
      export_excel: false,
      transactions_total_greater_than_from: "",
      transactions_total_less_than_from: "",
      creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
      creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
      creationDateFrom: yesterday,
      creationDateTo: todayEnd,
      all_time: 0
    })
  }
  const searchFunction = () => {
    setReload(!reload)
    setPageNumber(1)
  };

  const exportFunction = () => {

    setFilters({
      ...filters,
      export_excel: true
    })
    setReload(!reload)
  }
  const downloadExcel = async (fileName) => {
    const result = await unitAPI?.downloadExcel({
      params: {
        ...headerObject,
        fileName: fileName
      }
    })
    if (result.status && result?.data) {
      var _url = window.URL.createObjectURL(result?.data);
      window.open(_url, "_blank").focus(); // window.open + focus
    }
  }
  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >



      <Title
        createFunction={createFunction}
        openFilterColumn={openFilterColumn}
      />
      <Grid
        rows={rows}
        hasExport={rolesRedux?.export_unit?.value}
        exportFunction={exportFunction}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={false}
        creatFunction={createFunction}


        filterChilds={
          <Search
            filters={filters}
            setFilters={setFilters}

            loadingAccounts={loadingAccounts}
            loadingUnitSections={loadingUnitSections}
            loadingUnitTypes={loadingUnitTypes}

            unitSections={unitSections}
            unitTypes={unitTypes}
            accounts={accounts}
            
          />
        }
      />

    </Box>
  );
}
