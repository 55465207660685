import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';


const primaryTextSX = {
    color: 'white',
    fontFamily: 'Cairo',
    fontSize: '13px'
}


export default function DataGridHeader(props) {

    return (
        <Box sx={{
            display: 'flex',
            backgroundColor: '#17681b',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexDirection: 'row',

            marginBottom: '12px',
            paddingTop: '7px',
            paddingBottom: '7px',
            color: "white"

        }}>
            <Box sx={{
                width: '80px',
            }}>
            </Box>

            {props?.columns?.length > 0 && props?.columns?.map(((column, index) => {
                if (column?.renderHeader) {
                    
                    return column?.renderHeader()
                }
                else {
                    return <Box
                        key={'data_grid_header' + index}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            flex: 1,
                            ...column?.sx
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={primaryTextSX}
                        >
                            {column?.headerName}
                        </Typography>

                    </Box>
                }

            }

            ))}
            <Box sx={{
                width: '30px',
            }}>
            </Box>

        </Box>
    );
}
