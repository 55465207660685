import { Box, Button, Divider, Tooltip, Typography } from '@mui/material'
import React from 'react'
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectRoles } from '../../reduxStore/RolesReducer';
import MoreMenu from '../../Components/MoreMenu';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Cookies from "universal-cookie";
const cookies = new Cookies();

function Title({ createFunction, openFilterColumn, bulkUpdate, selectionModel }) {
    const [t] = useTranslation("common");
    const rolesRedux = useSelector(selectRoles)
    const gridtheme = useSelector((state) => state.themeData.gridtheme);
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "stretch",
                    position: "relative",
                }}
            >

                <Box
                    sx={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        margin: "0 10px",
                    }}
                >

                    {rolesRedux?.add_main_unit?.value && (cookies.get('id') == 46) ?
                        <Box
                            sx={{
                                marginRight: "10px",
                                backgroundColor: "#17681b",
                                color: "#FFFFFF",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => { createFunction("add") }}
                            id="demo-customized-button"
                            aria-controls={
                                openFilterColumn ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openFilterColumn ? "true" : undefined}
                            variant="contained"
                        >

                            <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("UNITS.add_main_unit")}</span>}>
                                <AddIcon
                                    sx={{
                                        color: "#fff",
                                        fontSize: "40px",
                                    }}
                                />
                            </Tooltip>

                        </Box>
                        : null}
                    {rolesRedux?.refresh_main_unit_from_wialon?.value ?
                        <Box
                            sx={{
                                marginRight: "10px",
                                backgroundColor: "#17681b",
                                color: "#FFFFFF",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => { createFunction("refresh") }}
                            id="demo-customized-button"
                            aria-controls={
                                openFilterColumn ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openFilterColumn ? "true" : undefined}
                            variant="contained"
                        >

                            <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("UNITS.refresh_data_from_wialon")}</span>}>
                                <RefreshIcon
                                    sx={{
                                        color: "#fff",
                                        fontSize: "40px",
                                    }}
                                />
                            </Tooltip>

                        </Box>
                        : null}
                    {rolesRedux?.refresh_unit_from_wialon?.value ?

                        <Box
                            sx={{
                                marginRight: "10px",
                                backgroundColor: "#17681b",
                                color: "#FFFFFF",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => { createFunction("refresh_not_updated_only") }}
                            id="demo-customized-button"
                            aria-controls={
                                openFilterColumn ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openFilterColumn ? "true" : undefined}
                            variant="contained"
                        >

                            <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("UNITS.refresh_not_updated_data_from_wialon")}</span>}>
                                <UpdateDisabledIcon
                                    sx={{
                                        color: "#fff",
                                        fontSize: "40px",
                                    }}
                                />
                            </Tooltip>
                        </Box> : null}
                    {rolesRedux?.delete_main_units?.value && false ?
                        <Box
                            sx={{
                                marginRight: "10px",
                                backgroundColor: "#ff3e3e",
                                color: "#FFFFFF",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => { createFunction("clearAll") }}
                            id="demo-customized-button"
                            aria-controls={
                                openFilterColumn ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openFilterColumn ? "true" : undefined}
                            variant="contained"
                        >

                            <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("UNITS.clear_all")}</span>}>
                                <DeleteForeverIcon
                                    sx={{
                                        color: "#fff",
                                        fontSize: "40px",
                                    }}
                                />
                            </Tooltip>

                        </Box>
                        : null}
                    {selectionModel?.length > 0 ?
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: 'column'
                            }}
                        >
                            <MoreMenu
                                options={[
                                    {
                                        name: t("GLOBAL.unsubscribe") + " " + selectionModel?.length + " " + t("GLOBAL.items"),
                                        onClick: () => { bulkUpdate({ is_subscribed: 0 }) },
                                        icon: <DoDisturbOnIcon style={{ fill: "#d32f2f" }} />
                                    },
                                    {
                                        name: t("GLOBAL.subscribe") + " " + selectionModel?.length + " " + t("GLOBAL.items"),
                                        onClick: () => { bulkUpdate({ is_subscribed: 1 }) },
                                        icon: <CheckCircleIcon style={{ fill: "#388e3c" }} />
                                    },
                                    {
                                        name: t('UNITS.refresh_data_from_wialon') + " " + selectionModel?.length + " " + t("GLOBAL.items"),
                                        onClick: () => { bulkUpdate({ refresh_data_from_wialon: 1 }) },
                                        icon: <RefreshIcon style={{ fill: "#17681b" }} />
                                    },
                                    {
                                        name: t('UNITS.unlink') + " " + selectionModel?.length + " " + t("GLOBAL.items"),
                                        onClick: () => {
                                            bulkUpdate({
                                                wialon_id: "",
                                                main_account_id: "",
                                                installation_date: "",
                                                wialon_name: "",
                                                is_installed: 0

                                            })
                                        },
                                        icon: <LinkOffIcon style={{ fill: "#ff0000" }} />
                                    },
                                    {
                                        name: t('UNITS.delete') + " " + selectionModel?.length + " " + t("GLOBAL.items"),
                                        onClick: () => { bulkUpdate({ delete: 1 }) },
                                        icon: <DeleteForeverIcon style={{ fill: "#ff0000" }} />
                                    }
                                ]}
                            />
                            {/* 
                            <Button
                                variant="outlined"
                                // spacing={2}
                                sx={{

                                    margin: 1,
                                    backgroundColor: "#ff3e3e",
                                    borderColor: gridtheme?.colorWhite,
                                    color: gridtheme?.colorWhite,
                                    boxShadow: "0 0 7px -2px white",
                                    //   color:SearchButtonTheme?.clear_button_text_color,

                                    "&:hover": {
                                        backgroundColor: "#f4bdbd",
                                        borderColor: gridtheme?.colorWhite,
                                        color: "#fff",
                                        boxShadow: "0 0 7px 1px white",
                                        boxShadow: "0",
                                    },
                                    height: "35px",
                                    width: "250px",
                                    fontFamily: "Cairo-Bold",
                                }}
                                className="iconeFilterClear"
                                color="secondary"
                                onClick={() => {

                                    bulkUpdate({is_subscribed:0})
                                }}
                            >
                                {t("GLOBAL.unsubscribe") + " " + selectionModel?.length + " " + t("GLOBAL.items")}
                            </Button>
                            <Button
                                variant="outlined"
                                // spacing={2}
                                sx={{

                                    margin: 1,
                                    backgroundColor: "#17681b",
                                    borderColor: gridtheme?.colorWhite,
                                    color: gridtheme?.colorWhite,
                                    boxShadow: "0 0 7px -2px white",
                                    //   color:SearchButtonTheme?.clear_button_text_color,

                                    "&:hover": {
                                        backgroundColor: "#17681bA0",
                                        borderColor: gridtheme?.colorWhite,
                                        color: "#fff",
                                        boxShadow: "0 0 7px 1px white",
                                        boxShadow: "0",
                                    },
                                    height: "35px",
                                    width: "250px",
                                    fontFamily: "Cairo-Bold",
                                }}
                                className="iconeFilterClear"
                                color="secondary"
                                onClick={() => {

                                    bulkUpdate({is_subscribed:1})
                                }}
                            >
                                {t("GLOBAL.subscribe") + " " + selectionModel?.length + " " + t("GLOBAL.items")}
                            </Button> */}
                        </Box>
                        : null}
                </Box>


                <Box
                    sx={{
                        flex: 2,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            marginLeft: "10px",
                            marginBottom: "10px",
                            fontFamily: "Cairo-Bold",
                            fontSize: "23px",
                            letterSpacing: "-1px",
                        }}
                    >
                        {t("UNITS.title_main")}
                    </Typography>
                </Box>


            </Box>
            <Divider
                sx={{
                    backgroundColor: "#EBF2F7",
                    margin: "30px",
                    marginTop: "10px",
                    width: "100%",
                }}
                style={{
                    height: "3px",
                }}
            />
        </>
    )
}

export default Title