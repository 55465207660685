import React from 'react';
import SalamLogo from "../assets/img/salam.png"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p className="text-center">© {new Date().getFullYear()} شركة السلام. جميع الحقوق محفوظة. 
        {/* <img src={SalamLogo} style={{
            marginRight:'10px',
            
            height: ' 48px',
            dropShadow: '0px 3px 6px #00000029',
            objectFit: 'cover',
            backgroundPosition: 'center center',
            backgroundColor:'#FFFFFF',
            opacity: 1,
            borderRadius:'50%',
            position: 'relative',
            top: '2px'
          }} /> */}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
