import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Checkbox, Grid, ListItemText } from "@mui/material";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Fade from "@mui/material/Fade";
import { json } from "react-router";
import Cookies from "universal-cookie";
import PopupForm from "../../Components/PopupForm";
const cookies = new Cookies()
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    paddingTop: "10px",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function FilterColumn(props) {

  const [t] = useTranslation("common");

  const dataGridName = window.location.href
  const handleClose = () => {
    props?.setOpen(false);

  };
  const setColumns = (columns) => { 
    props.setColumns(columns)
    
  }
  const handleChange = (name) => {
    let arr = [];
    // alert(name)
    props?.columns &&
      props?.columns?.map((itm) => {
        if (itm?.headerName == name) {
          if (itm?.hide)
            arr.push({
              ...itm,
              hide: false,
            });
          else
            arr.push({
              ...itm,
              hide: true,
            });
        } else {
          arr.push(itm);
        }
      });
    if (arr?.length > 0) {
      // localStorage.setItem(`${dataGridName + cookies.get('user_id')}`, JSON.stringify(arr))
      setColumns(arr);
    }
  };
  useEffect(() => {

    if (!localStorage.getItem(`${dataGridName + cookies.get('user_id')}`)) {

      localStorage.setItem(`${dataGridName + cookies.get('user_id')}`, JSON.stringify(props?.columns))
      setColumns(props?.columns)
    } else {


      let localColumns = JSON.parse(localStorage.getItem(`${dataGridName + cookies.get('user_id')}`))
      let columnsArray = []
      if (props?.columns?.length) {
        props?.columns?.map((itm) => {
          const localColumn = localColumns?.length? localColumns?.find(item=>item?.headerName == itm?.headerName):null
          if (localColumn) {
            columnsArray?.push({
              ...itm,
              hide:localColumn?.hide
            })
          }
          else{
            columnsArray?.push(itm)
          }
        })
      }
      localStorage.setItem(`${dataGridName + cookies.get('user_id')}`, JSON.stringify(columnsArray))
      
      setColumns(columnsArray)
    }
  }, [])

  return (
    <PopupForm
      // open={props?.openForm}
      setOpen={props?.setOpen}
      open={props?.open}
      onClose={handleClose}
      title={t("GLOBAL.show_hide_columns")}
      isFullScreen={false}

      content={<Box className="Container-fluid position-relative" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{
          marginTop: '10px'
        }}>
          {props?.columns?.map((name,index) => {
            if(index >= props?.columns?.length-1)
            {
              return null
            }
            else{
              return <Grid xs={6} sm={6} md={6} lg={6} sx={{
              padding: '0px'
            }}>
              <MenuItem
                key={name?.headerName}
                value={name?.headerName}
                onClick={() => handleChange(name?.headerName)}
              >
                <Checkbox checked={name?.hide ? false : true} />
                <ListItemText
                  primary={name?.headerName}
                  sx={{
                    "& .MuiTypography-root": {
                      fontFamily: "Cairo",
                    },
                  }}
                />
              </MenuItem>
            </Grid>
            }
          }
          )}


        </Grid>
      </Box>

      }
      footer={
        <>


          <Box sx={{ height: '100px', display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
            <Box display="flex" >

              <Button variant="outlined"
                // spacing={2}
                sx={{
                  width: '50% !important',
                  // flexGrow: 1, 
                  minWidth: '180px !important',
                  maxWidth: '180px !important',
                  margin: 1,
                  // backgroundColor:SearchButtonTheme?.clear_button_color,
                  color: "#e7a52a",
                  '&:hover': {
                    backgroundColor: `#e7a52a88   !important`,
                  },
                  height: '35px',
                  fontFamily: 'Cairo-Bold'

                }}
                className="iconeFilterClear"
                color="secondary"
                onClick={() => {
                  const showAllColumns = props?.columns?.map(column => {
                    return {
                      ...column,
                      hide: false
                    }
                  })
                  localStorage.setItem(`${dataGridName + cookies.get('user_id')}`, JSON.stringify(showAllColumns))
                  setColumns(showAllColumns)
                  props?.setOpen(false)
                }}
              >
                {t('GLOBAL.Select_all_and_close')}
              </Button>
              <Button
                variant="contained"
                // spacing={2} 
                sx={{
                  // flexGrow: 1,
                  margin: 1,
                  width: '180px !important',
                  minWidth: '180px !important',
                  maxWidth: '180px !important',
                  backgroundColor: '#e7a52a',
                  '&:hover': {
                    backgroundColor: '#e7a52a' + '88',
                  },
                  height: '35px',
                  fontFamily: 'Cairo-Bold'
                }}
                className="iconeFilterSearch"
                onClick={() => {
                  localStorage.setItem(`${dataGridName + cookies.get('user_id')}`, JSON.stringify(props?.columns))
                 
                  props?.setOpen(false)
                }}
              >
                {t('GLOBAL.Save_and_close')}
              </Button>
            </Box>
          </Box>

        </>

      }
    />
  );
}
