import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Checkbox from '@mui/material/Checkbox';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Box, Typography } from '@mui/material';



//BFS algorithm to find node by his ID
const bfsSearch = (graph, targetId) => {
    const queue = [...graph];

    while (queue?.length > 0) {
        const currNode = queue.shift();
        if (currNode?.id === targetId) {
            return currNode;
        }
        if (currNode?.children) {
            queue.push(...currNode?.children);
        }
    }
    return []; // Target node not found
};

export default function TreeCheckBoxes({
    data,
    selectedNodes,
    setSelectedNodes
}) {

    console.log("selectedNodes",selectedNodes);
    // Retrieve all ids from node to his children's
    function getAllIds(node, idList = []) {
        idList?.push(node.id);
        if (node?.children) {
            node?.children.forEach((child) => getAllIds(child, idList));
        }
        return idList;
    }
    // Get IDs of all children from specific node
    const getAllChild = (id) => {
        return getAllIds(bfsSearch(data, id));
    };

    // Get all father IDs from specific node
    const getAllFathers = (id, list = []) => {
        const node = bfsSearch(data, id);
        if (node?.parent) {
            list.push(node?.parent);

            return getAllFathers(node?.parent, list);
        }

        return list;
    };

    function isAllChildrenChecked(node, list) {
        const allChild = getAllChild(node.id);
        const nodeIdIndex = allChild?.indexOf(node.id);
        allChild?.splice(nodeIdIndex, 1);

        return allChild.every((nodeId) =>
            selectedNodes?.concat(list).includes(nodeId)
        );
    }

    const handleNodeSelect = (event, nodeId,childrenChecked) => {
        event.stopPropagation();
        const allChild = getAllChild(nodeId);
        const fathers = getAllFathers(nodeId);

        if (selectedNodes.includes(nodeId) || childrenChecked) {
            // Need to de-check
            setSelectedNodes((prevSelectedNodes) =>
                prevSelectedNodes.filter((id) => !allChild.concat(fathers).includes(id))
            );
        } else {
            // Need to check
            const ToBeChecked = allChild;
            for (let i = 0; i < fathers.length; ++i) {
                if (isAllChildrenChecked(bfsSearch(data, fathers[i]), ToBeChecked)) {
                    ToBeChecked.push(fathers[i]);
                }
            }
            setSelectedNodes((prevSelectedNodes) =>
                [...prevSelectedNodes].concat(ToBeChecked)
            );
        }
    };

    const handleExpandClick = (event) => {
        // prevent the click event from propagating to the checkbox
        event.stopPropagation();
    };

    const renderTree = (nodes) => {
        const childrenChecked = nodes?.children && nodes?.children.every((child) => selectedNodes?.includes(child?.id));
        const childrenUnchecked = nodes?.children && nodes?.children.every((child) => !selectedNodes?.includes(child?.id));
        const indeterminate = nodes?.children && !childrenChecked && !childrenUnchecked;

        return (
            <TreeItem
                key={nodes?.id}
                nodeId={nodes?.id}
                onClick={handleExpandClick}
                label={
                    <Box 
                    sx={{
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'flex-start',
                        alignItems:'center'
                    }}
                    >
                        <Checkbox
                            checked={selectedNodes?.includes(nodes?.id) || childrenChecked}
                            indeterminate={indeterminate}
                            tabIndex={-1}
                            disableRipple
                            onClick={(event) => handleNodeSelect(event, nodes?.id,childrenChecked)}
                        />
                        <Typography
                            sx={{
                                fontFamily: 'Cairo',
                                marginLeft: '10px'
                            }}
                        >
                            {nodes?.name}
                        </Typography>
                    </Box>
                }
            >
                {Array.isArray(nodes?.children)
                    ? nodes?.children?.map((node) => renderTree(node))
                    : null}
            </TreeItem>
        );
    };


    return (
        <div dir="rtl">
            <TreeView
                multiSelect
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronLeftIcon />}
                selected={selectedNodes}
            >
                {data?.length ? data?.map((node) => renderTree(node)) : null}
            </TreeView>
        </div>
    );

}
