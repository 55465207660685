import {
  Box,
  Button,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Grid from '../../Grid/Grid'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import Search from "./Search";
import userObject from "../../../Errors/Users/User";
import userAPI from "../../../Network/User";
import roleAPI from "../../../Network/Role";
import PopupForm from "../../../Components/PopupForm";
import Cookies from "universal-cookie";
import TreeCheckBoxes from "../../../Components/TreeCheckBoxes";
const cookies = new Cookies();




const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props?.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              backgroundColor:"#e7a52a",
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props?.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function UserRolesTree(props) {
  const [t] = useTranslation("common");
  const [roles, setRoles] = useState([])
  const [listOfSelectedRows, setListOfSelectedRows] = useState([])
  const [filterdRoles, setFilterdRoles] = useState([])
  const [roleName, setroleName] = useState()

  const [checkAllselected, setCheckAllselected] = useState(false)
  const [checkAllNotSelected, setCheckAllNotSelected] = useState(false)
  const [role, setRole] = useState('')

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,

  };

  const submit = async () => {
    if (!props?.object || !props?.object?.id) {
      toast.warn('USERS.please_select_a_user')
      return
    }

    try {
      let result = null;
     
      if (props?.object && props?.object?.id) {
        result = await userAPI.updateRoles({
          id: props?.object?.id,
          data: {
            role_ids: listOfSelectedRows?.filter(roleId=>!roleId?.includes("p-"))
          },
          params: {
            ...headerObject
          }
        });
      }

      if (result.status && result?.data?.status && result?.data?.data?.message) {
        toast.success(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        clearForm()
        props?.loadData()
        props?.setOpenForm(false)
      } else {


        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        // if (result && result?.data && result?.data?.data?.message) {
        //   if (result?.data?.data?.message?.text == "unauthorized to complete") {
        //     navigate("/redirect");
        //   }
        // }
      }

    } catch (error) {
      toast.error(error.message)
    }

  }

  const clearForm = () => {
    setListOfSelectedRows([])
  };

  useEffect(() => {
    if (!props?.object) clearForm();
    loadRolesData()

    getUserRoles(props?.object)
  }, [props?.openForm, props?.object]);

  const loadRolesData = async () => {
    let data = null;
    try {
      data = await roleAPI.getRoleList({
        params: {
          ...headerObject,

        }
      })
      if (data && data?.status) {
        // setRoles(data?.data?.data)
        const groupedItems = {};

        let originalData = data?.data?.data
        // Group items based on group_name
        originalData?.forEach(item => {
          const { group_name, id, name } = item;
          const parentId = `p-${item?.group_id}`;

          if (!groupedItems[parentId]) {
            groupedItems[parentId] = {
              id: parentId.toString(),
              name:group_name,
              children: []
            };
          }

          groupedItems[parentId]?.children?.push({
            id: id.toString(),
            name: name,
            parent: parentId
          });
        });

        // Convert the groupedItems object to an array
        const transformedData = Object.values(groupedItems);
        setRoles(transformedData)
        console.log("transformedData",transformedData);
      } else {
        toast.warn(t('NETWORK_MESSAGES.messageError'))
      }
    } catch (error) {
      console.log(error?.message)
    }
  }



  useEffect(() => {
    if (roles) {
      setFilterdRoles(roles)
    }
  }, [roles])

  const getUserRoles = async (user) => {

    if (user?.roles?.length) {
      setListOfSelectedRows(user?.roles?.map(id=>id.toString()))//get only ids 
    } else {
      setListOfSelectedRows([])
    }

  }

  return (
    <PopupForm
      isFullScreen={false}
      open={props?.openForm}
      setOpen={props?.setOpenForm}
      title={t("USERS.user_roles")}
      content={
        <TreeCheckBoxes
        setSelectedNodes={setListOfSelectedRows}
        selectedNodes={listOfSelectedRows}
        data={roles}
        />
        
      }
      footer={
        <RenderFooter
          open={props?.openForm}
          setOpen={props?.setOpenForm}
          submit={submit}
        />
      }
    />
  );
}
