import callAPI from '../ApiConfig'

const tags = async (props) => {


    return await callAPI({
        route: "tags/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addTag = async (props) => {


    return await callAPI({
        route: "tags/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editTag = async (props) => {

    return await callAPI({
        route: "tags/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteTag = async (props) => {


    return await callAPI({
        route: "tags/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}



const getTagList = async (props) => {

    return await callAPI({
        route: "tags/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const upload = async (props) => {

    let formdata = new FormData();

    formdata.append("files", props?.data?.file)

    return await callAPI({
        route: "tags/import",
        method: "post",
        signal: props?.signal,
        data: formdata,
        params: props?.params,
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            ...props?.headers
        }
    })
}


const downloadExcel = async (props) => {


    return await callAPI({
        route: "tags/download",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params
        },
        headers: {

        },
        responseType: "blob"

    })
}
const tagAPI = {
    tags: tags,
    addTag: addTag,
    editTag: editTag,
    deleteTag: deleteTag,
    getTagList: getTagList,
    upload: upload,
    downloadExcel: downloadExcel
}
export default tagAPI