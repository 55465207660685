import { Autocomplete, Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import helpers from '../../assets/js/helper';
import SearchInput from '../../Components/SearchInput';
import CustomDateRange from '../../Components/CustomDateRange';

const Search = ({ 
  filters, 
  setFilters,
  loadingAccounts,
  accounts,
  loadingUnitSections,
  loadingUnitTypes,
  unitSections,
  unitTypes,
}) => {
  const [t] = useTranslation("common");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={filters?.name}
        setValue={(value)=>{
          setFilters({
            ...filters,
            name:value
          })
        }}
        title={t('UNITS.name')}
      />
      <SearchInput
        value={filters?.transactions_total_less_than_from}
        setValue={(value)=>{
          if(!isNaN(value))
          {
            setFilters({
              ...filters,
              transactions_total_less_than_from:value
            })
          }
          
        }}
        title={t('UNITS.transactions_total_less_than_from')}
      />
      <SearchInput
        value={filters?.transactions_total_greater_than_from}
        setValue={(value)=>{
          if(!isNaN(value))
          {
            setFilters({
              ...filters,
              transactions_total_greater_than_from:value
            })
          }
          
        }}
        title={t('UNITS.transactions_total_greater_than_from')}
      />
      <SearchInput
        value={filters?.plate_number}
        setValue={(value)=>{
          setFilters({
            ...filters,
            plate_number:value
          })
        }}
        title={t('UNITS.plate_number')}
      />
      {/* <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        multiple={true}
        value={filters?.plate_types_selected?filters?.plate_types_selected:[]}
        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            plate_type_ids: v?.length>0?v?.map(object=>object?.id):[],
            plate_types_selected:v
          })
        }}
       
        options={plateTypes}
        loading={loadingPlateTypes}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.plate_type')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        multiple={true}
        value={filters?.plate_provinces_selected?filters?.plate_provinces_selected:[]}
        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            plate_province_ids: v?.length>0?v?.map(object=>object?.id):[],
            plate_provinces_selected:v
          })
        }}
        
        options={plateProvinces}
        loading={loadingPlateProvinces}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.plate_province')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        multiple={true}
        value={filters?.plate_letters_selected?filters?.plate_letters_selected:[]}
        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            plate_letter_ids: v?.length>0?v?.map(object=>object?.id):[],
            plate_letters_selected:v
          })
        }}
        options={plateLetters}
        loading={loadingPlateLetters}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.plate_letter')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      /> */}
      
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.accounts_selected ? filters?.accounts_selected : []}
        options={accounts}
        multiple={true}
        loading={loadingAccounts}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            account_ids: v?.length > 0 ? v?.map(user => user?.id) : [],
            accounts_selected: v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.accounts')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.unit_sections_selected ? filters?.unit_sections_selected : []}
        options={unitSections}
        multiple={true}
        loading={loadingUnitSections}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            unit_section_ids: v?.length > 0 ? v?.map(user => user?.id) : [],
            unit_sections_selected: v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.unit_section')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.unit_types_selected ? filters?.unit_types_selected : []}
        options={unitTypes}
        multiple={true}
        loading={loadingUnitTypes}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            unit_type_ids: v?.length > 0 ? v?.map(user => user?.id) : [],
            unit_types_selected: v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.unit_type')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TRANSACTIONS.carrier')}
          </Typography>

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.carrier}
            onChange={(e) => {
              setFilters({
                ...filters,
                carrier: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.carrier_governmental")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.carrier_private")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>
      <CustomDateRange
        dateRange={filters}
        setDateRange={(value) => {
          setFilters({
            ...filters,
            ...value
          })
        }}
      />
    </Box>
  );
};

export default Search