import { Box, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';


const GeofenceCodeTextField = ({
    branchCode, setBranchCode,
    portTypeCode, setPortTypeCode,
    portReturnCode, setPortReturnCode,
    portCategoryCode, setPortCategoryCode,
    portCode, setPortCode,
    geofenceFullCode, setGeofenceFullCode
}) => {
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);



    const handleInput = (event, ref, prevRef, maxLength, type) => {

        const input = event.target.value;
        const isBackspace = event.nativeEvent.inputType === 'deleteContentBackward';

        if (input.length >= maxLength && ref) {

            if (input.length == maxLength) {

                setGeofenceFullCode({
                    ...geofenceFullCode,
                    [type]: input
                })
            }

            ref.current.focus();
            ref.current.select();
        } else if (input.length === 0 && isBackspace) {

            setGeofenceFullCode({
                ...geofenceFullCode,
                [type]: input
            })
            prevRef.current.focus();
            prevRef.current.select();
        }
        else if (input.length < maxLength) {

            setGeofenceFullCode({
                ...geofenceFullCode,
                [type]: input
            })
        }

    };

    const handlePaste = (event) => {

        const clipboardData = event.clipboardData || window.clipboardData;
        const fullNumber = clipboardData.getData('text');

        if (!isNaN(fullNumber)) {


            const pastedValue = fullNumber;
            const paddedValue = pastedValue.padEnd(9, '0');
            const chunkLengths = [2, 2, 1, 1, 3];
            let startIndex = 0;
            const branchCode = paddedValue.slice(startIndex, startIndex + chunkLengths[0]);
            startIndex += chunkLengths[0];
            const portCategoryCode = paddedValue.slice(startIndex, startIndex + chunkLengths[1]);
            startIndex += chunkLengths[1];
            const portReturnCode = paddedValue.slice(startIndex, startIndex + chunkLengths[2]);
            startIndex += chunkLengths[2];
            const portTypeCode = paddedValue.slice(startIndex, startIndex + chunkLengths[3]);
            startIndex += chunkLengths[3];
            const portCode = paddedValue.slice(startIndex, startIndex + chunkLengths[4]);
            startIndex += chunkLengths[4];
            setGeofenceFullCode({
                branchCode: branchCode,
                portCategoryCode: portCategoryCode,
                portReturnCode: portReturnCode,
                portTypeCode: portTypeCode,
                portCode: portCode
            })
            // setBranchCode(branchCode);
            // setPortCategoryCode(portCategoryCode);
            // setPortReturnCode(portReturnCode);
            // setPortTypeCode(portTypeCode);
            // setPortCode(portCode);

        }
        else {
            console.log("not a number");
        }



    };


    return (
        <Box
            onPaste={handlePaste}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent:'center',
                alignItems:'center',
                marginTop: '20px',
                paddingLeft: '10px',
                paddingRight: '10px'
            }}
            style={{
                direction: 'ltr',
                textAlign: 'center'
            }}
        >

            <TextField
                inputRef={input1Ref}
                type="tel"
                pattern="[0-9]*"
                placeholder='الفرع'
                value={geofenceFullCode?.branchCode}
                onInput={(event) => handleInput(event, input2Ref, input1Ref, 2, "branchCode")}
                // value={branchCode}
                // onInput={(event) => handleInput(event, input2Ref, input1Ref, 2, setBranchCode)}
                style={{
                    direction: 'ltr',
                    textAlign: 'center',
                    flex:1
                }}
            />



            <TextField
                inputRef={input2Ref}
                type="tel"
                pattern="[0-9]*"
                placeholder='نوع المنفذ'
                value={geofenceFullCode?.portCategoryCode}
                onInput={(event) => handleInput(event, input3Ref, input1Ref, 2, "portCategoryCode")}
                // value={portCategoryCode}
                // onInput={(event) => handleInput(event, input3Ref, input1Ref, 2, setPortCategoryCode)}
                style={{
                    direction: 'ltr',
                    textAlign: 'center',
                    flex:1
                }}
            />



            <TextField
                inputRef={input3Ref}
                type="tel"
                pattern="[0-9]*"
                placeholder='عائدية المنفذ'
                value={geofenceFullCode?.portReturnCode}
                onInput={(event) => handleInput(event, input4Ref, input2Ref, 1, "portReturnCode")}
                // value={portReturnCode}
                // onInput={(event) => handleInput(event, input4Ref, input2Ref, 1, setPortReturnCode)}
                style={{
                    direction: 'ltr',
                    textAlign: 'center',
                    flex:1
                }}
            />


            <TextField
                inputRef={input4Ref}
                type="tel"
                pattern="[0-9]*"
                placeholder='حالة المنفذ'
                value={geofenceFullCode?.portTypeCode}
                onInput={(event) => handleInput(event, input5Ref, input3Ref, 1, "portTypeCode")}
                // value={portTypeCode}
                // onInput={(event) => handleInput(event, input5Ref, input3Ref, 1, setPortTypeCode)}
                style={{
                    direction: 'ltr',
                    textAlign: 'center',
                    flex:1
                }}
            />

            <TextField
                inputRef={input5Ref}
                type="tel"
                pattern="[0-9]*"
                placeholder='ترميز المنفذ'
                maxLength={3}
                value={geofenceFullCode?.portCode}
                onInput={(event) => handleInput(event, input5Ref, input4Ref, 3, "portCode")}
                // value={portCode}
                // onInput={(event) => handleInput(event, input5Ref, input4Ref, 3, setPortCode)}
                style={{
                    direction: 'ltr',
                    textAlign: 'center',
                    flex:1
                }}
            />

        </Box>
    );
}
export default GeofenceCodeTextField
