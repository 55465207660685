import callAPI from '../ApiConfig'
import $ from "jquery";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const routes = async (props) => {

    return await callAPI({
        route: "routes/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addRoute = async (props) => {


    return await callAPI({
        route: "routes/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editRoute = async (props) => {

    return await callAPI({
        route: "routes/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteRoute = async (props) => {


    return await callAPI({
        route: "routes/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getRouteList = async (props) => {

    return await callAPI({
        route: "routes/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const searchWialonRouteByCode = async (routeCode) => {

    const baseUrl = cookies.get("baseUrl");
    const sid = cookies.get("sid");
    const authHash = cookies.get("authHash");
    let resultObject = {
        status:false,
        data:{
            data:null,
            error:null,
        }
    }
    if (baseUrl && sid && authHash) {
        try {
            const response = await $.ajax({
                type: "GET",
                enctype: "application/json",
                processData: !1,
                contentType: !1,
                crossDomain: true,
                dataType: "jsonp",
                url: baseUrl + `/wialon/ajax.html?sid=${sid}&svc=core/search_items&params={"spec":{"itemsType":"avl_resource","propName":"zones_library","propValueMask":"*${routeCode}*","sortType":"sys_name","propType":"propitemname"},"force":1,"flags":4097,"from":0,"to":0}`,
                
            });
            
            let routesArray = []
            if(response?.items?.length)
            {
                
                for (let index = 0; index < response?.items?.length; index++) {
                    
                    const resource = response?.items[index];
                    
                    const resourceId = resource?.id
                    
                    if(!resource?.hasOwnProperty("zl") || resource?.id == parseInt(process.env.REACT_APP_LOCKED_RESOURCE_ID === undefined ?3291:process.env.REACT_APP_LOCKED_RESOURCE_ID))
                    {
                        continue
                    }
                    const routesIds = Object.keys(resource?.zl)
                    
                    for (let j = 0; j < routesIds.length; j++) {
                        
                        const routeObject = resource?.zl[routesIds[j]];
                        
                        if( routeObject?.n?.includes(routeCode) && (routeObject?.t == 1))
                        {
                            
                            routesArray.push({
                                id:resourceId+""+routesIds[j],
                                resource_id:resourceId,
                                geofence_id:routesIds[j],
                                name:routeObject?.n
                            })
                        }
                        
                    }
                    
                }
            }
            resultObject = {
                ...resultObject,
                status:true,
                data:{
                    ...resultObject?.data,
                    data:routesArray
                }
            }
            
            
        } catch (error) {
            console.log("Request failed:", error);
            resultObject = {
                ...resultObject,
                status:false,
                data:{
                    ...resultObject?.data,
                    error: error?.message
                }
            }
        }
    } else {
        cookies.set("active_session", "no", { path: "/" });
        resultObject = {
            ...resultObject,
            status:false,
            data:{
                ...resultObject?.data,
                error: "session expired"
            }
        }
    }
    
    return resultObject

}

const downloadExcel = async (props) => {


    return await callAPI({
        route: "routes/download",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params
        },
        headers: {

        },
        responseType:"blob"

    })
}
const routeAPI = {
    routes: routes,
    addRoute: addRoute,
    editRoute: editRoute,
    deleteRoute: deleteRoute,
    getRouteList: getRouteList,
    searchWialonRouteByCode: searchWialonRouteByCode,
    downloadExcel: downloadExcel
}
export default routeAPI