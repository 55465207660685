import React, { useState, useEffect } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDownGrid from "../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "universal-cookie";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import StarsIcon from "@mui/icons-material/Stars";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import FormContainer from "./Form/FormContainer";
import Title from "./Title";
import helpers from "../../assets/js/helper";
import activitiesAPI from "../../Network/activities/index";

const cookies = new Cookies();

export default function Activities() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);

  const [filters, setFilters] = useState({
    name: "",
    log_type_id: null,
    note: "",
    activitable_id: null,
    activitable_type: '',
    creation_date_from: '',
    creation_date_to: '',
    created_by_id: null
  })

  const [reload, setReload] = useState(false)

  const [openForm, setOpenForm] = useState(false);
  const [selectedObject, setselectedObject] = useState(null);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);


  const columns = [
    { field: "id", headerName: "#", minWidth: 10, maxWidth: 45 },
    { field: "name", headerName: t("ACTIVITIES.name"), minWidth: 110, maxWidth: 250, flex: 2 },
    { field: "note", headerName: t("ACTIVITIES.note"), minWidth: 260, maxWidth: 300, flex: 2 },
    { field: "created_at", headerName: t("ACTIVITIES.created_at"), minWidth: 180, maxWidth: 300, flex: 2 },
    { field: "created_by_id", headerName: t("ACTIVITIES.created_by_id"), minWidth: 130, maxWidth: 140, flex: 2,
    renderCell: (rows) => {
      let log_type = helpers?.getActivityEditor(rows?.row?.created_by_id)
      if (!log_type?.name) {
        log_type = { id: 0, name: t('GLOBAL.undefine') }
      }
      return (
        <Box sx={{fontSize: '12px'}}>
          {log_type?.name}
        </Box>
      )
    } 
   },
  
    { field: "log_type_id", headerName: t("ACTIVITIES.type"), minWidth: 140, maxWidth: 160, flex: 2,
    renderCell: (rows) => {
      let log_type = helpers?.getActivityType(rows?.row?.log_type_id)
      if (!log_type?.name) {
        log_type = { id: 0, name: t('GLOBAL.undefine') }
      }
      return (
        <Box sx={{fontSize: '11px'}}>
          {log_type?.name}
        </Box>
      )
    } 
  },]


  useEffect(() => {
    loadData()
  }, [pageNumber, pageSize, reload]);

  const loadData = async (searchParams) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        username: cookies.get("user_name") ? cookies.get("user_name") : null,
        user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
        account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
        account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
        authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
        sid: cookies.get("sid") ? cookies.get("sid") : null,
        baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
        id:cookies.get("id") ? cookies.get("id") : null,
        page: pageNumber,
        page_size: pageSize,
        search: "",
        ...searchParams,
        ...filters
      };

      setLoading(true);

      const result = await activitiesAPI.activities({
        params: object,
        signal: controller?.signal,
      });
      
      const usersResult = await activitiesAPI.getUsers({
        params: object,
        signal: controller?.signal,
      });

      const activityTypesResult = await activitiesAPI.getLogs({
        params: object,
        signal: controller?.signal,
      });
      
      setLoading(false);
    
      if (result.status && result?.data?.data?.data) {
        if (result?.data?.data?.data?.length > 0) {
          setRows(result?.data?.data?.data);
          setRowsTotal(result?.data?.data?.total_records);
          setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
        }
        else {
          setRows([]);
          setRowsTotal(0);
          setPageCount(0);
        }
        helpers.activitableTypes = activityTypesResult?.data?.data;
        helpers.users = usersResult?.data?.data?.data;
      } else {

        if(result?.data?.data?.message)
        {
          toast.error(result?.data?.data?.message?.text?result?.data?.data?.message?.text:result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };


  const createFunction = () => {
    setselectedObject(null);
    setOpenForm(true);
  };
  const updateFunction = (obj) => {
    setselectedObject(obj)
    setOpenForm(true)
  }

  const clearFilterFunction = () => {
    setFilters({
      name: "",
      note: "",
      creation_date_from: null,
      creation_date_to: null,
      created_by_id: null,
      activitable_id: null,
      activitable_type: ''
    })
  }
  const searchFunction = () => {
    
    setReload(!reload)
    setPageNumber(1)
  };

  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >

      {openForm ? (
        <FormContainer
          object={selectedObject}
          openForm={openForm}
          setOpenForm={setOpenForm}
          loadData={loadData}
        />
      ) : null}

      <Title
        createFunction={createFunction}
        openFilterColumn={openFilterColumn}
      />
      <Grid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={false}
        creatFunction={createFunction}


        filterChilds={
          <Search
            filters={filters}
            setFilters={setFilters}
          />
        }
      />

    </Box>
  );
}
