import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CustomCheckBox from './CustomCheckBox'
import SearchDateTime from './SearchDateTime'
import helpers from '../assets/js/helper'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const CustomDateRange = ({ dateRange, setDateRange,title,fromTitle, toTitle }) => {
    const [t] = useTranslation("common");
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
            }}
        >

            <Box
                sx={{
                    backgroundColor: "lightgray",
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "start",
                    ml: 1.5,
                    mt: 1,
                    mb: 1,
                    width: "100%",
                    "& .MuiTypography-root": {
                        fontFamily: "Cairo",
                    },
                }}
                className="px-2 rounded"
            >
                <Typography
                    sx={{
                        flex: 1,
                        fontFamily: 'Cairo',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {title?title:t('GLOBAL.choose_date')}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%'

                    }}
                >
                    <CustomCheckBox
                        label={<span style={{ fontFamily: 'Cairo' }}>{t('GLOBAL.all_time')}</span>}
                        notCheckedIcon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        setChecked={(value) => {
                            setDateRange({
                                ...dateRange,
                                all_time: value ? 1 : 0
                            })

                        }}
                        checked={dateRange?.all_time == 1}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        width: '100%'

                    }}
                >
                    <Typography
                        sx={{
                            flex: 1,
                            fontFamily: 'Cairo',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {t('GLOBAL.from')}
                    </Typography>
                    <SearchDateTime
                        disabled={dateRange?.all_time == 1}
                        value={dateRange?.creationDateFrom}
                        setValue={(value) => {

                            let datetimeString = null;

                            try {

                                const datetime = helpers.formatDateTime(value);
                                // if the input date is valid, format it to "YYYY-MM-DD hh:mm:ss"
                                datetimeString = datetime;


                            } catch (error) {
                            }
                            setDateRange({
                                ...dateRange,
                                creationDateFrom: value,
                                creation_date_from: datetimeString

                            })
                        }}
                        title={fromTitle?fromTitle:t("GLOBAL.creation_date_from")}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        width: '100%'

                    }}
                >
                    <Typography
                        sx={{
                            flex: 1,
                            fontFamily: 'Cairo',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {t('GLOBAL.to')}
                    </Typography>
                    <SearchDateTime
                        disabled={dateRange?.all_time == 1}
                        value={dateRange?.creationDateTo}
                        setValue={(value) => {
                            let datetimeString = null;
                            try {
                                const datetime = helpers.formatDateTime(value);
                                datetimeString = datetime;
                            } catch (error) {
                            }
                            setDateRange({
                                ...dateRange,
                                creationDateTo: value,
                                creation_date_to: datetimeString
                            })
                        }}
                        title={toTitle?toTitle:t("GLOBAL.creation_date_to")}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default CustomDateRange