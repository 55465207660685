import React, { useState } from 'react'
import { Typography, Tooltip } from '@mui/material';

function CustomLargeTextView({ text }) {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    return (
        <Tooltip title={text} open={isHovering} placement="top">
            <Typography
                variant="body1"
                sx={{
                    maxHeight: '20px',/* adjust as needed */
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap !important',
                    fontSize:'11px'
                }}
                style={{
                    direction:'rtl'
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {text}
            </Typography>
        </Tooltip>
    );
}

export default CustomLargeTextView

