import callAPI from '../ApiConfig'
import store from '../../app/store'

const profile = async (props) => {
    const storeState = store.getState();
    
    const username = "ext_user_"+props?.params?.user_id+"_"+props?.params?.account_id
    const id = props?.params?.id?props?.params?.id:0
    return await callAPI({
        route:"users/"+id,
        method:"get",
        signal:props?.signal,
        params:{
            ...props?.params,
            ...(!id>0? {
                wialon_custom_username:username
            }
            :
            {

            })
            
        },
        headers:{
            'Content-Type':'application/json',
            Accept:'application/json',
            ...props?.headers
        }
    })
    
}
const updateProfile = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"users/"+props?.params?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            // 'Content-Type':'multipart/form-data',
            Accept:'application/json'
        }
    })
    
}







const profileAPI={
    Profile:profile,
    UpdateProfile:updateProfile,

}
export default profileAPI