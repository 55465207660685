import { Box, Grid, Typography } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';

import CustomeSelectField from '../../../Components/CustomeSelectField';
import CloseIcon from "@mui/icons-material/Close";

const RenderContent = (props) => {
  const [t] = useTranslation("common");

  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.9)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "21120",
        }}
        onClick={() => {
          props?.setOpen(false);
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>

      <Box className="Container-fluid position-relative" sx={{ flexGrow: 1 }}>
        <Typography
          sx={{
            fontFamily: 'Cairo',
            marginLeft: '10px'
          }}
        >
          {props?.title}
        </Typography>
        <Grid container spacing={2} sx={{
          marginTop: '10px'
        }}>

          <Grid xs={12} sm={12} md={12} lg={12} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.select_wialon_unit")}
              haswidth={true}
              value={props?.object?.wialon_id?.value}
              list={props?.wialonUnits?.length ? props?.wialonUnits : []}
              error={props?.object?.wialon_id?.error}
              message={props?.object?.wialon_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {
                props?.setObject({
                  ...props?.object,
                  wialon_id: {
                    ...props?.object?.wialon_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {

                props?.setObject({
                  ...props?.object,
                  wialon_id: {
                    ...props?.object?.wialon_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RenderContent