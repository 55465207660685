import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Cookies from "universal-cookie";
import CustomDateRange from '../../Components/CustomDateRange';
const cookies = new Cookies();

const Search = ({ filters, setFilters, loadingBranches, branches, loadingTransactionStatuses, transactionStatuses }) => {
  const [t] = useTranslation("common");


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <CustomDateRange
        dateRange={filters}
        setDateRange={(value) => {
          setFilters({
            ...filters,
            ...value
          })
        }}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.branches_selected ? filters?.branches_selected : []}
        options={branches}
        multiple={true}
        loading={loadingBranches}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            branch_ids: v?.length > 0 ? v?.map(branch => branch?.id) : [],
            branch_names:v?.length > 0 ? v?.map(branch => branch?.name) : [],
            branches_selected: v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.branches_general')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

    </Box>
  );
};

export default Search