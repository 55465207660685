import callAPI from '../ApiConfig'
import $ from "jquery";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const transactions = async (props) => {

    return await callAPI({
        route: "transactions/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const transactionsProductTypesReport = async (props) => {

    return await callAPI({
        route: "transactions/product_type_report",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const transactionsSourceAndProductTypesReport = async (props) => {

    return await callAPI({
        route: "transactions/source_and_product_type_report",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}

const insertStopsInfoReport = async (props) => {

    return await callAPI({
        route: "transactions/insert_stops_info_report",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addTransaction = async (props) => {


    return await callAPI({
        route: "transactions/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editTransaction = async (props) => {

    return await callAPI({
        route: "transactions/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteTransaction = async (props) => {


    return await callAPI({
        route: "transactions/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getTransactionList = async (props) => {

    return await callAPI({
        route: "transactions/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}


const downloadExcel = async (props) => {


    return await callAPI({
        route: "transactions/download",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params
        },
        headers: {

        },
        responseType: "blob"

    })
}


const addPenddingReport = async (props) => {


    return await callAPI({
        route: "transactions/insert_pendding_report",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const downloadPenddingReport = async (props) => {


    return await callAPI({
        route: "transactions/download_transaction_report",
        method: "get",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}

const downloadPdf = async (props) => {


    return await callAPI({
        route: "transactions/download_pdf",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params
        },
        headers: {

        },
        responseType: "blob"

    })

}
const downloadTransactionPdf = async (props) => {


    return await callAPI({
        route: "transactions/download_transaction_pdf",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params
        },
        headers: {

        },
        responseType: "blob"

    })

}


const addCreatedgReport = async (props) => {


    return await callAPI({
        route: "transactions/insert_created_report",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}

const transactionStops = async (props) => {

    return await callAPI({
        route: "transactions/transaction_stops/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const transactionRoutes = async (props) => {

    return await callAPI({
        route: "transactions/transaction_routes/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}

const transactionProductTypes = async (props) => {

    return await callAPI({
        route: "transactions/transaction_product_types/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const transactionsDestinationAndProductTypesReport = async (props) => {

    return await callAPI({
        route: "transactions/destination_and_product_type_report",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}

const auditedTransactionByUsersReport = async (props) => {

    return await callAPI({
        route: "transactions/audited_transaction_by_users_report",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}

const transactionWithMonitoredUnitsReport = async (props) => {

    return await callAPI({
        route: "transactions/transaction_with_monitored_units_report",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}

const getTransactionType2List = async (props) => {

    return await callAPI({
        route: "transactions/transaction_type_2_list",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}
const missingDestinationsReport = async (props) => {

    return await callAPI({
        route: "transactions/missing_destinations_report",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const missingRoutesReport = async (props) => {

    return await callAPI({
        route: "transactions/missing_routes_report",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const transactionAPI = {
    transactions: transactions,
    addTransaction: addTransaction,
    editTransaction: editTransaction,
    deleteTransaction: deleteTransaction,
    getTransactionList: getTransactionList,
    downloadExcel: downloadExcel,
    addPenddingReport: addPenddingReport,
    downloadPenddingReport: downloadPenddingReport,
    downloadPdf: downloadPdf,
    addCreatedgReport: addCreatedgReport,
    transactionStops: transactionStops,
    transactionRoutes: transactionRoutes,
    transactionProductTypes: transactionProductTypes,
    transactionsProductTypesReport: transactionsProductTypesReport,
    transactionsSourceAndProductTypesReport: transactionsSourceAndProductTypesReport,
    transactionsDestinationAndProductTypesReport: transactionsDestinationAndProductTypesReport,
    downloadTransactionPdf: downloadTransactionPdf,
    auditedTransactionByUsersReport: auditedTransactionByUsersReport,
    transactionWithMonitoredUnitsReport: transactionWithMonitoredUnitsReport,
    getTransactionType2List: getTransactionType2List,
    insertStopsInfoReport: insertStopsInfoReport,
    missingDestinationsReport: missingDestinationsReport,
    missingRoutesReport: missingRoutesReport
}
export default transactionAPI