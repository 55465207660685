import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const DataRefreshComponent = ({ reload,setReload }) => {
    const [t] = useTranslation("common");
    const [isChecked, setIsChecked] = useState(false);
    const [secondsToReload, setSecondsToReload] = useState(10);

    useEffect(() => {
        let intervalId;

        if (isChecked) {
            intervalId = setInterval(() => {
                // Call your function to refresh data here
                
                setReload(prevReload => !prevReload);
            }, secondsToReload * 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [isChecked, secondsToReload]);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleSecondsChange = (e) => {
        const { value } = e.target;
        setSecondsToReload(Number(value));
    };

    return (
        <div
            style={{
                display: 'flex',
                backgroundColor:isChecked?'#17681b0A':'#E6E6E6',
                padding:'10px',
                borderRadius:'10px'

            }}
        >
            <label
                style={{
                    display: 'flex',

                }}
            >

                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                <Typography sx={{
                    fontFamily: 'Cairo',
                    marginLeft: '10px'
                }}>{t('GLOBAL.auto_refresh')}</Typography>
            </label>
            <input type="number" value={secondsToReload} style={{ width: '50px', marginLeft: '10px', marginRight: '10px' }} onChange={handleSecondsChange} disabled={!isChecked} />
            <Typography sx={{
                fontFamily: 'Cairo',
            }}>{t('GLOBAL.second')}
            </Typography>
        </div>
    );
};

export default DataRefreshComponent;
