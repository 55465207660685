import React, { useState, useEffect } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDownGrid from "../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "universal-cookie";
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import FormContainer from "./Form/FormContainer";
import Title from "./Title";
import { selectRoles } from "../../reduxStore/RolesReducer";
import geofenceGroupAPI from "../../Network/GeofenceGroup";

const cookies = new Cookies();

export default function GeofenceGroups() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);

  const [filters, setFilters] = useState({
    name: "",
    code: "",
    enabled: null,
  })
  const [reload, setReload] = useState(false)

  const [openForm, setOpenForm] = useState(false);
  const [selectedObject, setselectedObject] = useState(null);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };

  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const rolesRedux = useSelector(selectRoles)

  const columns = [
    { field: "id", headerName: "#", minWidth: 50, maxWidth: 100 },
    { field: "name", headerName: t("GEOFENCE_GROUPS.name"), minWidth: 110, maxWidth: 150, flex: 2 },
    // { field: "code", headerName: t("GEOFENCE_GROUPS.code"), minWidth: 110, maxWidth: 150, flex: 2 },
    {
      field: "enabled", headerName: t("GEOFENCE_GROUPS.enabled"), minWidth: 110, maxWidth: 150, flex: 1,
      renderCell: (rows) => (
        <>
          {rows?.row?.enabled ? (
            <MenuItem
              onClick={() => {

              }}
              className="ActionIcons"
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px 7px 0 0",
                }}
              >
                <CheckCircleIcon style={{ fill: "#388e3c" }} />
              </ListItemIcon>

            </MenuItem>
          ) : <MenuItem
            onClick={() => {

            }}
            className="ActionIcons"
          >
            <ListItemIcon
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "0px 7px 0 0",
              }}
            >
              <DoDisturbOnIcon style={{ fill: "#d32f2f" }} />
            </ListItemIcon>


          </MenuItem>}
        </>
      )
    },


    {
      headerName: t("GEOFENCE_GROUPS.actions"),
      field: "Actions",
      flex: 0.4,
      minWidth: 100,
      hide: !rolesRedux?.update_master?.value && !rolesRedux?.update_master?.value,
      renderCell: (rows) => (
        <DropDownGrid>
          {
            rolesRedux?.update_master?.value ?

              <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <EditIcon style={{ fill: "#17681b" }} />

                </ListItemIcon>
                {t('GLOBAL.action_edit')}
              </MenuItem> : null}


        </DropDownGrid>
      ),
    },
  ]


  useEffect(() => {
    loadData()
  }, [pageNumber, pageSize, reload]);

  const loadData = async (searchParams) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        username: cookies.get("user_name") ? cookies.get("user_name") : null,
        user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
        account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
        account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
        authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
        sid: cookies.get("sid") ? cookies.get("sid") : null,
        baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
        id: cookies.get("id") ? cookies.get("id") : null,
        page: pageNumber,
        page_size: pageSize,
        search: "",
        from_wialon: 0,
        ...searchParams,
        ...filters
      };

      setLoading(true);

      const result = await geofenceGroupAPI.geofenceGroups({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data?.data?.data) {




        if (result?.data?.data?.data?.length > 0) {
          setRows(result?.data?.data?.data);
          setRowsTotal(result?.data?.data?.total_records);
          setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
        }
        else {
          setRows([]);
          setRowsTotal(0);
          setPageCount(0);
        }
      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };

 


  const createFunction = () => {
    setselectedObject(null);
    setOpenForm(true);
  };
  const updateFunction = (obj) => {
    setselectedObject(obj)
    setOpenForm(true)
  }

  const clearFilterFunction = () => {
    setFilters({
      name: "",
      code: ""
    })
  }
  const searchFunction = () => {
    setReload(!reload)
    setPageNumber(1)
  };

  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >

      
      {openForm ? (
        <FormContainer
          object={selectedObject}
          openForm={openForm}
          setOpenForm={setOpenForm}
          loadData={loadData}
        />
      ) : null}


      <Title
        createFunction={createFunction}
        openFilterColumn={openFilterColumn}
      />
      <Grid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        hasImport={false}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={false}
        creatFunction={createFunction}


        filterChilds={
          <Search
            filters={filters}
            setFilters={setFilters}
          />
        }
      />

    </Box>
  );
}
