import { Box, Button } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const RenderFooter = (props) => {
    const [t] = useTranslation("common");
    const gridtheme = useSelector((state) => state.themeData.gridtheme);
  
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box display="flex">
            <Button
              variant="outlined"
              // spacing={2}
              sx={{
                width: "50% !important",
                // flexGrow: 1,
                minWidth: "80px !important",
                maxWidth: "80px !important",
                margin: 1,
                backgroundColor: "#d1d0d0",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px -2px white",
                //   color:SearchButtonTheme?.clear_button_text_color,
  
                "&:hover": {
                  backgroundColor: "#e7a52a",
                  borderColor: gridtheme?.colorWhite,
                  color: "#fff",
                  boxShadow: "0 0 7px 1px white",
                  boxShadow: "0",
                },
                height: "35px",
                fontFamily: "Cairo-Bold",
              }}
              className="iconeFilterClear"
              color="secondary"
              onClick={() => {
                // props.setOpen(false)
                props.clearForm();
              }}
            >
              {t("GLOBAL.clear")}
            </Button>
            <Button
              disabled={props?.isUploadingTime}
              variant="contained"
              // spacing={2}
              sx={{
                // flexGrow: 1,
                margin: 1,
                width: "80px !important",
                minWidth: "80px !important",
                maxWidth: "80px !important",
                backgroundColor: "#e7a52a",
                "&:hover": {
                  //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                },
                height: "35px",
                fontFamily: "Cairo-Bold",
              }}
              className="iconeFilterSearch"
              onClick={() => {
                props.submit();
              }}
            >
              {t("GLOBAL.Save")}
            </Button>
            <Button
              disabled={props?.isUploadingTime}
              variant="contained"
              // spacing={2}
              sx={{
                // flexGrow: 1,
                margin: 1,
                width: "280px !important",
                minWidth: "280px !important",
                maxWidth: "280px !important",
                backgroundColor: "#17681b",
                "&:hover": {
                  //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                },
                height: "35px",
                fontFamily: "Cairo-Bold",
              }}
              className="iconeFilterSearch"
              onClick={() => {
                props.submitAndRefresh();
              }}
            >
              {t("GLOBAL.Save_and_update_from_wialon")}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

export default RenderFooter