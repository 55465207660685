import { Box } from '@mui/material'
import React from 'react'
import DataGridHeader from './DataGridHeader'
import CustomFooter from './CustomFooter'
import DataGridRow from './DataGridRow'
import Preloading from '../../Pages/Preload/Preloading'

function CustomDataGrid({
   NavBarHeight,
   FooterHeight,
   columns,
   rows,
   loadData,
   setRows,
   setClickedCollapsed,
   ClickedCollapse,
   financialCustomerID,
   currentTabOpen,
   setCurrentTabOpen,
   currentUserOpen,
   setCurrentUserOpen,
   pageCount,
   page,
   setPage,
   setPageSize,
   pageSize,
   viewFunction,
   exportReport,
   createReport,
   showUpdate,
   UpdateFunction,
   auditFunction,
   createRouteFunction,
   loading,
   showRoutesFunction,
   showStopsFunction
}) {

   return (
      <>
         <Box sx={{
            backgroundColor: '#FFFFFF',
            overflow: 'auto',
            height: `calc(100vh - ${NavBarHeight + FooterHeight + 170}px)`, width: '100%'
         }}>
            <DataGridHeader columns={columns} />
            <Box sx={{
               direction: 'ltr',
               overflow: 'auto',
               minHeight: `calc(100vh - 350px)`,
               maxHeight: `calc(100vh - 350px)`,
            }}>
               {loading?<Preloading
               sx={{
                  direction: 'ltr',
                  overflow: 'auto',
                  position:'relative',
                  minHeight: `calc(100vh - 350px)`,
                  maxHeight: `calc(100vh - 350px)`,
               }}
               />:


               rows?.map((row, index) => <DataGridRow
                  loadData={loadData}
                  row={row}
                  viewFunction={viewFunction}
                  exportReport={exportReport}
                  createReport={createReport}
                  showUpdate={showUpdate}
                  UpdateFunction={UpdateFunction}
                  auditFunction={auditFunction}
                  createRouteFunction={createRouteFunction}
                  setClickedCollapsed={setClickedCollapsed}
                  showStopsFunction={showStopsFunction}
                  rows={rows}
                  setRows={setRows}
                  key={index + "_" + row?.id + "_customer"}
                  pageNumber={page}
                  showRoutesFunction={showRoutesFunction}
                 
                  ClickedCollapse={ClickedCollapse}
                  customerFromUrl={financialCustomerID}
                  currentTabOpen={currentTabOpen}
                  setCurrentTabOpen={setCurrentTabOpen}
                  currentUserOpen={currentUserOpen}
                  setCurrentUserOpen={setCurrentUserOpen}
               />)}
            </Box>
         </Box>

      </>
   )
}

export default CustomDataGrid