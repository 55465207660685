import callAPI from '../ApiConfig'
import store from '../../app/store'

const report = async (props) => {

    return await callAPI({
        route: "units/report",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}

const units = async (props) => {

    return await callAPI({
        route: "units/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addUnit = async (props) => {


    return await callAPI({
        route: "units/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editUnit = async (props) => {

    return await callAPI({
        route: "units/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteUnit = async (props) => {


    return await callAPI({
        route: "units/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getUnitList = async (props) => {

    return await callAPI({
        route: "units/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const refreshDataFromWialon = async (props) => {

    return await callAPI({
        route: "units/refresh_data_from_wialon",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}

const downloadExcel = async (props) => {


    return await callAPI({
        route: "units/download",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params
        },
        headers: {

        },
        responseType: "blob"

    })
}
const groups = async (props) => {


    return await callAPI({
        route: "units/groups",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params
        },
        headers: {

        },
    })
}


const unitAPI = {
    units: units,
    addUnit: addUnit,
    editUnit: editUnit,
    deleteUnit: deleteUnit,
    getUnitList: getUnitList,
    refreshDataFromWialon: refreshDataFromWialon,
    downloadExcel: downloadExcel,
    report: report,
    groups: groups
}
export default unitAPI