
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next";

import unitSectionObject, {
  objectMerge
} from "../../../Errors/Masters/Master";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import unitSectionAPI from "../../../Network/UnitSection";
import RenderFooter from "./RenderFooter";
import RenderContent from "./RenderContent";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();


export default function ImportFormContainer(props) {
  const [t] = useTranslation("common");
  
  const [unitSection, setUnitSection] = useState(unitSectionObject);
  const navigate = useNavigate();

  const submit = async (file) => {
    if (!file.name.endsWith('.xlsx')) {
      
      toast.error("يجب اختيار ملف نوع xlsx")
      // You can display an error message or take appropriate action here
    }
    confirmSubmit(file)

    return
    

  };
  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }
  const confirmSubmit = async (file) => {

    try {

      let params = {
        ...headerObject
      };

      let result = await unitSectionAPI.upload({
        data: {
          file: file
        },
        params: params,

      });


      if (result.status && result?.data?.data) {
        
        downloadExcel(result?.data?.data?.data?.result);
        if(result?.data?.status)
        {
          toast.success(result?.data?.data?.message);
          props?.loadData()
        }
        else{
          toast.error(result?.data?.data?.message);
        }
        
        props?.setOpenForm(false);
      }
      else{

        if (result?.data?.data?.message?.text == "unauthorized to complete") {
          navigate("/redirect");
        }

        else {

          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
      }
    } catch (error) {

      toast.error(error.message);
    }

  }

  const clearForm = () => {
    setUnitSection(unitSectionObject)
  };
  useEffect(() => {
    if (!props?.object) {
      clearForm();

    } else {
      const mergedObject = objectMerge(props?.object)
      setUnitSection(mergedObject)
    }
  }, [props?.openForm, props?.object]);


  const downloadExcel = async (fileName) => {
    const result = await unitSectionAPI?.downloadExcel({
      params: {
        ...headerObject,
        fileName: fileName
      }
    })
    if (result.status && result?.data) {
      var _url = window.URL.createObjectURL(result?.data);
      window.open(_url, "_blank").focus(); // window.open + focus
    }
  }

  return (
    <PopupForm
      open={props?.openForm}
      setOpen={props?.setOpenForm}
      title={
        t("UNIT_SECTIONS.import_form_title")
      }
      isFullScreen={false}
      customeHeight={'300px'}
      customWidth={'700px'}
      content={
        <RenderContent
          object={unitSection}
          setObject={(value) => {
            setUnitSection(value)
          }}
          submit={submit}
          open={props?.openForm}
          setOpen={props?.setOpenForm}
          isFullScreen={false}
        />
      }
      footer={
        // <RenderFooter
        //   open={props?.openForm}
        //   setOpen={props?.setOpenForm}
        //   submit={submit}
        //   clearForm={clearForm}
        // />
        null
      }
    />
  );
}
