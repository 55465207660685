import { Autocomplete, Box, FormControlLabel, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import helpers from '../../assets/js/helper';
import SearchInput from '../../Components/SearchInput';

const Search = ({filters,setFilters}) => {
  const [t] = useTranslation("common");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={filters?.name}
        setValue={(value)=>{
          setFilters({
            ...filters,
            name:value
          })
        }}
        title={t('TAGS.name')}
      />
      <SearchInput
        value={filters?.code}
        setValue={(value)=>{
          setFilters({
            ...filters,
            code:value
          })
        }}
        title={t('TAGS.code')}
      />
      <SearchInput
        value={filters?.main_account_name}
        setValue={(value)=>{
          setFilters({
            ...filters,
            main_account_name:value
          })
        }}
        title={t('UNITS.account_name')}
      />
      <SearchInput
        value={filters?.company_name}
        setValue={(value)=>{
          setFilters({
            ...filters,
            company_name:value
          })
        }}
        title={t('UNITS.company_name')}
      />
      
      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TAGS.enabled')}
          </Typography>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.enabled}
            onChange={(e) => {
              setFilters({
                ...filters,
                enabled:e?.target.value
              })
              
            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TAGS.enabled")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TAGS.disabled")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TAGS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TAGS.is_connected')}
          </Typography>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.is_connected}
            onChange={(e) => {
              setFilters({
                ...filters,
                is_connected:e?.target.value
              })
              
            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TAGS.connected")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TAGS.not_connected")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TAGS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>
      
    </Box>
  );
};

export default Search