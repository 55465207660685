// import React from 'react'
// import TextField from '@mui/material/TextField';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { Box } from '@mui/material';
// import ClearIcon from '@mui/icons-material/Clear';
// import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';

import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const SearchDateTime = ({ title, value, setValue,disabled }) => {

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button onClick={onClick} ref={ref}>
      {value}
    </Button>
  ));
  const ExampleCustomTimeInput = ({ date, value, onChange }) => {

    return (<>


      <select
        value={value?.split(":")[1].toString().padStart(2, '0')}
        onChange={(e) => {
          try {
            const minutes = parseInt(e.target.value);
            onChange(value?.split(":")[0].toString().padStart(2, '0') + ":" + minutes)
          } catch (error) {

          }

        }}
      >
        {[...Array(60).keys()].map((minute) => (
          <option key={minute} value={minute}>
            {minute.toString().padStart(2, '0')}
          </option>
        ))}
      </select>
      :
      <select
        value={value?.split(":")[0].toString().padStart(2, '0')}
        onChange={(e) => {
          try {
            const hours = parseInt(e.target.value);
            onChange(hours + ":" + value?.split(":")[1].toString().padStart(2, '0'))
          } catch (error) {

          }

        }}
      >
        {[...Array(24).keys()].map((hour) => (
          <option key={hour} value={hour}>
            {hour.toString().padStart(2, '0')}
          </option>
        ))}
      </select>
    </>
    )
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <DatePicker
        selected={value ? value : ""}
        onChange={(date) => setValue(date)}
        showTimeInput
        disabled={disabled}
        dateFormat="HH:mm  yyyy-MM-dd"
        placeholderText={title}
        customInput={<ExampleCustomInput />}
        customTimeInput={<ExampleCustomTimeInput />}
      />
    </Box>

  );

};



export default SearchDateTime;


