import { Box, Tooltip } from '@mui/material'
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

function CustomToggleStatus({ 
    trueOrFalse, 
    trueLabel, 
    falseLabel, 
    trueColor, 
    falseColor,
    ignoreTrue,
    ignorefalse }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {


                trueOrFalse ?
                    ignoreTrue ? <Box sx={{width:'24px'}}></Box> :
                        <Tooltip
                            title={
                                <span style={{ fontFamily: "Cairo-Medium" }}>
                                    {trueOrFalse
                                        ? trueLabel
                                        : falseLabel}
                                </span>
                            }
                        >
                            <CheckCircleIcon
                                sx={{
                                    fill: trueColor
                                }}
                            />
                        </Tooltip> :
                    ignorefalse ? <Box sx={{width:'24px'}}></Box>  :
                        <Tooltip
                            title={
                                <span style={{ fontFamily: "Cairo-Medium" }}>
                                    {trueOrFalse
                                        ? trueLabel
                                        : falseLabel}
                                </span>
                            }
                        >
                            <CancelIcon
                                sx={{
                                    fill: falseColor
                                }}
                            />
                        </Tooltip>}


        </Box >
    )
}

export default CustomToggleStatus