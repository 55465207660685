import { Box, TextField } from '@mui/material'
import React from 'react'

import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux'
import { ContainerOfInputFields } from './ThemDesign';

function CustomTextField({
    label,error,message,value,onChange,onClearClick,readOnly, type,haswidth,hasMultipleLine, customWidth,customPadding,
    focused,paddingHorizontal,inputPropStyle
}) {
    const maintheme=useSelector(state=>state.themeData.maintheme);
    const inputRef = React.useRef(null);

    // React.useEffect(() => {
    //    if(inputRef){
    //     inputRef.current.focus()
    //    }
    // }, [inputRef.current]);
    return (
        <ContainerOfInputFields
            mainTheme={maintheme}
            customWidth={customWidth?customWidth:"100%"}
            isForm={true}
            haswidth={haswidth?true:false}
            hasMultiLine={hasMultipleLine?true:false}
            paddingHorizontal={paddingHorizontal}
        >
            <Box
                sx={{
                    position: "relative",
                    // margin: "5px 0",
                    width: "100%",
                    backgroundColor: "#fff",
                    padding:customPadding?customPadding:'auto'
                }}
                className="orderdata"
            >
                <TextField
                    
                    variant="filled"
                    label={label}
                    value={value}
                    onChange={(e) => onChange && onChange(e)}
                    type={type? type:'text'}
                    focused={focused?focused:true}
                    // ref={inputRef}

                    autoComplete="new-password"
                    multiline={hasMultipleLine?true:false}
                    InputProps={{
                        readOnly:readOnly,
                       
                        
                    }}
                    inputProps={{
                        style:{
                            ...inputPropStyle
                        }
                        
                    }}
                    
                    
                    className={`${error ? 'errors' : ''}`}
                   
                    
                />
                {(value && !readOnly) ? (
                    <CloseIcon
                        className="closeIcon"
                        onClick={() => onClearClick && onClearClick()}
                    />
                ) : null}
            </Box>
            {
                error && message?.length ?
                    <Box sx={{
                        height: 'fit-content',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        width: '95%',
                    }}>
                        {
                            message && message?.length > 0 ? message?.map((messg) => (
                                <span style={{ fontFamily: 'Cairo-Bold', fontSize: '14px', color: 'red', height: 'auto' }}>{messg}</span>
                            )) : null
                        }
                    </Box> : null}
        </ContainerOfInputFields>
    )
}

export default CustomTextField