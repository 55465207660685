import {styled} from '@mui/material/styles'
import {Box,Typography} from "@mui/material"


export const WrapperOfLogin=styled(Box)(({theme,logintheme,screenwidth})=>({
    width:screenwidth<=900?'96vw':'100vw',
    height:'100vh',
    // background: 'transparent linear-gradient(241deg, #4156A6 100%, #5CC8E5 200%) 0% 0% no-repeat padding-box',
    opacity: 1,
    background: logintheme?logintheme?.platte_background_color:"linear-gradient(241deg, #4156A6 100%, #5CC8E5 100%)",
    // overflow:'hidden !important',
    // overflowX:'hidden !important',
    margin:0,
    // overflowX:'scroll'
    
}))
export const ContainerOfLoginForm=styled('div')(({theme,logintheme,screenwidth})=>({
    background:logintheme?.paperColor,
    // boxShadow: "0px 3px 80px #0000001A",
    width:screenwidth<=900?'100%':'80vw',
    zIndex:10,
    margin:0,
    minHeight:screenwidth<=900?'100vh':'600px',
    // overflowY:window.innerHeight<=850?'scroll ':'hidden',
    overflowX:'hidden',
    position:'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent:'center',
    flexDirection:screenwidth<=1000?'column':'column',
    '& .wrapperLoginContent':{
        position:'relative',
        width:'100%',
        // minHeight:screenwidth<=900?'650px':'600px',
        background: `${logintheme?.paperColor} 0% 0% no-repeat padding-box`,
        boxShadow: "0px 3px 80px #0000001A",
        opacity: 1,
        width:screenwidth<=900?'100%':'80vw',
        margin:0,
        minHeight:screenwidth<=900?'750px':'600px',
        overflowY:window.innerHeight<=850?'scroll ':'hidden',
        overflowX:'hidden',
        position:'relative',
        padding:screenwidth<=700?'0 20px':'0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent:'center',
        flexDirection:screenwidth<=1000?'column-reverse':'row',
    },
    '& .footer-message':{
        position:'relative',
        bottom:'-0px',
        // margin:screenwidth<=1200?'20px auto':'30px auto',
        backgroundColor: `${logintheme?.paperColor}`,
        textAlign: 'center',
        width:'100%',
        height:'100%',
        display: 'flex',    
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap:'wrap',
        marginBottom:'15px',
        textAlign: "left", 
        font: "normal normal 300 16px/37px Cairo-Light", 
        letterSpacing: "0px", 
        color: logintheme?.primaryColor, 
        opacity: "1"
    },
    overflow: "hidden",
    transition:'all 0.9s ease',
    // display:'none'
    
}))
export const TextLogin=styled(Typography)(({theme,logintheme,screenwidth})=>({
    position:'absolute',
    top:screenwidth<900?"12%":'5%',
    left:screenwidth>1500?'7%':'5%',
    textAlign: 'left',
    font: 'normal normal 300 16px/37px Cairo-Light',
    letterSpacing:' 0px',
    color: logintheme?.primaryColor,
    opacity: 1,
    cursor: 'pointer',
}))
export const RightSide=styled(Box)(({theme,logintheme,screenwidth})=>({
    display:'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginTop:screenwidth<=1200?screenwidth<=1000?'110px':'50px':'20px',
    width:screenwidth<=1200?'100%':'50%',
    height:screenwidth<=1000?'50%':'100%',
    flexDirection:'column',
    alignContent: 'center',
    padding:'auto 10px',
    '& .ContainerOfRight':{
        // width:'100%',
        marginLeft:screenwidth<=700?0:screenwidth<=1200?screenwidth<=700?'30px':'50px':screenwidth<=1480?'150px':'170px',
        width:screenwidth<=700?'100%':'501px'
    },
    "& .subContainerRight":{
        marginLeft: screenwidth<=1200?'0px':"0",
        display: "flex",
        flexDirection: "column",
        width:screenwidth<=700?'100%':'446px !important',
    },
    '& .welcome-message':{
        textAlign: screenwidth<=700?"center":"left", 
        font: `normal normal 600 35.5px/81px Cairo-SemiBold`, 
        letterSpacing: "0px", 
        color: logintheme?.primaryColor, 
        fontSize:screenwidth<=480?'20px':screenwidth<=700?'25px':'25.5px',
        opacity: "1",
        width:'100%',
        display: "block",
        wordWrap:'nowrap !important',
        overflowWrap:'nowrap !important',
        inlineSize:'90%',
        minWidth:screenwidth<=700?"100%":'90%',
        textJustify:' inter-character',

    },
    "& .Introductory-message":{
        textAlign: screenwidth<=700?"center":"left", 
        font: "normal normal 300 16px/25px Cairo-Light", 
        letterSpacing: "0px", 
        color: logintheme?.primaryColor, 
        opacity: "1",
        marginLeft:'0px',
        width:screenwidth<=700?"100%":'90%',
        display: "inline-block",


    },
    '& .user-name,& .user-password':{
        background: "#FFFFFF 0% 0% no-repeat padding-box !important", 
        boxShadow: "0px 0px 40px 10px #00000014 !important", 
        opacity: "1 !important",
        width:screenwidth<=700?"100%":'95%',
        position:'relative',
    },
    '& .user-name':{
        marginTop:screenwidth<=1200?'30px':'40px',
    },
    '& .user-password':{
        marginTop:screenwidth<=1200?'22px':'26px',
    },
    '& .MuiTextField-root,& .MuiTextField-root .MuiFilledInput-root':{
        height: '60px !important',
        background: "#FFFFFF 0% 0% no-repeat padding-box !important", 
        // boxShadow: "0px 12px 40px #00000014 !important", 
        opacity: "1 !important",
    },
    '& .MuiFilledInput-root:before':{
        border:'none !important',
        outline:'none !important',
    },
    '& .MuiFilledInput-root:after':{
        border:'none !important',
        outline:'none !important',
    },
    '& .MuiFilledInput-root.Mui-focused:before':{
        top:0,
        left:0,
        bottom:0,
        width:'5px !important',
        height: '100% !important',
        background: logintheme?.secondaryColor,
        overflow: "hidden",
        transition:'all 0.9s ease'
    },
    '& .MuiTextField-root .MuiFormLabel-root':{
        textAlign: "left", 
        font: "normal normal normal 12px/26px Cairo", 
        letterSpacing: "0.7px", 
        color: logintheme?.primaryColor, 
        opacity: "0.5"
    },
    '& .MuiTextField-root .MuiFilledInput-input':{
        textAlign: "left !important", 
        font: "normal normal 13px/25px Cairo-Medium !important", 
        letterSpacing: "0.9px !important", 
        color: `${logintheme?.primaryColor}     !important`,  
        opacity: "1",
        height: "30px !important",
        paddingRight:'55px !important',
        // width:'81%'
    },
    '& .passwordIcon':{
        position: "absolute",
        top:'53%',
        right:'10%',
        color:logintheme?.secondaryColor,
        cursor: "pointer",
    } ,
    '& .checkbox-remember-me':{
        marginTop:'10px',
    },
    '& .checkbox-remember-me .MuiTypography-root':{
        textAlign: "left", 
        font: "normal normal  14px/37px Cairo-Medium", 
        letterSpacing: "1px", 
        color: logintheme?.primaryColor, 
        opacity: "1"
    },
    '& .checkbox-remember-me .MuiCheckbox-root svg path':{
        stroke: logintheme?.secondaryColor, 
        fill:logintheme?.secondaryColor,
        color:logintheme?.secondaryColor,
        opacity: "1",
        borderRadius: "5px !important",
        strokeLinecap:'round'
    },
    "& .login-text":{
        textAlign: "left", 
        textDecoration: "underline", 
        font: "normal normal 16px/37px Cairo-Medium", 
        letterSpacing: "1px", 
        color: "#707070", 
        opacity: "1",
        cursor: "pointer", 
        margin:'0 10px'
    },
    '& .login-img':{
        background: "#51D9F0 0% 0% no-repeat padding-box", 
        opacity: "1",
        width: '25px',
        height: '25px',
        cursor: "pointer",
    },
    '& .forget-password':{
        textAlign: "left", 
        textDecoration: "underline", 
        font: "normal normal 16px/37px Cairo-Medium", 
        letterSpacing: "1px", 
        color: logintheme?.primaryColor, 
        opacity: "1",
        marginTop:'10px',
        cursor: "pointer",
    },

}))
export const LeftSide=styled(Box)(({theme,logintheme,screenwidth})=>({
    display:'flex',
    // justifyContent: 'center',
    alignItems: screenwidth<=1000?'center':'end',
    width:screenwidth<=1000?'100%':'50%',
    height:screenwidth<=1000?'50%':'100%',
    flexDirection:'column',
    alignContent: 'end',
    justifyContent: 'center',
    // alignItems: 'center',
    '& .ContainerOfLeft':{
        marginRight:'110px',
        display:'flex',
        flexDirection:'column',
    },
    '& img':{
        width:screenwidth<=1000?screenwidth<=850?'150px':'190px':'255px',
        height:screenwidth<=1000?screenwidth<=850?'130px':'190px':'255px',
        backgroundSize:'cover',
        backgroundPosition:'center center',
        backgroundRepeat:'no-repeat',
        position:screenwidth<=1000?'relative':'relative',
        top:screenwidth>1000?'0':'60px !important',
        left:screenwidth<=1000?'30%':'0',
        display:'block',
        margin:'0 auto',
        
        
    },
    '& .ContainerOfLeft_texts':{
        width:'250px',
        display:'flex',
        flexDirection:'column',
    },
    '& .company-name':{
        textAlign: 'center',
        font: 'normal normal 20px/39px Cairo-Medium',
        letterSpacing: '0px',
        color: logintheme?.primaryColor,
        display:screenwidth>1000?'inline-block':'none !important',
    },
    '& .company-title':{
        textAlign: "center", 
        font: "normal normal 200 16px/20px Cairo-ExtraLight", 
        letterSpacing: "0px", 
        color: logintheme?.primaryColor,
        display:screenwidth>1000?'inline-block':'none !important',
    }
}))
export const TitleText=styled(Typography)(({theme,logintheme})=>({
    textAlign: "left", 
    font: "normal normal 600 20px/81px Cairo-SemiBold", 
    letterSpacing: "0px", 
    color: logintheme?.primaryColor, 
    opacity: "1",
}))
export const ContainerOfInputLogin=styled(Box)(({theme,logintheme,customeWidth})=>({
    boxShadow: "0px 12px 40px #00000014 !important", 
    width:customeWidth?customeWidth:'100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin:'30px auto',
    '& .MuiTextField-root,& .MuiTextField-root .MuiFilledInput-root':{
        height: '60px !important',
        background: "#FFFFFF 0% 0% no-repeat padding-box !important", 
        // boxShadow: "0px 12px 40px #00000014 !important", 
        opacity: "1 !important",
    },
    '& .MuiFilledInput-root:before':{
        border:'none !important',
        outline:'none !important',
    },
    '& .MuiFilledInput-root:after':{
        border:'none !important',
        outline:'none !important',
    },
    '& .MuiFilledInput-root.Mui-focused:before':{
        top:0,
        left:0,
        bottom:0,
        width:'5px !important',
        height: '100% !important',
        background: logintheme?.secondaryColor,
        overflow: "hidden",
        transition:'all 0.9s ease'
    },
    '& .MuiTextField-root .MuiFormLabel-root':{
        textAlign: "left", 
        font: "normal normal normal 12px/26px Cairo", 
        letterSpacing: "0.7px", 
        color: logintheme?.primaryColor, 
        opacity: "0.5"
    },
    '& .MuiTextField-root .MuiFilledInput-input':{
        textAlign: "left !important", 
        font: "normal normal 13px/25px Cairo-Medium !important", 
        letterSpacing: "0.9px !important", 
        color: `${logintheme?.primaryColor}     !important`,  
        opacity: "1",
        height: "30px !important",
        paddingRight:'55px !important',
        // width:'81%'
    },
}))
export const ContainerOfRecivedSMSCode=styled(Box)(({theme,logintheme,customeWidth})=>({
    // boxShadow: "0px 12px 40px #00000014 !important", 
    width:customeWidth?customeWidth:'100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin:'30px auto',
    '& .MuiTextField-root,& .MuiTextField-root .MuiFilledInput-root':{
        height: '55px !important',
        width:'35px !important',
        background: "#FFFFFF 0% 0% no-repeat padding-box !important", 
        // boxShadow: "0px 12px 40px #00000014 !important", 
        opacity: "1 !important",
        boxShadow: "0px 3px 6px #00000029", 
        borderRadius: "12px", 
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        display:'none',
        margin: 0
    },
    '& .MuiFilledInput-root:before':{
        border:'none !important',
        outline:'none !important',
    },
    '& .MuiFilledInput-root:after':{
        border:'none !important',
        outline:'none !important',
    },
    '& .MuiFilledInput-root':{
        border: `3px solid ${logintheme?.primaryColor}`, 
    },
    '& .MuiFilledInput-root.Mui-focused':{
        border: `3px solid ${logintheme?.secondaryColor}`, 
    },
    '& .MuiFilledInput-root.Mui-focused:before':{
        top:0,
        left:0,
        bottom:0,
        right:0,
        width:'100px !important',
        height: '100% !important',
        border:`1px solid ${logintheme?.secondaryColor}`,
        // background: "#51D9F0",
        overflow: "hidden",
        transition:'all 0.9s ease'
    },
    '& .MuiTextField-root .MuiFormLabel-root':{
        textAlign: "left", 
        font: "normal normal normal 12px/26px Cairo", 
        letterSpacing: "0.7px", 
        color: logintheme?.primaryColor, 
        opacity: "0.5"
    },
    '& .MuiTextField-root .MuiFilledInput-input':{
        textAlign: "left !important", 
        font: "normal normalC300 18px/56px Cairo-Light !important", 
        letterSpacing: "1.5px !important", 
        color: `${logintheme?.primaryColor} !important`,  
        opacity: "1",
        height: "100% !important",
        width:'100% !important',

        textAlign: "left", 
        font: "normal normal 300 30px/56px Cairo", 
        letterSpacing: "1.5px", 
        padding:'0 !important',
        textAlign:'center', 
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            display: "none"
          }
   
     },
     '& .MuiTextField-root .MuiFilledInput-input::-webkit-outer-spin-button':{
        appearance: 'none !important',
     },

     
    '& .sms-code':{
        margin:'0 15px'
    }
}))
export const ContainerOfNewPasswordFields=styled(Box)(({theme,logintheme,customeWidth})=>({
    // boxShadow: "0px 12px 40px #00000014 !important", 
    // background: "#FFFFFF 0% 0% no-repeat padding-box !important", 
    // boxShadow: "0px 0px 40px 10px #00000014 !important", 
    opacity: "1 !important",
    width:'65%',
    position:'relative',
    display:'flex',
    flexDirection:'column',
    alignContents:'center',
    margin:'0 auto',
    justifyContent:'center',
    alignItems:'center',
    '& .MuiTextField-root,& .MuiTextField-root .MuiFilledInput-root':{
        height: '60px !important',
        width:'100%',
        background: "#FFFFFF 0% 0% no-repeat padding-box !important", 
        boxShadow: "0px 12px 40px #00000014 !important", 
        opacity: "1 !important",
    },
    '& .MuiFilledInput-root:before':{
        border:'none !important',
        outline:'none !important',
    },
    '& .MuiFilledInput-root:after':{
        border:'none !important',
        outline:'none !important',
    },
    '& .MuiFilledInput-root.Mui-focused:before':{
        top:0,
        left:0,
        bottom:0,
        width:'5px !important',
        height: '100% !important',
        background: logintheme?.secondaryColor,
        overflow: "hidden",
        transition:'all 0.9s ease'
    },
    '& .MuiTextField-root .MuiFormLabel-root':{
        textAlign: "left", 
        font: "normal normal normal 12px/26px Cairo", 
        letterSpacing: "0.7px", 
        color: logintheme?.primaryColor, 
        opacity: "0.5"
    },
    '& .MuiTextField-root .MuiFilledInput-input':{
        textAlign: "left !important", 
        font: "normal normal 13px/25px Cairo-Medium !important", 
        letterSpacing: "0.9px !important", 
        color: `${logintheme?.primaryColor}     !important`,  
        opacity: "1",
        height: "30px !important",
        paddingRight:'55px !important',
        // width:'81%'
    },
    '& .passwordIcon':{
        position: "absolute",
        top:'20px',
        right:'15px',
        color:logintheme?.secondaryColor,
        cursor: "pointer",
    } ,
  
}))