
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import { viewObjectKeys } from "../../../Errors/Transactions/Transaction";


import Cookies from "universal-cookie";
import { Box, Button, Grid } from "@mui/material";
import CustomTextField from "../../../Components/CustomTextField";

const cookies = new Cookies();



export default function ViewFormContainer(props) {
  const [t] = useTranslation("common");
  const [transaction, setTransaction] = useState(null);



  useEffect(() => {

    setTransaction(props?.object)

  }, [props?.openForm, props?.object]);



  return (
    <PopupForm
      open={props?.openForm}
      setOpen={props?.setOpenForm}
      title={t("TRANSACTIONS.view_title")}
      isFullScreen={false}

      content={<Box className="Container-fluid position-relative" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{
          marginTop: '10px'
        }}>
          {viewObjectKeys?.length ? viewObjectKeys?.map(viewObjectKey => {
            return <Grid xs={6} sm={6} md={6} lg={6} sx={{
              padding: '0px'
            }}>
              <CustomTextField
                label={t("TRANSACTIONS." + viewObjectKey)}
                hasMultipleLine={true}
                value={transaction?.hasOwnProperty(viewObjectKey) ? transaction[viewObjectKey] : ""}
                haswidth={true}
                readOnly={true}
              />

            </Grid>
          }) : null}

        </Grid>
      </Box>

      }
      footer={
        <Button
          variant="outlined"
          // spacing={2}
          sx={{
            width: "50% !important",
            // flexGrow: 1,
            minWidth: "80px !important",
            maxWidth: "80px !important",
            margin: 1,
            backgroundColor: "#d1d0d0",
            borderColor: "#ffffff",
            color: "#000000",
            boxShadow: "0 0 7px -2px white",
            //   color:SearchButtonTheme?.clear_button_text_color,

            "&:hover": {
              backgroundColor: "#e7a52a",
              borderColor: "#ffffff",
              color: "#fff",
              boxShadow: "0 0 7px 1px white",
              boxShadow: "0",
            },
            height: "35px",
            fontFamily: "Cairo-Bold",
          }}
          className="iconeFilterClear"
          color="secondary"
          onClick={() => {

            props?.setOpenForm(false);
          }}
        >
          {t("GLOBAL.close")}
        </Button>
      }
    />
  );
}
