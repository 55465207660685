import { Box, Divider, Tooltip, Typography } from '@mui/material'
import React from 'react'
import AddIcon from "@mui/icons-material/Add";
import * as BiIcons from "react-icons/bi";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectRoles } from '../../reduxStore/RolesReducer';
import DataRefreshComponent from '../../Components/DataRefreshComponent';

function Title({ title, secondaryTitle }) {
    const [t] = useTranslation("common");
    const rolesRedux = useSelector(selectRoles)
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        marginLeft: "10px",
                        marginBottom: "10px",
                        fontFamily: "Cairo-Bold",
                        fontSize: "23px",
                        letterSpacing: "-1px",
                    }}
                >
                    {title}
                </Typography>
            </Box>
            <Divider
                sx={{
                    backgroundColor: "#EBF2F7",
                    margin: "30px",
                    marginTop: "10px",
                    width: "100%",
                }}
                style={{
                    height: "3px",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        marginLeft: "10px",
                        marginBottom: "10px",
                        fontFamily: "Cairo-Bold",
                        fontSize: "23px",
                        letterSpacing: "-1px",
                    }}
                >
                    {secondaryTitle}
                </Typography>
            </Box>
        </>
    )
}

export default Title