import React from 'react'
import './Preloading.css'
import s11 from '../../assets/img/row-1-column-1.png'
import s12 from '../../assets/img/row-1-column-2.png'
import s13 from '../../assets/img/row-1-column-3.png'

import s21 from '../../assets/img/row-2-column-1.png'
import s22 from '../../assets/img/row-2-column-2.png'
import s23 from '../../assets/img/row-2-column-3.png'

import s31 from '../../assets/img/row-3-column-1.png'
import s32 from '../../assets/img/row-3-column-2.png'
import s33 from '../../assets/img/row-3-column-3.png'


export default function Preloading({sx}) {
  const imageUrl = [
    s11, s12, s13, s21, s22, s23, s31, s32, s33,
  ]
  return (
    <div className='containerOfPreloading' 
    style={{
      ...sx
    }}
    >
      <section>
        <div className='sk-cube-grid'>
          {imageUrl?.map((url, index) => {
            return (
              <div key={"preloading_key_"+index} className={'sk-cube sk-cube-'+(index+1)}
                style={{
                  backgroundImage: `url(${url})`,
                }}
              ></div>
            )
          })}

        </div>
      </section>
    </div>
  )
}
