import React, { useEffect, useRef } from 'react'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';

import { Drawer, AppBar, DrawerHeader } from './SideMenuTheme'
import { useSelector, useDispatch } from 'react-redux';
import HeaderDrawer from '../Components/HeaderDrawer';
import SideMenuItemArray from './SideMenuItemArray';
import { useTranslation } from "react-i18next";
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation } from 'react-router-dom'

import DashboardIcon from '@mui/icons-material/Dashboard';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { TbCurrentLocation } from 'react-icons/tb'
import { FaTruckMoving } from 'react-icons/fa'
import { BsTruck } from "react-icons/bs";
import { RiTruckFill } from "react-icons/ri";
import { MdSettings } from "react-icons/md";
import { TbArrowsTransferDown } from "react-icons/tb";
import { FaDigitalTachograph } from 'react-icons/fa'
import { IoSettings } from "react-icons/io5";
import { FaTags } from "react-icons/fa";
import { MdAccountTree } from "react-icons/md";
import { MdCategory } from "react-icons/md";
import { BsFillEyeFill, BsGeoFill, BsGeo, BsFillFuelPumpFill } from 'react-icons/bs'
import { IoStatsChartSharp } from "react-icons/io5";
import { MdLocationDisabled } from "react-icons/md";
import { TbRouteOff } from "react-icons/tb";
import { MdAssessment, MdOutlineWarehouse } from 'react-icons/md'
import { TbReportSearch } from 'react-icons/tb'
import { TbRoute } from 'react-icons/tb'
import { IoReceipt } from 'react-icons/io5'
import { IoNewspaper } from "react-icons/io5";
import { TbTruckOff } from "react-icons/tb";
import { IoIosPeople } from 'react-icons/io'
import { toast } from 'react-toastify';

import { Outlet } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'
import WialonLogo from "../assets/img/Logo.png";
import OPDCLogo from "../assets/img/opdc.png"
import SalamLogo from "../assets/img/salam.png"
import { setIsOpenSideMenu } from '../reduxStore/SettingsReducer'
import profileAPI from '../Network/Profile';
import { selectRoles } from '../reduxStore/RolesReducer';

const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL}`,
}
const cookies = new Cookies()
const tempImgURL = "https://images.unsplash.com/photo-1630945386735-372fbe731e3f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fHBlcG9sZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"

export default function SideMenu() {
  const [t] = useTranslation("common");
  const location = useLocation()
  const rolesRedux = useSelector(selectRoles)
  const drawerwidth = useSelector(state => state.settingsData.drawerwidth)
  const sidemenutheme = useSelector(state => state.themeData.sidemenutheme)
  const appbarTheme = useSelector(state => state.themeData.appbar)
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch()
  const [userInfo, setUserInfo] = React.useState();
  const globalLoading = useSelector(state => state.settingsData.globalLoading)
  const MainContainerRef = useRef(null);
  const isopensidemenu = useSelector(
    (state) => state.settingsData.isopensidemenu
  );
  useEffect(() => {
    setOpen(isopensidemenu);
  }, [isopensidemenu]);
  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(setIsOpenSideMenu(true))
    if (!isopensidemenu) {
      // dispatch(setCurrentlyOpendMenuItem(copyOfcurrentOpenMenuItem))
      handleOpenClosedMenu()
    }
  };
  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(setIsOpenSideMenu(false))
  };
  const navigate = useNavigate();

  const getUserData = async () => {
    let data = null
    try {
      let params = {
        username: cookies.get('user_name'),
        user_id: cookies.get('user_id'),
        account_name: cookies.get('account_name'),
        account_id: cookies.get('account_id'),
        sid: cookies.get('sid'),
        baseurl: cookies.get('baseUrl')
      }


      data = await profileAPI.Profile({
        params: params,
      });
      if (data && data?.status) {
        if (data?.data && data?.data?.data) {
          setUserInfo({
            ...userInfo,
            phone: data?.data?.data?.phone,
            full_name: data?.data?.data?.name,
            img_url: APP_CONSTANTS.API_URL_IMG + "uploads/users/" + data?.data?.data?.image_path,
            email: data?.data?.data?.email,
            hasImage: data?.data?.data?.image_path ? true : false
          })
          cookies.set('phone', data?.data?.data?.phone ? data?.data?.data?.phone : '', { path: '/' });

        }
        // toast.success(t('NETWORK_MESSAGES.infoUpdatedSuccessfully'))
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate('/redirect')
          }
        }
      } else {
        toast.warning(t('NETWORK_MESSAGES.infoAddedSuccessfully'))
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate('/redirect')
          }
        }
      }
    } catch (e) {
      console.log(e?.message)
    }
  }
  React.useEffect(() => {
    if (!cookies.get("token")) {
      getUserData()
    }

  }, [])

  const [pathsList, setPathList] = React.useState([
    {
      id: 1,
      name: t('SIDEMENU.dashboard'),
      to: 'dashboard',
      component: '',
      icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.dashboard')}</span> : ''} ><DashboardIcon className="iconSideMenu" /></Tooltip>,
      subLink: [],
      hasRole: (rolesRedux)=>!cookies.get('token'),
      ref: useRef(null),
    },
    {
      id: 2,
      name: t('SIDEMENU.geofences'),
      to: 'geofences',
      component: '',
      icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.geofences')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><TbCurrentLocation className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
      subLink: [],
      hasRole: (rolesRedux)=>rolesRedux?.view_geofence?.value,
      ref: useRef(null),
    },
    {
      id: 3,
      name: t('SIDEMENU.users'),
      to: 'users',
      component: '',
      icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.users')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><IoIosPeople className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
      subLink: [],
      hasRole: (rolesRedux)=>rolesRedux?.view_user?.value,
      ref: useRef(null),
    },
    // {
    //   id: 4,
    //   name: t('SIDEMENU.activities'),
    //   to: 'activities',
    //   component: '',
    //   icon:(open) =>  <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.activities')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><TbChecklist className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
    //   subLink: [],
    // hasRole: (rolesRedux)=>rolesRedux?.view_activity?.value
    // },
    {
      id: 5,
      name: t('SIDEMENU.units_settings'),
      to: '',
      component: '',
      ref: useRef(null),
      icon:(open) =>  <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.units')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><BsTruck className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
      subLink: [
        {
          id: 36,
          // roles: [22],
          name: t("SIDEMENU.units_governmental"),
          to: "units",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.units')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><RiTruckFill className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole: (rolesRedux)=>rolesRedux?.view_unit?.value,
          ref: useRef(null),
        },
        {
          id: 366,
          // roles: [22],
          name: t("SIDEMENU.units_private"),
          to: "units_private",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.units_private')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><FaTruckMoving className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole: (rolesRedux)=>rolesRedux?.view_unit?.value,
          ref: useRef(null),
        },
        {
          id: 36,
          // roles: [22],
          name: t("SIDEMENU.units_transactions"),
          to: "units_transactions",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.units_transactions')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><MdAssessment className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole: (rolesRedux)=>rolesRedux?.view_unit?.value,
          ref: useRef(null),
        },
      ],
      hasRole: (rolesRedux)=>rolesRedux?.view_unit?.value
    },
    {
      id: 6,
      name: t('SIDEMENU.routes'),
      to: 'routes',
      component: '',
      icon:(open) =>  <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.routes')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><TbRoute className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
      subLink: [],
      hasRole: (rolesRedux)=>rolesRedux?.view_route?.value,
      ref: useRef(null),
    },
    {
      id: 7,
      name: t('SIDEMENU.transactions'),
      to: 'transactions',
      component: '',
      icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.transactions')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><IoReceipt className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
      subLink: [],
      hasRole:(rolesRedux)=> false,
      ref: useRef(null),
    },
    
    {
      id: 7777,
      name: "مستندات حكومي",
      to: 'transactions_custom',
      component: '',
      icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{"مستندات حكومي"}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><IoNewspaper className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
      subLink: [],
      hasRole: (rolesRedux)=>rolesRedux?.view_transaction?.value ,//(cookies.get('id') == 46),
      ref: useRef(null),
    },
    {
      id: 7778,
      name: "مستندات اهلي",
      to: 'transactions_private',
      component: '',
      icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{"مستندات اهلي"}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><IoReceipt className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
      subLink: [],
      hasRole:(rolesRedux)=> rolesRedux?.view_transaction?.value, //(cookies.get('id') == 46),
      ref: useRef(null),
    },
    {
      id: 7779,
      name: "مستندات بدون مركبة",
      to: 'transactions_custom_no_unit',
      component: '',
      icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{"مستندات بدون مركبة"}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><TbTruckOff className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
      subLink: [],
      hasRole: (rolesRedux)=> rolesRedux?.view_transaction_no_unit?.value ,//(cookies.get('id') == 46),
      ref: useRef(null),
    },
    {
      id: 8,
      name: t('SIDEMENU.reports'),
      to: '',
      component: '',
      ref: useRef(null),
      icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.reports')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><TbReportSearch className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
      subLink: [
        {
          id: 71,
          // roles: [22],
          name: t("SIDEMENU.report_by_transaction_number"),
          to: "report_by_transaction_number",
          component: "",
          icon:(open) =>  <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_by_transaction_number')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><FaDigitalTachograph className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=> rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },
        {
          id: 72,
          // roles: [22],
          name: t("SIDEMENU.report_by_is_monitored"),
          to: "report_by_is_monitored",
          component: "",
          icon:(open) =>  <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_by_is_monitored')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><BsFillEyeFill className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=> rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },
        {
          id: 73,
          // roles: [22],
          name: t("SIDEMENU.report_by_source_geofence"),
          to: "report_by_source_geofence",
          component: "",
          icon:(open) =>  <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_by_source_geofence')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><BsGeoFill className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=> rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },
        {
          id: 74,
          // roles: [22],
          name: t("SIDEMENU.report_by_destination_geofence"),
          to: "report_by_destination_geofence",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_by_destination_geofence')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><BsGeo className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole: (rolesRedux)=> rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },
        {
          id: 75,
          // roles: [22],
          name: t("SIDEMENU.report_by_product_type"),
          to: "report_by_product_type",
          component: "",
          icon:(open) =>  <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_by_product_type')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><BsFillFuelPumpFill className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=> rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },
        {
          id: 76,
          // roles: [22],
          name: t("SIDEMENU.report_by_source_and_product_type"),
          to: "report_by_source_and_product_type",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_by_source_and_product_type')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><MdOutlineWarehouse className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=> rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },
        {
          id: 77,
          // roles: [22],
          name: t("SIDEMENU.report_by_destination_and_product_type"),
          to: "report_by_destination_and_product_type",
          component: "",
          icon:(open) =>  <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_by_destination_and_product_type')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><BsFillFuelPumpFill className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=> rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },
        {
          id: 78,
          // roles: [22],
          name: t("SIDEMENU.report_by_audited_per_user"),
          to: "report_by_audited_per_user",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_by_audited_per_user')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><BsFillFuelPumpFill className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=> rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },
        {
          id: 79,
          // roles: [22],
          name: t("SIDEMENU.report_audited_units_monitored_by_user"),
          to: "report_audited_units_monitored_by_user",
          component: "",
          icon:(open) =>  <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_audited_units_monitored_by_user')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><FaTruckMoving className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole: (rolesRedux)=> rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },
        {
          id: 80,
          // roles: [22],
          name: t("SIDEMENU.report_stops_info"),
          to: "report_stops_info",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_stops_info')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><TbArrowsTransferDown className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=> rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },
        {
          id: 81,
          // roles: [22],
          name: t("SIDEMENU.report_for_main_units"),
          to: "report_for_main_units",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_for_main_units')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><IoStatsChartSharp className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=> rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },
        {
          id: 82,
          // roles: [22],
          name: t("SIDEMENU.report_for_missing_destinations"),
          to: "report_for_missing_destinations",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_for_missing_destinations')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><MdLocationDisabled className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=> rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },
        {
          id: 83,
          // roles: [22],
          name: t("SIDEMENU.report_for_missing_routes"),
          to: "report_for_missing_routes",
          component: "",
          icon:(open) =>  <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_for_missing_routes')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><TbRouteOff className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole: (rolesRedux)=>rolesRedux?.view_transaction?.value,
          ref: useRef(null),
        },

      ],
      hasRole:(rolesRedux)=> rolesRedux?.view_transaction?.value
    },
    {
      id: 9,
      name: t('SIDEMENU.settings'),
      to: '',
      component: '',
      ref: useRef(null),
      icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.reports')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><IoSettings className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
      subLink: [

        {
          id: 91,
          // roles: [22],
          name: t("SIDEMENU.tags"),
          to: "tags",
          component: "",
          icon:(open) =>  <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.tags')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><FaTags className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole: (rolesRedux)=> rolesRedux?.view_master?.value && (cookies.get('id') == 46),
          ref: useRef(null),
        },
        {
          id: 92,
          // roles: [22],
          name: t("SIDEMENU.main_units"),
          to: "main_units",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_by_is_monitored')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><FaTruckMoving className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole: (rolesRedux)=>rolesRedux?.view_main_unit?.value,
          ref: useRef(null),
        },
        {
          id: 93,
          // roles: [22],
          name: t("SIDEMENU.mobile_users"),
          to: "mobile_users",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.report_by_source_geofence')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><IoIosPeople className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=> rolesRedux?.view_mobile_user?.value,
          ref: useRef(null),
        },
        {
          id: 94,
          // roles: [22],
          name: t("SIDEMENU.main_accounts"),
          to: "main_accounts",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.main_accounts')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><MdAccountTree className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=> false && rolesRedux?.view_master?.value,
          ref: useRef(null),
        },
        {
          id: 95,
          // roles: [22],
          name: t("SIDEMENU.unit_sections"),
          to: "unit_sections",
          component: "",
          icon:(open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.unit_sections')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><MdCategory className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole:(rolesRedux)=>  rolesRedux?.view_master?.value,
          ref: useRef(null),
        },
        {
          id: 96,
          // roles: [22],
          name: t("SIDEMENU.unit_types"),
          to: "unit_types",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.unit_types')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><MdCategory className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole: (rolesRedux)=> rolesRedux?.view_master?.value,
          ref: useRef(null),
        },
        {
          id: 97,
          // roles: [22],
          name: t("SIDEMENU.geofence_groups"),
          to: "geofence_groups",
          component: "",
          icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.geofence_groups')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><MdCategory className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
          subLink: [],
          hasRole: (rolesRedux)=>rolesRedux?.view_geofence_group?.value,
          ref: useRef(null),
        },

      ],
      hasRole: (rolesRedux)=> rolesRedux?.view_main_unit?.value && !cookies.get('token')
    },
    {
      id: 8000,
      name: t('SIDEMENU.main_units_installation_check'),
      to: "main_units_installation_check",
      component: '',
      icon: (open) => <Tooltip arrow placement="right" title={!open ? <span style={{ fontFamily: 'Cairo-Medium' }}>{t('SIDEMENU.main_units_installation_check')}</span> : ''} sx={{ fontFamily: 'Cairo-Bold' }}><span><TbTruckOff className="iconSideMenu" sx={{ transform: ' matrix(0, -1, 1, 0, 0, 0)' }} /></span></Tooltip>,
      subLink: [],
      hasRole:(rolesRedux)=> rolesRedux?.view_main_unit?.value && cookies.get('token'),
      ref: useRef(null),
    }
  ])


  const renderComponent = () => {
    let check = false;
    pathsList && pathsList?.length > 0 && pathsList?.map((itm) => {
      if (location?.pathname == itm?.to) {
        return itm?.component;
      }
    })

  }
  const getUserImage = () => {
    return userInfo?.hasImage ? userInfo.img_url : require('../assets/img/Logo1.png')
  }
  React.useEffect(() => {

  }, [globalLoading]);

  const logout = () => {

    if (cookies.get('language'))
      cookies.remove('language')
    if (cookies.get('access_type'))
      cookies.remove('access_type')

    cookies.set('sid', null, { path: "/" })

    if (cookies.get('token')) {
      cookies.remove('token')
      window.location.href = '/login'
    }
    else {
      window.location.href = cookies.get('hostUrl')
    }
  }
  const handleOpenClosedMenu = () => {
    if (pathsList && pathsList.length) {
      console.log("asdasdasdsadasdsad", window.location.pathname);
      var arr = [],
        checkExsts = false;
      pathsList?.map((item) => {
        if (item?.subLink?.length > 0) {
          let find = item?.subLink?.find((subItem) => {
            console.log("asdasdasdsadasdsad", ("/system/" + subItem?.to) + "---" + window.location.pathname);
            if ("/system/" + subItem?.to === window.location.pathname) {
              return true;
            } else return false;
          });
          if (find) {
            checkExsts = true;
            arr.push({
              ...item,
              openCollapse: true,
            });
          } else {
            arr.push(item);
          }
        } else {
          arr.push(item);
        }
      });
      if (checkExsts) {
        // dispatch(setPathNameClicked(false))
        setPathList(arr);
      }
    }
  }
  return (
    <Box sx={{ display: 'flex', transition: 'all 0.9 ease !important' }}>
      <CssBaseline />
      <AppBar
        position="fixed" 
        open={open} 
        drawerwidth={drawerwidth} 
        sidemenutheme={sidemenutheme}
        appbarTheme={appbarTheme}
      >

        <Toolbar
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <img src={WialonLogo} style={{
            width: '200px',
            height: ' 48px',
            dropShadow: '0px 3px 6px #00000029',
            objectFit: 'cover',
            backgroundPosition: 'center center',
            opacity: 1,
            position: 'relative',
            top: '2px'
          }} />
          <Box>
            <img src={OPDCLogo} style={{

              height: ' 48px',
              dropShadow: '0px 3px 6px #00000029',
              objectFit: 'cover',
              backgroundPosition: 'center center',
              opacity: 1,
              borderRadius: '50%',
              position: 'relative',
              top: '2px'
            }} />
            <img src={SalamLogo} style={{
              marginRight: '10px',

              height: ' 48px',
              dropShadow: '0px 3px 6px #00000029',
              objectFit: 'cover',
              backgroundPosition: 'center center',
              backgroundColor: '#FFFFFF',
              opacity: 1,
              borderRadius: '50%',
              position: 'relative',
              top: '2px'
            }} />
          </Box>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        drawerwidth={drawerwidth}
        sidemenutheme={sidemenutheme}
      >
        <HeaderDrawer
          open={open}
          setOpen={setOpen}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
          getUserImage={getUserImage}
        />

        <Divider sx={{
          width: open ? '92%' : "50%",
          height: "2px",
          background: "#EBF2F7 0% 0% no-repeat padding-box",
          margin: '10px auto',
          opacity: "1",
          display: 'flex',
          alignSelf: 'center'
        }} />

        <Box 
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            // overflowY:'scroll',
            overflow: "hidden",
            marginTop: !isopensidemenu ? "20px" : "0",
          }}
        >
          <Box 
             sx={{
              width: "100%",
              height: "100%",
              overflowY: "auto !important",
              overflowX: "hidden",
              transition: "all 0.3s ease-in-out",
              scrollBehavior: "smooth",
              "&:hover": {
                // overflowY: "auto",
              },
            }}
            ref={MainContainerRef}
          
          >
            <SideMenuItemArray 
            open={isopensidemenu} 
            setOpen={(value) => {
                dispatch(setIsOpenSideMenu(value));
            }}
            pathsList={pathsList} 
            setPathList={setPathList}
            MainContainerRef={MainContainerRef}
            />
          </Box>
          {/* <Box sx={{height:'130px',width:"100%",marginTop:10}}></Box> */}
          <Box
            sx={{
              // position: 'fixed',
              // bottom: '0px',
              // backgroundColor: sidemenutheme?.backgroundColor,
              // left: 0,
              // right: 0,
              // height:'10% !important',
              // flex:0.1,
              width: open ? drawerwidth - 1 : '83px',
              boxShadow: "0px 4px 6px #00000029;",
              display: 'flex',
              // marginTop:open?'30px':'70px',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: open ? 'start' : 'center',
              '& .iconSideMenu': {
                color: `${sidemenutheme?.iconColor}`,
                fontSize: '28px'
              },
              '& .MuiListItemText-root .Mui51Typography-root,& .logout': {
                textAlign: "left",
                font: "normal normal 15px/30px Cairo-Medium !important", letterSpacing: "0px", color: "#52575D", opacity: "1"
              },
              "& .logout": {
                margin: '0 7px !important',
                position: "relative",
                top: '-2px'
              },
              transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
              // boxShadow:'-9px 3px 15px -7px #000'
            }}
          >
            <Divider
              sx={{
                width: !open ? "60%" : '92%',
                height: "3px",
                background: "#EBF2F7 0% 0% no-repeat padding-box",
                margin: '0px auto',
                // marginRight:open?0:'15px',
                opacity: "1",
                marginLeft: open ? '9px' : '17px',
                display: 'flex',
                // alignSelf:'center'
              }} />

            <Box sx={{ marginLeft: '10px', padding: '15px 10px', display: 'flex !important', justifyContent: open ? 'start' : 'center', alignItems: 'center', cursor: 'pointer' }}>
              <LogoutIcon onClick={logout} sx={{ transform: ' matrix(-1, 0, 0, -1, 0, 0)' }} className="iconSideMenu" />
              {open ? <span onClick={logout} className='logout' sx={{ opacity: open ? 1 : 0, margin: '0 10px !important' }} >{t('SIDEMENU.logout')}</span> : null}

            </Box>
          </Box>
        </Box>

      </Drawer>
      <Box component="main" sx={{
        flexGrow: 1,
        p: 3,
        marginTop: window.location.pathname === '/admin/available-compounds/compound-map' ? '0px' : '60px',
        backgroundColor: 'transparent',
        // paddingRight:0,
        // paddingLeft:0,
        minWidth: '900px !important'
      }}
      onClick={() => {
          dispatch(setIsOpenSideMenu(false));
        }}
      >

        {/* <DrawerHeader /> */}
        <Outlet />

      </Box>

    </Box>
  )
}
