import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Grid from '../../Grid/Grid'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import Search from "./Search";
import userObject from "../../../Errors/Users/User";
import userAPI from "../../../Network/User";
import PopupForm from "../../../Components/PopupForm";
import Cookies from "universal-cookie";
import CustomTextField from "../../../Components/CustomTextField";
import geofenceGroupAPI from "../../../Network/GeofenceGroup";
import CustomeSelectField from "../../../Components/CustomeSelectField";
const cookies = new Cookies();





const RenderContent = (props) => {
  const [t] = useTranslation("common");




  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [columns, setColumns] = useState([
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
    },
    { headerName: t("USERS.geofence_group_id"), field: "id", flex: 0.5 },
    { headerName: t("USERS.geofence_group_name"), field: "name", flex: 2 },
    // { headerName: t("GEOFENCES.code"), field: "code", flex: 2 }

  ]);
  const handleCheckBoxChange = useCallback((rows) => {


    props?.setListOfSelectedRows(rows);
    let arr = [];
    props?.geofenceGroups &&
      props?.geofenceGroups?.length &&
      props?.geofenceGroups?.map((itm) => {
        if (rows?.find((e) => e == itm?.id)) {
          arr.push(itm);
        }
      });

    props?.setGeofenceGroup(arr);
  }, []);




  return (
    <Box className="Container-fluid">
      <Box className="row d-flex justify-content-center align-items-start">
        <Box className="col-12 col-sm-12 col-md-12 my-3 px-0"
          sx={{
            '& .MuiCheckbox-root': {
              color: '#1e6a99 !important',
              fill: '#1e6a99 !important'

            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography sx={{
              fontFamily: 'Cairo',
              marginLeft: '10px',
              backgroundColor: "#edf4fb",
              padding: "4px",
              borderRadius: '5px'
            }}>{t('GLOBAL.total') + " : " + props?.allGeofenceGroups?.length}</Typography>

            <Typography sx={{
              fontFamily: 'Cairo',
              marginLeft: '10px',
              backgroundColor: "#e2f079",
              borderRadius: '5px',
              padding: "4px",
            }}>{t('GLOBAL.total_filtered') + " : " + props?.geofenceGroups?.length}</Typography>
            <Typography sx={{
              fontFamily: 'Cairo',
              marginLeft: '10px',
              backgroundColor: "#bdf4c6",
              borderRadius: '5px',
              padding: "4px",
            }}>{t('GLOBAL.SEARCH_RESULT_OF_selected') + " : " + props?.listOfSelectedRows?.length}</Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <CustomTextField
              label={t("USERS.geofence_group_name")}
              value={props?.filterObject?.name}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {
                props?.setFilterObject({
                  ...props?.filterObject,
                  name: e?.target?.value
                })
              }}
              onClearClick={() => {
                props?.setFilterObject({
                  ...props?.filterObject,
                  name: ""
                })
              }}
            />
            <CustomeSelectField
              label={t("GLOBAL.selected_status")}
              haswidth={true}
              value={props?.filterObject?.selected_status?.value}
              list={[
                {
                  name: t("GLOBAL.selected"),
                  id: 1
                },
                {
                  name: t("GLOBAL.not_selected"),
                  id: 2
                }
              ]}
              error={props?.filterObject?.selected_status?.error}
              message={props?.filterObject?.selected_status?.message}
              readOnly={false}
              onChange={(e, newValue) => {
                props?.setFilterObject({
                  ...props?.filterObject,
                  selected_status: {
                    ...props?.filterObject?.selected_status,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setFilterObject({
                  ...props?.filterObject,
                  selected_status: {
                    ...props?.filterObject?.selected_status,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />


          </Box>
          <Grid
            rows={props?.geofenceGroups}
            columns={columns}
            setColumns={setColumns}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            loading={loading}
            handlePageChange={handlePageChange}
            rowsTotal={props?.geofenceGroups?.length}
            checkScreenSize={screenwidth}
            pageCount={Math.ceil(props?.geofenceGroups?.length / pageSize)}
            clearFunction={props?.handleClearInAddvancSearch}
            searchFunction={props?.handleSearchInAdvancedSearch}
            filterChilds={
              <Search
                setGeofenceGroupName={props?.setGeofenceGroupName}

                geofenceGroupName={props?.geofenceGroupName}

                setCheckAllselected={props?.setCheckAllselected}
                checkAllselected={props?.checkAllselected}
                setCheckAllNotSelected={props?.setCheckAllNotSelected}
                checkAllNotSelected={props?.checkAllNotSelected}
              />
            }
            filterHasSelectCounter={true}
            filterHasSelectCounterValue={props?.listOfSelectedRows?.length}
            notHaveAdvancSearch={true}
            hasSelectOption={true}
            handleCheckBoxChange={handleCheckBoxChange}
            listOfSelectedRows={props?.listOfSelectedRows}
            // handleRowCellChange={handleRowCellChange}
            paginationMode={"client"}
          />
        </Box>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props?.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              backgroundColor: "#e7a52a",
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props?.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function UserGeofenceGroups(props) {
  const [t] = useTranslation("common");
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorsObject] = useState(userObject);
  const dispatch = useDispatch();
  const [geofenceGroups, setGeofenceGroups] = useState([])
  const [listOfSelectedRows, setListOfSelectedRows] = useState([])
  const [filterdGeofenceGroups, setFilterdGeofenceGroups] = useState([])
  const [geofenceGroupName, setGeofenceGroupName] = useState()

  const [checkAllselected, setCheckAllselected] = useState(false)
  const [checkAllNotSelected, setCheckAllNotSelected] = useState(false)
  const [isUploadingTime, setIsUploadingTime] = useState(false)
  const [geofenceGroup, setGeofenceGroup] = useState('')
  const [filterObject, setFilterObject] = useState({})

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,

  };

  const submit = async () => {
    if (!props?.object || !props?.object?.id) {
      toast.warn('USERS.please_select_a_user')
      return
    }

    try {
      let result = null;
      setLoading(true)
      if (props?.object && props?.object?.id) {
        result = await userAPI.updateGeofenceGroups({
          id: props?.object?.id,
          data: {
            geofence_group_ids: listOfSelectedRows
          },
          params: {
            ...headerObject
          }
        });
      }

      if (result.status && result?.data?.status && result?.data?.data?.message) {
        toast.success(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        clearForm()
        props?.loadData()
        props?.setOpenForm(false)
      } else {


        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        // if (result && result?.data && result?.data?.data?.message) {
        //   if (result?.data?.data?.message?.text == "unauthorized to complete") {
        //     navigate("/redirect");
        //   }
        // }
      }


      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }

  }

  const clearForm = () => {
    setListOfSelectedRows([])
  };

  useEffect(() => {
    if (!props?.object) clearForm();
    loadGeofenceGroups()

    getUserGeofenceGroups(props?.object)
  }, [props?.openForm, props?.object]);

  const loadGeofenceGroups = async () => {
    let data = null;
    try {
      data = await geofenceGroupAPI.getGeofenceGroupList({
        params: {
          ...headerObject,

        }
      })
      if (data && data?.status) {
        setGeofenceGroups(data?.data?.data)

      } else {
        toast.warn(t('NETWORK_MESSAGES.messageError'))
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const handleSearchInAdvancedSearch = () => {
    let arr = [...geofenceGroups];
    let geofenceGroupNameIdOrAccountName = filterObject?.name

    if (geofenceGroupNameIdOrAccountName) {
      arr = arr?.filter((itm) => {
        if (
          (itm?.name && itm?.name?.toLowerCase().includes(geofenceGroupNameIdOrAccountName && geofenceGroupNameIdOrAccountName?.toLowerCase()))
          ||
          (itm?.account_name && itm?.account_name?.toLowerCase().includes(geofenceGroupNameIdOrAccountName && geofenceGroupNameIdOrAccountName?.toLowerCase()))
          ||
          (itm?.id && itm?.id?.toString().includes(geofenceGroupNameIdOrAccountName && geofenceGroupNameIdOrAccountName?.toLowerCase()))
        ) {
          return true
        }

        return false
      })

    }
    if (filterObject?.branch_id?.value?.code) {
      arr = arr.filter(item => item.code.substring(0, 2) === filterObject?.branch_id?.value?.code)
    }
    if (filterObject?.port_category_id?.value?.code) {
      arr = arr.filter(item => item.code.substring(2, 4) === filterObject?.port_category_id?.value?.code)
    }
    if (filterObject?.port_return_id?.value?.code) {
      arr = arr.filter(item => item.code.substring(4, 5) === filterObject?.port_return_id?.value?.code)
    }
    if (filterObject?.port_type_id?.value?.code) {
      arr = arr.filter(item => item.code.substring(5, 6) === filterObject?.port_type_id?.value?.code)
    }
    if (filterObject?.selected_status?.value?.id) {
      if (filterObject?.selected_status?.value?.id == 1) {
        arr = arr.filter(item => listOfSelectedRows?.includes(item.id))
      }
      else {
        arr = arr.filter(item => !listOfSelectedRows?.includes(item.id))
      }

    }

    setFilterdGeofenceGroups(arr)

    if (checkAllselected) {
      setFilterdGeofenceGroups(arr.filter(itm => listOfSelectedRows?.includes(itm?.id)))
    }
    if (checkAllNotSelected) {
      setFilterdGeofenceGroups(arr.filter(itm => !listOfSelectedRows?.includes(itm?.id)))
    }

  }
  const handleClearInAddvancSearch = () => {

    setGeofenceGroupName('')
    setFilterdGeofenceGroups(geofenceGroups)
    setCheckAllselected(false)
  }

  useEffect(() => {
    if (geofenceGroups) {
      setFilterdGeofenceGroups(geofenceGroups)
    }

  }, [geofenceGroups])

  const getUserGeofenceGroups = async (user) => {

    if (user?.geofence_group_ids?.length) {

      setListOfSelectedRows(user?.geofence_group_ids)//get only ids 
    } else {
      setListOfSelectedRows([])
    }

  }
  useEffect(() => {
    handleSearchInAdvancedSearch()


    return () => {

    }
  }, [filterObject])


  return (
    <PopupForm
      isFullScreen={false}
      open={props?.openForm}
      setOpen={props?.setOpenForm}
      title={t("USERS.geofence_groups")}
      content={
        <RenderContent
          filterObject={filterObject}
          setFilterObject={setFilterObject}
          setGeofenceGroups={setGeofenceGroups}
          geofenceGroups={filterdGeofenceGroups}
          geofenceGroup={geofenceGroup}
          setGeofenceGroup={setGeofenceGroup}
          allGeofenceGroups={geofenceGroups}

          geofenceGroupName={geofenceGroupName}
          setGeofenceGroupName={setGeofenceGroupName}
          handleSearchInAdvancedSearch={handleSearchInAdvancedSearch}
          handleClearInAddvancSearch={handleClearInAddvancSearch}
          setListOfSelectedRows={setListOfSelectedRows}
          listOfSelectedRows={listOfSelectedRows}
          checkAllselected={checkAllselected}
          setCheckAllselected={setCheckAllselected}
          setCheckAllNotSelected={setCheckAllNotSelected}
          checkAllNotSelected={checkAllNotSelected}
          open={props?.openForm} setOpen={props?.setOpenForm}
        />
      }
      footer={
        <RenderFooter
          open={props?.openForm}
          setOpen={props?.setOpenForm}
          submit={submit}
        />
      }
    />
  );
}
