import React, { useState, useEffect } from "react";
// reactstrap components
import { styled } from "@mui/material/styles";
import { Box, Button, CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

import profileAPI from "../../Network/Profile/index";
import Preloading from "../Preload/Preloading";

const cookies = new Cookies();


const CustomeHeader = styled(Box)(({ theme, MainTheme, SideMenuTheme }) => ({
  width: "95%",
  height: "200px",
  backgroundColor: "#c8c8c8",
  color: MainTheme?.disabledGlobalText
    ? MainTheme?.gloablTextColor
    : MainTheme?.primaryTextColor,
  position: "relative",
  top: "0px",
  borderRadius: "10px",
  zIndex: 0,
}));

const CustomeWrapperContent = styled(Box)(
  ({ theme, MainTheme, screenWidth }) => ({
    width: "100%",
    display: "flex",
    flexDirection: screenWidth <= 991 ? "column" : "row",
    justifyContent: "center",
    alignItems: "start",
    position: "relative",
    top: "-120px",
    zIndex: 100,
  })
);


const RightSide = styled(Box)(({ theme, MainTheme, screenWidth }) => ({
  width: screenWidth <= 991 ? "80%" : "50%",
  height: "400px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  boxShadow: `1px 0px 8px 1px ${MainTheme?.paperTextColor + "33"}`,
  zIndex: "2000 !important",
  margin: " 10px auto",
  padding: "10px 20px",
  position: "relative",
  "& p": {
    fontSize: "27px",
    color: MainTheme?.disabledGlobalText
      ? MainTheme?.gloablTextColor
      : MainTheme?.paperTextColor,
  },
  borderRadius: "5px",
  boxShadow: "5px 5px 40px #c5c5c5,-5px -5px 40px #fbfbfb",
}));


export default function Profile() {
  const MainTheme = useSelector((state) => state.settingsData.MainTheme);
  const SideMenuTheme = useSelector(
    (state) => state.settingsData.SideMenuTheme
  );
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const userName = cookies.get("user_name") ? cookies.get("user_name") : "";

  const globalLoading = useSelector(
    (state) => state.settingsData.globalLoading
  );
  const [userInfo, setUserInfo] = useState({
    name: userName,
    phone: {
      value: "",
      error: false,
      message: []
    },
    full_name: {
      value: "",
      error: false,
      message: []
    },

  });


  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    getUserData();
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
    return () => { };
  }, []);



  const submit = async () => {
    let data = null;

    let checkErrorObject = {
      ...userInfo
    }

    let hasError = false
    let readyToSendObject = {}
    if (userInfo?.full_name?.value && userInfo?.full_name?.value?.trim()?.length > 0) {
      let full_name = userInfo?.full_name?.value?.trim()
      readyToSendObject["full_name"] = full_name?.replace(/\\|\/|\'/g, "");
    }
    else {
      hasError = true
      checkErrorObject['full_name']['error'] = true
      checkErrorObject['full_name']['message'] = [t('GLOBAL.mandatory_field')]
    }

    if (userInfo?.phone?.error) {
      hasError = true
    }

    console.log("userinfo",hasError?"error":"no error");
    if (hasError) {
      setUserInfo({
        ...checkErrorObject
      })
      return
    }

    try {
      setLoading(true);
      let params = {
        username: cookies.get('user_name'),
        user_id: cookies.get('user_id'),
        account_name: cookies.get('account_name'),
        account_id: cookies.get('account_id'),
        sid: cookies.get('sid'),
        baseurl: cookies.get('baseUrl'),
        id: cookies.get('id')
      }



      var str = userInfo?.full_name?.value
        ? userInfo?.full_name?.value?.replace(/ +(?= )/g, "")
        : "";
      str = str ? str.trim() : "";
      let dataList = {
        name: str,
        phone: userInfo?.phone?.value
      };

      data = await profileAPI.UpdateProfile({
        params: params,
        data: dataList,
      });
      if (data && data?.status) {
        cookies.set("phone", userInfo?.phone?.value, { path: "/" });
        setLoading(false);
        toast.success(t("NETWORK_MESSAGES.infoUpdatedSuccessfully"));
        setUserInfo({
          ...userInfo,
          full_name: str,
        });
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");

          }
        }
      } else {
        setLoading(false);
        toast.warning(t("NETWORK_MESSAGES.infoAddedSuccessfully"));
        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");

          }
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e?.message);
    }
  };


  const getUserData = async () => {
    let data = null;
    try {
      let params = {
        username: cookies.get('user_name'),
        user_id: cookies.get('user_id'),
        account_name: cookies.get('account_name'),
        account_id: cookies.get('account_id'),
        sid: cookies.get('sid'),
        baseurl: cookies.get('baseUrl'),
        id: cookies.get('id')
      };

      data = await profileAPI.Profile({
        params: params,
      });
      setLoading(false)
      if (data && data?.status && data?.data?.status) {

        setUserInfo({
          ...userInfo,
          phone: {
            value: data?.data?.data?.phone,
            error: false,
            message: []
          },
          full_name: {
            value: data?.data?.data?.name,
            error: false,
            message: []
          }

        });
      } else {

        if (data && data?.data && data?.data?.data?.message) {
          if (data?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
    } catch (e) {
      console.log(e?.message);
    }
  };



  const handlePhoneChange = (e) => {
    let inputtxt = e.target;

    let object = {
      ...userInfo,
      phone: {
        value: inputtxt.value,
        error: false,
        message: []
      }
    };
    if (isNaN(inputtxt.value)) {

      setUserInfo({
        ...userInfo,
        phone: {
          value: inputtxt.value,
          error: true,
          message: ["يجب ان يكون الهاتف رقم"]
        }
      });
      return false;
    }
    

    if (inputtxt?.value?.length == 11 && !isNaN(inputtxt.value)) {

      object = {
        ...userInfo,
        phone: {
          value: inputtxt.value,
          error: false,
          message: []
        }
      };
    } else if (e?.target?.value?.length >= 11) {

      object = {
        ...userInfo,
        phone: {
          value: inputtxt.value,
          error: true,
          message: ["رقم الهاتف بصيغة غير صحيحة !",]
        }

      };
    } else {
      object = {
        ...userInfo,
        phone: {
          value: inputtxt.value,
          error: false,
          message: []
        }
      };
    }

    setUserInfo({
      ...userInfo,
      ...object,
    });
  };



  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {globalLoading ? <Preloading dashboardMode={true} /> : null}

      <CustomeHeader MainTheme={MainTheme} SideMenuTheme={SideMenuTheme} />

      <CustomeWrapperContent screenWidth={screenWidth} MainTheme={MainTheme}>

        <RightSide screenWidth={screenWidth} MainTheme={MainTheme}>
          <p
            style={{
              display: "flex",
              justifyContent: "start",
              fontFamily: "Cairo-Medium",
            }}
          >
            {t("PROFILE.USER_TITLE")}
          </p>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100% !important",
              "& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg,& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                color: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.paperTextColor,
              },
              "& input": {
                color: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.paperTextColor,
              },
              "& .MuiOutlinedInput-root": {
                borderColor: `1px solid ${MainTheme?.paperTextColor} !important`,
                color: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.paperTextColor,
              },
              "& .MuiInputLabel-root": {
                // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                color: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.paperTextColor,
              },
              "& .MuiFormControl-root": {
                // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                color: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.paperTextColor,
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: MainTheme?.disabledGlobalText
                    ? MainTheme.gloablTextColor
                    : MainTheme?.paperTextColor,
                },
                "&:hover fieldset": {
                  borderColor: MainTheme?.disabledGlobalText
                    ? MainTheme.gloablTextColor
                    : MainTheme?.paperTextColor,
                },
                "&.Mui-focused fieldset": {
                  borderColor: MainTheme?.secondaryColor,
                  color: MainTheme?.disabledGlobalText
                    ? MainTheme.gloablTextColor
                    : MainTheme?.secondaryColor,
                },
              },
              "& .MuiOutlinedInput": {
                // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                // border: "0 none",
              },
              "& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root:before": {
                borderColor: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.paperTextColor,
              },
              "& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root .Mui-focused": {
                borderColor: MainTheme?.secondaryColor,
                color: MainTheme?.disabledGlobalText
                  ? MainTheme.gloablTextColor
                  : MainTheme?.secondaryColor,
              },
              "& p,& span,& label,& input,& button": {
                fontFamily: "Cairo",
              },
            }}
            fullWidth
          >
            <Box
              sx={{
                width: "50%",
              }}
              fullWidth
              autoComplete="off"
            >
              <TextField
                sx={{ width: "98%" }}
                onChange={(e) => {

                }}
                focused
                inputProps={{
                  readOnly: true,
                }}
                value={cookies.get('user_name')}
                id="outlined-basic"
                label={t("PROFILE.USERNAME")}
                variant="standard"
              />
            </Box>
            <Box
              sx={{
                width: "50%",
              }}
              fullWidth
              autoComplete="off"
            >
              <TextField
                sx={{ width: "98%" }}
                focused
                value={userInfo?.full_name?.value}
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    full_name: {
                      value: e.target.value,
                      error: false,
                      message: []
                    }
                  })

                }}
                id="outlined-basic"
                label={t("PROFILE.FULL_NAME")}
                variant="standard"
              />
            </Box>

            <Box
              sx={{
                m: 0.1,
                width: "100%",
                "& .MuiFormHelperText-root": {
                  fontSize: "15px !important",
                },
              }}
              fullWidth
              autoComplete="off"
            >
              <TextField
                sx={{
                  width: "99%",
                  mt: 1,
                  "& input": {
                    textAlign: "left !important",
                  },
                }}
                focused
                onChange={handlePhoneChange}
                value={userInfo?.phone?.value}
                id="outlined-basic"
                inputProps={{
                  dir: "ltr",
                  textAlign: "right",
                }}
                //  type="tel"
                error={userInfo?.phone?.error}
                helperText={userInfo?.phone?.message}
                label={t("PROFILE.PHONE")}
                variant="standard"
                placeholder={"07812346794"}
              />
            </Box>


            <Box
              //
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                mr: 0.6,
              }}
              fullWidth
            //
            // autoComplete="off"
            >
              <Button
                onClick={submit}
                sx={{
                  mt: 2,
                  backgroundColor: MainTheme?.secondaryColor,
                  color: MainTheme?.disabledGlobalText
                    ? MainTheme.gloablTextColor
                    : MainTheme?.oppositeSecondaryColor,
                }}
                variant="contained"
              >
                {t("PROFILE.UPDATE_BUTTON")}
              </Button>
            </Box>
          </Box>
        </RightSide>
      </CustomeWrapperContent>

      {loading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 324234,
            backgroundColor: "rgba(0,0,0,0.3)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            size={40}
            style={{
              color: "#fff",
            }}
          />
        </div>
      ) : null}
    </Box>
  );
}
