import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './style.css'
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
// import Chart from 'react-apexcharts'
import dashboardAPI from "../../Network/Dashboard";
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import Preloading from '../../Pages/Preload/Preloading';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import RouteIcon from '@mui/icons-material/Route';
import { setTransactionFilters } from "../../reduxStore/SettingsReducer";
import Search from "./Search";
import branchAPI from "../../Network/Branch";
import transactionStatusAPI from "../../Network/TransactionStatus";
import CustomeFilter from "../Grid/Components/CustomeFilter";
import PieChart from "./Components/PieChart";
import StackChart from "./Components/StackChart";
import transactionAPI from "../../Network/Transaction";

const cookies = new Cookies();



const todayStart = new Date();
const yesterday = new Date(todayStart);
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(0, 0, 0, 0);

const todayEnd = new Date();
todayEnd.setHours(23, 59, 59, 999);
export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation("common");

  const [reload, setReload] = useState(false)
  const [branches, setBranches] = useState([])
  const [loadingBranches, setLoadingBranches] = useState(true)

  const [transactionStatuses, setTransactionStatuses] = useState([])
  const [loadingTransactionStatuses, setLoadingTransactionStatuses] = useState(true)

  const [loading, setLoading] = useState(true)
  const [dashboardObject, setDashboardObject] = useState({
    total_monitored: 0,
    total_not_monitored: 0,
    total_in_road: 0,
    total_completed: 0,
    total_exceeded: 0,
    total_in_road_valid: 0,
    total_in_road_not_valid: 0,
    total_completed_valid: 0,
    total_completed_not_valid: 0,
    all_transactions: 0,
    total_governmental: 0,
    total_private: 0,
    total_no_unit: 0

  })
  const [chartObject, setChartObject] = useState({
    all_transactions_pie: {
      options: {},
      series: [],
      labels: []
    },
    monitored_pie: {
      options: {},
      series: [],
      labels: []
    },
    monitored_statuses_pie: {
      options: {},
      series: [],
      labels: []
    },
    in_road_statuses_pie: {
      options: {},
      series: [],
      labels: []
    },
    is_completed_statuses_pie: {
      options: {},
      series: [],
      labels: []
    },
    audited_statuses_pie: {
      options: {},
      series: [],
      labels: []
    }

  })
  const [auditedData, setAuditedData] = useState({
    auditedData: [],
    auditedLabels: []
  })
  const [filters, setFilters] = useState({
    unit: null,
    unit_id: "",
    sourceGeofence: null,
    source_geofence_id: "",
    destinationGeofence: null,
    destination_geofence_id: "",
    route: null,
    route_id: "",
    creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
    creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
    creationDateFrom: yesterday,
    creationDateTo: todayEnd,
    export_excel: false,
    note: "",
    destination_geofence_name: "",
    source_geofence_name: "",
    all_time: 0
  })
  const globalLoading = useSelector(state => state.settingsData.globalLoading)
  const controller = new AbortController();


  const getResources = () => {
    const headerObject = {
      username: cookies.get("user_name") ? cookies.get("user_name") : null,
      user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
      account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
      account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
      authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
      sid: cookies.get("sid") ? cookies.get("sid") : null,
      baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
      id: cookies.get("id") ? cookies.get("id") : null,
    }

    branchAPI.getBranchList({
      params: {
        ...headerObject,
      }

    }).then(branchesResult => {
      if (branchesResult.status && branchesResult?.data?.data) {
        setBranches(branchesResult?.data?.data)
      }
      setLoadingBranches(false);
    }).catch(error => {
      console.log(error);
      setLoadingBranches(false);
    }).finally(() => {
      setLoadingBranches(false);
    })

    transactionStatusAPI.getTransactionStatusList({
      params: {
        ...headerObject,
      }

    }).then(transactionStatusesResult => {
      if (transactionStatusesResult.status && transactionStatusesResult?.data?.data) {
        setTransactionStatuses(transactionStatusesResult?.data?.data)
      }
      setLoadingTransactionStatuses(false);
    }).catch(error => {
      console.log(error);
      setLoadingTransactionStatuses(false);
    }).finally(() => {
      setLoadingTransactionStatuses(false);
    })

  }


  useEffect(() => {
    getResources()
    return () => {

    }
  }, [])

  useEffect(() => {
    loadData()
    // setLoading(false)
  }, [reload])

  const loadData = async (searchParams) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        username: cookies.get("user_name") ? cookies.get("user_name") : null,
        user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
        account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
        account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
        authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
        sid: cookies.get("sid") ? cookies.get("sid") : null,
        baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
        id: cookies.get("id") ? cookies.get("id") : null,

        search: "",
        ...searchParams,
        ...filters
      };

      setLoading(true);

      const result = await dashboardAPI.dashboard({
        params: object,
        signal: controller?.signal,
      });


      if (result.status && result?.data?.data) {
        setDashboardObject({
          ...dashboardObject,
          ...result?.data?.data
        })
        setChartObject({
          all_transactions_pie: {
            series: [result?.data?.data?.all_transactions],
            labels: [t('DASHBOARD.all_transactions')],
            options: {},
            colors: ['#17681b']
          },
          monitored_pie: {
            series: [result?.data?.data?.total_monitored, result?.data?.data?.total_not_monitored],
            labels: [t('DASHBOARD.total_monitored'), t('DASHBOARD.total_not_monitored')],
            options: {},
            colors: ['#17681b', '#bd0404']
          },
          monitored_statuses_pie: {
            series: [result?.data?.data?.total_in_road, result?.data?.data?.total_completed, result?.data?.data?.total_exceeded],
            labels: [t('DASHBOARD.total_in_road'), t('DASHBOARD.total_completed'), t('DASHBOARD.total_exceeded')],
            options: {},
            colors: ['#c8b803', '#17681b', '#bd0404']
          },
          in_road_statuses_pie: {
            series: [result?.data?.data?.total_in_road_valid, result?.data?.data?.total_in_road_not_valid],
            labels: [t('DASHBOARD.total_in_road_valid'), t('DASHBOARD.total_in_road_not_valid')],
            options: {},
            colors: ['#17681b', '#bd0404']
          },
          is_completed_statuses_pie: {
            series: [result?.data?.data?.total_completed_valid, result?.data?.data?.total_completed_not_valid],
            labels: [t('DASHBOARD.total_completed_valid'), t('DASHBOARD.total_completed_not_valid')],
            options: {},
            colors: ['#17681b', '#bd0404']
          },
          audited_statuses_pie: {
            series: [result?.data?.data?.total_audited, result?.data?.data?.total_not_audited],
            labels: [t('DASHBOARD.total_audited'), t('DASHBOARD.total_not_audited')],
            options: {},
            colors: ['#17681b', '#bd0404']
          },
          transaction_types_pie: {
            series: [result?.data?.data?.total_governmental, result?.data?.data?.total_private, result?.data?.data?.total_no_unit],
            labels: [t('DASHBOARD.total_governmental'), t('DASHBOARD.total_private'), t('DASHBOARD.total_no_unit')],
            options: {},
            colors: ['#17681b', '#bd0404','#c8b803']
          },

        })

      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      const result2 = await transactionAPI.auditedTransactionByUsersReport({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result2.status && result2?.data?.data) {


        let series = result2?.data?.data?.map(item => {
          return {
            // "غير مدقق":item?.status_1_name_total,
            "قيد التدقيق": item?.status_2_name_total,
            "مخالف": item?.status_3_name_total,
            "غير مخالف": item?.status_4_name_total,
          }
        });

        let transformedSeries = Object.keys(series[0]).filter(key => key !== "audited_by_name").map(key => {
          return {
            name: key,
            data: series.map(item => item[key])
          };
        });
        console.log("transformedSeries", transformedSeries);
        setAuditedData({
          auditedData: transformedSeries,
          auditedLabels: result2?.data?.data?.map(item => item?.audited_by_name)
        })

      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };


  const showDetailsFromIndex = (index, type) => {

    let dashboardFilters = {}
    const CommonFilters = {
      creation_date_to: filters?.creation_date_to,
      creation_date_from: filters?.creation_date_from,
      creationDateFrom: filters?.creationDateFrom,
      creationDateTo: filters?.creationDateTo,
      all_time: filters?.all_time,
      branch_ids: filters?.branch_ids,
      branches_selected: filters?.branches_selected
    }
    if (index == 0 && type == 1) {
      // all transactions
      dashboardFilters = {
        ...CommonFilters,


      }

    }
    else if (index == 0 && type == 2) {
      dashboardFilters = {
        ...CommonFilters,
        is_monitored: 1

      }
    }
    else if (index == 1 && type == 2) {
      dashboardFilters = {
        ...CommonFilters,
        is_monitored: 0

      }

    }
    else if (index == 0 && type == 3) {
      dashboardFilters = {
        ...CommonFilters,
        is_monitored: 1,
        exceeded_allowed_time: 0,
        is_complete: 0

      }

    }
    else if (index == 1 && type == 3) {
      dashboardFilters = {
        ...CommonFilters,
        is_monitored: 1,
        exceeded_allowed_time: 0,
        is_complete: 1

      }

    }
    else if (index == 2 && type == 3) {
      dashboardFilters = {
        ...CommonFilters,
        is_monitored: 1,
        exceeded_allowed_time: 1,
        is_complete: 0

      }

    }
    else if (index == 0 && type == 4) {
      dashboardFilters = {
        ...CommonFilters,
        is_monitored: 1,
        exceeded_allowed_time: 0,
        is_complete: 0,
        valid: 1

      }

    }
    else if (index == 1 && type == 4) {
      dashboardFilters = {
        ...CommonFilters,
        is_monitored: 1,
        exceeded_allowed_time: 0,
        is_complete: 0,
        valid: 0

      }

    }
    else if (index == 0 && type == 5) {
      dashboardFilters = {
        ...CommonFilters,
        is_monitored: 1,
        exceeded_allowed_time: 0,
        is_complete: 1,
        valid: 1

      }

    }
    else if (index == 1 && type == 5) {
      dashboardFilters = {
        ...CommonFilters,
        is_monitored: 1,
        exceeded_allowed_time: 0,
        is_complete: 1,
        valid: 0

      }

    }
    else if (index == 0 && type == 6) {
      dashboardFilters = {
        ...CommonFilters,
        transaction_status_ids: [2, 3, 4]

      }

    }
    else if (index == 1 && type == 6) {
      dashboardFilters = {
        ...CommonFilters,
        transaction_status_ids: [1]

      }

    }
    else if (index == 2 && type == 6) {
      dashboardFilters = {
        ...CommonFilters,

      }

    }
    else if (type == 7) {
      
      // if(index == 0)
      // {
      //   const currentRootURL = window.location.origin;

      //   // Append "system/transactions" to the root URL
      //   const newURL = `${currentRootURL}/system/transactions_custom`;
    
      //   // Open the new tab with the constructed URL
      //   const newTab = window.open(newURL, '_blank');
      // }
      // else if(index == 1)
      // {
      //   const currentRootURL = window.location.origin;

      //   // Append "system/transactions" to the root URL
      //   const newURL = `${currentRootURL}/system/transactions_private`;
    
      //   // Open the new tab with the constructed URL
      //   const newTab = window.open(newURL, '_blank');
      // }
      // else {
      //   const currentRootURL = window.location.origin;

      //   // Append "system/transactions" to the root URL
      //   const newURL = `${currentRootURL}/system/transactions_custom_no_unit`;
    
      //   // Open the new tab with the constructed URL
      //   const newTab = window.open(newURL, '_blank');
      // }
      return

    }
    // Save state to local storage
    localStorage.setItem('dashboardFilters', JSON.stringify(dashboardFilters));
    // setLoading(true)
    // Get the current root URL
    const currentRootURL = window.location.origin;

    // Append "system/transactions" to the root URL
    const newURL = `${currentRootURL}/system/transactions`;

    // Open the new tab with the constructed URL
    const newTab = window.open(newURL, '_blank');

    // navigate("/system/transactions");
  }
  const showDetails = (object) => {
    dispatch(setTransactionFilters({
      creation_date_to: filters?.creation_date_to,
      creation_date_from: filters?.creation_date_from,
      creationDateFrom: filters?.creationDateFrom,
      creationDateTo: filters?.creationDateTo,
      all_time: filters?.all_time,
      ...object
    }))


    navigate("/system/transactions");
  }
  const clearFilterFunction = () => {
    setFilters({
      name: "",
      code: "",
      transaction_id: "",
      transaction_number: "",
      type_id: null,
      wialon_id: null,
      export_excel: false,
      creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
      creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
      creationDateFrom: yesterday,
      creationDateTo: todayEnd,
      export_excel: false,
      note: "",
      destination_geofence_name: "",
      source_geofence_name: "",
      all_time: 0
    })
  }
  const searchFunction = () => {
    setReload(!reload)

  };

  return (
    loading || globalLoading ? <Preloading dashboardMode={true} />
      :
      <Box className="dashboard container-fluid">

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'stretch',
            // backgroundColor: '#fff',
            position: 'relative',
            paddingTop: '20px'
            // top:'-25px'
          }}
        >

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              variant='h4'
              sx={{
                marginLeft: '10px',
                marginBottom: '10px',
                fontFamily: 'Cairo-Bold',
                fontSize: '23px',
                letterSpacing: '-1px',
              }}>
              {t('DASHBOARD.title')}
            </Typography>
          </Box>




        </Box>

        <Divider
          sx={{
            backgroundColor: '#EBF2F7',
            margin: '30px',
            marginTop: '10px',
            marginBottom: '10px',
            width: '100%'
          }}
          style={{
            height: '3px',
          }}
        />
        {filters?.all_time ? null :
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              variant='h4'
              sx={{
                marginLeft: '10px',
                marginBottom: '10px',
                fontFamily: 'Cairo-Bold',
                fontSize: '18px',
                letterSpacing: '-1px',
              }}>
              {t('GLOBAL.from') + " " + filters?.creation_date_from + " - " + t('GLOBAL.to') + " " + filters?.creation_date_to}
            </Typography>
          </Box>}
        {filters?.branch_names?.length > 0 ? <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '18px',
              letterSpacing: '-1px',
            }}>
            {(filters?.branch_names?.length == 1 ? "للفرع " : "للفروع  ") + filters?.branch_names}
          </Typography>
        </Box> : null}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',

          }}
        >

          <Box sx={{
            width: '100%',
            height: '395px',
            backgroundColor: '#fff',
            boxShadow: '0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '5px',
            position: 'relative'
          }}>
            <span
              onClick={() => showDetailsFromIndex(0, 1)}
              className="hover-style"
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                paddingLeft: '10px',
                paddingRight: '10px',
                fontSize: '20px',
              }}>{`جميع المستندات ${dashboardObject?.total_monitored + dashboardObject?.total_not_monitored}`}</span>
            <PieChart redirectIndex={(index) => showDetailsFromIndex(index, 2)} series={chartObject?.monitored_pie?.series} labels={chartObject?.monitored_pie?.labels} colors={chartObject?.monitored_pie?.colors} />

          </Box>
          <Box sx={{
            width: '100%',
            height: '395px',
            backgroundColor: '#fff',
            boxShadow: '0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '5px',
            position: 'relative'
          }}>
            <span
              onClick={()=>{}}
              className="hover-style"
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                paddingLeft: '10px',
                paddingRight: '10px',
                fontSize: '20px',
              }}>{`جميع انواع المستندات  ${dashboardObject?.total_governmental + dashboardObject?.total_private + dashboardObject?.total_no_unit}`}</span>
            <PieChart redirectIndex={(index) => showDetailsFromIndex(index, 7)} series={chartObject?.transaction_types_pie?.series} labels={chartObject?.transaction_types_pie?.labels} colors={chartObject?.transaction_types_pie?.colors} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',

          }}
        >
          <Box sx={{
            width: '100%',
            height: '395px',
            backgroundColor: '#fff',
            boxShadow: '0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '5px',
            position: 'relative'
          }}>
            <span
              onClick={() => showDetailsFromIndex(0, 2)}
              className="hover-style"
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                paddingLeft: '10px',
                paddingRight: '10px',
                fontSize: '20px',
              }}>{`جميع المستندات المراقبة ${dashboardObject?.total_in_road + dashboardObject?.total_completed + dashboardObject?.total_exceeded}`}</span>
            <PieChart redirectIndex={(index) => showDetailsFromIndex(index, 3)} series={chartObject?.monitored_statuses_pie?.series} labels={chartObject?.monitored_statuses_pie?.labels} colors={chartObject?.monitored_statuses_pie?.colors} />
          </Box>
          <Box sx={{
            width: '100%',
            height: '395px',
            backgroundColor: '#fff',
            boxShadow: '0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '5px',
            position: 'relative'
          }}>
            <span
              onClick={() => showDetailsFromIndex(0, 3)}
              className="hover-style"
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                paddingLeft: '10px',
                paddingRight: '10px',
                fontSize: '20px',
              }}>{`جميع المستندات في الطريق ${dashboardObject?.total_in_road_valid + dashboardObject?.total_in_road_not_valid}`}</span>
            <PieChart redirectIndex={(index) => showDetailsFromIndex(index, 4)} series={chartObject?.in_road_statuses_pie?.series} labels={chartObject?.in_road_statuses_pie?.labels} colors={chartObject?.in_road_statuses_pie?.colors} />
          </Box>

        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',

          }}
        >
          <Box sx={{
            width: '100%',
            height: '395px',
            backgroundColor: '#fff',
            boxShadow: '0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '5px',
            position: 'relative'
          }}>
            <span
              onClick={() => showDetailsFromIndex(1, 3)}
              className="hover-style"
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                paddingLeft: '10px',
                paddingRight: '10px',
                fontSize: '20px',
              }}>{`جميع المستندات المكتملة ${dashboardObject?.total_completed_valid + dashboardObject?.total_completed_not_valid}`}</span>
            <PieChart redirectIndex={(index) => showDetailsFromIndex(index, 5)} series={chartObject?.is_completed_statuses_pie?.series} labels={chartObject?.is_completed_statuses_pie?.labels} colors={chartObject?.is_completed_statuses_pie?.colors} />
          </Box>
          <Box sx={{
            width: '100%',
            height: '395px',
            backgroundColor: '#fff',
            boxShadow: '0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '5px',
            position: 'relative'
          }}>
            <span
              onClick={() => showDetailsFromIndex(2, 6)}
              className="hover-style"
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                paddingLeft: '10px',
                paddingRight: '10px',
                fontSize: '20px',
              }}>{`جميع المستندات المدققة والغير مدققة ${dashboardObject?.total_audited + dashboardObject?.total_not_audited}`}</span>
            <PieChart redirectIndex={(index) => showDetailsFromIndex(index, 6)} series={chartObject?.audited_statuses_pie?.series} labels={chartObject?.audited_statuses_pie?.labels} colors={chartObject?.audited_statuses_pie?.colors} />
          </Box>


        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',

          }}
        >

          <Box sx={{
            width: '100%',
            height: '800px',
            backgroundColor: '#fff',
            boxShadow: '0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '5px',
            position: 'relative'
          }}>
            <span
              onClick={() => showDetailsFromIndex(2, 6)}
              className="hover-style"
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                paddingLeft: '10px',
                paddingRight: '10px',
                fontSize: '20px',
              }}>{`احصائيات التدقيق للمستخدمين - ${dashboardObject?.total_audited}`}</span>
            <StackChart data={auditedData} />
          </Box>

        </Box>

        <CustomeFilter
          clearFunction={clearFilterFunction}
          searchFunction={searchFunction}
          resultLength={dashboardObject?.all_transactions}
        >

          <Search
            filters={filters}
            setFilters={setFilters}
            loadingBranches={loadingBranches}
            branches={branches}
            transactionStatuses={transactionStatuses}
            loadingTransactionStatuses={loadingTransactionStatuses}
          />

        </CustomeFilter>

      </Box>
  );
}
