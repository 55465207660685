import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import helpers from '../../../assets/js/helper';
import CustomeSelectField from '../../../Components/CustomeSelectField';
import CustomTextField from '../../../Components/CustomTextField';
import CloseIcon from "@mui/icons-material/Close";
import transactionAPI from '../../../Network/Transaction';
import unitAPI from '../../../Network/Unit';
import geofenceAPI from '../../../Network/Geofence';
import routeAPI from '../../../Network/Route';
import Cookies from "universal-cookie";
import CustomDateTimeField from '../../../Components/CustomDateTimeField';
const cookies = new Cookies();

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const timerUnits = useRef(null)
  const timerSourceGeofences = useRef(null)
  const timerDestinationGeofences = useRef(null)
  const timerRoutes = useRef(null)

  const [units, setUnits] = useState([])
  const [sourceGeofences, setSourceGeofences] = useState([])
  const [destinationGeofences, setDestinationGeofences] = useState([])
  const [routes, setRoutes] = useState([])

  const [loadingUnits, setLoadingUnits] = useState(false)
  const [loadingSourceGeofences, setLoadingSourceGeofences] = useState(false)
  const [loadingDestinationGeofences, setLoadingDestinationGeofences] = useState(false)
  const [loadingRoutes, setLoadingRoutes] = useState(false)


  const [searchTermUnit, setSearchTermUnit] = useState("");
  const [searchTermSourceGeofence, setSearchTermSourceGeofence] = useState("");
  const [searchTermDestinationGeofence, setSearchTermDestinationGeofence] = useState("");
  const [searchTermRoute, setSearchTermRoute] = useState("");
  const [geofenceChanged, setGeofenceChanged] = useState(false)
  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }

  const handleSearchTermUnitChange = (newValue) => {

    setSearchTermUnit(newValue);
    if (timerUnits.current) {
      clearTimeout(timerUnits?.current)
    }
    timerUnits.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingUnits(true);

        const result = await unitAPI.getUnitList({
          params: {
            ...headerObject,
            name: newValue,
            page_size: 20,
            page: 1
          }

        })
        setLoadingUnits(false);

        if (result.status && result?.data?.data?.data) {
          setUnits(result?.data?.data?.data)
        }
      }
      else {
        setUnits([])
      }
    }, 1000)
  };

  const handleSearchTermSourceGeofenceChange = (newValue) => {

    setSearchTermSourceGeofence(newValue);
    if (timerSourceGeofences.current) {
      clearTimeout(timerSourceGeofences?.current)
    }
    timerSourceGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingSourceGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [1,3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingSourceGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setSourceGeofences(result?.data?.data?.data)
        }
      }
      else {
        setSourceGeofences([])
      }
    }, 1000)
  };
  const handleSearchTermDestinationGeofenceChange = (newValue) => {

    setSearchTermDestinationGeofence(newValue);
    if (timerDestinationGeofences.current) {
      clearTimeout(timerDestinationGeofences?.current)
    }
    timerDestinationGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingDestinationGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [2,3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingDestinationGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setDestinationGeofences(result?.data?.data?.data)
        }
      }
      else {
        setDestinationGeofences([])
      }
    }, 1000)
  };

  const handleSearchTermRouteChange = (newValue) => {

    setSearchTermRoute(newValue);
    if (timerRoutes.current) {
      clearTimeout(timerRoutes?.current)
    }
    timerRoutes.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingRoutes(true);

        const result = await routeAPI.getRouteList({
          params: {
            ...headerObject,
            name: newValue,
            page_size: 20,
            page: 1
          }

        })
        setLoadingRoutes(false);

        if (result.status && result?.data?.data?.data) {
          setRoutes(result?.data?.data?.data)
        }
      }
      else {
        setRoutes([])
      }
    }, 1000)
  };

  const loadRoutes = async () => {
  
    setLoadingRoutes(true);

    const result = await routeAPI.getRouteList({
      params: {
        ...headerObject,
        code: props?.object?.source_geofence_id?.value?.code + "-" + props?.object?.destination_geofence_id?.value?.code + "-",
        page_size: 20,
        page: 1
      }

    })
    setLoadingRoutes(false);

    if (result.status && result?.data?.data?.data) {
      setRoutes(result?.data?.data?.data)
      props?.setObject({
        ...props?.object,
        routes: {
          ...props?.object?.routes,
          value: Array.isArray(result?.data?.data?.data) && result?.data?.data?.data?.length > 0 ? result?.data?.data?.data?.map(route => {
            return {
              route_id: route?.id,
              route_name: route?.name,
              route_code: route?.code
            }
          }) : [],
          error: false,
          message: []
        }
      })

    }

    else {
      setRoutes([])
      props?.setObject({
        ...props?.object,
        routes: {
          ...props?.object?.routes,
          value: [],
          error: false,
          message: []
        }
      })

    }
  }

  
  useEffect(() => {
    if (props?.object?.source_geofence_id?.value?.id && props?.object?.destination_geofence_id?.value?.id) {
      loadRoutes()
    }
    return () => {

    }
  }, [geofenceChanged])


  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.9)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "21120",
        }}
        onClick={() => {
          props?.setOpen(false);
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>

      <Box
        className="row d-flex justify-content-center align-items-start"
        sx={{ transition: "all 0.7s ease" }}
      >
        <CustomTextField
          label={t("TRANSACTIONS.transaction_number")}
          value={props?.object?.transaction_number?.value}
          error={props?.object?.transaction_number?.error}
          message={props?.object?.transaction_number?.message}
          haswidth={true}
          readOnly={false}
          focused={true}
          onChange={(e) => {

            props?.setObject({
              ...props?.object,
              transaction_number: {
                ...props?.object?.transaction_number,
                value: e?.target?.value,
                error: false,
                message: []
              }
            })

          }}
          onClearClick={() => {
            props?.setObject({
              ...props?.object,
              transaction_number: {
                ...props?.object?.transaction_number,
                value: "",
                error: false,
                message: []
              }
            })
          }}
        />
        <Box sx={{ margin: "8px 0" }}></Box>
        <CustomeSelectField
          label={t("TRANSACTIONS.unit")}
          haswidth={true}
          onInputChange={handleSearchTermUnitChange}
          inputValue={searchTermUnit}
          loading={loadingUnits}
          loadingText={t('GLOBAL.searching')}
          value={props?.object?.unit_id?.value}
          list={units}
          error={props?.object?.unit_id?.error}
          message={props?.object?.unit_id?.message}
          readOnly={false}
          onChange={(e, newValue) => {

            props?.setObject({
              ...props?.object,
              unit_id: {
                ...props?.object?.unit_id,
                value: newValue,
                error: false,
                message: []
              }
            })
          }}
          onClearClick={() => {
            props?.setObject({
              ...props?.object,
              unit_id: {
                ...props?.object?.unit_id,
                value: "",
                error: false,
                message: []
              }
            })
          }}
        />
        <Box sx={{ margin: "8px 0" }}></Box>
        <CustomeSelectField
          label={t("TRANSACTIONS.source_geofence")}
          haswidth={true}
          onInputChange={handleSearchTermSourceGeofenceChange}
          inputValue={searchTermSourceGeofence}
          loading={loadingSourceGeofences}
          loadingText={t('GLOBAL.searching')}
          value={props?.object?.source_geofence_id?.value}
          list={sourceGeofences}
          error={props?.object?.source_geofence_id?.error}
          message={props?.object?.source_geofence_id?.message}
          readOnly={false}
          onChange={(e, newValue) => {
            setGeofenceChanged(!geofenceChanged)
            props?.setObject({
              ...props?.object,
              source_geofence_id: {
                ...props?.object?.source_geofence_id,
                value: newValue,
                error: false,
                message: []
              }
            })
          }}
          onClearClick={() => {
            setGeofenceChanged(!geofenceChanged)
            props?.setObject({
              ...props?.object,
              source_geofence_id: {
                ...props?.object?.source_geofence_id,
                value: "",
                error: false,
                message: []
              }
            })
          }}
        />
        <Box sx={{ margin: "8px 0" }}></Box>
        <CustomeSelectField
          label={t("TRANSACTIONS.destination_geofence")}
          haswidth={true}
          onInputChange={handleSearchTermDestinationGeofenceChange}
          inputValue={searchTermDestinationGeofence}
          loading={loadingDestinationGeofences}
          loadingText={t('GLOBAL.searching')}
          value={props?.object?.destination_geofence_id?.value}
          list={destinationGeofences}
          error={props?.object?.destination_geofence_id?.error}
          message={props?.object?.destination_geofence_id?.message}
          readOnly={false}
          onChange={(e, newValue) => {
            setGeofenceChanged(!geofenceChanged)
            props?.setObject({
              ...props?.object,
              destination_geofence_id: {
                ...props?.object?.destination_geofence_id,
                value: newValue,
                error: false,
                message: []
              }
            })
          }}
          onClearClick={() => {
            setGeofenceChanged(!geofenceChanged)
            props?.setObject({
              ...props?.object,
              destination_geofence_id: {
                ...props?.object?.destination_geofence_id,
                value: "",
                error: false,
                message: []
              }
            })
          }}
        />
        <Box sx={{ margin: "8px 0" }}></Box>
        <CustomDateTimeField
          label={t('TRANSACTIONS.date_time')}
          value={props?.object?.created_at?.value}
          error={props?.object?.created_at?.error}
          message={props?.object?.created_at?.message}
          haswidth={true}
          readOnly={false}
          minDate={new Date()}
          maxDate={null}
          onChange={(e) => {
            
            props?.setObject({
              ...props?.object,
              created_at: {
                ...props?.object?.created_at,
                value: e,
                error: false,
                message: []
              }
            })
          }}
          onClearClick={() => {
            props?.setObject({
              ...props?.object,
              created_at: {
                ...props?.object?.created_at,
                value: null,
                error: false,
                message: []
              }
            })
          }}
        />
        <Box sx={{ margin: "8px 0" }}></Box>
        {/* <CustomeSelectField
          label={t("TRANSACTIONS.route")}
          haswidth={true}
          onInputChange={handleSearchTermRouteChange}
          inputValue={searchTermRoute}
          loading={loadingRoutes}
          loadingText={t('GLOBAL.searching')}
          value={props?.object?.route_id?.value}
          list={routes}
          error={props?.object?.route_id?.error}
          message={props?.object?.route_id?.message}
          readOnly={false}
          onChange={(e, newValue) => {

            props?.setObject({
              ...props?.object,
              route_id: {
                ...props?.object?.route_id,
                value: newValue,
                error: false,
                message: []
              }
            })
          }}
          onClearClick={() => {
            props?.setObject({
              ...props?.object,
              route_id: {
                ...props?.object?.route_id,
                value: "",
                error: false,
                message: []
              }
            })
          }}
        /> */}

        {(props?.object?.source_geofence_id?.value?.id && props?.object?.destination_geofence_id?.value?.id) ?
          Array.isArray(props?.object?.routes?.value) && props?.object?.routes?.value?.length > 0 ?
            <Box>
              <Typography
                sx={{
                  fontFamily: 'Cairo-Medium'
                }}
              >
                {t("TRANSACTIONS.routes_allowed")}
              </Typography>
              {props?.object?.routes?.value?.map((route, index) => {
                return <Typography
                  sx={{
                    fontFamily: 'Cairo-Medium'
                  }}
                  key={route?.route_id}>
                  {(index + 1) + "- " + route?.route_name + " : " + route?.route_code}
                </Typography>
              })}
            </Box> :
            <Typography
              sx={{
                fontFamily: 'Cairo-Medium'
              }}
            >
              {t("TRANSACTIONS.no_routes_found")}
            </Typography>


          : null}
        {props?.object?.routes?.error ?
          <Typography
            sx={{
              color: 'red',
              fontFamily: 'Cairo-Medium'
            }}
          >
            {props?.object?.routes?.message}
          </Typography> : null}
        <Box sx={{ margin: "8px 0" }}></Box>

      </Box>
    </Box>
  );
};

export default RenderContent