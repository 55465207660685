import React from 'react'
import { DataGrid,
 } from '@mui/x-data-grid';
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGrid)(({them,rowCount,virtualScrollName})=>({
      backgroundColor:'#FFFFFF',
      width:'100%',
      border:'none !important',
      "& .MuiDataGrid-renderingZone": {
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-cell": {
        lineHeight: "unset !important",
        maxHeight: "none !important",
        whiteSpace: "normal",
        outline:'none !important',
        border:'none !important',
        // borderBottom:'1.8px dashed lightgrey !important'
      },
      '& .MuiDataGrid-row:last-child .MuiDataGrid-cell':{
        border:'none !important',
      },
      "& .MuiDataGrid-row": {
        maxHeight: "none !important",
        height:'fit-content !important',
        position: 'relative !important',

      },
      "& .MuiDataGrid-columnHeaderTitle":{
        fontFamily: "Cairo-Bold",
        
      },
      '& .MuiDataGrid-cellContent,& .MuiDataGrid-cellContent div,& .MuiDataGrid-cellContent span,& .MuiDataGrid-cellContent p':{
        fontFamily: "Cairo-Medium",
        textAlign: "top !important",
        wordWrap: 'break-word !important',
        spaceWord: 'break-word !important',
        position:'relative',
        top:0,
        display:'flex !important',
        alignItems:'center !important',
        justifyContent:'start !important',
        whiteSpace: 'break-spaces ',
        // overflow: 'scroll',
        // textOverflow: 'ellipsis',
        // lineBreak: 'anywhere !important',
        // height:'fit-content !important',
        minHeight:'40px',
        fontSize: '13px'

      },
      "& .MuiDataGrid-cell,& .MuiDataGrid-cell div,& .MuiDataGrid-cell span,& .MuiDataGrid-cell p":{
        fontFamily: "Cairo-Medium",
        textAlign: "left !important",
        wordWrap: 'break-word !important',
        spaceWord: 'break-word !important',
        whiteSpace: 'break-spaces ',
        // overflow: 'scroll',
        // textOverflow: 'ellipsis',
        // lineBreak: 'anywhere !important',
        margin:'1.5px 0 !important'

      },
      '& .MuiDataGrid-columnHeaderTitleContainerContent,& .MuiDataGrid-columnHeaderTitleContainerContent div,& .MuiDataGrid-columnHeaderTitleContainerContent span,& .MuiDataGrid-columnHeaderTitleContainerContent p':{
        fontFamily: "Cairo-Medium",
        textAlign: "left !important",
        color:'#fff'
      },
      '& .cutomeRenderHeader':{
         top:'-4px !important',
         position:'relative !important',
         left:'0 !important'
      }
      ,
      '& .MuiDataGrid-cell,& .MuiDataGrid-columnHeaderTitleContainer':{
        display:'flex !important',
        justifyContent: 'center !important',
      },
      '& .MuiDataGrid-columnHeaders':{
        position: 'absolute !important', top: '0', left: '0',
      },
      '& .MuiDataGrid-columnHeaders':{
        backgroundColor:'#17681b'
      },
      '& .MuiDataGrid-columnHeaderTitle,& .MuiSvgIcon-root':{
        color:'#fff',
      },
      '& .MuiDataGrid-columnSeparator':{
        display:'none'
      },
      '& .MuiDataGrid-menuIcon,& .MuiDataGrid-iconButtonContainer':{
        display:'none !important'
      },
      '& .MuiDataGrid-main':{
        overflowY:'scroll !important',
      },
      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
        // overflow: "auto !important"
        height:'12px',

      },
      '& .gridHeaderItem':{
        display:'flex',
        justifyContent: 'end',
        alignItems: 'start',
        alignSelf: 'end',
        fontSize: '13px !important',
      },
     [virtualScrollName]:{
        // height:'398px !important',
      },
      '& .MuiCheckbox-root svg':{
        fill:'black !important'
      },
      
    
      
}))

  

export default StyledDataGrid

