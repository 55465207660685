import callAPI from '../ApiConfig'

const unitSections = async (props) => {


    return await callAPI({
        route: "unit_sections/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addUnitSection = async (props) => {


    return await callAPI({
        route: "unit_sections/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editUnitSection = async (props) => {

    return await callAPI({
        route: "unit_sections/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteUnitSection = async (props) => {


    return await callAPI({
        route: "unit_sections/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}



const getUnitSectionList = async (props) => {

    return await callAPI({
        route: "unit_sections/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const upload = async (props) => {

    let formdata = new FormData();

    formdata.append("files", props?.data?.file)

    return await callAPI({
        route: "unit_sections/import",
        method: "post",
        signal: props?.signal,
        data: formdata,
        params: props?.params,
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            ...props?.headers
        }
    })
}


const downloadExcel = async (props) => {


    return await callAPI({
        route: "unit_sections/download",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params
        },
        headers: {

        },
        responseType: "blob"

    })
}
const unitSectionAPI = {
    unitSections: unitSections,
    addUnitSection: addUnitSection,
    editUnitSection: editUnitSection,
    deleteUnitSection: deleteUnitSection,
    getUnitSectionList: getUnitSectionList,
    upload: upload,
    downloadExcel: downloadExcel
}
export default unitSectionAPI