const transactionObject = {
    
    id: "",
    unit_id: {
        value: null,
        error: false,
        message: "",
        required: false
    },
    source_geofence_id: {
        value: null,
        error: false,
        message: "",
        required: false
    },
    destination_geofence_id: {
        value: null,
        error: false,
        message: "",
        required: false
    },
    routes: {
        value: [],
        error: false,
        message: "",
        required: false
    },
    created_at: {
        value: null,
        error: false,
        message: "",
        required: false
    },
    transaction_number: {
        value: null,
        error: false,
        message: "",
        required: false
    }

    /// update /////////////////////////////////////////
    ,
    sourceGps: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    destinationGps: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    regNumber: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    fDate: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    regType: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    province: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    voucherNo: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    voucherType: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    driverName: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    sourceWHouse: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    sourceProvince: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    destinationWHouse: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    destinationProvince: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    carrier: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    carrierName: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    productType: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    liters: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    voucherType2: {
        value: "",
        error: false,
        message: "",
        required: false
    }
}
export const transactionAuditObject = {
    
    id: "",
    transaction_id: {
        value: null,
        error: false,
        message: "",
        required: false
    },
    transaction_status_id: {
        value: null,
        error: false,
        message: "",
        required: false
    },
    note: {
        value: "",
        error: false,
        message: "",
        required: false
    }
}
export const viewObjectKeys = [
    "id",
    "transaction_number",
    "unit_name",
    "formatted_datetime",
    "inserted_at",
    "time_difference",
    "out_from_source_at",
    "created_by_name",
    "source_geofence_name",
    "destination_geofence_name",
    "source_geofence_code",
    "destination_geofence_code",
    "source_warehouse",
    "destination_warehouse",
    "routes_codes",
    "note",
    "is_monitored_title",
    
    "exceeded_allowed_time_title",
    "is_complete_title",
    "has_violation_title",
    "arrived_at",
    "unit_registration_type",
    "unit_province",
    "transaction_type",
    "driver_name",
    
    "source_province",
    
    "destination_province",
    "carrier",
    "carrier_name",
    "product_type",
    "liters",
    "transaction_type_2",
    "trip_duration_time_difference",
    "trip_range_time_difference"
    
]
export const viewExtra1ObjectKeys = [
    
    "inserted_at",
    "time_difference",
    "source_geofence_code",
    "destination_geofence_code",
    "source_warehouse",
    "destination_warehouse",
    
    
]
export const viewExtra2ObjectKeys = [
    
    "unit_registration_type",
    "unit_province",
    "transaction_type",
    "driver_name",
    "source_province",
    "destination_province",
   
    
]
export const viewExtra3ObjectKeys = [
    
    "routes_codes",
    "note",
    "exceeded_allowed_time_title",
    "carrier",
    "carrier_name",
    "transaction_type_2"
    
]
export const resetErrors = (currentObject) => {
    var objectKeys = Object.keys(currentObject);

    var updatedObject = {
        ...currentObject
    }
    try {
        objectKeys.filter(key => key !== "id").map((key) => {

            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {

    }

    return updatedObject

}
export const checkErrors = (errorMessages, currentObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        if (!key?.includes('contacts')) {
            let messages = []
            errorMessages[key].map((message) => {
                messages.push(message)
            })

            currentObject[key]["error"] = true
            currentObject[key]["message"] = messages
        }
    })

    return currentObject
}
export const objectMerge = (updatedObject) => {
    const mergedObject = {
        ...updatedObject,
        id: updatedObject?.id,
        unit_id: {
            value: updatedObject?.unit_id,
            error: false,
            message: "",
            required: false
        },
        source_geofence_id: {
            value: updatedObject?.source_geofence_id,
            error: false,
            message: "",
            required: false
        },
        destination_geofence_id: {
            value: updatedObject?.destination_geofence_id,
            error: false,
            message: "",
            required: false
        },
        routes: {
            value: Array.isArray(updatedObject?.routes)?updatedObject?.routes:[],
            error: false,
            message: "",
            required: false
        },
        created_at: {
            value: updatedObject?.created_at,
            error: false,
            message: "",
            required: false
        },
        transaction_number: {
            value: updatedObject?.transaction_number,
            error: false,
            message: "",
            required: false
        }
    }

    return mergedObject;
}
export const objectMergeUpdate = (updatedObject) => {
    const mergedObject = {
        ...updatedObject,
        id: updatedObject?.id,
        unit_id: {
            value: updatedObject?.unit_id,
            error: false,
            message: "",
            required: false
        },
        source_geofence_id: {
            value: updatedObject?.source_geofence_id,
            error: false,
            message: "",
            required: false
        },
        destination_geofence_id: {
            value: updatedObject?.destination_geofence_id,
            error: false,
            message: "",
            required: false
        },
        routes: {
            value: Array.isArray(updatedObject?.routes)?updatedObject?.routes:[],
            error: false,
            message: "",
            required: false
        },
        created_at: {
            value: updatedObject?.created_at,
            error: false,
            message: "",
            required: false
        },
        // transaction_number: {
        //     value: updatedObject?.transaction_number,
        //     error: false,
        //     message: "",
        //     required: false
        // },
        /////////////////////////////////////////////////////////////////////
        sourceGps: {
            value: updatedObject?.source_geofence_code,
            error: false,
            message: "",
            required: false
        },
        destinationGps: {
            value: updatedObject?.destination_geofence_code,
            error: false,
            message: "",
            required: false
        },
        regNumber: {
            value: updatedObject?.unit_registration_number,
            error: false,
            message: "",
            required: false
        },
        fDate: {
            value: updatedObject?.created_at,
            error: false,
            message: "",
            required: false
        },
        regType: {
            value: updatedObject?.unit_registration_type,
            error: false,
            message: "",
            required: false
        },
        province: {
            value: updatedObject?.unit_province,
            error: false,
            message: "",
            required: false
        },
        voucherNo: {
            value: updatedObject?.transaction_number,
            error: false,
            message: "",
            required: false
        },
        voucherType: {
            value: updatedObject?.transaction_type,
            error: false,
            message: "",
            required: false
        },
        driverName: {
            value: updatedObject?.driver_name,
            error: false,
            message: "",
            required: false
        },
        sourceWHouse: {
            value: updatedObject?.source_warehouse,
            error: false,
            message: "",
            required: false
        },
        sourceProvince: {
            value: updatedObject?.source_province,
            error: false,
            message: "",
            required: false
        },
        destinationWHouse: {
            value: updatedObject?.destination_warehouse,
            error: false,
            message: "",
            required: false
        },
        destinationProvince: {
            value: updatedObject?.destination_province,
            error: false,
            message: "",
            required: false
        },
        carrier: {
            value: updatedObject?.carrier,
            error: false,
            message: "",
            required: false
        },
        carrierName: {
            value: updatedObject?.carrier_name,
            error: false,
            message: "",
            required: false
        },
        productType: {
            value: updatedObject?.product_type,
            error: false,
            message: "",
            required: false
        },
        liters: {
            value: updatedObject?.liters,
            error: false,
            message: "",
            required: false
        },
        voucherType2: {
            value: updatedObject?.transaction_type_2,
            error: false,
            message: "",
            required: false
        }
    }

    return mergedObject;
}
export const resetObject = (currentObject, obj) => {
    // var objectKeys = Object.keys(currentObject);

    try {

        currentObject[obj]["error"] = false
        currentObject[obj]["message"] = ""
    } catch (error) {

    }

    return currentObject
}
export default transactionObject;