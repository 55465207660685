import { Autocomplete, Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import helpers from '../../assets/js/helper';
import SearchInput from '../../Components/SearchInput';
import CustomDateRange from '../../Components/CustomDateRange';

const Search = ({
  filters,
  setFilters,
  loadingPlateProvinces,
  loadingPlateTypes,
  loadingPlateLetters,
  plateProvinces,
  plateLetters,
  plateTypes,
  accounts,
  loadingAccounts,
  loadingUsers,
  users,
  isGovernmental
}) => {
  const [t] = useTranslation("common");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={filters?.name}
        setValue={(value) => {
          setFilters({
            ...filters,
            name: value
          })
        }}
        title={t('UNITS.name')}
      />
      <SearchInput
        value={filters?.plate_number}
        setValue={(value) => {
          setFilters({
            ...filters,
            plate_number: value
          })
        }}
        title={t('UNITS.plate_number')}
      />
      <SearchInput
        value={filters?.vin}
        setValue={(value) => {
          setFilters({
            ...filters,
            vin: value
          })
        }}
        title={t('UNITS.vin')}
      />
      {isGovernmental == 1 ? null :
        <>
          <SearchInput
            value={filters?.receipt_number}
            setValue={(value) => {
              setFilters({
                ...filters,
                receipt_number: value
              })
            }}
            title={t('UNITS.receipt_number')}
          />
          <SearchInput
            value={filters?.company_name}
            setValue={(value) => {
              setFilters({
                ...filters,
                company_name: value
              })
            }}
            title={t('UNITS.company_name')}
          />
          <CustomDateRange
            dateRange={filters}
            fromTitle={t('GLOBAL.installation_date_from')}
            toTitle={t('GLOBAL.installation_date_to')}
            title={t('GLOBAL.choose_installation_date_range')}
            setDateRange={(value) => {
              setFilters({
                ...filters,
                ...value
              })
            }}
          />
          <Box
            sx={{
              display: "flex",

              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >

            <Box
              sx={{
                backgroundColor: "lightgray",
                display: "flex",
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "start",
                ml: 1.5,
                mt: 1,
                mb: 1,
                width: "100%",
                "& .MuiTypography-root": {
                  fontFamily: "Cairo",
                },
              }}
              className="px-2 rounded"
            >
              <Typography>
                {t('UNITS.receipt_number')}
              </Typography>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{
                  width: "100%",
                  "& .MuiTypography-root": {
                    fontFamily: "Cairo",
                  },
                }}
                value={filters?.has_receipt_number}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    has_receipt_number: e?.target.value
                  })

                }}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio sx={{ fontFamily: "Cairo" }} />}
                  label={t("UNITS.has_receipt_number")}
                />
                <FormControlLabel
                  value={0}
                  control={<Radio sx={{ fontFamily: "Cairo" }} />}
                  label={t("UNITS.doesnt_have_receipt_number")}
                />
                <FormControlLabel
                  value={-1}
                  control={<Radio sx={{ fontFamily: "Cairo" }} />}
                  label={t("UNITS.all")}
                />


              </RadioGroup>
            </Box>
          </Box>
        </>}
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        multiple={true}
        value={filters?.plate_types_selected ? filters?.plate_types_selected : []}
        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            plate_type_ids: v?.length > 0 ? v?.map(object => object?.id) : [],
            plate_types_selected: v
          })
        }}

        options={plateTypes}
        loading={loadingPlateTypes}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.plate_type')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        multiple={true}
        value={filters?.plate_provinces_selected ? filters?.plate_provinces_selected : []}
        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            plate_province_ids: v?.length > 0 ? v?.map(object => object?.id) : [],
            plate_provinces_selected: v
          })
        }}

        options={plateProvinces}
        loading={loadingPlateProvinces}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.plate_province')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        multiple={true}
        value={filters?.plate_letters_selected ? filters?.plate_letters_selected : []}
        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            plate_letter_ids: v?.length > 0 ? v?.map(object => object?.id) : [],
            plate_letters_selected: v
          })
        }}
        options={plateLetters}
        loading={loadingPlateLetters}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}


        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.plate_letter')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        multiple={true}
        value={filters?.monitors_selected ? filters?.monitors_selected : []}
        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            monitored_by_ids: v?.length > 0 ? v?.map(object => object?.id) : [],
            monitors_selected: v
          })
        }}
        options={users}
        loading={loadingUsers}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}


        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.monitored_by')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.accounts_selected ? filters?.accounts_selected : []}
        options={accounts}
        multiple={true}
        loading={loadingAccounts}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            account_ids: v?.length > 0 ? v?.map(user => user?.id) : [],
            accounts_selected: v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.account_name')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />


      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('UNITS.is_monitored')}
          </Typography>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.is_monitored}
            onChange={(e) => {
              setFilters({
                ...filters,
                is_monitored: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.is_monitored")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.is_not_monitored")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>


    </Box>
  );
};

export default Search