import callAPI from '../ApiConfig'
import $ from "jquery";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const portCategories = async (props) => {

    return await callAPI({
        route: "port_categories/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addPortCategory = async (props) => {


    return await callAPI({
        route: "port_categories/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editPortCategory = async (props) => {

    return await callAPI({
        route: "port_categories/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deletePortCategory = async (props) => {


    return await callAPI({
        route: "port_categories/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getPortCategoryList = async (props) => {

    return await callAPI({
        route: "port_categories/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const portCategoryAPI = {
    portCategories: portCategories,
    addPortCategory: addPortCategory,
    editPortCategory: editPortCategory,
    deletePortCategory: deletePortCategory,
    getPortCategoryList: getPortCategoryList
}
export default portCategoryAPI