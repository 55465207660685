import callAPI from '../ApiConfig'

const geofenceGroups = async (props) => {


    return await callAPI({
        route: "geofence_groups/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const geofenceGroup = async (props) => {


    return await callAPI({
        route: "geofence_groups/" + props?.data?.id,
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}

const addGeofenceGroup = async (props) => {


    return await callAPI({
        route: "geofence_groups/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editGeofenceGroup = async (props) => {

    return await callAPI({
        route: "geofence_groups/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteGeofenceGroup = async (props) => {


    return await callAPI({
        route: "geofence_groups/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}



const getGeofenceGroupList = async (props) => {

    return await callAPI({
        route: "geofence_groups/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const upload = async (props) => {

    let formdata = new FormData();

    formdata.append("files", props?.data?.file)

    return await callAPI({
        route: "geofence_groups/import",
        method: "post",
        signal: props?.signal,
        data: formdata,
        params: props?.params,
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            ...props?.headers
        }
    })
}


const downloadExcel = async (props) => {


    return await callAPI({
        route: "geofence_groups/download",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params
        },
        headers: {

        },
        responseType: "blob"

    })
}
const geofenceGroupAPI = {
    geofenceGroups: geofenceGroups,
    addGeofenceGroup: addGeofenceGroup,
    editGeofenceGroup: editGeofenceGroup,
    deleteGeofenceGroup: deleteGeofenceGroup,
    getGeofenceGroupList: getGeofenceGroupList,
    upload: upload,
    downloadExcel: downloadExcel,
    geofenceGroup: geofenceGroup
}
export default geofenceGroupAPI