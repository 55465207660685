import { Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "universal-cookie";
import {useNavigate} from 'react-router-dom'
const cookies = new Cookies();
export default function SessionEnd() {
  const navigate=useNavigate()
  const [t] = useTranslation("common");
  const [value, setValue] = useState(5);
  var CounterDown = null;
  useEffect(() => {
    let currentValue = value;
    CounterDown = setInterval(() => {
      if (currentValue > 0) {
        setValue((pre) => pre - 1);
        currentValue--;
      }
      if (currentValue == 0) {
        let  basurl = cookies.get("baseUrl");
        if(basurl && basurl.includes("http"))
        {
         
          window.location.href = cookies.get("baseUrl");
        }
        else{
          navigate('/sorry')
        }
        clearInterval(CounterDown);
      }
    }, 1000);
    return () => {
      clearInterval(CounterDown);
    };
  }, []);

  useEffect(() => {
    if (value == 0) {
      clearInterval();
      let  basurl = cookies.get("baseUrl");
      if(basurl && basurl.includes("http"))
      {
       
        window.location.href = cookies.get("baseUrl");
      }
      else{
        navigate('/sorry')
      }
    }
  }, [value]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#17681b",
        zIndex: 2147483647,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          height: "auto",
          flexDirection: "column",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontFamily: "Cairo-Medium",
              color: "#fff",
              fontSize: "30px",
            }}
          >
            {t("GLOBAL.endSessionMessage")}
          </span>
          <span
            style={{
              fontFamily: "Cairo-Medium",
              color: "#f00",
              fontSize: "30px",
              margin: "0 10px",
            }}
          >
            {value > 0 ? value : 0}
          </span>
          <span
            style={{
              fontFamily: "Cairo-Medium",
              color: "#fff",
              fontSize: "30px",
            }}
          >
            {t("GLOBAL.endSessionMessageUnits")}
          </span>
          <CircularProgress
            sx={{ color: "#fff", fontSize: "30px", margin: "0 20px" }}
          />
        </div>
      </Box>
    </div>
  );
}
