import callAPI from '../ApiConfig'

const mainAccounts = async (props) => {


    return await callAPI({
        route: "main_accounts/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addMainAccount = async (props) => {


    return await callAPI({
        route: "main_accounts/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editMainAccount = async (props) => {

    return await callAPI({
        route: "main_accounts/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteMainAccount = async (props) => {


    return await callAPI({
        route: "main_accounts/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}



const getMainAccountList = async (props) => {

    return await callAPI({
        route: "main_accounts/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}




const mainAccountAPI = {
    mainAccounts: mainAccounts,
    addMainAccount: addMainAccount,
    editMainAccount: editMainAccount,
    deleteMainAccount: deleteMainAccount,
    getMainAccountList: getMainAccountList,
}
export default mainAccountAPI