import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Box, Button, Typography } from '@mui/material'
import MainUnitsGrid from './InstallationCheckGrid';
import mainUnitAPI from '../../Network/MainUnit';
import Cookies from "universal-cookie";
import { useNavigate } from 'react-router';
import CustomTextField from '../../Components/CustomTextField';
import Preloading from '../Preload/Preloading';
const cookies = new Cookies();

export default function InstallationCheck() {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false)
  const [mainUnits, setMainUnits] = useState([])
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [filters, setFilters] = useState({
    plate_number_exact: "",
  })
  const navigate = useNavigate();

  const jwt = cookies.get("token");

  var headers = {
    jwt: jwt,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const controller = new AbortController();


  useEffect(() => {
    let abortController = new AbortController();

    loadData()
    return () => {
      abortController.abort();
    }
  }, [])

  const loadData = async (searchParams) => {
    if (filters?.plate_number_exact?.length <= 0) {
      setMainUnits([])
      return;
    }
    let object = {

      page: pageNumber,
      page_size: pageSize,
      search: "",
      ...searchParams,
      ...filters
    };

    setLoading(true);

    const result = await mainUnitAPI.mainUnitsTemp({
      params: object,
      signal: controller?.signal,
      headers: headers
    });
    setLoading(false);

    if (result.status && result?.data?.data) {



      if (result?.data?.data?.data?.length > 0) {
        setMainUnits(result?.data?.data?.data);

      }
      else {
        setMainUnits([]);

      }

    } else {

      if (result?.data?.data?.message) {
        toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
      }
      else if (!result?.data?.error) {
        toast.error(t("NETWORK_MESSAGES.unknownError"));
        return;
      }
      if (result && result?.data && result?.data?.data?.message) {
        if (result?.data?.data?.message?.text == "unauthorized to complete") {
          navigate("/login");
        }
      }
    }
    setLoading(false);

  }


  return (
    <Box sx={{
      // padding: '10px',
      // paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',
      paddingLeft: '10px',
      paddingRight: '10px'

    }}
      onKeyDown={(event) => {


        if (event.key === 'Enter') {
          loadData()
          event.preventDefault();
        }

      }}
      className=" pt-3 mt-1"

    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '10px',
          marginBottom: '10px',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CustomTextField
          label={t("UNITS.plate_number")}
          value={filters?.plate_number_exact}
          error={null}
          message={null}
          haswidth={true}
          readOnly={false}
          focused={true}
          onChange={(e) => {
            setFilters({
              ...filters,
              plate_number_exact: e?.target?.value,
            })

          }}
          onClearClick={() => {
            setFilters({
              ...filters,
              plate_number_exact: "",
            })
           
          }}
        />

        <Button
          disabled={loading}
          variant="contained"
          // spacing={2}
          sx={{
            // flexGrow: 1,
            margin: 1,
            width: "80px !important",
            minWidth: "80px !important",
            maxWidth: "80px !important",
            backgroundColor: "#e7a52a",
            "&:hover": {
              //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
            },
            height: "35px",
            fontFamily: "Cairo-Bold",
          }}
          className="iconeFilterSearch"
          onClick={() => {
            loadData()
          }}
        >
          {t("GLOBAL.SEARCH_SEARCH_BUTTON")}
        </Button>
      </Box>
      {mainUnits?.length ?
        <MainUnitsGrid mainUnits={mainUnits} />
        : <Typography
          sx={{

            fontFamily: 'Cairo',
            color: '#000000',
            fontSize: '16px',
            marginTop: '10px',
            textAlign: 'center',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {"لايوجد مركبات مسجلة"}
        </Typography>}
      {loading ? <Preloading
        sx={{
          direction: 'ltr',
          overflow: 'auto',
          position: 'relative',
          minHeight: `calc(100vh - 350px)`,
          maxHeight: `calc(100vh - 350px)`,
        }}
      /> : null}
    </Box>
  )
}
