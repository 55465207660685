import React, { useEffect } from 'react'
import { Wrapper, Upload } from './InputFileStyleComponent';
import { useSelector, useDispatch } from 'react-redux'
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from '@mui/material';

export default function InputFile({ object, setObject, title, subTitle, onFileChange, submitForm, loading ,templateFileName}) {

    const [t, i18n] = useTranslation('common');

    useEffect(() => {
        const body = document.querySelector('body');
        const upload = document.querySelector('.upload');
        const uploadButtonText = document.querySelector('.upload-button-text');
        const uploadFilename = document.querySelector('.upload-filename');
        const fileInput = document.getElementById('file');
        if (fileInput)
            fileInput.onchange = () => uploadFile(fileInput.files[0]);
        function uploadFile(file) {
            if (file) {
                onFileChange(file)
                // setObject(file.files[0])

                // Add the file name to the input and change the button to an upload button
                uploadFilename.classList.remove('inactive');
                uploadFilename.innerText = file?.name?.length > 20 ? file?.name?.substring(0, 20) + "..." : file?.name;
                uploadButtonText.innerText = 'رفع الملف';
                fileInput.remove();
                uploadButtonText.addEventListener("click", async () => {
                    upload.classList.add("uploading");

                    submitForm(file)
                    // Here you can upload the file to a database, server, or wherever you need it.
                    // You can access the uploaded file by the `file` parameter.
                    // Reset the input after a delay. For actual use, only remove the uploading class when the file is done uploading!
                    if (!loading)
                        upload.classList.remove("uploading");

                });
            }
        }

        // Drop stuff
        const dropArea = document.querySelector('.drop-area');
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, preventDefaults, false);
        });
        function preventDefaults(e) {
            e.preventDefault();
            e.stopPropagation();
        }


        ['dragenter', 'dragover'].forEach(eventName => {
            body.addEventListener(eventName, displayDropArea, false);
        });
        ['dragleave', 'drop'].forEach(eventName => {
            body.addEventListener(eventName, hideDropArea, false);
        });
        ['dragenter', 'dragover'].forEach(eventName => {
            dropArea.addEventListener(eventName, highlight, false);
        });
        ['dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, unhighlight, false);
        });
        function highlight(e) {
            if (!dropArea.classList.contains('highlight'))
                dropArea.classList.add('highlight');
        }
        function unhighlight(e) {
            dropArea.classList.remove('highlight');
        }
        function displayDropArea() {
            if (!dropArea.classList.contains('highlight'))
                dropArea.classList.add('droppable');
        }
        function hideDropArea() {
            dropArea.classList.remove('droppable');
        }
        // Handle dropped files
        dropArea.addEventListener('drop', handleDrop, false);
        function handleDrop(e) {
            let dt = e.dataTransfer;
            let files = dt.files;
            let file = files[0];
            uploadFile(file);
        }

    }, [])

    const downloadExcelTemplate = () => {
        let templatePath = process.env.PUBLIC_URL + '/import_points_template.xlsx';
        if(templateFileName)
        {
            templatePath = process.env.PUBLIC_URL + "/"+templateFileName;
        }


        

        // Create an anchor element
        const link = document.createElement('a');
        link.href = templatePath;
        link.download = templateFileName?templateFileName:'import_points_template.xlsx'; // Name for the downloaded file
        document.body.appendChild(link);

        // Simulate click to trigger download
        link.click();

        // Clean up the anchor element
        document.body.removeChild(link);
    };
    return (
        <Wrapper className="wrapper">



            <Typography
                variant='h4'
                sx={{
                    marginLeft: '10px',
                    marginBottom: '10px',
                    fontFamily: 'Cairo',
                    fontSize: '23px',
                    letterSpacing: '-1px',
                }}>
                {subTitle}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Button onClick={downloadExcelTemplate}>
                    <Typography
                        variant='h6'
                        sx={{
                            marginLeft: '10px',
                            marginBottom: '10px',
                            fontFamily: 'Cairo',
                            fontSize: '16px',
                            letterSpacing: '-1px',
                        }}> {t('GLOBAL.import_point_template')}
                    </Typography>
                </Button>
            </Box>
            <div className="upload-wrapper">
                <Upload className="upload drop-area" buttonBgColor={"#17681b"} buttonTextColor={"white"} color={"#17681b"} bgColor={"yellow"}>
                    <div className="upload-info" >
                        <AttachmentIcon sx={{ color: "#17681b", margin: '0 5px' }} size={16} />
                        <span className="upload-filename inactive drop-text" style={{ fontFamily: 'Cairo' }}>{t('GLOBAL.import_form_message_not_selected_file')}</span>
                    </div>
                    <button className="upload-button" >
                        <input type="file" id="file" accept=".xlsx" />
                        <label for="file" className="upload-button-text" style={{ fontFamily: 'Cairo' }}>{t('GLOBAL.import_form_upload_button')}</label>
                    </button>
                    <div className="upload-hint" style={{ fontFamily: 'Cairo' }} >{t('GLOBAL.import_form_upload_button')}...</div>
                    <div className="upload-progress" ></div>
                </Upload>
            </div>
        </Wrapper>
    )
}
