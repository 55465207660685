import { Box, FormControlLabel, Switch } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import CustomTextField from '../../../Components/CustomTextField';
import CloseIcon from "@mui/icons-material/Close";

const RenderContent = (props) => {
  const [t] = useTranslation("common");


  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.9)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "21120",
        }}
        onClick={() => {
          props?.setOpen(false);
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>

      <Box
        className="row d-flex justify-content-center align-items-start"
        sx={{ transition: "all 0.7s ease" }}
      >
        <CustomTextField
          label={t("UNIT_TYPES.name")}
          value={props?.object?.name?.value}
          error={props?.object?.name?.error}
          message={props?.object?.name?.message}
          haswidth={true}
          readOnly={false}
          focused={true}
          onChange={(e) => {

            props?.setObject({
              ...props?.object,
              name: {
                ...props?.object?.name,
                value: e?.target?.value,
                error: false,
                message: []
              }
            })

          }}
          onClearClick={() => {
            props?.setObject({
              ...props?.object,
              name: {
                ...props?.object?.name,
                value: "",
                error: false,
                message: []
              }
            })
          }}
        />
        <Box sx={{ margin: "8px 0" }}></Box>
        <CustomTextField
          label={t("UNIT_TYPES.code")}
          value={props?.object?.code?.value}
          error={props?.object?.code?.error}
          message={props?.object?.code?.message}
          haswidth={true}
          readOnly={false}
          focused={true}
          onChange={(e) => {

            props?.setObject({
              ...props?.object,
              code: {
                ...props?.object?.code,
                value: e?.target?.value,
                error: false,
                message: []
              }
            })

          }}
          onClearClick={() => {
            props?.setObject({
              ...props?.object,
              code: {
                ...props?.object?.code,
                value: "",
                error: false,
                message: []
              }
            })
          }}
        />
        
        <Box sx={{ margin: "8px 0" }}></Box>
        <Box
          sx={{
            backgroundColor: '#fff',
            color: '#000',
            // borderRadius:'10px',
            height: '55px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            width: '93% !important',
            boxShadow: '0px 6px 20px 3px #00000014 !important',
            margin: 'auto',
            marginTop: '18px',
            paddingLeft: '15px',
            "& .MuiTypography-root": {
              fontFamily: 'Cairo-Medium'
            }

          }}
        >
          <FormControlLabel control={<Switch checked={props?.object?.enabled?.value == 1} onChange={(e) => {

            props?.setObject({
              ...props?.object,
              enabled: {
                ...props?.object?.enabled,
                value: e.target.checked,
                error: false,
                message: []
              }
            })
          }} />} sx={{ fontFamily: 'Cairo-Medium' }} label={!props?.enabled?.value ? t('UNIT_TYPES.enabled') : t('UNIT_TYPES.disabled')} />

        </Box>
        <Box sx={{ margin: "8px 0" }}></Box>
      </Box>
    </Box>
  );
};

export default RenderContent