import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import helpers from '../../assets/js/helper';
import SearchInput from '../../Components/SearchInput';
import SearchDate from '../../Components/SearchDate';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ClearIcon from '@mui/icons-material/Clear';

const Search = ({filters,setFilters}) => {
  const [t] = useTranslation("common");
  

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={filters?.name}
        setValue={(value)=>{
          setFilters({
            ...filters,
            name:value
          })
        }}
        title={t('ACTIVITIES.name')}
      />
      <SearchInput
        value={filters?.note}
        setValue={(value)=>{
          setFilters({
            ...filters,
            note:value
          })
        }}
        title={t('ACTIVITIES.note')}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={helpers?.getUsers(filters?.created_by_id)}
        options={helpers?.users ? helpers?.users : []}
      
        getOptionLabel={(option) => option && option?.name || ""}
        
        onChange={(e, v, r) => { 
          setFilters({
            ...filters,
            created_by_id:v?.id
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('ACTIVITIES.created_by_id')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={helpers?.getActivityType(filters?.log_type_id)}
        options={helpers?.activitableTypes ? helpers?.activitableTypes : []}
      
        getOptionLabel={(option) => option && option?.name || ""}
        
        onChange={(e, v, r) => { 
          setFilters({
            ...filters,
            log_type_id:v?.id
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('ACTIVITIES.type')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />         
      
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label={'من'}
            inputFormat="MM/DD/YYYY"
            value={filters?.creation_date_from}
            onChange={(date) => {
              setFilters({
                ...filters,
                creation_date_from: date
              })
            }}
            renderInput={(params) => (
              <TextField {...params}
                placeholder={t('الى')}
                variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DesktopDatePicker
            label={'الى'}
            inputFormat="MM/DD/YYYY"
            value={filters?.creation_date_to}
            onChange={(date) => {
              setFilters({
                ...filters,
                creation_date_to: date
              })
            }}
            renderInput={(params) => (
              <TextField {...params}
                placeholder={t('الى')}
                variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
            )}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default Search;