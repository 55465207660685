
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import transactionObject, {
  checkErrors,
  objectMerge,
  resetErrors,
  resetObject,
} from "../../../Errors/Transactions/Transaction";
import { toast } from "react-toastify";
import helpers from "../../../assets/js/helper";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import transactionAPI from "../../../Network/Transaction";
import RenderFooter from "./RenderFooter";
import RenderContent from "./RenderContent";
import moment from "moment/moment";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};


export default function FormContainer(props) {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState(transactionObject);
  const navigate = useNavigate();

  const submit = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {


        let checkErrorObject = {
          ...transaction
        }
        let hasError = false
        let readyToSendObject = {}
        if (transaction?.unit_id?.value?.id) {
          let unit_id = transaction?.unit_id?.value?.id
          readyToSendObject["unit_id"] = unit_id;
        }
        else {
          hasError = true
          checkErrorObject['unit_id']['error'] = true
          checkErrorObject['unit_id']['message'] = [t('GLOBAL.mandatory_field')]
        }
        if (transaction?.source_geofence_id?.value?.id) {
          let source_geofence_id = transaction?.source_geofence_id?.value?.id
          readyToSendObject["source_geofence_id"] = source_geofence_id;
        }
        else {
          hasError = true
          checkErrorObject['source_geofence_id']['error'] = true
          checkErrorObject['source_geofence_id']['message'] = [t('GLOBAL.mandatory_field')]
        }
        if (transaction?.destination_geofence_id?.value?.id) {
          let destination_geofence_id = transaction?.destination_geofence_id?.value?.id;
          readyToSendObject["destination_geofence_id"] = destination_geofence_id;
        }
        else {
          hasError = true
          checkErrorObject['destination_geofence_id']['error'] = true
          checkErrorObject['destination_geofence_id']['message'] = [t('GLOBAL.mandatory_field')]
        }

        if (transaction?.transaction_number?.value) {
          let transaction_number = transaction?.transaction_number?.value;
          readyToSendObject["transaction_number"] = transaction_number;
        }
        else{
          hasError=true
          checkErrorObject['transaction_number']['error']=true
          checkErrorObject['transaction_number']['message']=[t('GLOBAL.mandatory_field')]
        }

        if (Array.isArray(transaction?.routes?.value) && transaction?.routes?.value?.length > 0) {
          let routes = transaction?.routes?.value;
          readyToSendObject["routes"] = routes;
        }
        else {
          hasError = true
          checkErrorObject['routes']['error'] = true
          checkErrorObject['routes']['message'] = [t('TRANSACTIONS.no_routes_found')]
        }

        let datetimeString = moment().format('YYYY-MM-DD hh:mm:ss');

        try {

          const date = helpers.formatDateTime(transaction?.created_at?.value);

          // if the input date is valid, format it to "YYYY-MM-DD hh:mm:ss"
          datetimeString = date;



        } catch (error) {
        }

        readyToSendObject["created_at"] = datetimeString;



        if (hasError) {
          setTransaction({
            ...checkErrorObject
          })
          return
        }



        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
          id: cookies.get("id"),
        };

        let result = null;
        if (props?.object && props?.object?.id) {
          readyToSendObject["id"] = props?.object?.id;

          result = await transactionAPI.editTransaction({
            data: readyToSendObject,
            params: params,
            id: props?.object?.id,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        } else {

          result = await transactionAPI.addTransaction({
            data: readyToSendObject,
            params: params,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        }

        if (result?.status && result?.data?.status) {

          setLoading(false);

          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));
          clearForm();
          props.loadData();
          props.setOpenForm(false);
        } else {

          setLoading(false);

          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }
        setLoading(false);
      } catch (error) {

        setLoading(false);
        toast.error(error.message);
      }
    } else {

      // navigate('/redirect')
    }
  };

  const clearForm = () => {
    setTransaction(transactionObject)
  };
  useEffect(() => {
    if (!props?.object) {
      clearForm();

    } else {
      setTransaction(objectMerge(props?.object))
    }
  }, [props?.openForm, props?.object]);


  return (
    <PopupForm
      open={props.openForm}
      setOpen={props.setOpenForm}
      title={
        props?.object?.id
          ? t("TRANSACTIONS.update_title")
          : t("TRANSACTIONS.add_title")
      }
      isFullScreen={false}
      content={
        <RenderContent
          object={transaction}
          setObject={(value) => {
            setTransaction(value)
          }}
          open={props.openForm}
          setOpen={props.setOpenForm}
          isFullScreen={false}
        />
      }
      footer={
        <RenderFooter
          open={props.openForm}
          setOpen={props.setOpenForm}
          submit={submit}
          clearForm={clearForm}
        />
      }
    />
  );
}
