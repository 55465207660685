
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import routeObject, {
  objectMerge
} from "../../../Errors/Routes/Route";

import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import routeAPI from "../../../Network/Route";
import RenderFooter from "./RenderFooter";
import RenderContent from "./RenderContent";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();


export default function FormContainer(props) {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(true);
  const [route, setRoute] = useState(routeObject);
  const navigate = useNavigate();

  const submit = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {

        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
          id: cookies.get("id"),
        };

        let result = null;
        if (props?.object && props?.object?.id) {
          let checkErrorObject = {
            ...route
          }
          let hasError = false
          let readyToSendObject = {}
          if (route?.name?.value && route?.name?.value?.trim()?.length > 0) {
            let name = route?.name?.value?.trim()
            readyToSendObject["name"] = name?.replace(/\\|\/|\'/g, "");
          }
          else {
            hasError = true
            checkErrorObject['name']['error'] = true
            checkErrorObject['name']['message'] = [t('GLOBAL.mandatory_field')]
          }
          if (route?.code?.value && route?.code?.value?.trim()?.length > 0) {
            let code = route?.code?.value?.trim()
            readyToSendObject["code"] = code?.replace(/\\|\/|\'/g, "");
          }
          else {
            hasError = true
            checkErrorObject['code']['error'] = true
            checkErrorObject['code']['message'] = [t('GLOBAL.mandatory_field')]
          }
          if (route?.wialon_id?.value) {
            let resource_id = route?.wialon_id?.value?.resource_id;
            let geofence_id = route?.wialon_id?.value?.geofence_id;
            readyToSendObject["resource_id"] = resource_id;
            readyToSendObject["geofence_id"] = geofence_id;
          }
          else{
            if(props?.object?.id)
            {
              toast.warn(t("ROUTES.you_have_to_select_rout_to_link"))
              return false
            }
          }
          readyToSendObject["update_wialon_link"] = 1;
          if (hasError) {
            setRoute({
              ...checkErrorObject
            })
            return
          }
          readyToSendObject["id"] = props?.object?.id;

          setLoading(true)
          result = await routeAPI.editRoute({
            data: readyToSendObject,
            params: params,
            id: props?.object?.id,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        } else {

          let checkErrorObject = {
            ...route
          }
          let hasError = false
          let readyToSendObject = {
            routes:[]
          }
          if (!route?.source_geofence_id?.value?.id) {
            hasError = true
            checkErrorObject['source_geofence_id']['error'] = true
            checkErrorObject['source_geofence_id']['message'] = [t('GLOBAL.mandatory_field')]
          }
          if (!route?.destination_geofence_id?.value?.id) {
            hasError = true
            checkErrorObject['destination_geofence_id']['error'] = true
            checkErrorObject['destination_geofence_id']['message'] = [t('GLOBAL.mandatory_field')]
          }

          if (hasError) {
            setRoute({
              ...checkErrorObject
            })
            return
          }
          route?.routes?.map(item=>{
            readyToSendObject?.routes?.push({
              source_geofence_id:route?.source_geofence_id?.value?.id,
              destination_geofence_id:route?.destination_geofence_id?.value?.id,
              code:route?.source_geofence_id?.value?.code+"-"+route?.destination_geofence_id?.value?.code+"-"+item?.code,
              name:route?.name?.value,
              resource_id: item?.route?.resource_id,
              geofence_id: item?.route?.geofence_id,
              note: item?.note

            })
          })

          result = await routeAPI.addRoute({
            data: readyToSendObject,
            params: params,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        }

        if (result?.status && result?.data?.status) {

          setLoading(false);

          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));
          clearForm();
          props.loadData();
          props.setOpenForm(false);
        } else {

          setLoading(false);

          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }
        setLoading(false);
      } catch (error) {

        setLoading(false);
        toast.error(error.message);
      }
    } else {

      // navigate('/redirect')
    }
  };

  const clearForm = () => {
    setRoute(routeObject)
  };
  useEffect(() => {
    if (!props?.object) {
      clearForm();

    } 
    if(props?.objectType === "transaction")
    {
      
      let name = ""
      if (props?.transaction?.source_geofence_name) {
        name = props?.transaction?.source_geofence_name
      }
      if (props?.transaction?.destination_geofence_name) {
        name += " - " + props?.transaction?.destination_geofence_name
      }
      let code = (props?.transaction?.source_geofence_code ? props?.transaction?.source_geofence_code : "") + "-" + ( props?.transaction?.destination_geofence_code ?  props?.transaction?.destination_geofence_code : "")
      let updatedObject = {
        ...routeObject,
        destination_geofence_id: {
          value: {
            id: props?.transaction?.destination_geofence_id,
            name: props?.transaction?.destination_geofence_name,
            code: props?.transaction?.destination_geofence_code,
          },
          error: false,
          message: "",
          required: false
        },
        source_geofence_id: {
          value: {
            id: props?.transaction?.source_geofence_id,
            name: props?.transaction?.source_geofence_name,
            code: props?.transaction?.source_geofence_code,
          },
          error: false,
          message: "",
          required: false
        },
        number: {
          value: 1,
          error: false,
          message: "",
          required: false
        },
        name: {
          value: name,
          error: false,
          message: "",
          required: false
        },
        code: {
          value: code,
          error: false,
          message: "",
          required: false
        }


      }
     
      setRoute(updatedObject)
    }
    else {

      setRoute(objectMerge(props?.object))
    }
    setLoading(false)
  }, [props?.openForm, props?.object]);


  return (
    <PopupForm
      open={props.openForm}
      setOpen={props.setOpenForm}
      title={
        props?.object?.id
          ? t("ROUTES.update_title")
          : t("ROUTES.add_title")
      }
      isFullScreen={false}
      content={
        loading?null:
        <RenderContent
          object={route}
          setObject={(value) => {
            setRoute(value)
          }}
          method={props?.objectType === "transaction"?"add_from_transaction":( props?.object?.id ? "update" : "add")}
          open={props.openForm}
          setOpen={props.setOpenForm}
          isFullScreen={false}
        />
      }
      footer={
        <RenderFooter
          open={props.openForm}
          setOpen={props.setOpenForm}
          submit={submit}
          clearForm={clearForm}
        />
      }
    />
  );
}
