import { Box } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import CustomTextField from '../../../Components/CustomTextField';
import CloseIcon from "@mui/icons-material/Close";


const RenderContent = (props) => {
  const [t] = useTranslation("common");
  

  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.9)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "21120",
        }}
        onClick={() => {
          props?.setOpen(false);
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>

      <Box
        className="row d-flex justify-content-center align-items-start"
        sx={{ transition: "all 0.7s ease" }}
      >
        <CustomTextField
          label={t("GEOFENCES.name")}
          value={props?.object?.name?.value}
          error={props?.object?.name?.error}
          message={props?.object?.name?.message}
          haswidth={true}
          readOnly={false}
          focused={true}
          hasMultipleLine={true}
          onChange={(e) => {
            let filteredValue = "";
            if(e?.target?.value )
            {
              filteredValue = e?.target?.value.replace(/[^a-zA-Z0-9\u0600-\u06FF\s]/g, '');
            }

            if(filteredValue?.length > 120)
            {
              return
            }
            

            props?.setObject({
              ...props?.object,
              name: {
                ...props?.object?.name,
                value: filteredValue,
                error: false,
                message: []
              }
            })

          }}
          onClearClick={() => {
            props?.setObject({
              ...props?.object,
              name: {
                ...props?.object?.name,
                value: "",
                error: false,
                message: []
              }
            })
          }}
        />
        
      </Box>
    </Box>
  );
};

export default RenderContent