
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import geofenceObject, {
  objectMerge
} from "../../../Errors/Geofences/Geofence";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import geofenceAPI from "../../../Network/Geofence";
import RenderFooter from "./RenderFooter";
import RenderContent from "./RenderContent";
import swal from "sweetalert";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();


export default function UpdateCodeFormContainer(props) {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [geofence, setGeofence] = useState(geofenceObject);
  const navigate = useNavigate();

  const submit = async () => {
    try {
      let checkErrorObject = {
        ...geofence
      }
      let hasError = false
      let readyToSendObject = {}
      if (geofence?.name?.value && geofence?.name?.value?.trim()?.length > 0) {
        let name = geofence?.name?.value?.trim()
        readyToSendObject["name"] = name?.replace(/\\|\/|\'/g, "");
      }
      else {
        hasError = true
        checkErrorObject['name']['error'] = true
        checkErrorObject['name']['message'] = [t('GLOBAL.mandatory_field')]
      }
      if (geofence?.code?.value && geofence?.code?.value?.trim()?.length > 0) {
        let code = geofence?.code?.value?.trim()
        readyToSendObject["code"] = code?.replace(/\\|\/|\'/g, "");
      }
      else {
        hasError = true
        checkErrorObject['code']['error'] = true
        checkErrorObject['code']['message'] = [t('GLOBAL.mandatory_field')]
      }
      if (geofence?.port_code?.value && geofence?.port_code?.value?.trim()?.length > 0) {
        let port_code = geofence?.port_code?.value?.trim()
        readyToSendObject["port_code"] = port_code?.replace(/\\|\/|\'/g, "");
      }
      else {
        hasError = true
        checkErrorObject['port_code']['error'] = true
        checkErrorObject['port_code']['message'] = [t('GLOBAL.mandatory_field')]
      }
      if (geofence?.branch_id?.value?.id) {
        let branch_id = geofence?.branch_id?.value?.id;
        readyToSendObject["branch_id"] = branch_id;
      }
      else {
        hasError = true
        checkErrorObject['branch_id']['error'] = true
        checkErrorObject['branch_id']['message'] = [t('GLOBAL.mandatory_field')]
      }

      if (geofence?.port_type_id?.value?.id) {
        let port_type_id = geofence?.port_type_id?.value?.id;
        readyToSendObject["port_type_id"] = port_type_id;
      }
      else {
        hasError = true
        checkErrorObject['port_type_id']['error'] = true
        checkErrorObject['port_type_id']['message'] = [t('GLOBAL.mandatory_field')]
      }

      if (geofence?.port_category_id?.value?.id) {
        let port_category_id = geofence?.port_category_id?.value?.id;
        readyToSendObject["port_category_id"] = port_category_id;
      }
      else {
        hasError = true
        checkErrorObject['port_category_id']['error'] = true
        checkErrorObject['port_category_id']['message'] = [t('GLOBAL.mandatory_field')]
      }

      if (geofence?.port_return_id?.value?.id) {
        let port_return_id = geofence?.port_return_id?.value?.id;
        readyToSendObject["port_return_id"] = port_return_id;
      }
      else {
        hasError = true
        checkErrorObject['port_return_id']['error'] = true
        checkErrorObject['port_return_id']['message'] = [t('GLOBAL.mandatory_field')]
      }

      // if (geofence?.wialon_id?.value) {
      //   let resource_id = geofence?.wialon_id?.value?.resource_id;
      //   let geofence_id = geofence?.wialon_id?.value?.geofence_id;
      //   readyToSendObject["resource_id"] = resource_id;
      //   readyToSendObject["geofence_id"] = geofence_id;
      // }
      // else{
      //   if(props?.object?.id)
      //   {
      //     toast.warn(t("GEOFENCES.you_have_to_select_point_to_link"))
      //     return false
      //   }
      // }
      readyToSendObject["update_wialon_link"] = 0;
     

      if (hasError) {
        setGeofence({
          ...checkErrorObject
        })
        return
      }

      confirmSubmit(readyToSendObject)
      
    } catch (error) {

      setLoading(false);
      toast.error(error.message);
    }

  };
  const confirmSubmit = async (readyToSendObject) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {

        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
          id: cookies.get("id"),
        };

        let result = null;
        if (props?.object && props?.object?.id) {
          readyToSendObject["id"] = props?.object?.id;

          result = await geofenceAPI.editGeofenceCode({
            data: readyToSendObject,
            params: params,
            id: props?.object?.id,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        } else {

          toast.error(t("NETWORK_MESSAGES.unknownError"))
          return
        }

        if (result?.status && result?.data?.status) {

          setLoading(false);

          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));
          clearForm();
          props?.loadData();
          props?.setOpenForm(false);
        } else {

          setLoading(false);

          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }
        setLoading(false);
      } catch (error) {

        setLoading(false);
        toast.error(error.message);
      }
    } else {

      // navigate('/redirect')
    }
  }

  const clearForm = () => {
    setGeofence(geofenceObject)
  };
  useEffect(() => {
    if (!props?.object) {
      clearForm();

    } else {
      const mergedObject = objectMerge(props?.object)
      setGeofence(mergedObject)
    }
  }, [props?.openForm, props?.object]);


  return (
    <PopupForm
      open={props?.openForm}
      setOpen={props?.setOpenForm}
      title={
        props?.object?.id
          ? t("GEOFENCES.update_code")
          : t("GEOFENCES.add_title")
      }
      isFullScreen={false}
      content={
        <RenderContent
          object={geofence}
          setObject={(value) => {
            setGeofence(value)
          }}
          open={props?.openForm}
          setOpen={props?.setOpenForm}
          loadingBranches={props?.loadingBranches}
          loadingPortCategories={props?.loadingPortCategories}
          loadingPortReturns={props?.loadingPortReturns}
          loadingPortTypes={props?.loadingPortTypes}
          branches={props?.branches}
          portCategories={props?.portCategories}
          portReturns={props?.portReturns}
          portTypes={props?.portTypes}
          isFullScreen={false}
        />
      }
      footer={
        <RenderFooter
          open={props?.openForm}
          setOpen={props?.setOpenForm}
          submit={submit}
          clearForm={clearForm}
        />
      }
    />
  );
}
