import React from 'react'
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import $ from 'jquery'
import Cookies from "universal-cookie";
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import PopupAlert from './PopupAlert'
import profileAPI from '../Network/Profile/index'
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Preload from '../Pages/Preload/Preload';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoles, setRolesRedux } from '../reduxStore/RolesReducer';

const cookies = new Cookies();

export default function ProviderRedirect(props) {
  const search = useLocation().search;
  const rolesRedux = useSelector(selectRoles);
  const dispatch = useDispatch();
  const sid = new URLSearchParams(search).get('sid');
  const baseUrl = new URLSearchParams(search).get('baseUrl');
  const user = new URLSearchParams(search).get('user');
  const authHash = new URLSearchParams(search).get('authHash');
  const [t] = useTranslation('common')
  const [units, setUnits] = useState([])
  const [activeSession, setActiveSession] = useState(false)
  const navigate = useNavigate()
  const [showAlert, setShowAlert] = useState(false)
  const [phone, setPhone] = useState('')
  const [username, setUserName] = useState('')
  const [phoneError, setPhoneError] = useState({
    err: false,
    mes: ''
  })


  useEffect(() => {
    checkUserHasPhone();
  }, [])

  const checkUserHasPhone = async () => {
    if (cookies.get('active_session') == 'yes') {
      let data = null
      try {
        let params = {
          username: cookies.get('user_name'),
          user_id: cookies.get('user_id'),
          account_name: cookies.get('account_name'),
          account_id: cookies.get('account_id'),
          sid: cookies.get('sid'),
          baseurl: cookies.get('baseUrl'),
          id: cookies.get('id')
        }
       
        data = await profileAPI.Profile({
          params: params
        });
        if (data && data?.status && data?.data?.status) {
          
          if (data?.data?.data?.roles) {
            handleCheckIdExists(data?.data?.data?.roles)
          }
          if (data?.data && data?.data?.data && (!data?.data?.data?.phone || !data?.data?.data?.name)) {
            setPhone(data?.data?.data?.phone)
            setUserName(data?.data?.data?.name)
            setShowAlert(true)

          }
          if (data && data?.data && data?.data?.data?.message) {
            if (data?.data?.data?.message?.text == "unauthorized to complete") {
              navigate('/redirect')
            }
          }
        } else {
          if (data && data?.data && data?.data?.data?.message) {
            if (data?.data?.data?.message?.text == "unauthorized to complete") {
              navigate('/redirect')
            }
          }
        }
      } catch (e) {
        console.log(e?.message)
      }
    } else {
      navigate('/redirect')
    }
  }
  const handleCheckIdExists = (roles) => {

    if (rolesRedux) {
      

      const updatedRoles = Object.entries(rolesRedux).reduce(
        (acc, [key, value]) => {
          if (roles?.includes(value?.id)) {
            acc[key] = { ...value, value: true };
          } else {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      
      dispatch(setRolesRedux(updatedRoles));
    }
  };

  const saveProfileInfor = async (obj) => {
    if (phoneError?.err) {
      return;
    }
    if (cookies.get('active_session') && cookies.get('active_session') == 'yes') {

      let data = null
      if (!obj?.name) {
        toast.warning('اسم المستخدم مطلوب')
        return
      }
      if (!obj?.phone) {
        toast.warning('رقم الهاتف مطلوب')
        return
      }

      try {
        let params = {
          username: cookies.get('user_name'),
          user_id: cookies.get('user_id'),
          account_name: cookies.get('account_name'),
          account_id: cookies.get('account_id'),
          sid: cookies.get('sid'),
          baseurl: cookies.get('baseUrl'),
          id: cookies.get('id')
        }


        data = await profileAPI.UpdateProfile({
          params: params,
          data: {
            name: obj?.name,
            phone: obj?.phone
          },
        });
        if (data && data?.status) {
          
          setShowAlert(false)
          cookies.set('phone', obj?.phone, { path: '/' })
          toast.success(t('NETWORK_MESSAGES.infoUpdatedSuccessfully'))
          if (data && data?.data && data?.data?.data?.message) {
            if (data?.data?.data?.message?.text == "unauthorized to complete") {
              navigate('/redirect')
            }
          }

        } else {
          toast.warning(t('NETWORK_MESSAGES.infoAddedSuccessfully'))
          if (data && data?.data && data?.data?.data?.message) {
            if (data?.data?.data?.message?.text == "unauthorized to complete") {
              navigate('/redirect')
            }
          }
        }
      } catch (e) {
        console.log(e?.message)
      }
    } else {
      navigate('/redirect')
    }
  }

  try {
    if (!cookies.get('sid') || !cookies.get('baseUrl')) {
      navigate('/')
    }
  } catch (err) {
    console.log(err.message)
  }

  return (
    <Box sx={{ position: 'relative' }}>



      <PopupAlert object={null} phoneError={phoneError} setPhoneError={setPhoneError} addForm={showAlert} phone={phone} username={username} setAddForm={setShowAlert} loadData={null} submit={saveProfileInfor} />
      {/* <Box className="btn btn-primary text-light position-absolute" sx={{top:window.location.pathname=="/system/dashboard"?'-54px':'-74px',left:'0px',zIndex:'234234'}} onClick={handleSession}>Get New Session SID</Box> */}
      {props?.children}
    </Box>
  )
}
