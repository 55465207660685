
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import activityObject, {
  checkErrors,
  objectMerge,
  resetErrors,
  resetObject,
} from "../../../Errors/Activities/Activities"

// import EmployeeAPI from '../../../../Global/Network/Employee/index'

import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import userAPI from "../../../Network/User";
import RenderFooter from "./RenderFooter";
import RenderContent from "./RenderContent";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};


export default function FormContainer(props) {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(activityObject);
  const navigate = useNavigate();

  const submit = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {

        let checkErrorObject = {
          ...user
        }
        let hasError = false
        let readyToSendObject = {}
        if (user?.name?.value && user?.name?.value?.trim()?.length > 0) {
          let name = user?.name?.value?.trim()
          readyToSendObject["name"] = name?.replace(/\\|\/|\'/g, "");
        }
        else {
          hasError = true
          checkErrorObject['name']['error'] = true
          checkErrorObject['name']['message'] = [t('GLOBAL.mandatory_field')]
        }
        if (user?.note?.value && user?.note?.value?.trim()?.length > 0) {
          let note = user?.note?.value?.trim()
          readyToSendObject["note"] = note?.replace(/\\|\/|\'/g, "");
        }
        else {
          hasError = true
          checkErrorObject['note']['error'] = true
          checkErrorObject['note']['message'] = [t('GLOBAL.mandatory_field')]
        }
        if (user?.activitable_id?.value) {
          let activitable_id = user?.activitable_id?.value.trim();
          readyToSendObject["activitable_id"] = activitable_id?.replace(/\\|\/|\'/g, "");
        }
        else {
          hasError = true
          checkErrorObject['activitable_id']['error'] = true
          checkErrorObject['activitable_id']['message'] = [t('GLOBAL.mandatory_field')]
        }
        if (user?.activitable_type?.value) {
          let activitable_type = user?.activitable_type?.value.trim();
          readyToSendObject["activitable_type"] = activitable_type?.replace(/\\|\/|\'/g, "");
        }
        else {
          hasError = true
          checkErrorObject['activitable_type']['error'] = true
          checkErrorObject['activitable_type']['message'] = [t('GLOBAL.mandatory_field')]
        }

        let enabled = user?.enabled?.value ? 1 : 0
        readyToSendObject["enabled"] = enabled;



        if (hasError) {
          setUser({
            ...checkErrorObject
          })
          return
        }



        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
          id: cookies.get("id"),
        };

        let result = null;
        if (props?.object && props?.object?.id) {
          readyToSendObject["id"] = props?.object?.id;

          result = await userAPI.editUser({
            data: readyToSendObject,
            params: params,
            id: props?.object?.id,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        } else {

          result = await userAPI.addUser({
            data: readyToSendObject,
            params: params,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        }

        if (result?.status && result?.data?.status) {

          setLoading(false);

          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));
          clearForm();
          props.loadData();
          props.setOpenForm(false);
        } else {

          setLoading(false);

          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text)
          }
        }
        setLoading(false);
      } catch (error) {

        setLoading(false);
        toast.error(error.message);
      }
    } else {

      // navigate('/redirect')
    }
  };

  const clearForm = () => {
    setUser(activityObject)
  };

  useEffect(() => {
    if (!props?.object) {
      clearForm();

    } else {
      
      setUser(objectMerge(props?.object))
    }
  }, [props?.openForm, props?.object]);


  return (
    <PopupForm
      open={props.openForm}
      setOpen={props.setOpenForm}
      title={
        props?.object?.id
          ? t("USERS.update_title")
          : t("USERS.add_title")
      }
      isFullScreen={false}
      content={
        <RenderContent
          object={user}
          setObject={(value) => {
            setUser(value)
          }}
          open={props.openForm}
          setOpen={props.setOpenForm}
          isFullScreen={false}
        />
      }
      footer={
        <RenderFooter
          open={props.openForm}
          setOpen={props.setOpenForm}
          submit={submit}
          clearForm={clearForm}
        />
      }
    />
  );
}
