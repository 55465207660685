import { Box, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import tagAPI from "../../../Network/Tag";

import CustomeSelectField from '../../../Components/CustomeSelectField';
import CustomTextField from '../../../Components/CustomTextField';
import CloseIcon from "@mui/icons-material/Close";
import { selectRoles } from '../../../reduxStore/RolesReducer';
import { useSelector } from 'react-redux';
import mainAccountAPI from '../../../Network/MainAccount';
import Cookies from "universal-cookie";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import helpers from '../../../assets/js/helper';
import CustomDateField from '../../../Components/CustomDateField';
const cookies = new Cookies();

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  


  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.9)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "21120",
        }}
        onClick={() => {
          props?.setOpen(false);
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>

      <Box className="Container-fluid position-relative" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{
          marginTop: '10px'
        }}>
          {/* <Grid xs={12} sm={12} md={12} lg={12} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.name")}
              value={props?.object?.name?.value}
              error={props?.object?.name?.error}
              message={props?.object?.name?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  name: {
                    ...props?.object?.name,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  name: {
                    ...props?.object?.name,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.plate_number")}
              value={props?.object?.plate_number?.value}
              error={props?.object?.plate_number?.error}
              message={props?.object?.plate_number?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {
                if (!isNaN(e?.target?.value)) {
                  props?.setObject({
                    ...props?.object,
                    plate_number: {
                      ...props?.object?.plate_number,
                      value: e?.target?.value,
                      error: false,
                      message: []
                    }
                  })
                }

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_number: {
                    ...props?.object?.plate_number,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.plate_letter")}
              haswidth={true}
              value={props?.object?.plate_letter_id?.value ? props?.object?.plate_letter_id?.value : null}
              list={props?.plateLetters?.length ? props?.plateLetters : []}
              error={props?.object?.plate_letter_id?.error}
              message={props?.object?.plate_letter_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {


                props?.setObject({
                  ...props?.object,
                  plate_letter_id: {
                    ...props?.object?.plate_letter_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_letter_id: {
                    ...props?.object?.plate_letter_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.plate_province")}
              haswidth={true}
              value={props?.object?.plate_province_id?.value ? props?.object?.plate_province_id?.value : null}
              list={props?.plateProvinces?.length ? props?.plateProvinces : []}
              error={props?.object?.plate_province_id?.error}
              message={props?.object?.plate_province_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {
                props?.setObject({
                  ...props?.object,
                  plate_province_id: {
                    ...props?.object?.plate_province_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_province_id: {
                    ...props?.object?.plate_province_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.plate_type")}
              haswidth={true}
              value={props?.object?.plate_type_id?.value ? props?.object?.plate_type_id?.value : null}
              list={props?.plateTypes?.length ? props?.plateTypes : []}
              error={props?.object?.plate_type_id?.error}
              message={props?.object?.plate_type_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {


                props?.setObject({
                  ...props?.object,
                  plate_type_id: {
                    ...props?.object?.plate_type_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_type_id: {
                    ...props?.object?.plate_type_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>


         
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.tag")}
              haswidth={true}
              onInputChange={handleSearchTermTagChange}
              inputValue={searchTermTag}
              loading={loadingTags}
              loadingText={t('GLOBAL.searching')}
              value={props?.object?.tag_id?.value?props?.object?.tag_id?.value:null}
              list={tags}
              error={props?.object?.tag_id?.error}
              message={props?.object?.tag_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {
               
                props?.setObject({
                  ...props?.object,
                  tag_id: {
                    ...props?.object?.tag_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
               
                props?.setObject({
                  ...props?.object,
                  tag_id: {
                    ...props?.object?.tag_id,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.main_account")}
              haswidth={true}
              onInputChange={handleSearchTermMainAccountChange}
              inputValue={searchTermMainAccount}
              loading={loadingMainAccounts}
              loadingText={t('GLOBAL.searching')}
              value={props?.object?.main_account_id?.value?props?.object?.main_account_id?.value:null}
              list={mainAccounts}
              error={props?.object?.main_account_id?.error}
              message={props?.object?.main_account_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {
                
                props?.setObject({
                  ...props?.object,
                  main_account_id: {
                    ...props?.object?.main_account_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                
                props?.setObject({
                  ...props?.object,
                  main_account_id: {
                    ...props?.object?.main_account_id,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid> */}
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={"#"}
              value={props?.object?.numbering?.value}
              error={props?.object?.numbering?.error}
              message={props?.object?.numbering?.message}
              haswidth={true}
              readOnly={true}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  numbering: {
                    ...props?.object?.numbering,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  numbering: {
                    ...props?.object?.numbering,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.receipt_number")}
              value={props?.object?.receipt_number?.value}
              error={props?.object?.receipt_number?.error}
              message={props?.object?.receipt_number?.message}
              haswidth={true}
              readOnly={true}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  receipt_number: {
                    ...props?.object?.receipt_number,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  receipt_number: {
                    ...props?.object?.receipt_number,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          {cookies.get('id') == 46?
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.tid")}
              value={props?.object?.tid?.value}
              error={props?.object?.tid?.error}
              message={props?.object?.tid?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  tid: {
                    ...props?.object?.tid,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  tid: {
                    ...props?.object?.tid,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>:null}
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.epc")}
              value={props?.object?.epc?.value}
              error={props?.object?.epc?.error}
              message={props?.object?.epc?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  epc: {
                    ...props?.object?.epc,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  epc: {
                    ...props?.object?.epc,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.company_name")}
              value={props?.object?.company_name?.value}
              error={props?.object?.company_name?.error}
              message={props?.object?.company_name?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  company_name: {
                    ...props?.object?.company_name,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  company_name: {
                    ...props?.object?.company_name,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.driver_name")}
              value={props?.object?.driver_name?.value}
              error={props?.object?.driver_name?.error}
              message={props?.object?.driver_name?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  driver_name: {
                    ...props?.object?.driver_name,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  driver_name: {
                    ...props?.object?.driver_name,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.plate_number")}
              value={props?.object?.plate_number?.value}
              error={props?.object?.plate_number?.error}
              message={props?.object?.plate_number?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  plate_number: {
                    ...props?.object?.plate_number,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_number: {
                    ...props?.object?.plate_number,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.plate_letter")}
              value={props?.object?.plate_letter?.value}
              error={props?.object?.plate_letter?.error}
              message={props?.object?.plate_letter?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  plate_letter: {
                    ...props?.object?.plate_letter,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_letter: {
                    ...props?.object?.plate_letter,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.plate_province")}
              value={props?.object?.plate_province?.value}
              error={props?.object?.plate_province?.error}
              message={props?.object?.plate_province?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  plate_province: {
                    ...props?.object?.plate_province,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_province: {
                    ...props?.object?.plate_province,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.plate_type")}
              value={props?.object?.plate_type?.value}
              error={props?.object?.plate_type?.error}
              message={props?.object?.plate_type?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  plate_type: {
                    ...props?.object?.plate_type,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_type: {
                    ...props?.object?.plate_type,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>


          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.national_id_number")}
              value={props?.object?.national_id_number?.value}
              error={props?.object?.national_id_number?.error}
              message={props?.object?.national_id_number?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  national_id_number: {
                    ...props?.object?.national_id_number,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  national_id_number: {
                    ...props?.object?.national_id_number,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.name_and_type")}
              value={props?.object?.name_and_type?.value}
              error={props?.object?.name_and_type?.error}
              message={props?.object?.name_and_type?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  name_and_type: {
                    ...props?.object?.name_and_type,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  name_and_type: {
                    ...props?.object?.name_and_type,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.car_registration_number")}
              value={props?.object?.car_registration_number?.value}
              error={props?.object?.car_registration_number?.error}
              message={props?.object?.car_registration_number?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  car_registration_number: {
                    ...props?.object?.car_registration_number,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  car_registration_number: {
                    ...props?.object?.car_registration_number,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomDateField
              label={t('UNITS.car_registration_date')}
              value={props?.object?.car_registration_date?.value}
              error={false}
              message={""}
              haswidth={true}
              readOnly={false}
              minDate={null}
              maxDate={null}
              onChange={(date) => {
                props?.setObject({
                  ...props?.object,
                  car_registration_date: {
                    ...props?.object?.car_registration_date,
                    value: date,
                    value_formated: helpers.formatDateTime(date),
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  car_registration_date: {
                    ...props?.object?.car_registration_date,
                    value: null,
                    value_formated: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
            
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomDateField
              label={t('UNITS.car_registration_to_date')}
              value={props?.object?.car_registration_to_date?.value}
              error={false}
              message={""}
              haswidth={true}
              readOnly={false}
              minDate={null}
              maxDate={null}
              onChange={(date) => {
                props?.setObject({
                  ...props?.object,
                  car_registration_to_date: {
                    ...props?.object?.car_registration_to_date,
                    value: date,
                    value_formated: helpers.formatDateTime(date),
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  car_registration_to_date: {
                    ...props?.object?.car_registration_to_date,
                    value: null,
                    value_formated: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
            
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.truck_vin_number")}
              value={props?.object?.truck_vin_number?.value}
              error={props?.object?.truck_vin_number?.error}
              message={props?.object?.truck_vin_number?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  truck_vin_number: {
                    ...props?.object?.truck_vin_number,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  truck_vin_number: {
                    ...props?.object?.truck_vin_number,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.vin_number")}
              value={props?.object?.vin_number?.value}
              error={props?.object?.vin_number?.error}
              message={props?.object?.vin_number?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  vin_number: {
                    ...props?.object?.vin_number,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  vin_number: {
                    ...props?.object?.vin_number,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.takeel_number")}
              value={props?.object?.takeel_number?.value}
              error={props?.object?.takeel_number?.error}
              message={props?.object?.takeel_number?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  takeel_number: {
                    ...props?.object?.takeel_number,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  takeel_number: {
                    ...props?.object?.takeel_number,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.takeel_by")}
              value={props?.object?.takeel_by?.value}
              error={props?.object?.takeel_by?.error}
              message={props?.object?.takeel_by?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  takeel_by: {
                    ...props?.object?.takeel_by,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  takeel_by: {
                    ...props?.object?.takeel_by,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomDateField
              label={t('UNITS.created_date')}
              value={props?.object?.created_date?.value}
              error={false}
              message={""}
              haswidth={true}
              readOnly={false}
              minDate={null}
              maxDate={null}
              onChange={(date) => {
                props?.setObject({
                  ...props?.object,
                  created_date: {
                    ...props?.object?.created_date,
                    value: date,
                    value_formated: helpers.formatDateTime(date),
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  created_date: {
                    ...props?.object?.created_date,
                    value: null,
                    value_formated: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
            
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomDateField
              label={t('UNITS.expire_date')}
              value={props?.object?.expire_date?.value}
              error={false}
              message={""}
              haswidth={true}
              readOnly={false}
              minDate={null}
              maxDate={null}
              onChange={(date) => {
                props?.setObject({
                  ...props?.object,
                  expire_date: {
                    ...props?.object?.expire_date,
                    value: date,
                    value_formated: helpers.formatDateTime(date),
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  expire_date: {
                    ...props?.object?.expire_date,
                    value: null,
                    value_formated: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
            
          </Grid>

          {/* <Grid xs={12} sm={12} md={4} lg={4} sx={{
            padding: '0px'
          }}>
            <CustomDateField
              label={t('UNITS.installation_date')}
              value={props?.object?.installation_date?.value}
              error={false}
              message={""}
              haswidth={true}
              readOnly={false}
              minDate={null}
              maxDate={null}
              onChange={(date) => {
                props?.setObject({
                  ...props?.object,
                  installation_date: {
                    ...props?.object?.installation_date,
                    value: date,
                    value_formated: helpers.formatDateTime(date),
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  installation_date: {
                    ...props?.object?.installation_date,
                    value: null,
                    value_formated: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />

          </Grid> */}
          <Grid xs={12} sm={12} md={12} lg={12} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.note")}
              value={props?.object?.note?.value}
              error={props?.object?.note?.error}
              message={props?.object?.note?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  note: {
                    ...props?.object?.note,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  note: {
                    ...props?.object?.note,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <Box
              sx={{
                backgroundColor: '#fff',
                color: '#000',
                // borderRadius:'10px',
                height: '55px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                width: '93% !important',
                boxShadow: '0px 6px 20px 3px #00000014 !important',
                margin: 'auto',
                marginTop: '5px',
                marginBottom: '5px',
                paddingLeft: '15px',
                "& .MuiTypography-root": {
                  fontFamily: 'Cairo-Medium'
                }

              }}
            >
              <FormControlLabel control={<Switch checked={props?.object?.is_subscribed?.value == 1} onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  is_subscribed: {
                    ...props?.object?.is_subscribed,
                    value: e.target.checked,
                    error: false,
                    message: []
                  }
                })
              }} />} sx={{ fontFamily: 'Cairo-Medium' }} label={!props?.is_subscribed?.value ? t('UNITS.subscribed') : t('UNITS.not_subscribed')} />
            </Box>
          </Grid>
          {/* <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <Box
              sx={{
                backgroundColor: '#fff',
                color: '#000',
                // borderRadius:'10px',
                height: '55px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                width: '93% !important',
                boxShadow: '0px 6px 20px 3px #00000014 !important',
                margin: 'auto',
                marginTop: '10px',
                marginBottom: '10px',
                paddingLeft: '15px',
                "& .MuiTypography-root": {
                  fontFamily: 'Cairo-Medium'
                }

              }}
            >
              <FormControlLabel control={<Switch checked={props?.object?.is_installed?.value == 1} onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  is_installed: {
                    ...props?.object?.is_installed,
                    value: e.target.checked,
                    error: false,
                    message: []
                  }
                })
              }} />} sx={{ fontFamily: 'Cairo-Medium' }} label={!props?.is_installed?.value ? t('UNITS.installed') : t('UNITS.not_installed')} />
            </Box>
          </Grid> */}
          
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <Box
              sx={{
                backgroundColor: '#fff',
                color: '#000',
                // borderRadius:'10px',
                height: '55px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                width: '93% !important',
                boxShadow: '0px 6px 20px 3px #00000014 !important',
                margin: 'auto',
                marginTop: '5px',
                marginBottom: '5px',
                paddingLeft: '15px',
                "& .MuiTypography-root": {
                  fontFamily: 'Cairo-Medium'
                }

              }}
            >
              <FormControlLabel control={<Switch checked={props?.object?.enabled?.value == 1} onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  enabled: {
                    ...props?.object?.enabled,
                    value: e.target.checked,
                    error: false,
                    message: []
                  }
                })
              }} />} sx={{ fontFamily: 'Cairo-Medium' }} label={!props?.enabled?.value ? t('UNITS.enabled') : t('UNITS.disabled')} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RenderContent