import { Box, Divider, FormControlLabel, Switch, Tooltip, Typography } from '@mui/material'
import React from 'react'
import AddIcon from "@mui/icons-material/Add";
import * as BiIcons from "react-icons/bi";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectRoles } from '../../reduxStore/RolesReducer';
import DataRefreshComponent from '../../Components/DataRefreshComponent';

function Title({ createFunction, openFilterColumn, reload, setReload, title,fast,setFast }) {
    const [t] = useTranslation("common");
    const rolesRedux = useSelector(selectRoles)
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {rolesRedux?.add_transaction?.value ?
                    <Box
                        sx={{
                            display: "flex",
                            flex: 1,
                            alignItems: "center",
                            margin: "0 10px",
                        }}
                    >
                        <Box
                            sx={{
                                marginRight: "10px",
                                backgroundColor: "#17681b",
                                color: "#FFFFFF",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={createFunction}
                            id="demo-customized-button"
                            aria-controls={
                                openFilterColumn ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openFilterColumn ? "true" : undefined}
                            variant="contained"
                        >

                            <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("TRANSACTIONS.add")}</span>}>
                                <AddIcon
                                    sx={{
                                        color: "#fff",
                                        fontSize: "40px",
                                    }}
                                />
                            </Tooltip>
                        </Box>
                        <DataRefreshComponent
                            reload={reload}
                            setReload={setReload}
                        />

                    </Box> : <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                        }}
                    >
                        <DataRefreshComponent
                            reload={reload}
                            setReload={setReload}
                        />
                    </Box>}
                {setFast && false?
                <Box
                    sx={{
                        
                        "& .MuiTypography-root": {
                            fontFamily: 'Cairo-Medium'
                        }

                    }}
                >
                    <FormControlLabel control={<Switch checked={fast == 1} onChange={(e) => {
                        
                        setFast(e.target.checked?1:0)
                        
                    }} />} sx={{ fontFamily: 'Cairo-Medium' }} label={fast ? t('TRANSACTIONS.fast') : t('TRANSACTIONS.normal')} />

                </Box>:null}

                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            marginLeft: "10px",
                            marginBottom: "10px",
                            fontFamily: "Cairo-Bold",
                            fontSize: "23px",
                            letterSpacing: "-1px",
                        }}
                    >
                        {t("TRANSACTIONS.title") + (title ? title : "")}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1
                    }}
                >

                </Box>
            </Box>
            <Divider
                sx={{
                    backgroundColor: "#EBF2F7",
                    margin: "30px",
                    marginTop: "10px",
                    width: "100%",
                }}
                style={{
                    height: "3px",
                }}
            />
        </>
    )
}

export default Title