import callAPI from '../ApiConfig'
import $ from "jquery";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const geofences = async (props) => {

    return await callAPI({
        route: "geofences/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addGeofence = async (props) => {


    return await callAPI({
        route: "geofences/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editGeofence = async (props) => {

    return await callAPI({
        route: "geofences/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteGeofence = async (props) => {


    return await callAPI({
        route: "geofences/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getGeofenceList = async (props) => {

    return await callAPI({
        route: "geofences/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const searchWialonGeofenceByCode = async (geofenceCode) => {

    const baseUrl = cookies.get("baseUrl");
    const sid = cookies.get("sid");
    const authHash = cookies.get("authHash");

    let resultObject = {
        status: false,
        data: {
            data: null,
            error: null,
        }
    }
    if (baseUrl && sid && authHash) {
        try {
            const response = await $.ajax({
                type: "GET",
                enctype: "application/json",
                processData: !1,
                contentType: !1,
                crossDomain: true,
                dataType: "jsonp",
                url: baseUrl + `/wialon/ajax.html?sid=${sid}&svc=core/search_items&params={"spec":{"itemsType":"avl_resource","propName":"zones_library","propValueMask":"*${geofenceCode}*","sortType":"sys_name","propType":"propitemname"},"force":1,"flags":4097,"from":0,"to":0}`,

            });

            let geofencesArray = []
            if (response?.items?.length) {

                for (let index = 0; index < response?.items?.length; index++) {

                    const resource = response?.items[index];
                    const resourceId = resource?.id
                    if (!resource?.hasOwnProperty("zl") || resource?.id == parseInt(process.env.REACT_APP_LOCKED_RESOURCE_ID === undefined ? 3291 : process.env.REACT_APP_LOCKED_RESOURCE_ID)) {
                        continue
                    }
                    const geofencesIds = Object.keys(resource?.zl)
                    for (let j = 0; j < geofencesIds.length; j++) {

                        const geofenceObject = resource?.zl[geofencesIds[j]];

                        if (geofenceObject?.n?.includes(geofenceCode) && (geofenceObject?.t == 2 || geofenceObject?.t == 3)) {

                            geofencesArray.push({
                                id: resourceId + "" + geofencesIds[j],
                                resource_id: resourceId,
                                geofence_id: geofencesIds[j],
                                name: geofenceObject?.n
                            })
                        }

                    }

                }
            }
            resultObject = {
                ...resultObject,
                status: true,
                data: {
                    ...resultObject?.data,
                    data: geofencesArray
                }
            }


        } catch (error) {
            console.log("Request failed:", error);
            resultObject = {
                ...resultObject,
                status: false,
                data: {
                    ...resultObject?.data,
                    error: error?.message
                }
            }
        }
    } else {
        cookies.set("active_session", "no", { path: "/" });
        resultObject = {
            ...resultObject,
            status: false,
            data: {
                ...resultObject?.data,
                error: "session expired"
            }
        }
    }
    return resultObject

}

const downloadExcel = async (props) => {


    return await callAPI({
        route: "geofences/download",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params
        },
        headers: {

        },
        responseType: "blob"

    })
}

const editGeofenceName = async (props) => {

    return await callAPI({
        route: "geofences/update_name/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}

const uploadPoints = async (props) => {

    let formdata = new FormData();

    formdata.append("files", props?.data?.file)

    return await callAPI({
        route: "geofences/import",
        method: "post",
        signal: props?.signal,
        data: formdata,
        params: props?.params,
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            ...props?.headers
        }
    })
}

const editGeofenceCode = async (props) => {

    return await callAPI({
        route: "geofences/update_code/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}

const geofenceAPI = {
    geofences: geofences,
    addGeofence: addGeofence,
    editGeofence: editGeofence,
    deleteGeofence: deleteGeofence,
    getGeofenceList: getGeofenceList,
    searchWialonGeofenceByCode: searchWialonGeofenceByCode,
    downloadExcel: downloadExcel,
    editGeofenceName: editGeofenceName,
    uploadPoints:uploadPoints,
    editGeofenceCode:editGeofenceCode
}
export default geofenceAPI