import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import CustomTextField from '../../../Components/CustomTextField';
import CloseIcon from "@mui/icons-material/Close";
import InputFile from '../../../Components/InputFile/InputFile';
import axios from 'axios';


const RenderContent = (props) => {
  const [t] = useTranslation("common");

  const [loading, setLoading] = useState(false)

  

  const onFileChange = (file) => {
    console.log("file changed",file);
  }

  const submitForm = async (file) => {
    console.log("file submitted",file);
    props?.submit(file)
    return
   

  };

  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.9)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "21120",
        }}
        onClick={() => {
          props?.setOpen(false);
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>

      <Box
        className="row d-flex justify-content-center align-items-start"
        sx={{ transition: "all 0.7s ease" }}
      >

        <InputFile submitForm={submitForm} loading={loading} onFileChange={onFileChange} title={t('GLOBAL.import_form_title')} subTitle={""} templateFileName={"masters_import_template.xlsx"} />


      </Box>
    </Box>
  );
};

export default RenderContent