import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  useState,
  useEffect
} from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Grid from '../../Grid/Grid'
import PopupForm from "../../../Components/PopupForm";
import Cookies from "universal-cookie";
import transactionAPI from "../../../Network/Transaction";
import LocationOnIcon from '@mui/icons-material/LocationOn';
const cookies = new Cookies();





const RenderContent = (props) => {
  const [t] = useTranslation("common");

  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [columns, setColumns] = useState([
    { field: "index_column", headerName: "ت", minWidth: 50, maxWidth: 60 },
    {
      headerName: t("TRANSACTIONS.route_name"),field: "route_name", flex: 1
    },
    { headerName: t("TRANSACTIONS.route_code"), field: "route_code", flex: 1 },
    

  ]);


  return (
    <Box className="Container-fluid">
      <Box className="row d-flex justify-content-center align-items-start">
        <Box className="col-12 col-sm-12 col-md-12 my-3 px-0"
          sx={{
            '& .MuiCheckbox-root': {
              color: '#1e6a99 !important',
              fill: '#1e6a99 !important'

            }
          }}
        >
          <Grid
            rows={props?.data}
            columns={columns}
            setColumns={setColumns}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            handlePageChange={handlePageChange}
            rowsTotal={props?.data?.length}
            pageCount={Math.ceil(props?.data?.length / pageSize)}
            filterChilds={null}
            notHaveAdvancSearch={true}
            // handleRowCellChange={handleRowCellChange}
            paginationMode={"client"}
          />
        </Box>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props?.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>

        </Box>
      </Box>
    </Box>
  );
};

export default function TransactionRoutes(props) {
  const [t] = useTranslation("common");
  const [data, setData] = useState([])

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,

  };

  useEffect(() => {
    if (props?.object)
      loadData()
  }, [props?.openForm, props?.object]);

  const loadData = async () => {
    let data = null;
    try {
      data = await transactionAPI.transactionRoutes({
        params: {
          ...headerObject,
          transaction_id: props?.object?.id

        }
      })
      if (data && data?.status && data?.data?.status) {
        setData(data?.data?.data)
      } else {
        toast.warn(t('NETWORK_MESSAGES.messageError'))
      }
    } catch (error) {
      console.log(error?.message)
    }
  }


  return (
    <PopupForm
    isFullScreen={false}
      open={props?.openForm}
      setOpen={props?.setOpenForm}
      title={t("TRANSACTIONS.routes")}
      content={
        <RenderContent
          setData={setData}
          data={data}
          open={props?.openForm}
          setOpen={props?.setOpenForm}
        />
      }
      footer={
        <RenderFooter
          open={props?.openForm}
          setOpen={props?.setOpenForm}
        />
      }
    />
  );
}
