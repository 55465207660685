import {
  Box,
  Button,
  IconButton,
  Tooltip,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import React, {
  useState,
  useEffect
} from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Grid from '../../Grid/Grid'
import PopupForm from "../../../Components/PopupForm";
import Cookies from "universal-cookie";
import mainUnitApi from "../../../Network/MainUnit";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const cookies = new Cookies();





const RenderContent = (props) => {
  const [t] = useTranslation("common");

  
  const handlePageChange = (newPage) => {
    props?.setPageNumber(newPage + 1);
  };
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [columns, setColumns] = useState([
    { field: "index_column", headerName: "ت", minWidth: 50, maxWidth: 60 },
    {
      headerName: t("UNITS.username"),field: "name", flex: 1
    },
    {
      field: "is_subscribed", headerName: t("UNITS.is_subscribed"), minWidth: 110, maxWidth: 150, flex: 1,
      renderCell: (rows) => (
        <>
          {rows?.row?.is_subscribed ? (
            <MenuItem
              onClick={() => {

              }}
              className="ActionIcons"
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px 7px 0 0",
                }}
              >
                <CheckCircleIcon style={{ fill: "#388e3c" }} />
              </ListItemIcon>

            </MenuItem>
          ) : <MenuItem
            onClick={() => {

            }}
            className="ActionIcons"
          >
            <ListItemIcon
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "0px 7px 0 0",
              }}
            >
              <DoDisturbOnIcon style={{ fill: "#d32f2f" }} />
            </ListItemIcon>


          </MenuItem>}
        </>
      )
    },
    { headerName: t("UNITS.formatted_created_at"), field: "formatted_created_at", flex: 1 },
    {
      headerName: t("UNITS.log_map"), field: "map", flex: 0.2,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Tooltip
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("UNITS.log_map")}
              </span>
            }
          >
            <LocationOnIcon style={{ fill: "#ffffff" }} />

          </Tooltip>
        </Box>
      ),
      renderCell: (rows) => (

        rows?.row?.latitude && rows?.row?.longitude ?
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("UNITS.log_map")}
                </span>
              }
            >
              <IconButton onClick={() => {
                window.open(
                  `https://maps.google.com/?q=${rows?.row?.latitude},${rows?.row?.longitude}`,
                  "_blank"
                );
              }} >

                <LocationOnIcon style={{ fill: "#17681b" }} />

              </IconButton>
            </Tooltip>
          </Box> : null

      )
    }
    

  ]);

  

  return (
    <Box className="Container-fluid">
      <Box className="row d-flex justify-content-center align-items-start">
        <Box className="col-12 col-sm-12 col-md-12 my-3 px-0"
          sx={{
            '& .MuiCheckbox-root': {
              color: '#1e6a99 !important',
              fill: '#1e6a99 !important'

            }
          }}
        >
          <Grid
            rows={props?.data}
            columns={columns}
            setColumns={setColumns}
            pageSize={props?.pageSize}
            setPageSize={props?.setPageSize}
            pageNumber={props?.pageNumber}
            setPageNumber={props?.setPageNumber}
            handlePageChange={handlePageChange}
            rowsTotal={props?.data?.length}
            pageCount={props?.pageCount}
            filterChilds={null}
            notHaveAdvancSearch={true}
            // handleRowCellChange={handleRowCellChange}
            paginationMode={"client"}
          />
        </Box>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props?.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>

        </Box>
      </Box>
    </Box>
  );
};

export default function MainUnitLogs(props) {
  const [t] = useTranslation("common");
  const [data, setData] = useState([])
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsTotal, setRowsTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,

  };

  useEffect(() => {
    if (props?.object)
      loadData()
  }, [props?.openForm, props?.object,pageNumber, pageSize]);


  const loadData = async () => {
    let data = null;
    try {
      data = await mainUnitApi.mainUnitLogs({
        params: {
          ...headerObject,
          main_unit_id: props?.object?.id,
          page: pageNumber,
          page_size: pageSize,

        }
      })
      if (data && data?.status && data?.data?.status) {
        setData(data?.data?.data?.data)
        setRowsTotal(data?.data?.data?.total_records);
        setPageCount(Math.ceil(data?.data?.data?.total_records / pageSize));
      } else {
        setData([])
        setRowsTotal(0);
        setPageCount(0);
        toast.warn(t('NETWORK_MESSAGES.messageError'))
      }
    } catch (error) {
      console.log(error?.message)
    }
  }


  return (
    <PopupForm
    isFullScreen={false}
      open={props?.openForm}
      setOpen={props?.setOpenForm}
      title={t("UNITS.logs")}
      content={
        <RenderContent
          setData={setData}
          data={data}
          setPageSize={setPageSize}
          setPageNumber={setPageNumber}
          pageSize={pageSize}
          pageNumber={pageNumber}
          pageCount={pageCount}
          open={props?.openForm}
          setOpen={props?.setOpenForm}
        />
      }
      footer={
        <RenderFooter
          open={props?.openForm}
          setOpen={props?.setOpenForm}
        />
      }
    />
  );
}
