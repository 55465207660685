import moment from 'moment';
import Host from './Host';

const helpers = {
    getImageUrl: function getImageUrl(image_url) {
        var hostURLParts = Host.split("/")
        var hostURL = hostURLParts[0] + "//" + hostURLParts[2] + "/"
        const fullImageURL = hostURL + "api/public/" + image_url


        return fullImageURL;
    },
    stringToColour: function stringToColour(str) {
        var colour = '#fff';
        if (str) {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            colour = '#';
            for (var i = 0; i < 3; i++) {
                var value = (hash >> (i * 8)) & 0xFF;
                colour += ('00' + value.toString(16)).substr(-2);
            }
        }

        return colour;
    },
    recursionCategory: function recursionCategory(data) {
        var parent = [];
        for (let item = 0; item < data.length; item++) {



            var child = {
                name: data[item].name,
                attributes: {
                    id: data[item].id,
                    color: helpers.stringToColour(data[item].condition_value),
                    default_value: data[item].default_value,
                    is_mandatory: data[item].is_mandatory,
                    is_multiple_select: data[item].is_multiple_select,
                    has_condition: data[item].has_condition,
                    condition_value: data[item].condition_value,
                    field_type_name: data[item]["field_type"].name,
                    field_type_has_list: data[item]["field_type"].has_list,
                }
            }


            var children = data[item].children;
            var childrenMapped = null
            if (children != null && children.length > 0) {
                childrenMapped = recursionCategory(children)
            }
            parent.push({
                ...child,
                children: childrenMapped
            })
        }
        return parent;
    },
    listToTree: function listToTree(items, id = null, link = 'survey_field_id') {
        return items
            .filter(item => item[link] === id)
            .map(item => ({ ...item, children: helpers.listToTree(items, item.id) }));
    },
    checkDarknessOrLightnessForColor: function checkDarknessOrLightnessForColor(color) {
        // Variables for red, green, blue values
        var r, g, b, hsp;

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {

            // If RGB --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

            r = color[1];
            g = color[2];
            b = color[3];
        }
        else {

            // If hex --> Convert it to RGB: http://gist.github.com/983661
            color = +("0x" + color.slice(1).replace(
                color.length < 5 && /./g, '$&$&'));

            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        );

        // Using the HSP value, determine whether the color is light or dark
        if (hsp > 127.5) {

            return false;//light
        }
        else {

            return true;//dark
        }

    },
    formatDate: function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    },
    formatTime: function formatTime(date, notHasSeconds) {
        var d = new Date(date),
            hours = d.getHours(),
            min = d.getMinutes(),
            sec = d.getSeconds();

        if (hours < 10)
            hours = '0' + hours;
        if (min < 10)
            min = '0' + min;
        if (sec < 10) {
            sec = "0" + sec;
        }
        if (notHasSeconds)
            return [hours, min].join(':');
        else
            return [hours, min, sec].join(':');

    },
    getImage: (obj, demourl) => {
        let imageSrc = "";
        if (obj?.image?.image_url)
            imageSrc = helpers.getImageUrl(obj?.image?.image_url)
        else
            imageSrc = demourl

        return imageSrc;

    },
    geofenceTypes: [
        {
            id: 1,
            name: "تحميل"
        },
        {
            id: 2,
            name: "تفريغ"
        },
        {
            id: 3,
            name: "تحميل وتفريغ"
        }
    ],

    getUsers: (userId) => {
        let selectedTypeArray = helpers?.users?.filter(user => user?.id == userId);
        if (selectedTypeArray?.length > 0) {
            return selectedTypeArray[0]
        }
        return null
    },

    getGeoFenceType: (type_id) => {
        let selectedTypeArray = helpers?.geofenceTypes?.filter(type => type?.id == type_id)
        if (selectedTypeArray?.length > 0) {
            return selectedTypeArray[0]
        }
        return null
    },

    getActivityType: (log_type_id) => {
        let selectedTypeArray = helpers?.activitableTypes?.filter(type => type?.id == log_type_id);
        if (selectedTypeArray?.length > 0) {
            return selectedTypeArray[0]
        }
        return null
    },

    getActivityEditor: (created_by_id) => {
        let selectedTypeArray = helpers?.users?.filter(type => type?.id == created_by_id)
        if (selectedTypeArray?.length > 0) {
            return selectedTypeArray[0]
        }
        return null
    },
    extractNumbers: (str) => {
        const arabicNumbers = {
            "٠": "0",
            "١": "1",
            "٢": "2",
            "٣": "3",
            "٤": "4",
            "٥": "5",
            "٦": "6",
            "٧": "7",
            "٨": "8",
            "٩": "9",
        };
        let strAllArabicNumbers = "";
        for (let i = 0; i < str.length; i++) {
            const char = str[i];
            if (arabicNumbers[char]) {
                strAllArabicNumbers += arabicNumbers[char];
            } else {
                strAllArabicNumbers += char;
            }
        }
        let longestSequence = "";
        let currentSequence = "";

        for (let i = 0; i < strAllArabicNumbers.length; i++) {
            const char = strAllArabicNumbers[i];


            if (!isNaN(char) && char !== " ") {
                // if it's a number, add it to the current sequence
                currentSequence += char;
            } else {
                // if it's not a number, compare the current sequence to the longest sequence
                if (currentSequence.length > longestSequence.length) {
                    longestSequence = currentSequence;
                }
                currentSequence = "";
            }
        }

        // check if the last sequence was the longest
        if (currentSequence.length > longestSequence.length) {
            longestSequence = currentSequence;
        }

        return longestSequence;
    },
    extractRouteCode: (routeName) => {
        const pattern = /\d+-\d+-\d{2}/; // matches one or more digits, then a hyphen, then 8 digits, then a hyphen, then 2 digits
        const matches = routeName.match(pattern);

        if (matches) {
            return matches[0]
        } else {
            return ""
        }
    },
    formatDate: function formatDate(date) {
        if (!date) {
            return null
        }
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear()
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        let formatedDate = [year, month, day].join("-");
        return formatedDate;
    },
    formatDateTime: function formatDate(date) {
        if (!date) {
            return null
        }
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear(),
            hours = d.getHours(),
            min = d.getMinutes(),
            sec = d.getSeconds();

        if (hours < 10) hours = "0" + hours;
        if (min < 10) min = "0" + min;
        if (sec < 10) {
            sec = "0" + sec;
        }

        let time = [hours, min, sec].join(":");
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        let formatedDate = [year, month, day].join("-") + " " + time;
        return formatedDate;
    },
    formatTime: function formatTime(date) {
        if (!date) {
            return null
        }
        var d = new Date(date),
            hours = d.getHours(),
            min = d.getMinutes(),
            sec = d.getSeconds();

        if (hours < 10) hours = "0" + hours;
        if (min < 10) min = "0" + min;
        if (sec < 10) {
            sec = "0" + sec;
        }

        return [hours, min, sec].join(":");
    },
    isArabicAndNumbers: function isArabicAndNumbers(event) {
        var arabicCharUnicodeRange = /[\u0600-\u06FF]/;
        var key = event.key;
       
        if (key == 8 || key == 0 || key === 32) {
            return true;
        }

        var str = String.fromCharCode(key);
        if (arabicCharUnicodeRange.test(str)) {
            return true;
        }

        return false;
    },
    durationFromNow: (datetimeString, minutes) => {


        try {
            const currentTime = moment();
            const targetTime = moment(datetimeString, 'YYYY-MM-DD HH:mm:ss');
            const minutesDifference = targetTime.diff(currentTime, 'minutes');

            if (minutesDifference >= -minutes && minutesDifference <= 0) {
                // Time difference is 20 minutes or less
                return true
            } else {
                // Time difference is more than 20 minutes
                return false
            }
        } catch (error) {
            console.log(error);

            return false
        }


    }
};
export default helpers;
