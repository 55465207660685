import callAPI from '../ApiConfig'
import $ from "jquery";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const roles = async (props) => {

    return await callAPI({
        route: "roles/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addRole = async (props) => {


    return await callAPI({
        route: "roles/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editRole = async (props) => {

    return await callAPI({
        route: "roles/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteRole = async (props) => {


    return await callAPI({
        route: "roles/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getRoleList = async (props) => {

    return await callAPI({
        route: "roles/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const roleAPI = {
    roles: roles,
    addRole: addRole,
    editRole: editRole,
    deleteRole: deleteRole,
    getRoleList: getRoleList
}
export default roleAPI