import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import helpers from '../../../assets/js/helper';
import CustomeSelectField from '../../../Components/CustomeSelectField';
import CustomTextField from '../../../Components/CustomTextField';
import CloseIcon from "@mui/icons-material/Close";
import routeAPI from '../../../Network/Route';
import geofenceAPI from '../../../Network/Geofence';
import Cookies from "universal-cookie";
import { useEffect } from 'react';
import RefreshIcon from "@mui/icons-material/Refresh";
const cookies = new Cookies();


const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const timer = useRef(null)
  const timerSourceGeofences = useRef(null)
  const timerDestinationGeofences = useRef(null)
  const [numberOfRoutesInDb, setNumberOfRoutesInDb] = useState(0)

  const [geofenceChanged, setGeofenceChanged] = useState(false)
  const [routesFromDbLoaded, setRoutesFromDbLoaded] = useState(false)
  const [routesFromWialonLoaded, setRoutesFromWialonLoaded] = useState(false)

  const [sourceGeofences, setSourceGeofences] = useState([])
  const [destinationGeofences, setDestinationGeofences] = useState([])

  const [wialonRoutes, setWialonRoutes] = useState([])
  const [loadingWialonRoutes, setLoadingWialonRoutes] = useState(false)

  const [loadingSourceGeofences, setLoadingSourceGeofences] = useState(false)
  const [loadingDestinationGeofences, setLoadingDestinationGeofences] = useState(false)

  const [searchTermSourceGeofence, setSearchTermSourceGeofence] = useState("");
  const [searchTermDestinationGeofence, setSearchTermDestinationGeofence] = useState("");
  const [reloadRoutes, setReloadRoutes] = useState(false)
  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }

  const onCodeUpdate = (newValue) => {
    if (timer.current) {
      clearTimeout(timer?.current)
    }
    
    timer.current = setTimeout(async () => {
      
      if (newValue?.length >= 3) {
        setLoadingWialonRoutes(true);
       
        const result = await routeAPI.searchWialonRouteByCode(newValue);
        setLoadingWialonRoutes(false);

        if (result.status && result?.data?.data) {
          setWialonRoutes(result?.data?.data)
        }
      }
      else {
        
        setWialonRoutes([])
      }
      if(!(props?.object?.id && props?.method == "update" ))
      {
        setRoutesFromWialonLoaded(true)
      }
      
    }, 1000)
  }

  const handleSearchTermSourceGeofenceChange = (newValue) => {

    setSearchTermSourceGeofence(newValue);
    if (timerSourceGeofences.current) {
      clearTimeout(timerSourceGeofences?.current)
    }
    timerSourceGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingSourceGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [1, 3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingSourceGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setSourceGeofences(result?.data?.data?.data)
        }
      }
      else {
        setSourceGeofences([])
      }
    }, 1000)
  };
  const handleSearchTermDestinationGeofenceChange = (newValue) => {

    setSearchTermDestinationGeofence(newValue);
    if (timerDestinationGeofences.current) {
      clearTimeout(timerDestinationGeofences?.current)
    }
    timerDestinationGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingDestinationGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [2, 3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingDestinationGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setDestinationGeofences(result?.data?.data?.data)
        }
      }
      else {
        setDestinationGeofences([])
      }
    }, 1000)
  };

  const extractCode = (text, sourceOrDestination) => {
    try {
      var parts = text.split("-");
      if (sourceOrDestination && parts[0]) {
        return parts[0];
      } else if (!sourceOrDestination && parts[1]) {
        return parts[1];
      }
      else {
        return ""
      }
    } catch (error) {
      return ""
    }
  }
  const getName = (firstPart, secondPart) => {
    let name = ""
    if (firstPart) {
      name = firstPart
    }
    if (secondPart) {
      name += " - " + secondPart
    }
    return name
  }

  
  const loadRoutes = async () => {


    
    const result = await routeAPI.getRouteList({
      params: {
        ...headerObject,
        code: props?.object?.source_geofence_id?.value?.code + "-" + props?.object?.destination_geofence_id?.value?.code + "-",
        page_size: 20,
        page: 1
      }

    })
    // setReloadRoutes(!reloadRoutes)

    if (result.status && result?.data?.data?.data && result?.data?.data?.data?.length>0) {
      const maxLastPartItem = result?.data?.data?.data?.reduce((maxItem, currentItem) => {
        const currentLastPart = parseInt(currentItem?.code?.split('-').pop(), 10);
        const maxLastPart = parseInt(maxItem?.code?.split('-').pop(), 10);

        return currentLastPart > maxLastPart ? currentItem : maxItem;
      }, result?.data?.data?.data[0]);
      const maxLastPart = parseInt(maxLastPartItem?.code?.split('-').pop(), 10);
      setNumberOfRoutesInDb(maxLastPart)
    }

    else {
      setNumberOfRoutesInDb(0)
    }
    setRoutesFromDbLoaded(true)
  }

  // useEffect(() => {
  //   setRoutesNumber(props?.object?.number?.value)
  //   return () => {
      
  //   }
  // }, [reloadRoutes])
  

  useEffect(() => {
    if (props?.object?.source_geofence_id?.value?.id && props?.object?.destination_geofence_id?.value?.id) {
      
      loadRoutes()
    }
    return () => {

    }
  }, [geofenceChanged])

  useEffect(() => {
    if(routesFromDbLoaded)
    {
      
      setRoutesFromDbLoaded(false)
      onCodeUpdate(props?.object?.source_geofence_id?.value?.code + "-" + props?.object?.destination_geofence_id?.value?.code + "-")
    }
  
    return () => {
      
    }
  }, [routesFromDbLoaded])

  useEffect(() => {
    if(routesFromWialonLoaded)
    {
     
      setRoutesFromWialonLoaded(false)
      setRoutesNumber(props?.object?.number?.value)
    }
  
    return () => {
      
    }
  }, [routesFromWialonLoaded])
  
  

  useEffect(() => {

    
    if (props?.object?.id && props?.method == "update" ) {
      onCodeUpdate(props?.object?.code?.value)
    }


    return () => {

    }
  }, [props?.object, props?.method])


  const setRoutesNumber = (value) => {
    let pathsArray = props?.object?.paths?.length ? props?.object?.paths : []
    let newPathsArray = []
    if (props?.object?.source_geofence_id?.value?.code
      && props?.object?.destination_geofence_id?.value?.code
      && value
      && !isNaN(value)) {
      newPathsArray = Array.from({ length: value }, (_, index) => {
        const paddedCode = String(index + 1 + numberOfRoutesInDb).padStart(2, '0');
        const path = pathsArray?.find(item => item?.code == paddedCode)

        return path ? path : {
          code: paddedCode,
          route: null,
          routeList: wialonRoutes?.length ? wialonRoutes?.filter(item => {
            const wialonRouteCode = helpers.extractRouteCode(item?.name)

            return wialonRouteCode == (props?.object?.source_geofence_id?.value?.code + "-" + props?.object?.destination_geofence_id?.value?.code + "-" + paddedCode)

          }) : []
        }
      })
    }


    props?.setObject({
      ...props?.object,
      number: {
        ...props?.object?.number,
        value: value,
        error: false,
        message: []
      },
      routes: newPathsArray
    })
  }

  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.9)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "21120",
        }}
        onClick={() => {
          props?.setOpen(false);
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>

      <Box
        className="row d-flex justify-content-center align-items-start"
        sx={{ transition: "all 0.7s ease" }}
      >
        <CustomTextField
          label={t("ROUTES.name")}
          value={props?.object?.name?.value}
          error={props?.object?.name?.error}
          message={props?.object?.name?.message}
          haswidth={true}
          readOnly={true}
          focused={true}
          onChange={(e) => {

            props?.setObject({
              ...props?.object,
              name: {
                ...props?.object?.name,
                value: e?.target?.value,
                error: false,
                message: []
              }
            })

          }}
          onClearClick={() => {
            props?.setObject({
              ...props?.object,
              name: {
                ...props?.object?.name,
                value: "",
                error: false,
                message: []
              }
            })
          }}
        />
        {(props?.method == "add" || props?.method == "add_from_transaction") ? null :
          <>
            <Box sx={{ margin: "8px 0" }}></Box>
            <CustomTextField
              label={t("ROUTES.code")}
              value={props?.object?.code?.value}
              error={props?.object?.code?.error}
              message={props?.object?.code?.message}
              haswidth={true}
              readOnly={true}
              focused={true}
              onChange={(e) => {
                // if (!isNaN(e?.target?.value)) {

                props?.setObject({
                  ...props?.object,
                  code: {
                    ...props?.object?.code,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  },
                  wialon_id: {
                    ...props?.object?.wialon_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
                onCodeUpdate(e?.target?.value)
                // }


              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  code: {
                    ...props?.object?.code,
                    value: "",
                    error: false,
                    message: []
                  },
                  wialon_id: {
                    ...props?.object?.wialon_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
                setWialonRoutes([])
              }}
            />
          </>
        }

        {props?.method == "update" ? null :
          <>

            <Box sx={{ margin: "8px 0" }}></Box>
            <CustomeSelectField
              label={t("ROUTES.source_geofence")}
              haswidth={true}
              onInputChange={handleSearchTermSourceGeofenceChange}
              inputValue={searchTermSourceGeofence}
              loading={loadingSourceGeofences}
              loadingText={t('GLOBAL.searching')}
              value={props?.object?.source_geofence_id?.value}
              list={sourceGeofences}
              error={props?.object?.source_geofence_id?.error}
              message={props?.object?.source_geofence_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {
                setGeofenceChanged(!geofenceChanged)
                let code = (newValue?.code ? newValue?.code : "") + "-" + (props?.object?.destination_geofence_id?.value?.code ? props?.object?.destination_geofence_id?.value?.code : "")
                props?.setObject({
                  ...props?.object,
                  source_geofence_id: {
                    ...props?.object?.source_geofence_id,
                    value: newValue,
                    error: false,
                    message: []
                  },
                  code: {
                    ...props?.object?.code,
                    value: code,
                    error: false,
                    message: []
                  },
                  name: {
                    ...props?.object?.name,
                    value: getName(newValue?.name, props?.object?.destination_geofence_id?.value?.name),
                    error: false,
                    message: []
                  },
                  number: {
                    ...props?.object?.number,
                    value: "",
                    error: false,
                    message: []
                  },
                  routes: []
                })
              }}
              onClearClick={() => {
                setGeofenceChanged(!geofenceChanged)
                let code = extractCode(props?.object?.code?.value, false)
                if (code) {
                  code = "-" + (props?.object?.source_geofence_id?.value?.code ? props?.object?.source_geofence_id?.value?.code : "")
                }

                props?.setObject({
                  ...props?.object,
                  source_geofence_id: {
                    ...props?.object?.source_geofence_id,
                    value: "",
                    error: false,
                    message: []
                  },
                  code: {
                    ...props?.object?.code,
                    value: code,
                    error: false,
                    message: []
                  },
                  name: {
                    ...props?.object?.name,
                    value: getName("", props?.object?.destination_geofence_id?.value?.name),
                    error: false,
                    message: []
                  },
                  number: {
                    ...props?.object?.number,
                    value: "",
                    error: false,
                    message: []
                  },
                  routes: []
                })
              }}
            />
            <Box sx={{ margin: "8px 0" }}></Box>
            <CustomeSelectField
              label={t("ROUTES.destination_geofence")}
              haswidth={true}
              onInputChange={handleSearchTermDestinationGeofenceChange}
              inputValue={searchTermDestinationGeofence}
              loading={loadingDestinationGeofences}
              loadingText={t('GLOBAL.searching')}
              value={props?.object?.destination_geofence_id?.value}
              list={destinationGeofences}
              error={props?.object?.destination_geofence_id?.error}
              message={props?.object?.destination_geofence_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {
                setGeofenceChanged(!geofenceChanged)
                let code = (props?.object?.source_geofence_id?.value?.code ? props?.object?.source_geofence_id?.value?.code : "") + "-" + (newValue?.code ? newValue?.code : "")
                props?.setObject({
                  ...props?.object,
                  destination_geofence_id: {
                    ...props?.object?.destination_geofence_id,
                    value: newValue,
                    error: false,
                    message: []
                  },
                  code: {
                    ...props?.object?.code,
                    value: code,
                    error: false,
                    message: []
                  },
                  name: {
                    ...props?.object?.name,
                    value: getName(props?.object?.source_geofence_id?.value?.name, newValue?.name),
                    error: false,
                    message: []
                  },
                  number: {
                    ...props?.object?.number,
                    value: "",
                    error: false,
                    message: []
                  },
                  routes: []
                })
              }}
              onClearClick={() => {
                setGeofenceChanged(!geofenceChanged)
                let code = extractCode(props?.object?.code?.value, true)
                if (code) {
                  code = (props?.object?.source_geofence_id?.value?.code ? props?.object?.source_geofence_id?.value?.code : "") + "-"
                }
                props?.setObject({
                  ...props?.object,
                  destination_geofence_id: {
                    ...props?.object?.destination_geofence_id,
                    value: "",
                    error: false,
                    message: []
                  },
                  code: {
                    ...props?.object?.code,
                    value: code,
                    error: false,
                    message: []
                  },
                  name: {
                    ...props?.object?.name,
                    value: getName(props?.object?.source_geofence_id?.value?.name, ""),
                    error: false,
                    message: []
                  },
                  number: {
                    ...props?.object?.number,
                    value: "",
                    error: false,
                    message: []
                  },
                  routes: []


                })
              }}
            />
          </>}
        {props?.method == "update" ? null :
          <>
            <Box sx={{ margin: "8px 0" }}></Box>
            <CustomTextField
              label={t("ROUTES.number")}
              value={props?.object?.number?.value}
              error={props?.object?.number?.error}
              message={props?.object?.number?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {
                let value = ""
                if (!isNaN(e?.target?.value) && parseInt(e?.target?.value) < 100) {
                  value = e?.target?.value
                }
                setRoutesNumber(value)
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  number: {
                    ...props?.object?.number,
                    value: "",
                    error: false,
                    message: []
                  },
                  routes: []
                })
              }}
            />
          </>}
        {props?.method == "update" ? null :
          <>
            <Box sx={{ margin: "8px 0" }}></Box>
            {/* {(
          props?.object?.source_geofence_id?.value?.code && props?.object?.destination_geofence_id?.value?.code && props?.object?.number?.value && !isNaN(props?.object?.number?.value)) ? Array.from({ length: props?.object?.number?.value }, (_, index) => {
          const paddedIndex = String(index + 1 + numberOfRoutesInDb).padStart(2, '0'); */}
            {props?.object?.routes?.length ? props?.object?.routes?.map((route, index) => {
              return (
                <Box key={index + "route" + route?.code}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setGeofenceChanged(!geofenceChanged)
                    }}
                    sx={{

                    }}
                  >

                    <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("ROUTES.refresh_route_from_wialon")}</span>}>
                      <RefreshIcon
                        sx={{
                          color: "#17681b",
                          fontSize: "40px",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                  <Typography
                    sx={{
                      width: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {props?.object?.source_geofence_id?.value?.code + "-" + props?.object?.destination_geofence_id?.value?.code + "-" + route?.code}
                  </Typography>
                  <CustomeSelectField
                    label={t("ROUTES.wialon_id")}
                    haswidth={true}
                    customeWidth={"50%"}
                    value={route?.route}
                    list={route?.routeList}
                    error={false}
                    message={false}
                    readOnly={false}
                    onChange={(e, newValue) => {
                      props?.setObject({
                        ...props?.object,
                        routes: props?.object?.routes?.map(currentRoute => {
                          if (currentRoute?.code == route?.code) {
                            return {
                              ...currentRoute,
                              route: newValue
                            }

                          }
                          return currentRoute
                        })
                      })

                    }}
                    onClearClick={() => {
                      props?.setObject({
                        ...props?.object,
                        routes: props?.object?.routes?.map(currentRoute => {
                          if (currentRoute?.code == route?.code) {
                            return {
                              ...currentRoute,
                              route: null
                            }

                          }
                          return currentRoute
                        })
                      })
                    }}
                  />
                  <CustomTextField
                    label={t("ROUTES.note")}
                    value={route?.note}
                    error={false}
                    message={null}
                    haswidth={true}
                    readOnly={false}
                    focused={true}
                    onChange={(e) => {
                      let filteredValue = "";
                      if (e?.target?.value) {
                        filteredValue = e?.target?.value.replace(/[^a-zA-Z0-9\u0600-\u06FF\s]/g, '');
                      }

                      if (filteredValue?.length > 50) {
                        return
                      }
                      props?.setObject({
                        ...props?.object,
                        routes: props?.object?.routes?.map(currentRoute => {
                          if (currentRoute?.code == route?.code) {
                            return {
                              ...currentRoute,
                              note: filteredValue
                            }

                          }
                          return currentRoute
                        })
                      })
                    }}
                    onClearClick={() => {
                      props?.setObject({
                        ...props?.object,
                        routes: props?.object?.routes?.map(currentRoute => {
                          if (currentRoute?.code == route?.code) {
                            return {
                              ...currentRoute,
                              note: null
                            }

                          }
                          return currentRoute
                        })
                      })
                    }}
                  />
                </Box>
              );
            })
              : null}
          </>
        }
        {(props?.method == "add" || props?.method == "add_from_transaction") ? null :
          <>
            <Box sx={{ margin: "8px 0" }}></Box>
            {loadingWialonRoutes ? <CircularProgress /> :
              wialonRoutes?.length > 0 ?
                <CustomeSelectField
                  label={t("ROUTES.wialon_id")}
                  haswidth={true}
                  value={props?.object?.wialon_id?.value}
                  list={wialonRoutes}
                  error={props?.object?.wialon_id?.error}
                  message={props?.object?.wialon_id?.message}
                  readOnly={false}
                  onChange={(e, newValue) => {

                    props?.setObject({
                      ...props?.object,
                      code: {
                        ...props?.object?.code,
                        value: helpers.extractRouteCode(newValue?.name),
                        error: false,
                        message: []
                      },
                      wialon_id: {
                        ...props?.object?.wialon_id,
                        value: newValue,
                        error: false,
                        message: []
                      }
                    })
                  }}
                  onClearClick={() => {
                    props?.setObject({
                      ...props?.object,
                      wialon_id: {
                        ...props?.object?.wialon_id,
                        value: null,
                        error: false,
                        message: []
                      }
                    })
                  }}
                /> : <Typography
                  sx={{
                    fontFamily: 'Cairo',
                    color: 'red',
                  }}
                >
                  {props?.object?.code?.value ? t('ROUTES.no_routes_with_same_code_found') : null}
                </Typography>}
          </>}
      </Box>
    </Box>
  );
};

export default RenderContent