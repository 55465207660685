import React from 'react'
import ReactApexChart from 'react-apexcharts'

function StackChart({ data }) {



    const state = {

        series: data?.auditedData,
        options: {
            chart: {
                toolbar: {
                    show: false
                  },
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%'
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: true,
                            formatter: (val,config)=>{
                                console.log(val);
                                return " المجموع "+val
                            },
                            align: 'right',
                            offsetY: 0,
                            minWidth:100,
                            maxWidth:100,
                            
                            style: {
                                fontSize: '11px',
                                fontWeight: 900,
                                color:'#FFFFFF',
                                fontFamily: 'Cairo-Medium'
                            }
                        },
                        style:{
                            fontFamily: 'Cairo-Medium'
                        }
                        
                    }
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            title: {
                text: ''
            },
            xaxis: {
                categories: data?.auditedLabels,
                labels: {
                    formatter: function (val) {
                        return val + " الف"
                    },
                    style:{
                        fontFamily: 'Cairo-Medium'
                    }
                }
            },
            yaxis: {
                title: {
                    text: undefined
                },
                labels: {
                    show: true,
                    align: 'right',
                    minWidth: 160,
                    maxWidth: 160,
                    style: {
                        colors: "#000000",
                        fontSize: '12px',
                        fontFamily: 'Cairo-Medium',
                        fontWeight: 400,
                        
                        
                    },
                    offsetX: -110,
                    offsetY: 0,
                    rotate: 0,
                },
            },

            fill: {
                opacity: 1
            },
            dataLabels: {
                enabled: true,
                
                formatter: function (val, opts) {
                    
                    
                    const dataPointIndex = opts?.dataPointIndex;
                    const seriesIndex = opts?.seriesIndex
                    
                    let percentageString = (val).toFixed(0) + '%';
                    return data?.auditedData[seriesIndex]?.data[dataPointIndex] + " - "+percentageString;
                    // return series[seriesIndex];
                }
            },

            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '15px',
                fontFamily: 'Cairo-Medium',
                margin: '10px'
            },

            tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: true,
                intersect: false,
                inverseOrder: false,
                custom: undefined,
                fillSeriesColor: true,
                theme: 'dark',
                style: {
                    fontSize: '12px',
                    fontFamily: 'Cairo-Medium'
                },
            },
            colors:['#e7a52a','#bd0404','#0d490d'],// 
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },


    };



    return (


        <div id="chart" style={{ width: '100%' }}>
            <ReactApexChart options={state.options} series={state.series} type="bar" height={650} />
        </div>
    )
}

export default StackChart