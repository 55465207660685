import { Autocomplete, Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import SearchInput from '../../Components/SearchInput';



const Search = ({ 
  filters, 
  setFilters,
  loadingBranches,
  loadingPortCategories,
  loadingPortReturns,
  loadingPortTypes,
  branches,
  portCategories,
  portTypes,
  portReturns
}) => {
  const [t] = useTranslation("common");

  

  
  

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={filters?.name}
        setValue={(value) => {
          setFilters({
            ...filters,
            name: value
          })
        }}
        title={t('GEOFENCES.name')}
      />
      <SearchInput
        value={filters?.code}
        setValue={(value) => {
          setFilters({
            ...filters,
            code: value
          })
        }}
        title={t('GEOFENCES.code')}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.branch}
        options={branches}
        loading={loadingBranches}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            branch_id: v?v?.id:"",
            branch:v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('GEOFENCES.branch')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.port_type}
        options={portTypes}
        loading={loadingPortTypes}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            port_type_id: v?v?.id:"",
            port_type:v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('GEOFENCES.port_type')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.port_category}
        options={portCategories}
        loading={loadingPortCategories}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            port_category_id: v?v?.id:"",
            port_category:v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('GEOFENCES.port_category')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.port_return}
        options={portReturns}
        loading={loadingPortReturns}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            port_return_id: v?v?.id:"",
            port_return:v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('GEOFENCES.port_return')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('GEOFENCES.linked_to_wialon')}
          </Typography>

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.linked_to_wialon}
            onChange={(e) => {
              setFilters({
                ...filters,
                linked_to_wialon: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("GEOFENCES.linked_to_wialon")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("GEOFENCES.not_linked_to_wialon")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("GEOFENCES.all")}
            />


          </RadioGroup>
        </Box>
      </Box>

    </Box>
  );
};

export default Search