const userObject = {
    id: "",
    name: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    username: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    password: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    enabled: {
        value: "",
        error: false,
        message: "",
        required: false
    },

    created_at: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    roles:[]
}
export const resetErrors = (currentObject) => {
    var objectKeys = Object.keys(currentObject);

    var updatedObject = {
        ...currentObject
    }
    try {
        objectKeys.filter(key => key !== "id").map((key) => {

            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {

    }

    return updatedObject

}
export const checkErrors = (errorMessages, currentObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        if (!key?.includes('contacts')) {
            let messages = []
            errorMessages[key].map((message) => {
                messages.push(message)
            })

            currentObject[key]["error"] = true
            currentObject[key]["message"] = messages
        }
    })

    return currentObject
}
export const objectMerge = (updatedObject) => {
    const mergedObject = {
        ...updatedObject,
        id: updatedObject?.id,
        name: {
            value: updatedObject?.name,
            error: false,
            message: "",
            required: false
        },
        username: {
            value: updatedObject?.username,
            error: false,
            message: "",
            required: false
        },
        password: {
            value: updatedObject?.password,
            error: false,
            message: "",
            required: false
        },
        enabled: {
            value: updatedObject?.enabled,
            error: false,
            message: "",
            required: false
        }
    }

    return mergedObject;
}
export const resetObject = (currentObject, obj) => {
    // var objectKeys = Object.keys(currentObject);

    try {

        currentObject[obj]["error"] = false
        currentObject[obj]["message"] = ""
    } catch (error) {

    }

    return currentObject
}
export default userObject;