import { createSlice } from '@reduxjs/toolkit';
const directions__= localStorage.getItem('direction')? (localStorage.getItem('direction')==1?'rtl':'ltr'):'rtl'
var screenwidth= window.innerWidth
window.addEventListener('resize', ()=>{
   screenwidth=window.innerWidth
})
const GridMode=localStorage.getItem('gridMode')?localStorage.getItem('gridMode')=='true'?true:false:false;

const initialState={
    code:'ar',
    offLine:false,
    directions:'rtl',
    screenwidth:screenwidth,
    drawerwidth:350,
    currentpath:'admin/dashboard',
    globalLoading:false,
    polygons:[],
    isopensidemenu:false,
    transactionFilters:null,
    currentOpenMenuCollapse:null,
    currentlyOpenedMenuItem:null,

}
export const settingsSlice = createSlice({
  name: 'settingsData',
  initialState:initialState,
  reducers: {
    setCode:(state,action)=>{
      state.code = action.payload
    },
    setOffLine:(state,action)=>{
     state.offLine=action.payload
    },
    setscreenwidth:(state,action)=>{
      state.screenwidth=action.payload
    },
    setDrawerWidth:(state,action)=>{
      state.drawerwidth=action.payload
    },
    setCurrentPath:(state,action)=>{
      state.currentpath=action.payload
    },
    setPolygonsRedux:(state,action)=>{
      state.polygons=action.payload
    },
    setIsOpenSideMenu:(state,action)=>{
      state.isopensidemenu=action.payload
    },
    setGlobalLoading:(state,action)=>{
      state.globalLoading=action.payload
    },
    setTransactionFilters:(state,action)=>{
      state.transactionFilters=action.payload
    },
    setcurrentOpenMenuCollapse:(state,action)=>{
      state.currentOpenMenuCollapse=action?.payload
    },
    setCurrentlyOpendMenuItem:(state,action)=>{
      state.currentlyOpenedMenuItem=action?.payload
    }
  }
});

export const {
  setGlobalLoading,
  setOffLine,
  setscreenwidth,
  setCurrentPath,
  setPolygonsRedux,
  setIsOpenSideMenu,
  setCode,
  setTransactionFilters,
  setcurrentOpenMenuCollapse,
  setCurrentlyOpendMenuItem
} = settingsSlice.actions;

export const selectTransactionFilter = state => {

  return state.settingsData.transactionFilters
};

export default settingsSlice.reducer;
