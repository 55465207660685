import { Autocomplete, Box, FormControlLabel, Switch, TextField } from '@mui/material';
import React from 'react'
import SearchInput from '../../../Components/SearchInput';
import { useTranslation } from 'react-i18next';

const FilterSearchContent = (props) => {
    const [t] = useTranslation("common");
  
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100% !important",
        }}
      >
        <SearchInput
          value={props?.roleName}
          setValue={props?.setroleName}
          title={t("USERS.role_name_search")}
        />
        <Box sx={{ margin: '10px 0' }}></Box>
        
  
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            width: "100%",
            marginLeft: "20px !important",
            marginTop: "10px !important",
            "& .MuiTypography-root": {
              fontFamily: "Cairo-Medium",
            },
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={props.checkAllselected}
                onChange={(e) => {
                  props.setCheckAllselected(e.target.checked);
                  if (e.target.checked) props.setCheckAllNotSelected(false);
                }}
              />
            }
            label={t('USERS.role_selected')}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            width: "100%",
            marginLeft: "20px !important",
            marginTop: "10px !important",
            "& .MuiTypography-root": {
              fontFamily: "Cairo-Medium",
            },
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={props.checkAllNotSelected}
                onChange={(e) => {
                  props.setCheckAllNotSelected(e.target.checked);
                  if (e.target.checked) props.setCheckAllselected(false);
                }}
              />
            }
            label={t('USERS.role_unselected')}
          />
  
        </Box>
      </Box>
    );
  };

export default FilterSearchContent