const unitObject = {
    id: "",
    name: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    plate_number: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    driver_name: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    unit_section_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    unit_type_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    type: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    province: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    number_and_letter: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    vin: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    plate_type_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    plate_province_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    plate_letter_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    wialon_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    cls: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    mu: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    wialon_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    uacl: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    created_at: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    monitored_by_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
}
export const resetErrors = (currentObject) => {
    var objectKeys = Object.keys(currentObject);

    var updatedObject = {
        ...currentObject
    }
    try {
        objectKeys.filter(key => key !== "id").map((key) => {

            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {

    }

    return updatedObject

}
export const checkErrors = (errorMessages, currentObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        if (!key?.includes('contacts')) {
            let messages = []
            errorMessages[key].map((message) => {
                messages.push(message)
            })

            currentObject[key]["error"] = true
            currentObject[key]["message"] = messages
        }
    })

    return currentObject
}
export const objectMerge = (updatedObject) => {
    const mergedObject = {
        ...updatedObject,
        id: updatedObject?.id,
        name: {
            value: updatedObject?.name,
            error: false,
            message: "",
            required: false
        },

        plate_number: {
            value: updatedObject?.plate_number,
            error: false,
            message: "",
            required: false
        },
        plate_type_id: {
            value: updatedObject?.plate_type,
            error: false,
            message: "",
            required: false
        },
        plate_province_id: {
            value: updatedObject?.plate_province,
            error: false,
            message: "",
            required: false
        },
        plate_letter_id: {
            value: updatedObject?.plate_letter,
            error: false,
            message: "",
            required: false
        },
        cls: {
            value: updatedObject?.cls,
            error: false,
            message: "",
            required: false
        },
        uacl: {
            value: updatedObject?.uacl,
            error: false,
            message: "",
            required: false
        },
        wialon_id: {
            value: updatedObject?.wialon_id,
            error: false,
            message: "",
            required: false
        },
        mu: {
            value: updatedObject?.mu,
            error: false,
            message: "",
            required: false
        },
        monitored_by_id: {
            value: updatedObject?.monitored_by,
            error: false,
            message: "",
            required: false
        },
        driver_name: {
            value: updatedObject?.driver_name,
            error: false,
            message: "",
            required: false
        },
        unit_section_id: {
            value: updatedObject?.unit_section_id,
            error: false,
            message: "",
            required: false
        },
        unit_type_id: {
            value: updatedObject?.unit_type_id,
            error: false,
            message: "",
            required: false
        },
        unit_section_id: {
            value: updatedObject?.unit_section_id,
            error: false,
            message: "",
            required: false
        },
        type: {
            value: updatedObject?.type,
            error: false,
            message: "",
            required: false
        },
        province: {
            value: updatedObject?.province,
            error: false,
            message: "",
            required: false
        },
        number_and_letter: {
            value: updatedObject?.number_and_letter,
            error: false,
            message: "",
            required: false
        },
        vin: {
            value: updatedObject?.vin,
            error: false,
            message: "",
            required: false
        },
    }

    return mergedObject;
}
export const resetObject = (currentObject, obj) => {
    // var objectKeys = Object.keys(currentObject);

    try {

        currentObject[obj]["error"] = false
        currentObject[obj]["message"] = ""
    } catch (error) {

    }

    return currentObject
}
export default unitObject;