import callAPI from '../ApiConfig'
import $ from "jquery";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const portReturns = async (props) => {

    return await callAPI({
        route: "port_returns/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addPortReturn = async (props) => {


    return await callAPI({
        route: "port_returns/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editPortReturn = async (props) => {

    return await callAPI({
        route: "port_returns/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deletePortReturn = async (props) => {


    return await callAPI({
        route: "port_returns/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getPortReturnList = async (props) => {

    return await callAPI({
        route: "port_returns/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const portReturnAPI = {
    portReturns: portReturns,
    addPortReturn: addPortReturn,
    editPortReturn: editPortReturn,
    deletePortReturn: deletePortReturn,
    getPortReturnList: getPortReturnList
}
export default portReturnAPI