const geofenceObject = {
    id: "",
    name: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    code: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    port_type_id: {
        value: null,
        error: false,
        message: "",
        required: false
    },
    branch_id: {
        value: null,
        error: false,
        message: "",
        required: false
    },
    port_category_id: {
        value: null,
        error: false,
        message: "",
        required: false
    },
    port_return_id: {
        value: null,
        error: false,
        message: "",
        required: false
    },
    port_code: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    wialon_id: {
        value: null,
        error: false,
        message: "",
        required: false
    },
    data: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    created_at: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    update_wialon_link:{
        value:false,
        error:false,
        message:"",
        required:false
    }
}
export const resetErrors = (currentObject) => {
    var objectKeys = Object.keys(currentObject);

    var updatedObject = {
        ...currentObject
    }
    try {
        objectKeys.filter(key => key !== "id").map((key) => {

            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {

    }

    return updatedObject

}
export const checkErrors = (errorMessages, currentObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        if (!key?.includes('contacts')) {
            let messages = []
            errorMessages[key].map((message) => {
                messages.push(message)
            })

            currentObject[key]["error"] = true
            currentObject[key]["message"] = messages
        }
    })

    return currentObject
}
export const objectMerge = (updatedObject) => {
    const mergedObject = {
        ...updatedObject,
        id: updatedObject?.id,
        name: {
            value: updatedObject?.name,
            error: false,
            message: "",
            required: false
        },
        code: {
            value: updatedObject?.code,
            error: false,
            message: "",
            required: false
        },
        wialon_id: {
            value: updatedObject?.wialon_id,
            error: false,
            message: "",
            required: false
        },
        port_type_id: {
            value: updatedObject?.port_type,
            error: false,
            message: "",
            required: false
        },
        branch_id: {
            value: updatedObject?.branch,
            error: false,
            message: "",
            required: false
        },
        port_category_id: {
            value: updatedObject?.port_category,
            error: false,
            message: "",
            required: false
        },
        port_return_id: {
            value: updatedObject?.port_return,
            error: false,
            message: "",
            required: false
        },
        port_code: {
            value: updatedObject?.port_code,
            error: false,
            message: "",
            required: false
        },
        update_wialon_link:{
            value:false,
            error:false,
            message:"",
            required:false
        }

    }

    return mergedObject;
}
export const resetObject = (currentObject, obj) => {
    // var objectKeys = Object.keys(currentObject);

    try {

        currentObject[obj]["error"] = false
        currentObject[obj]["message"] = ""
    } catch (error) {

    }

    return currentObject
}
export default geofenceObject;