import { Box, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import helpers from '../../../assets/js/helper';
import CustomeSelectField from '../../../Components/CustomeSelectField';
import CustomTextField from '../../../Components/CustomTextField';
import CloseIcon from "@mui/icons-material/Close";
import geofenceAPI from '../../../Network/Geofence';
import GeofenceCodeTextField from '../../../Components/GeofenceCodeTextField';


const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const timer = useRef(null)
  const timerReload = useRef(null)
  const [reload, setRelaod] = useState(false)
  const [wialonGeofences, setWialonGeofences] = useState([])
  const [loadingWialonGeofences, setLoadingWialonGeofences] = useState(false)

  const [branchCode, setBranchCode] = useState(props?.object?.branch_id?.value?.code ? props?.object?.branch_id?.value?.code : "")
  const [portTypeCode, setPortTypeCode] = useState(props?.object?.port_type_id?.value?.code ? props?.object?.port_type_id?.value?.code : "")
  const [portReturnCode, setPortReturnCode] = useState(props?.object?.port_return_id?.value?.code ? props?.object?.port_return_id?.value?.code : "")
  const [portCategoryCode, setPortCategoryCode] = useState(props?.object?.port_category_id?.value?.code ? props?.object?.port_category_id?.value?.code : "")
  const [portCode, setPortCode] = useState(props?.object?.port_code?.value ? props?.object?.port_code?.value : "")

  const [combinedCode, setCombinedCode] = useState(props?.object?.code?.value ? props?.object?.code?.value : "")

  const [geofenceFullCode, setGeofenceFullCode] = useState(
    {
      branchCode: "",
      portCategoryCode: "",
      portReturnCode: "",
      portTypeCode: "",
      portCode: ""
    }
  )

  const onCodeUpdate = (newValue) => {

    if (timer.current) {
      clearTimeout(timer?.current)
    }
    timer.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingWialonGeofences(true);

        const result = await geofenceAPI.searchWialonGeofenceByCode(newValue?.slice(-3) === "000" ? newValue.slice(0, -3) : newValue);
        setLoadingWialonGeofences(false);

        if (result.status && result?.data?.data) {
          setWialonGeofences(result?.data?.data)
        }
      }
      else {
        setWialonGeofences([])
      }
    }, 1000)
  }



  useEffect(() => {
    if (timerReload.current) {
      clearTimeout(timerReload?.current)
    }
    timerReload.current = setTimeout(async () => {
      setRelaod(!reload)
    }, 100)

    return () => {

    }
  }, [])

  useEffect(() => {

    if (
      props?.object?.branch_id?.value?.id
      &&
      props?.object?.port_type_id?.value?.id
      &&
      props?.object?.port_category_id?.value?.id
      &&
      props?.object?.port_return_id?.value?.id

    ) {
      onCodeUpdate(props?.object?.code?.value)
    }

    return () => {

    }
  }, [props?.object, geofenceFullCode])


  useEffect(() => {
    setBranchCode(props?.object?.branch_id?.value?.code ? props?.object?.branch_id?.value?.code : "")
    setPortTypeCode(props?.object?.port_type_id?.value?.code ? props?.object?.port_type_id?.value?.code : "")
    setPortReturnCode(props?.object?.port_return_id?.value?.code ? props?.object?.port_return_id?.value?.code : "")
    setPortCategoryCode(props?.object?.port_category_id?.value?.code ? props?.object?.port_category_id?.value?.code : "")
    setPortCode(props?.object?.port_code?.value ? props?.object?.port_code?.value : "")
    setGeofenceFullCode({
      branchCode:props?.object?.branch_id?.value?.code ? props?.object?.branch_id?.value?.code : "",
      portTypeCode:props?.object?.port_type_id?.value?.code ? props?.object?.port_type_id?.value?.code : "",
      portReturnCode:props?.object?.port_return_id?.value?.code ? props?.object?.port_return_id?.value?.code : "",
      portCategoryCode:props?.object?.port_category_id?.value?.code ? props?.object?.port_category_id?.value?.code : "",
      portCode:props?.object?.port_code?.value ? props?.object?.port_code?.value : ""
    })


    return () => {

    }
  }, [reload])


  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.9)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "21120",
        }}
        onClick={() => {
          props?.setOpen(false);
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>

      <Box
        className="row d-flex justify-content-center align-items-start"
        sx={{ transition: "all 0.7s ease" }}
      >
        <CustomTextField
          label={t("GEOFENCES.name")}
          value={props?.object?.name?.value}
          error={props?.object?.name?.error}
          message={props?.object?.name?.message}
          haswidth={true}
          readOnly={true}
          focused={true}
          onChange={(e) => {
            let filteredValue = "";
            if (e?.target?.value) {
              filteredValue = e?.target?.value.replace(/[^a-zA-Z0-9\u0600-\u06FF\s]/g, '');
            }

            if (filteredValue?.length > 50) {
              return
            }




            props?.setObject({
              ...props?.object,
              name: {
                ...props?.object?.name,
                value: filteredValue,
                error: false,
                message: []
              }
            })

          }}
          onClearClick={() => {
            props?.setObject({
              ...props?.object,
              name: {
                ...props?.object?.name,
                value: "",
                error: false,
                message: []
              }
            })
          }}
        />
        <Box sx={{ margin: "8px 0" }}></Box>
        <CustomTextField
          label={t("GEOFENCES.code")}
          value={props?.object?.code?.value}
          error={props?.object?.code?.error}
          message={props?.object?.code?.message}
          haswidth={true}
          readOnly={true}
          focused={true}
          onChange={(e) => {
            // if (!isNaN(e?.target?.value)) {

            props?.setObject({
              ...props?.object,
              code: {
                ...props?.object?.code,
                value: e?.target?.value,
                error: false,
                message: []
              },
              wialon_id: {
                ...props?.object?.wialon_id,
                value: null,
                error: false,
                message: []
              }
            })
            onCodeUpdate(e?.target?.value)
            // }


          }}
          onClearClick={() => {
            props?.setObject({
              ...props?.object,
              code: {
                ...props?.object?.code,
                value: "",
                error: false,
                message: []
              },
              wialon_id: {
                ...props?.object?.wialon_id,
                value: null,
                error: false,
                message: []
              }
            })
            setWialonGeofences([])
          }}
        />

        <Box sx={{ margin: "8px 0" }}></Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding:0
          }}
        >
          <CustomeSelectField
            label={t("GEOFENCES.port_type")}
            haswidth={true}
            value={props?.object?.port_type_id?.value}
            list={props?.portTypes?.length ? props?.portTypes : []}
            error={props?.object?.port_type_id?.error}
            message={props?.object?.port_type_id?.message}
            readOnly={false}
            onChange={(e, newValue) => {
              let code = props?.object?.branch_id?.value?.code ? props?.object?.branch_id?.value?.code : "00"
              code += props?.object?.port_category_id?.value?.code ? props?.object?.port_category_id?.value?.code : "00"
              code += props?.object?.port_return_id?.value?.code ? props?.object?.port_return_id?.value?.code : "0"
              code += newValue?.code ? newValue?.code : "0"
              code += props?.object?.port_code?.value?.length == 3 ? props?.object?.port_code?.value : "000"
              if (portTypeCode != newValue?.code) {
                setPortTypeCode(newValue?.code)
              }
              if (geofenceFullCode?.portTypeCode != newValue?.code) {
                setGeofenceFullCode({
                  ...geofenceFullCode,
                  portTypeCode: newValue?.code
                })
              }

              props?.setObject({
                ...props?.object,
                port_type_id: {
                  ...props?.object?.port_type_id,
                  value: newValue,
                  error: false,
                  message: []
                },
                code: {
                  ...props?.object?.code,
                  value: code,
                  error: false,
                  message: []
                },
                wialon_id: {
                  ...props?.object?.wialon_id,
                  value: null,
                  error: false,
                  message: []
                }
              })
            }}
            onClearClick={() => {
              setPortTypeCode("")
              setGeofenceFullCode({
                ...geofenceFullCode,
                portTypeCode: ""
              })
              props?.setObject({
                ...props?.object,
                port_type_id: {
                  ...props?.object?.port_type_id,
                  value: null,
                  error: false,
                  message: []
                }
              })
            }}
          />

          <CustomeSelectField
            label={t("GEOFENCES.port_return")}
            haswidth={true}
            value={props?.object?.port_return_id?.value}
            list={props?.portReturns?.length ? props?.portReturns : []}
            error={props?.object?.port_return_id?.error}
            message={props?.object?.port_return_id?.message}
            readOnly={false}
            onChange={(e, newValue) => {
              let code = props?.object?.branch_id?.value?.code ? props?.object?.branch_id?.value?.code : "00"
              code += props?.object?.port_category_id?.value?.code ? props?.object?.port_category_id?.value?.code : "00"
              code += newValue?.code ? newValue?.code : "0"
              code += props?.object?.port_type_id?.value?.code ? props?.object?.port_type_id?.value?.code : "0"
              code += props?.object?.port_code?.value?.length == 3 ? props?.object?.port_code?.value : "000"
              if (portReturnCode != newValue?.code) {
                setPortReturnCode(newValue?.code)
              }
              if (geofenceFullCode?.portReturnCode != newValue?.code) {
                setGeofenceFullCode({
                  ...geofenceFullCode,
                  portReturnCode: newValue?.code
                })
              }

              props?.setObject({
                ...props?.object,
                port_return_id: {
                  ...props?.object?.port_return_id,
                  value: newValue,
                  error: false,
                  message: []
                },
                code: {
                  ...props?.object?.code,
                  value: code,
                  error: false,
                  message: []
                },
                wialon_id: {
                  ...props?.object?.wialon_id,
                  value: null,
                  error: false,
                  message: []
                }
              })
            }}
            onClearClick={() => {
              setPortReturnCode("")

              setGeofenceFullCode({
                ...geofenceFullCode,
                portReturnCode: ""
              })

              props?.setObject({
                ...props?.object,
                port_return_id: {
                  ...props?.object?.port_return_id,
                  value: null,
                  error: false,
                  message: []
                }
              })
            }}
          />

          <CustomeSelectField
            label={t("GEOFENCES.port_category")}
            haswidth={true}
            value={props?.object?.port_category_id?.value}
            list={props?.portCategories?.length ? props?.portCategories : []}
            error={props?.object?.port_category_id?.error}
            message={props?.object?.port_category_id?.message}
            readOnly={false}
            onChange={(e, newValue) => {
              let code = props?.object?.branch_id?.value?.code ? props?.object?.branch_id?.value?.code : "00"
              code += newValue?.code ? newValue?.code : "00"
              code += props?.object?.port_return_id?.value?.code ? props?.object?.port_return_id?.value?.code : "0"
              code += props?.object?.port_type_id?.value?.code ? props?.object?.port_type_id?.value?.code : "0"
              code += props?.object?.port_code?.value?.length == 3 ? props?.object?.port_code?.value : "000"

              if (portCategoryCode != newValue?.code) {
                setPortCategoryCode(newValue?.code)
              }
              if (geofenceFullCode?.portCategoryCode != newValue?.code) {
                setGeofenceFullCode({
                  ...geofenceFullCode,
                  portCategoryCode: newValue?.code
                })
              }
              props?.setObject({
                ...props?.object,
                port_category_id: {
                  ...props?.object?.port_category_id,
                  value: newValue,
                  error: false,
                  message: []
                },
                code: {
                  ...props?.object?.code,
                  value: code,
                  error: false,
                  message: []
                },
                wialon_id: {
                  ...props?.object?.wialon_id,
                  value: null,
                  error: false,
                  message: []
                }
              })
            }}
            onClearClick={() => {
              setPortCategoryCode("")
              setGeofenceFullCode({
                ...geofenceFullCode,
                portCategoryCode: ""
              })
              props?.setObject({
                ...props?.object,
                port_category_id: {
                  ...props?.object?.port_category_id,
                  value: null,
                  error: false,
                  message: []
                }
              })
            }}
          />

          <CustomeSelectField
            label={t("GEOFENCES.branch")}
            haswidth={true}
            value={props?.object?.branch_id?.value}
            list={props?.branches?.length ? props?.branches : []}
            error={props?.object?.branch_id?.error}
            message={props?.object?.branch_id?.message}
            readOnly={false}
            onChange={(e, newValue) => {

              let code = newValue?.code ? newValue?.code : "00"
              code += props?.object?.port_category_id?.value?.code ? props?.object?.port_category_id?.value?.code : "00"
              code += props?.object?.port_return_id?.value?.code ? props?.object?.port_return_id?.value?.code : "0"
              code += props?.object?.port_type_id?.value?.code ? props?.object?.port_type_id?.value?.code : "0"
              code += props?.object?.port_code?.value?.length == 3 ? props?.object?.port_code?.value : "000"


              setBranchCode(newValue?.code)

              setGeofenceFullCode({
                ...geofenceFullCode,
                branchCode: newValue?.code
              })


              props?.setObject({
                ...props?.object,
                branch_id: {
                  ...props?.object?.branch_id,
                  value: newValue,
                  error: false,
                  message: []
                },
                code: {
                  ...props?.object?.code,
                  value: code,
                  error: false,
                  message: []
                },
                wialon_id: {
                  ...props?.object?.wialon_id,
                  value: null,
                  error: false,
                  message: []
                }
              })
            }}
            onClearClick={() => {
              setBranchCode("")
              setGeofenceFullCode({
                ...geofenceFullCode,
                branchCode: ""
              })
              props?.setObject({
                ...props?.object,
                branch_id: {
                  ...props?.object?.branch_id,
                  value: null,
                  error: false,
                  message: []
                }
              })
            }}
          />
        </Box>

        <GeofenceCodeTextField
          geofenceFullCode={geofenceFullCode}
          setGeofenceFullCode={(value) => {
            setGeofenceFullCode(value)
            const selectedBranch = props?.branches?.find(item => item?.code == value?.branchCode)
            const selectedPortCategory = props?.portCategories?.find(item => item?.code == value?.portCategoryCode)
            const selectedPortReturn = props?.portReturns?.find(item => item?.code == value?.portReturnCode)
            const selectedPortType = props?.portTypes?.find(item => item?.code == value?.portTypeCode)
            const selectedPortCode = value?.portCode?.length == 3 ? value?.portCode : "000"

            let code = selectedBranch?.code?.length == 2 ? selectedBranch?.code : "00"
            code += selectedPortCategory?.code?.length == 2 ? selectedPortCategory?.code : "00"
            code += selectedPortReturn?.code?.length == 1 ? selectedPortReturn?.code : "0"
            code += selectedPortType?.code?.length == 1 ? selectedPortType?.code : "0"
            code += selectedPortCode


            props?.setObject({
              ...props?.object,
              branch_id: {
                ...props?.object?.branch_id,
                value: selectedBranch,
                error: false,
                message: []
              },
              port_category_id: {
                ...props?.object?.port_category_id,
                value: selectedPortCategory,
                error: false,
                message: []
              },
              port_return_id: {
                ...props?.object?.port_return_id,
                value: selectedPortReturn,
                error: false,
                message: []
              },
              port_type_id: {
                ...props?.object?.port_type_id,
                value: selectedPortType,
                error: false,
                message: []
              },
              port_code: {
                ...props?.object?.port_code,
                value: selectedPortCode,
                error: false,
                message: []
              },
              code: {
                ...props?.object?.code,
                value: code,
                error: false,
                message: []
              },
              wialon_id: {
                ...props?.object?.wialon_id,
                value: null,
                error: false,
                message: []
              }
            })


          }}

        />


        <Box sx={{ margin: "8px 0" }}></Box>

        {/* {loadingWialonGeofences ? <CircularProgress /> :
          wialonGeofences?.length > 0 ?
            <CustomeSelectField
              label={t("GEOFENCES.wialon_id")}
              haswidth={true}
              value={props?.object?.wialon_id?.value}
              list={wialonGeofences}
              error={props?.object?.wialon_id?.error}
              message={props?.object?.wialon_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {

                props?.setObject({
                  ...props?.object,
                  code: {
                    ...props?.object?.code,
                    value: helpers.extractNumbers(newValue?.name),
                    error: false,
                    message: []
                  },
                  wialon_id: {
                    ...props?.object?.wialon_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  wialon_id: {
                    ...props?.object?.wialon_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            /> : <Typography
              sx={{
                fontFamily: 'Cairo',
                color: 'red',
              }}
            >
              {props?.object?.code?.value ? t('GEOFENCES.no_points_with_same_code_found') : null}
            </Typography>} */}
      </Box>
    </Box>
  );
};

export default RenderContent