import { Autocomplete, Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import SearchInput from '../../Components/SearchInput';
import geofenceAPI from '../../Network/Geofence';
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Search = ({filters,setFilters}) => {
  const [t] = useTranslation("common");
  const timerSourceGeofences = useRef(null)
  const timerDestinationGeofences = useRef(null)
  const [sourceGeofences, setSourceGeofences] = useState([])
  const [destinationGeofences, setDestinationGeofences] = useState([])
  const [loadingSourceGeofences, setLoadingSourceGeofences] = useState(false)
  const [loadingDestinationGeofences, setLoadingDestinationGeofences] = useState(false)
  const [searchTermSourceGeofence, setSearchTermSourceGeofence] = useState("");
  const [searchTermDestinationGeofence, setSearchTermDestinationGeofence] = useState("");

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }

  const handleSearchTermSourceGeofenceChange = (newValue) => {

    setSearchTermSourceGeofence(newValue);
    if (timerSourceGeofences.current) {
      clearTimeout(timerSourceGeofences?.current)
    }
    timerSourceGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingSourceGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [1,3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingSourceGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setSourceGeofences(result?.data?.data?.data?.map(item=>{
            return {
              id:item?.id,
              name:item?.name,
              code:item?.code
            }
          }))
        }
      }
      else {
        setSourceGeofences([])
      }
    }, 1000)
  };
  const handleSearchTermDestinationGeofenceChange = (newValue) => {

    setSearchTermDestinationGeofence(newValue);
    if (timerDestinationGeofences.current) {
      clearTimeout(timerDestinationGeofences?.current)
    }
    timerDestinationGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingDestinationGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [2,3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingDestinationGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setDestinationGeofences(result?.data?.data?.data?.map(item=>{
            return {
              id:item?.id,
              name:item?.name,
              code:item?.code
            }
          }))
        }
      }
      else {
        setDestinationGeofences([])
      }
    }, 1000)
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={filters?.name}
        setValue={(value)=>{
          setFilters({
            ...filters,
            name:value
          })
        }}
        title={t('ROUTES.name')}
      />
      <SearchInput
        value={filters?.code}
        setValue={(value)=>{
          setFilters({
            ...filters,
            code:value
          })
        }}
        title={t('ROUTES.code')}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        onInputChange={(event, newInputValue) => {

          handleSearchTermSourceGeofenceChange(newInputValue);
        }}
        inputValue={searchTermSourceGeofence}
        loading={loadingSourceGeofences}
        loadingText={t('GLOBAL.searching')}
        value={filters?.sourceGeofence}
        options={sourceGeofences}

        getOptionLabel={(option) => {
          
          return option && option?.name || ""
        }}



        onChange={(e, v, r) => {
          
          setFilters({
            ...filters,
            sourceGeofence: v,
            source_geofence_id:v?v?.id:""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.source_geofence')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        onInputChange={(event, newInputValue) => {

          handleSearchTermDestinationGeofenceChange(newInputValue);
        }}
        inputValue={searchTermDestinationGeofence}
        loading={loadingDestinationGeofences}
        loadingText={t('GLOBAL.searching')}
        value={filters?.destinationGeofence}
        options={destinationGeofences}

        getOptionLabel={(option) => {
          
          return option && option?.name || ""
        }}



        onChange={(e, v, r) => {
          
          setFilters({
            ...filters,
            destinationGeofence: v,
            destination_geofence_id:v?v?.id:""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.destination_geofence')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      
      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('GEOFENCES.linked_to_wialon')}
          </Typography>

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.linked_to_wialon}
            onChange={(e) => {
              setFilters({
                ...filters,
                linked_to_wialon: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("GEOFENCES.linked_to_wialon")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("GEOFENCES.not_linked_to_wialon")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("GEOFENCES.all")}
            />


          </RadioGroup>
        </Box>
      </Box>

    </Box>
  );
};

export default Search