import { Box } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';


export default function PieChart({ labels, series, redirectIndex, colors, width }) {
  const [t] = useTranslation('common');



  const options = {

    series: series,
    options: {
      chart: {
        
        width: 380,
        type: 'pie',
        events: {
          dataPointSelection: function (event, chartContext, config) {
            handleBarClicked(event, chartContext, config)
          }
        },
      },
      labels: labels,

      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          const seriesIndex = opts.seriesIndex;
          let percentageString = (val).toFixed(0) + '%';
           
          return series[seriesIndex] + " - "+percentageString;
        }
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '15px',
        fontFamily: 'Cairo-Medium',
        margin: '10px'
      },
      colors: colors,
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: true,
        theme: false,
        style: {
          fontSize: '12px',
          fontFamily: 'Cairo-Medium'
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },


  };

  const navigate = useNavigate()

  const handleBarClicked = (event, chartContext, config) => {
    redirectIndex(config?.dataPointIndex)

  }


  return (


    <div id="chart">
      {options?.series?.length > 0 ?
        <ReactApexChart options={options?.options} series={options?.series} type="pie" width={380} />
        : "لاتوجد بيانات"}
    </div>


  );

}
