import React, { useState, useEffect } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDownGrid from "../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Typography,
  IconButton
} from "@mui/material";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import transactionAPI from "../../Network/Transaction";
import FormContainer from "./Form/FormContainer";
import Title from "./Title";
import { selectRoles } from "../../reduxStore/RolesReducer";
import CustomToggleStatus from "../../Components/CustomToggleStatus";
import CustomLargeTextView from "../../Components/CustomLargeTextView";
import DownloadIcon from '@mui/icons-material/Download';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import RouteIcon from '@mui/icons-material/Route';
import ViewFormContainer from "./ViewForm/ViewFormContainer";
import moment from "moment";
import branchAPI from "../../Network/Branch";
import swal from "sweetalert";
import CancelIcon from '@mui/icons-material/Cancel';
import UpdateFormContainer from "./UpdateForm/UpdateFormContainer";
import FlagIcon from '@mui/icons-material/Flag';
import EditIcon from "@mui/icons-material/Edit";
import helpers from "../../assets/js/helper";
import { selectTransactionFilter, setTransactionFilters } from "../../reduxStore/SettingsReducer";
import transactionStatusAPI from "../../Network/TransactionStatus";
import AuditFormContainer from "./AuditForm/AuditFormContainer";
import VerifiedIcon from '@mui/icons-material/Verified';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import CreateRouteFormContainer from "../Routes/Form/FormContainer"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AdjustIcon from '@mui/icons-material/Adjust';
import TransactionStops from "./TransactionStops/TransactionStops";
import TransactionRoutes from "./TransactionRoutes/TransactionRoutes";
import CustomMultiStatusIcon from "../../Components/CustomMultiStatusIcon";


import { SiMicrosoftexcel } from 'react-icons/si'
import { BsFilePdfFill } from 'react-icons/bs'
import userAPI from "../../Network/User";
import portCategoryAPI from "../../Network/PortCategory";

const cookies = new Cookies();

const todayStart = new Date();
const yesterday = new Date(todayStart);
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(0, 0, 0, 0);

const todayEnd = new Date();
todayEnd.setHours(23, 59, 59, 999);
export default function Transactions(props) {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const transactionFilters = useSelector(selectTransactionFilter)
  const [branches, setBranches] = useState([])
  const [portCategories, setPortCategories] = useState([])
  const [loadingPortCategories, setLoadingPortCategories] = useState(true)
  const [loadingBranches, setLoadingBranches] = useState(true)

  const [users, setUsers] = useState([])
  const [loadingUsers, setLoadingUsers] = useState(true)

  const [transactionType2List, setTransactionType2List] = useState([])
  const [loadingTransactionType2List, setloadingTransactionType2List] = useState(true)


  const [transactionStatuses, setTransactionStatuses] = useState([])
  const [loadingTransactionStatuses, setLoadingTransactionStatuses] = useState(true)

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);

  const [filters, setFilters] = useState({
    unit: null,
    unit_id: "",
    sourceGeofence: null,
    source_geofence_id: "",
    destinationGeofence: null,
    destination_geofence_id: "",
    route: null,
    route_id: "",
    creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
    creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
    creationDateFrom: yesterday,
    creationDateTo: todayEnd,
    export_excel: false,
    export_pdf: false,
    note: "",
    destination_geofence_name: "",
    source_geofence_name: "",
    all_time: 0
  })
  const [reload, setReload] = useState(false)

  const [openForm, setOpenForm] = useState(false);
  const [openViewForm, setOpenViewForm] = useState(false);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [openAuditForm, setOpenAuditForm] = useState(false)
  const [openCreateRouteForm, setOpenCreateRouteForm] = useState(false)
  const [selectedObject, setselectedObject] = useState(null);
  const [showStops, setShowStops] = useState(false)
  const [showRoutes, setShowRoutes] = useState(false)
  const [fast, setFast] = useState(1)
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const rolesRedux = useSelector(selectRoles)

  const [columns, setColumns] = useState([
    { field: "index_column", headerName: "ت", minWidth: 50, maxWidth: 60 },
    { field: "id", headerName: "#", minWidth: 50, maxWidth: 60 },
    { field: "transaction_number", headerName: t("TRANSACTIONS.transaction_number"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "unit_name", headerName: t("TRANSACTIONS.unit"), minWidth: 110, maxWidth: 150, flex: 2 },
    {
      field: "source_geofence_name", headerName: t("TRANSACTIONS.source_geofence"), minWidth: 110, maxWidth: 150, flex: 2,
      renderCell: (rows) => {
        return (
          <Typography
            sx={{
              fontFamily: "Cairo-Bold",
              color: '#000000',
              fontWeight: 600,
              fontSize: '14px'
            }}

          >
            {rows?.row?.source_geofence_name}
          </Typography>
        )
      }
    },
    {
      field: "destination_geofence_name", headerName: t("TRANSACTIONS.destination_geofence"), minWidth: 110, maxWidth: 150, flex: 2,
      renderCell: (rows) => {
        return (
          <Typography
            sx={{
              fontFamily: "Cairo-Bold",
              color: '#000000',
              fontWeight: 600,
              fontSize: '14px'
            }}

          >
            {rows?.row?.destination_geofence_name}
          </Typography>
        )
      }
    },
    {
      field: "destination_warehouse", headerName: t("TRANSACTIONS.destination_warehouse_grid_header_1") + " " + t("TRANSACTIONS.destination_warehouse_grid_header_2"), minWidth: 110, maxWidth: 150, flex: 2,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography
            sx={{

              fontFamily: "Cairo-Medium"
            }}

          >
            {t("TRANSACTIONS.destination_warehouse_grid_header_1")}
          </Typography>
          <Typography
            sx={{

              fontFamily: "Cairo-Medium"
            }}

          >
            {t("TRANSACTIONS.destination_warehouse_grid_header_2")}
          </Typography>
        </Box>
      )
    },
    // {
    //   field: "routes_names", headerName: t("TRANSACTIONS.route"), minWidth: 110, maxWidth: 150, flex: 2,
    //   renderCell: (rows) => {
    //     return (
    //       <CustomLargeTextView
    //         text={rows?.row?.routes_names}
    //       />


    //     )
    //   }
    // },
    {
      field: "routes_count", headerName: t("TRANSACTIONS.routes_count"), minWidth: 50, maxWidth: 50,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Tooltip
            sx={{
              borderWidth: '3px',
              borderColor: '#FFFFFF',
              borderStyle: 'solid',
              borderRadius: '50%'
            }}
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("TRANSACTIONS.routes_count")}
              </span>
            }
          >
            <RouteIcon />
          </Tooltip>
        </Box>
      ),

      renderCell: (rows) => {
        return (
          (rows?.row?.routes_count == 0 || !rows?.row?.routes_count) ?
            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("TRANSACTIONS.no_routes")}
                </span>
              }
            >
              <CancelIcon
                sx={{
                  fill: "#FF0000"
                }}
              />
            </Tooltip> :
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Tooltip
                title={
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("TRANSACTIONS.routes_count")}
                  </span>
                }
              >

                <IconButton
                  onClick={() => { showRoutesFunction(rows?.row) }}
                  sx={{
                    fontSize: '16px',
                    color: '#000000DE'
                  }}
                >

                  {parseInt(rows?.row?.routes_count)}

                </IconButton>

              </Tooltip>

            </Box>

        )
      }
    },
    // {
    //   field: "far_from_source", headerName: t("TRANSACTIONS.not_far_from_source"), minWidth: 50, maxWidth: 50,
    //   renderHeader: (rows) => (
    //     <Box
    //       sx={{
    //         display: 'flex',
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //         width: '100%'
    //       }}>


    //       <Tooltip
    //         title={
    //           <span style={{ fontFamily: "Cairo-Medium" }}>
    //             {t("TRANSACTIONS.not_far_from_source")}
    //           </span>
    //         }
    //       >
    //         <AdjustIcon />
    //       </Tooltip>
    //     </Box>
    //   ),
    //   renderCell: (rows) => {
    //     return (

    //         <CustomToggleStatus
    //           trueOrFalse={ rows?.row?.far_from_source == 0 }
    //           trueLabel={t("TRANSACTIONS.not_far_from_source")}
    //           falseLabel={t("TRANSACTIONS.far_from_source")}
    //           trueColor={"green"}
    //           falseColor={"red"}
    //           ignoreTrue={rows?.row?.is_arrived == 0}
    //         />

    //     )
    //   }
    // },
    {
      field: "is_monitored",
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Tooltip
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("TRANSACTIONS.is_monitored")}
              </span>
            }
          >
            <VisibilityIcon />
          </Tooltip>
        </Box>
      ),
      headerName: t("TRANSACTIONS.is_monitored"), minWidth: 50, maxWidth: 50,
      renderCell: (rows) => {
        return (
          <CustomToggleStatus
            trueOrFalse={rows?.row?.is_monitored == 1}
            trueLabel={t("TRANSACTIONS.monitored")}
            falseLabel={t("TRANSACTIONS.not_monitored")}
            trueColor={"green"}
            falseColor={"red"}
          />

        )
      }
    },

    {
      field: "has_violation", headerName: t("TRANSACTIONS.doesnt_has_violation_header"), minWidth: 50, maxWidth: 50,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}>


          <Tooltip
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("TRANSACTIONS.doesnt_has_violation")}
              </span>
            }
          >
            <ThumbUpAltIcon />
          </Tooltip>
        </Box>
      ),
      renderCell: (rows) => {
        return (
          // rows?.row?.has_valid_routes == 0?
          <CustomMultiStatusIcon
            multiStatusArray={[

              {
                statusIsTrue: (
                  rows?.row?.is_monitored == 1
                  &&
                  rows?.row?.has_valid_routes == 0
                ),
                label: t("TRANSACTIONS.no_valid_routes"),
                icon: <CancelIcon
                  sx={{
                    fill: "orange"
                  }}
                />
              },
              {
                statusIsTrue: (
                  rows?.row?.is_monitored == 1
                  &&
                  rows?.row?.has_valid_routes == 1
                  &&
                  rows?.row?.has_violation == 0
                  &&
                  rows?.row?.is_arrived == 1
                ),
                label: t("TRANSACTIONS.doesnt_has_violation"),
                icon: <CheckCircleIcon
                  sx={{
                    fill: "green"
                  }}
                />
              },
              {
                statusIsTrue: (
                  rows?.row?.is_monitored == 1
                  &&
                  rows?.row?.has_valid_routes == 1
                  &&
                  rows?.row?.has_violation == 1
                ),
                label: t("TRANSACTIONS.has_violation"),
                icon: <CancelIcon
                  sx={{
                    fill: "red"
                  }}
                />
              }

            ]}
          />
          // :
          // rows?.row?.is_monitored == 0 ? <Box></Box> :
          //   <CustomToggleStatus
          //     trueOrFalse={rows?.row?.has_violation == 0}
          //     trueLabel={t("TRANSACTIONS.doesnt_has_violation")}
          //     falseLabel={t("TRANSACTIONS.has_violation")}
          //     trueColor={"green"}
          //     falseColor={"red"}
          //     ignoreTrue={rows?.row?.is_arrived == 0}
          //   />

        )
      }
    },
    {
      field: "is_arrived", headerName: t("TRANSACTIONS.is_arrived"), minWidth: 50, maxWidth: 50,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}>


          <Tooltip
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("TRANSACTIONS.is_arrived")}
              </span>
            }
          >
            <FlagIcon />
          </Tooltip>
        </Box>
      ),
      renderCell: (rows) => {
        return (
          rows?.row?.is_monitored == 0 ? <Box></Box> :
            <CustomToggleStatus
              trueOrFalse={rows?.row?.is_arrived == 1}
              trueLabel={t("TRANSACTIONS.is_arrived")}
              falseLabel={t("TRANSACTIONS.isnt_arrived")}
              trueColor={"green"}
              falseColor={(rows?.row?.is_complete == 1
                && rows?.row?.exceeded_allowed_time == 0
                && rows?.row?.payload_not_unloaded == 0
                && rows?.row?.payload_unloaded_incomplete == 0) ? "orange" : "red"}
              ignorefalse={rows?.row?.is_complete == 0}
            />

        )
      }
    },
    {
      field: "is_complete", headerName: t("TRANSACTIONS.is_complete_grid_title"), minWidth: 50, maxWidth: 50,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Tooltip
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("TRANSACTIONS.is_complete_grid_title")}
              </span>
            }
          >
            <CheckCircleIcon />
          </Tooltip>
        </Box>
      ),
      renderCell: (rows) => {
        return (
          rows?.row?.is_monitored == 0 || (
            rows?.row?.is_complete == 0
            &&
            rows?.row?.exceeded_allowed_time == 0

          ) ? <Box></Box> :

            <CustomMultiStatusIcon
              multiStatusArray={[
                {
                  statusIsTrue: (
                    rows?.row?.is_complete == 1
                    && rows?.row?.exceeded_allowed_time == 0
                    && rows?.row?.payload_not_unloaded == 0
                    && rows?.row?.payload_unloaded_incomplete == 0
                  ),
                  label: t("TRANSACTIONS.is_complete"),
                  icon: <CheckCircleIcon
                    sx={{
                      fill: "green"
                    }}
                  />
                },
                {
                  statusIsTrue: (
                    rows?.row?.is_complete == 1
                    && rows?.row?.exceeded_allowed_time == 0
                    && rows?.row?.payload_not_unloaded == 1 // status 7
                    && rows?.row?.payload_unloaded_incomplete == 0
                  ),
                  label: t("TRANSACTIONS.payload_not_unloaded"),
                  icon: <CancelIcon
                    sx={{
                      fill: "orange"
                    }}
                  />
                }
                ,
                {
                  statusIsTrue: (
                    rows?.row?.is_complete == 1
                    && rows?.row?.exceeded_allowed_time == 0
                    && rows?.row?.payload_unloaded_incomplete == 1 // status 8
                  ),
                  label: t("TRANSACTIONS.payload_unloaded_incomplete"),
                  icon: <CancelIcon
                    sx={{
                      fill: "red"
                    }}
                  />
                }
              ]}
            />

        )
      }
    },

    // {
    //   field: "is_complete", headerName: t("TRANSACTIONS.is_complete"), minWidth: 50, maxWidth: 50,
    //   renderHeader: (rows) => (
    //     <Box
    //       sx={{
    //         display: 'flex',
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //         width: '100%'
    //       }}
    //     >
    //       <Tooltip
    //         title={
    //           <span style={{ fontFamily: "Cairo-Medium" }}>
    //             {t("TRANSACTIONS.is_complete")}
    //           </span>
    //         }
    //       >
    //         <CheckCircleIcon />
    //       </Tooltip>
    //     </Box>
    //   ),
    //   renderCell: (rows) => {
    //     return (
    //       rows?.row?.is_monitored == 0 || (
    //         rows?.row?.is_complete == 0
    //         &&
    //         rows?.row?.exceeded_allowed_time == 0

    //       ) ? <Box></Box> :
    //         <CustomToggleStatus
    //           trueOrFalse={rows?.row?.is_complete == 1 && rows?.row?.exceeded_allowed_time == 0}
    //           trueLabel={t("TRANSACTIONS.is_complete")}
    //           falseLabel={t("TRANSACTIONS.not_complete")}
    //           trueColor={"green"}
    //           falseColor={"red"}
    //         />

    //     )
    //   }
    // },
    {
      field: "stop_violation_counter", headerName: t("TRANSACTIONS.stop_violation_counter"), minWidth: 50, maxWidth: 50,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Tooltip
            sx={{
              borderWidth: '3px',
              borderColor: '#FFFFFF',
              borderStyle: 'solid',
              borderRadius: '50%'
            }}
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("TRANSACTIONS.stop_violation_counter")}
              </span>
            }
          >
            <LocalParkingIcon />
          </Tooltip>
        </Box>
      ),
      renderCell: (rows) => {
        return (
          (rows?.row?.stop_violation_counter == 0 && rows?.row?.stop_out_violation_counter == 0) ? <Box></Box> :
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Tooltip
                title={
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("TRANSACTIONS.stop_violation_counter")}
                  </span>
                }
              >
                <IconButton
                  onClick={() => { showStopsFunction(rows?.row) }}
                  sx={{
                    fontSize: '16px',
                    color: '#000000DE'
                  }}
                >
                  {parseInt(rows?.row?.stop_violation_counter) + parseInt(rows?.row?.stop_out_violation_counter)}
                </IconButton>
              </Tooltip>
            </Box>

        )
      }
    },

    // {
    //   hide: true,
    //   field: "exceeded_allowed_time", headerName: t("TRANSACTIONS.not_exceeded_allowed_time"), minWidth: 50, maxWidth: 50,
    //   renderHeader: (rows) => (
    //     <Box>
    //       <Tooltip
    //         title={
    //           <span style={{ fontFamily: "Cairo-Medium" }}>
    //             {t("TRANSACTIONS.not_exceeded_allowed_time")}
    //           </span>
    //         }
    //       >
    //         <AccessTimeFilledIcon />
    //       </Tooltip>
    //     </Box>
    //   ),
    //   renderCell: (rows) => {
    //     return (
    //       (rows?.row?.is_monitored == 0) ? <Box></Box> :
    //         <CustomToggleStatus
    //           trueOrFalse={rows?.row?.exceeded_allowed_time == 0}
    //           trueLabel={t("TRANSACTIONS.not_exceeded_allowed_time")}
    //           falseLabel={t("TRANSACTIONS.exceeded_allowed_time")}
    //           trueColor={"green"}
    //           falseColor={"red"}
    //           ignoreTrue={rows?.row?.is_complete == 0}
    //         />

    //     )
    //   }
    // },
    {
      field: "note", headerName: t("TRANSACTIONS.note"), minWidth: 110, maxWidth: 150, flex: 2,
      renderCell: (rows) => {
        return (
          <CustomLargeTextView
            text={rows?.row?.note}
          />


        )
      }
    },

    { field: "product_type", headerName: t("TRANSACTIONS.product_type"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "liters", headerName: t("TRANSACTIONS.liters"), minWidth: 110, maxWidth: 150, flex: 2 },
    {
      field: "formatted_datetime", headerName: t("TRANSACTIONS.date_time"), minWidth: 110, maxWidth: 150, flex: 2,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography
            sx={{

              fontFamily: "Cairo-Medium",
              fontSize: 14
            }}

          >
            {t("TRANSACTIONS.date_time1")}
          </Typography>
          <Typography
            sx={{

              fontFamily: "Cairo-Medium",
              fontSize: 14
            }}

          >
            {t("TRANSACTIONS.date_time2")}
          </Typography>
        </Box>
      )
    },
    {
      field: "inserted_at", headerName: t("TRANSACTIONS.inserted_at"), minWidth: 130, maxWidth: 150, flex: 2,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography
            sx={{

              fontFamily: "Cairo-Medium",
              fontSize: 14
            }}

          >
            {t("TRANSACTIONS.inserted_at1")}
          </Typography>
          <Typography
            sx={{

              fontFamily: "Cairo-Medium",
              fontSize: 14
            }}

          >
            {t("TRANSACTIONS.inserted_at2")}
          </Typography>
        </Box>
      )
    },
    {
      field: "out_from_source_at", headerName: t("TRANSACTIONS.out_from_source_at"), minWidth: 150, maxWidth: 150, flex: 2,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography
            sx={{

              fontFamily: "Cairo-Medium",
              fontSize: 14
            }}

          >
            {t("TRANSACTIONS.out_from_source_at1")}
          </Typography>
          <Typography
            sx={{

              fontFamily: "Cairo-Medium",
              fontSize: 14
            }}

          >
            {t("TRANSACTIONS.out_from_source_at2")}
          </Typography>
        </Box>
      )
    },
    {
      field: "trip_duration_time_difference", headerName: t("TRANSACTIONS.trip_duration_time_difference"), minWidth: 150, maxWidth: 150, flex: 2,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Tooltip
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("TRANSACTIONS.trip_duration_time_difference_tooltip")}
              </span>
            }
          >
            <Typography
              sx={{

                fontFamily: "Cairo-Medium",
                fontSize: 14
              }}

            >
              {t("TRANSACTIONS.trip_duration_time_difference")}
            </Typography>
          </Tooltip>
        </Box>
      ),
      renderCell: (rows) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("TRANSACTIONS.trip_duration_time_difference_tooltip")}
                </span>
              }
            >
              <Typography
                sx={{

                  fontFamily: "Cairo-Medium",
                  fontSize: 14
                }}

              >
                {rows?.row?.trip_duration_time_difference}
              </Typography>
            </Tooltip>
          </Box>
        )

      }
    },
    {
      field: "trip_range_time_difference", headerName: t("TRANSACTIONS.trip_range_time_difference"), minWidth: 150, maxWidth: 150, flex: 2,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Tooltip
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("TRANSACTIONS.trip_range_time_difference_tooltip")}
              </span>
            }
          >
            <Typography
              sx={{

                fontFamily: "Cairo-Medium",
                fontSize: 14
              }}

            >
              {t("TRANSACTIONS.trip_range_time_difference")}
            </Typography>
          </Tooltip>
        </Box>
      ),
      renderCell: (rows) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("TRANSACTIONS.trip_range_time_difference_tooltip")}
                </span>
              }
            >
              <Typography
                sx={{

                  fontFamily: "Cairo-Medium",
                  fontSize: 14
                }}

              >
                {rows?.row?.trip_range_time_difference}
              </Typography>
            </Tooltip>
          </Box>
        )

      }
    },
    { field: "created_by_name", headerName: t("TRANSACTIONS.created_by_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    {
      field: "audited_by_name_custom", headerName: t("TRANSACTIONS.audited_by_name"), minWidth: 110, maxWidth: 150, flex: 2,
      renderCell: (rows) => {



        if (rows?.row?.transaction_status_id == 1) {
          return "-"
        }
        else {
          return rows?.row?.audited_by_name;
        }

      }
    },
    { field: "transaction_status_name", headerName: t("TRANSACTIONS.transaction_status_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "arrived_at", headerName: t("TRANSACTIONS.arrived_at"), minWidth: 110, maxWidth: 150, flex: 2 },
    {
      headerName: t("TRANSACTIONS.actions"),
      field: "Actions",
      flex: 0.4,
      minWidth: 100,
      // hide: !rolesRedux?.export_transaction_report?.value && !rolesRedux?.view_transaction?.value,
      renderCell: (rows) => (
        (!rolesRedux?.export_transaction_report?.value && !rolesRedux?.view_transaction?.value)
          // || rows.row?.enabled === 0 
          ? null :
          <DropDownGrid>
            {rolesRedux?.view_transaction?.value ?
              <MenuItem onClick={() => {
                viewFunction(rows?.row)
              }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <VisibilityIcon style={{ fill: "#17681b" }} />

                </ListItemIcon>
                {t('TRANSACTIONS.view_transaction')}
              </MenuItem> : null}
            {rows?.row?.is_arrived == 1 && rolesRedux?.export_transaction_report?.value ?
              <MenuItem onClick={() => {
                exportReport(rows?.row)
              }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <DownloadIcon style={{ fill: "#17681b" }} />

                </ListItemIcon>
                {t('GLOBAL.download_report')}
              </MenuItem> : null}
            {rows?.row?.is_arrived == 1 && rolesRedux?.export_transaction_report?.value ?
              <MenuItem onClick={() => {
                createReport(rows?.row)
              }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <NoteAddIcon style={{ fill: "#17681b" }} />

                </ListItemIcon>
                {t('GLOBAL.create_report')}
              </MenuItem> : null}
            {showUpdate(rolesRedux?.update_transaction?.value, rows?.row) ?
              <MenuItem onClick={() => {
                UpdateFunction(rows?.row)
              }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <EditIcon style={{ fill: "#17681b" }} />

                </ListItemIcon>
                {t('TRANSACTIONS.update_destination_geofence')}
              </MenuItem> : null}
            {rolesRedux?.add_transaction_audit?.value ?
              <MenuItem onClick={() => {
                auditFunction(rows?.row)
              }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <VerifiedIcon style={{ fill: "#17681b" }} />

                </ListItemIcon>
                {t('TRANSACTIONS.audit')}
              </MenuItem> : null}
            {rolesRedux?.add_route?.value && rows?.row?.destination_geofence_name ?
              <MenuItem onClick={() => {
                createRouteFunction(rows?.row)
              }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <AddRoadIcon style={{ fill: "#17681b" }} />

                </ListItemIcon>
                {t('TRANSACTIONS.create_route')}
              </MenuItem> : null}
            {rows?.row?.latitude && rows?.row?.longitude ?
              <MenuItem onClick={() => {
                window.open(
                  `https://maps.google.com/?q=${rows?.row?.latitude},${rows?.row?.longitude}`,
                  "_blank"
                );
              }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <LocationOnIcon style={{ fill: "#17681b" }} />

                </ListItemIcon>
                {t('TRANSACTIONS.map_location')}
              </MenuItem> : null}


          </DropDownGrid>
      ),
    },
  ])

  const showUpdate = (has_update_role, transaction) => {

    return (
      has_update_role
      && !transaction?.destination_geofence_name
      && transaction?.enabled
      // && helpers.durationFromNow(transaction?.formatted_datetime, 30)
    )

  }
  const getRowClassName = (params) => {
    if (params?.row?.enabled === 0) {
      return 'highlighted-disabled-row'
    }
    else {
      if (params?.row?.transaction_status_id === 2) {
        return 'highlighted-auditing-row'
      }
      else if (params?.row?.transaction_status_id === 3) {
        return 'highlighted-invalid-row'
      }
      else if (params?.row?.transaction_status_id === 4) {
        return 'highlighted-valid-row'
      }
      else {
        return ''
      }
    }



  };

  const getResources = () => {

    portCategoryAPI.getPortCategoryList({
      params: {
        ...headerObject,
      }

    }).then(portCategoriesResult => {
      if (portCategoriesResult.status && portCategoriesResult?.data?.data) {
        setPortCategories(portCategoriesResult?.data?.data)
      }
      setLoadingPortCategories(false);
    }).catch(error => {
      console.log(error);
      setLoadingPortCategories(false);
    }).finally(() => {
      setLoadingPortCategories(false);
    })

    branchAPI.getBranchList({
      params: {
        ...headerObject,
      }

    }).then(branchesResult => {
      if (branchesResult.status && branchesResult?.data?.data) {
        setBranches(branchesResult?.data?.data)
      }
      setLoadingBranches(false);
    }).catch(error => {
      console.log(error);
      setLoadingBranches(false);
    }).finally(() => {
      setLoadingBranches(false);
    })

    transactionStatusAPI.getTransactionStatusList({
      params: {
        ...headerObject,
      }

    }).then(transactionStatusesResult => {
      if (transactionStatusesResult.status && transactionStatusesResult?.data?.data) {
        setTransactionStatuses(transactionStatusesResult?.data?.data)
      }
      setLoadingTransactionStatuses(false);
    }).catch(error => {
      console.log(error);
      setLoadingTransactionStatuses(false);
    }).finally(() => {
      setLoadingTransactionStatuses(false);
    })

    userAPI.getUserList({
      params: {
        ...headerObject,
      }

    }).then(usersResult => {
      if (usersResult.status && usersResult?.data?.data) {
        setUsers(usersResult?.data?.data)
      }
      setLoadingUsers(false);
    }).catch(error => {
      console.log(error);
      setLoadingUsers(false);
    }).finally(() => {
      setLoadingUsers(false);
    })

    transactionAPI.getTransactionType2List({
      params: {
        ...headerObject,
      }

    }).then(tt2Result => {
     
      if (tt2Result.status && tt2Result?.data?.data) {

        setTransactionType2List(tt2Result?.data?.data)
      }
      setloadingTransactionType2List(false);
    }).catch(error => {
      console.log(error);
      setloadingTransactionType2List(false);
    }).finally(() => {
      setloadingTransactionType2List(false);
    })


  }


  useEffect(() => {
    getResources()
    return () => {

    }
  }, [])

  useEffect(() => {
    const savedState = localStorage.getItem('dashboardFilters');

    if (!savedState) {
      // if (!transactionFilters) {
      loadData()
    }

  }, [pageNumber, pageSize, reload]);

  useEffect(() => {
    const savedState = localStorage.getItem('dashboardFilters');
    if (savedState) {
      const savedStateJson = {
        ...JSON.parse(savedState)
      }

      setFilters({
        ...filters,
        ...savedStateJson,
        creationDateFrom: new Date(savedStateJson?.creationDateFrom),
        creationDateTo: new Date(savedStateJson?.creationDateTo),

      })

      localStorage.clear();

      setTimeout(() => {
        // Code to execute after waiting for one second
        // ...
        setReload(!reload)
      }, 1000); // 1000 milliseconds = 1 second



    }
    // if (transactionFilters) {
    //   setFilters({
    //     ...filters,
    //     ...transactionFilters
    //   })

    //   dispatch(setTransactionFilters(null))
    //   setReload(!reload)
    // }
  }, [])

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }
  const loadData = async (searchParams) => {


    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        ...headerObject,
        ...(filters?.export_excel || filters?.export_pdf ? {} :
          {
            page: pageNumber,
            page_size: pageSize,
          }),
        ...(props?.noUnit ? {
          no_unit: 1,
        } : {}),
        search: "",
        ...searchParams,
        ...filters,
        fast:fast
      };

      setLoading(true);

      const result = await transactionAPI.transactions({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data?.data) {


        if (filters?.export_excel) {
          setFilters({
            ...filters,
            export_excel: false
          })
          downloadExcel(result?.data?.data)
        }
        else if (filters?.export_pdf) {
          setFilters({
            ...filters,
            export_pdf: false
          })
          downloadPDF(result?.data?.data)
        }
        else {
          if (result?.data?.data?.data?.length > 0) {
            setRows(result?.data?.data?.data);
            setRowsTotal(result?.data?.data?.total_records);
            setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
          }
          else {
            setRows([]);
            setRowsTotal(0);
            setPageCount(0);
          }
        }
      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };

  const createRouteFunction = (obj) => {
    setselectedObject(obj)
    setOpenCreateRouteForm(true)
  }

  const auditFunction = (obj) => {
    setselectedObject(obj)
    setOpenAuditForm(true)
  }
  const createFunction = () => {
    setselectedObject(null);
    setOpenForm(true);
  };
  const showStopsFunction = (obj) => {
    setselectedObject(obj)
    setShowStops(true)
  }
  const showRoutesFunction = (obj) => {
    setselectedObject(obj)
    setShowRoutes(true)
  }
  const viewFunction = (obj) => {
    setselectedObject(obj)
    setOpenViewForm(true)
  }
  const UpdateFunction = (obj) => {
    setselectedObject(obj)
    setOpenUpdateForm(true)
  }
  const exportReport = (object) => {
    confirmExportReport(object)

    // swal({
    //   title: t('GLOBAL.are_you_sure'),
    //   text: t('TRANSACTIONS.start_export_report'),
    //   icon: 'info',
    //   buttons: [t('GLOBAL.no'), t('GLOBAL.yes')],
    //   dangerMode: true,
    // }).then(async (updateWithWialonLink) => {

    //   if (updateWithWialonLink) {
    //     confirmExportReport(object)
    //   } else {
    //     // alert('canceled')
    //   }
    // })


  }

  const createReport = async (object) => {
    setLoading(true)
    let readyToSendObject = {}

    let params = {
      ...headerObject
    };

    let result = null;
    if (object && object?.id) {
      readyToSendObject["transaction_id"] = object?.id;

      try {
        result = await transactionAPI.addCreatedgReport({
          data: readyToSendObject,
          params: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (result?.status && result?.data?.status) {
          toast.success("تم انشاء تقرير")
          setLoading(false)
        } else {

          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {
            setLoading(false)
            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }

      } catch (error) {
        setLoading(false)

        toast.error(error.message);
      }
    }
    setLoading(false)
  }

  const confirmExportReport = async (object) => {
    setLoading(true)
    let readyToSendObject = {}

    let params = {
      ...headerObject
    };

    let result = null;
    if (object && object?.id) {
      readyToSendObject["transaction_id"] = object?.id;

      try {
        result = await transactionAPI.addPenddingReport({
          data: readyToSendObject,
          params: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (result?.status && result?.data?.status) {




          const fileResult = await transactionAPI.downloadPenddingReport({
            params: {
              ...params,
              pending_transaction_report_id: result?.data?.data?.id
            }
          })

          if (fileResult?.status && fileResult?.data?.status) {

            downloadPDF(fileResult?.data?.data?.report_path, object)
          }
          else {
            toast.error(fileResult?.data?.data?.message?.text ? fileResult?.data?.data?.message?.text : fileResult?.data?.data?.message)
            setLoading(false)
          }


        } else {


          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }

      } catch (error) {
        setLoading(false)

        toast.error(error.message);
      }
    }
  }
  const downloadPDF = async (fileName, transaction) => {
    const result = await transactionAPI.downloadTransactionPdf({
      params: {
        ...headerObject,
        fileName: fileName
      }
    })
    setLoading(false)
    if (result.status && result?.data) {
      const url = window.URL.createObjectURL(new Blob([result?.data]));
      const link = document.createElement('a');
      link.href = url;
      const reportName = ` (${transaction?.transaction_number ? transaction?.transaction_number : " 000"}) حسب المستند  (${transaction?.unit_name ? transaction?.unit_name : ""}) مراقبة المركبة `
      link.setAttribute('download', reportName + '.pdf');
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    }

  }
  const exportFunction = () => {

    setFilters({
      ...filters,
      export_excel: true
    })
    setReload(!reload)
  }
  const exportPDF = () => {
    setFilters({
      ...filters,
      export_pdf: true
    })
    setReload(!reload)
  }

  const clearFilterFunction = () => {
    setFilters({
      name: "",
      code: "",
      transaction_id: "",
      transaction_number: "",
      type_id: null,
      wialon_id: null,
      export_excel: false,
      export_pdf: false,
      creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
      creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
      creationDateFrom: yesterday,
      creationDateTo: todayEnd,
      export_excel: false,
      note: "",
      destination_geofence_name: "",
      source_geofence_name: "",
      all_time: 1
    })
  }
  const searchFunction = () => {
    setReload(!reload)
    setPageNumber(1)
  };

  const downloadExcel = async (fileName) => {
    const result = await transactionAPI?.downloadExcel({
      params: {
        ...headerObject,
        fileName: fileName
      }
    })
    if (result.status && result?.data) {
      var _url = window.URL.createObjectURL(result?.data);
      window.open(_url, "_blank").focus(); // window.open + focus
    }
  }

  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >
      {showStops ? (
        <TransactionStops

          object={selectedObject}
          openForm={showStops}
          setOpenForm={setShowStops}
          loadData={loadData}
        />
      ) : null}
      {showRoutes ? (
        <TransactionRoutes
          object={selectedObject}
          openForm={showRoutes}
          setOpenForm={setShowRoutes}
          loadData={loadData}
        />
      ) : null}
      {openForm ? (
        <FormContainer
          object={selectedObject}
          openForm={openForm}
          setOpenForm={setOpenForm}
          loadData={loadData}
        />
      ) : null}
      {openViewForm && selectedObject ? (
        <ViewFormContainer
          object={selectedObject}
          openForm={openViewForm}
          setOpenForm={setOpenViewForm}
        />
      ) : null}
      {openUpdateForm && selectedObject ? (
        <UpdateFormContainer
          object={selectedObject}
          openForm={openUpdateForm}
          setOpenForm={setOpenUpdateForm}
          loadData={loadData}
        />
      ) : null}
      {openAuditForm && selectedObject ? (
        <AuditFormContainer
          object={selectedObject}
          openForm={openAuditForm}
          setOpenForm={setOpenAuditForm}
          loadData={loadData}
        />
      ) : null}

      {openCreateRouteForm ? (
        <CreateRouteFormContainer
          object={true}
          transaction={selectedObject}
          objectType={"transaction"}
          openForm={openCreateRouteForm}
          setOpenForm={setOpenCreateRouteForm}
          loadData={loadData}
        />
      ) : null}

      <Title
        createFunction={createFunction}
        openFilterColumn={openFilterColumn}
        reload={reload}
        setReload={(value) => {
          setReload(value)

        }}
        fast={fast}
        setFast={setFast}
        title={props?.noUnit?" بدون مركبة":""}
      />
      <Grid
        rows={rows}
        multipleExports={
          // rolesRedux?.export_transaction?.value?
          // [
          //   { icon: <SiMicrosoftexcel color="green" size={"25"} />, name: t('TRANSACTIONS.exportFullExcel'), key: 'report1', onClick: () => { exportFunction() } },
          //   { icon: <BsFilePdfFill color="red" size={"25"} />, name: t('TRANSACTIONS.exportByTransactionNumber'), key: 'report2', onClick: () => { exportPDF()  } },
          //   { icon: <BsFilePdfFill color="red" size={"25"} />, name: t('TRANSACTIONS.exportByIsMonitored'), key: 'report3', onClick: () => { console.log("report_3"); } },
          //   { icon: <BsFilePdfFill color="red" size={"25"} />, name:  t('TRANSACTIONS.exportBySourceGeofence'), key: 'report4', onClick: () => { console.log("report_4"); } },
          //   { icon: <BsFilePdfFill color="red" size={"25"} />, name:  t('TRANSACTIONS.exportByDestinationGeofence'), key: 'report4', onClick: () => { console.log("report_4"); } },
          // ]
          // :
          []
        }
        fixHeight={'calc(100vh - 300px)'}
        getRowClassName={getRowClassName}
        hasExport={true}
        exportFunction={exportFunction}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={false}
        creatFunction={createFunction}

        hasColumnsHidShow={true}
        setColumns={setColumns}

        filterChilds={
          <Search
            filters={filters}
            setFilters={setFilters}
            loadingBranches={loadingBranches}
            branches={branches}
            loadingPortCategories={loadingPortCategories}
            portCategories={portCategories}
            transactionStatuses={transactionStatuses}
            loadingTransactionStatuses={loadingTransactionStatuses}
            users={users}
            loadingUsers={loadingUsers}
            transactionType2List={transactionType2List}
            loadingTransactionType2List={loadingTransactionType2List}
          />
        }
      />

    </Box>
  );
}
