import React, { useState, useEffect, useRef } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import LocalParkingIcon from '@mui/icons-material/LocalParking';
import {
  Box,
  Alert,
  AlertTitle,
  TextField,
  Button,
  Tooltip,
  IconButton
} from "@mui/material";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import transactionAPI from "../../Network/Transaction";
import Title from "./Title";
import { selectRoles } from "../../reduxStore/RolesReducer";

import moment from "moment";
import { selectTransactionFilter } from "../../reduxStore/SettingsReducer";

import LocationOnIcon from '@mui/icons-material/LocationOn';

import { SiMicrosoftexcel } from 'react-icons/si'
import { BsFilePdfFill } from 'react-icons/bs'
import geofenceAPI from "../../Network/Geofence";
import CustomeSelectField from "../../Components/CustomeSelectField";
import helpers from "../../assets/js/helper";
import CustomDateTimeField from "../../Components/CustomDateTimeField";
import CustomDateField from "../../Components/CustomDateField";
import Stops from "./Stops/Stops";

const cookies = new Cookies();

const todayStart = new Date();
const yesterday = new Date(todayStart);
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(0, 0, 0, 0);

const todayEnd = new Date();
todayEnd.setHours(23, 59, 59, 999);
export default function ReportStopsInfo() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const transactionFilters = useSelector(selectTransactionFilter)
  const [branches, setBranches] = useState([])
  const [loadingBranches, setLoadingBranches] = useState(true)

  const timerSourceGeofences = useRef(null)
  const timerDestinationGeofences = useRef(null)

  const [sourceGeofences, setSourceGeofences] = useState([])
  const [destinationGeofences, setDestinationGeofences] = useState([])

  const [loadingSourceGeofences, setLoadingSourceGeofences] = useState(false)
  const [loadingDestinationGeofences, setLoadingDestinationGeofences] = useState(false)

  const [searchTermSourceGeofence, setSearchTermSourceGeofence] = useState("");
  const [searchTermDestinationGeofence, setSearchTermDestinationGeofence] = useState("");

  const [transactionProductTypes, setTransactionProductTypes] = useState([])
  const [loadingTransactionProductTypes, setLoadingTransactionProductTypes] = useState(true)

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);
  const [showAlert, setShowAlert] = useState(false)
  const [filters, setFilters] = useState({
    unit: null,
    unit_id: "",
    sourceGeofence: null,
    source_geofence_id: "",
    destinationGeofence: null,
    destination_geofence_id: "",
    route: null,
    route_id: "",
    reportDate: moment(),
    report_date: moment().format('YYYY-MM-DD'),
    creationDateFrom: yesterday,
    creationDateTo: todayEnd,
    export_excel: false,
    export_pdf: false,
    note: "",
    destination_geofence_name: "",
    source_geofence_name: "",
    all_time: 0
  })
  const [reload, setReload] = useState(false)



  const [openForm, setOpenForm] = useState(false);
  const [openViewForm, setOpenViewForm] = useState(false);
  const [selectedObject, setselectedObject] = useState(null);
  const [showStops, setShowStops] = useState(false)
  const [showRoutes, setShowRoutes] = useState(false)

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const rolesRedux = useSelector(selectRoles)

  const [columns, setColumns] = useState([
    { field: "index_column", headerName: "ت", minWidth: 50, maxWidth: 60 },
    { field: "unit_name", headerName: t("TRANSACTIONS.unit_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "source_name", headerName: t("TRANSACTIONS.source_geofence_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "destination_name", headerName: t("TRANSACTIONS.destination_geofence_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "begining_trip_time_string", headerName: t("TRANSACTIONS.begining_trip_time_string"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "end_trip_time_string", headerName: t("TRANSACTIONS.end_trip_time_string"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "trip_duration", headerName: t("TRANSACTIONS.trip_duration"), minWidth: 110, maxWidth: 150, flex: 2 },
    // { field: "total_stops", headerName: t("TRANSACTIONS.total_stops"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "park_duration_str", headerName: t("TRANSACTIONS.park_duration_str"), minWidth: 110, maxWidth: 150, flex: 2 },
    {
      field: "total_stops", headerName: t("TRANSACTIONS.total_stops"), minWidth: 110, maxWidth: 150, flex: 3,
      renderHeader: (rows) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Tooltip
            sx={{
              borderWidth: '3px',
              borderColor: '#FFFFFF',
              borderStyle: 'solid',
              borderRadius: '50%'
            }}
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("TRANSACTIONS.total_stops")}
              </span>
            }
          >
            <LocalParkingIcon />
          </Tooltip>
        </Box>
      ),
      renderCell: (rows) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("TRANSACTIONS.total_stops")}
                </span>
              }
            >
              <IconButton
                onClick={() => { showStopsFunction(rows?.row) }}
                sx={{
                  fontSize: '16px',
                  color: '#000000DE'
                }}
              >
                {rows?.row?.total_stops}
              </IconButton>
            </Tooltip>
          </Box>

        )
      }
    },

  ])

  const showStopsFunction = (obj) => {
    setselectedObject(obj)
    setShowStops(true)
  }

  const handleSearchTermSourceGeofenceChange = (newValue) => {

    setSearchTermSourceGeofence(newValue);
    if (timerSourceGeofences.current) {
      clearTimeout(timerSourceGeofences?.current)
    }
    timerSourceGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingSourceGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [1, 3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingSourceGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setSourceGeofences(result?.data?.data?.data)
        }
      }
      else {
        setSourceGeofences([])
      }
    }, 1000)
  };
  const handleSearchTermDestinationGeofenceChange = (newValue) => {

    setSearchTermDestinationGeofence(newValue);
    if (timerDestinationGeofences.current) {
      clearTimeout(timerDestinationGeofences?.current)
    }
    timerDestinationGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingDestinationGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [2, 3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingDestinationGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setDestinationGeofences(result?.data?.data?.data)
        }
      }
      else {
        setDestinationGeofences([])
      }
    }, 1000)
  };

  useEffect(() => {

    loadData()


  }, [pageNumber, pageSize, reload]);


  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }
  const loadData = async (searchParams) => {

    if (!filters?.sourceGeofence?.name || !filters?.destinationGeofence?.name || !filters?.report_date) {
      setShowAlert(true)
      return
    }
    else {
      setShowAlert(false)
    }

    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        ...headerObject,


      };
      let data = {
        ...(filters?.export_excel || filters?.export_pdf ? {} :
          {
            page: pageNumber,
            page_size: pageSize,
          }),

        search: "",
        ...searchParams,
        ...filters,

      };



      setLoading(true);

      const result = await transactionAPI.insertStopsInfoReport({
        params: object,
        data: data,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data?.data) {


        if (filters?.export_excel) {
          setFilters({
            ...filters,
            export_excel: false
          })
          downloadExcel(result?.data?.data)
        }
        else if (filters?.export_pdf) {
          setFilters({
            ...filters,
            export_pdf: false
          })
          downloadPDF(result?.data?.data)
        }
        else {
          if (result?.data?.data?.data?.length > 0) {
            let data = []

            if (result?.data?.data?.data?.length > 1) {
              let dataResult = result?.data?.data?.data
              let rowStyleClassNumber = true

              data.push({
                ...dataResult[0],
                rowStyleClassNumber: rowStyleClassNumber
              })
              for (let index = 1; index < dataResult?.length; index++) {
                const currentRow = dataResult[index];
                const previousRow = dataResult[index - 1];
                if (currentRow?.destination_geofence_id != previousRow?.destination_geofence_id) {
                  rowStyleClassNumber = !rowStyleClassNumber
                }
                data.push({
                  ...currentRow,
                  rowStyleClassNumber: rowStyleClassNumber
                })
              }
            }
            else {
              data = result?.data?.data?.data
            }
            console.log(data);
            setRows(data);
            setRowsTotal(result?.data?.data?.total_records);
            setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
          }
          else {
            setRows([]);
            setRowsTotal(0);
            setPageCount(0);
          }
        }
      } else {
        setFilters({
          ...filters,
          export_pdf: false,
          export_excel: false
        })

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };

  const createFunction = () => {
    setselectedObject(null);
    setOpenForm(true);
  };



  const downloadPDF = async (data, transaction) => {
    const result = await transactionAPI.downloadPdf({
      params: {
        ...headerObject,
        fileName: data?.result
      }
    })
    setLoading(false)
    if (result.status && result?.data) {
      const url = window.URL.createObjectURL(new Blob([result?.data]));
      const link = document.createElement('a');
      link.href = url;
      let reportName = t('TRANSACTIONS.reportBySourceAndProductType')
      if (filters?.sourceGeofence?.name) {
        reportName += (" : " + filters?.sourceGeofence?.name)
      }

      link.setAttribute('download', reportName + '.' + data?.type);
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    }

  }
  const exportFunction = () => {

    setFilters({
      ...filters,
      export_excel: true
    })
    setReload(!reload)
  }
  const exportPDF = () => {
    setFilters({
      ...filters,
      export_pdf: true
    })
    setReload(!reload)
  }

  const clearFilterFunction = () => {
    setFilters({
      name: "",
      code: "",
      transaction_id: "",
      transaction_number: "",
      type_id: null,
      wialon_id: null,
      export_excel: false,
      export_pdf: false,
      creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
      creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
      creationDateFrom: yesterday,
      creationDateTo: todayEnd,
      export_excel: false,
      note: "",
      destination_geofence_name: "",
      source_geofence_name: "",
      all_time: 1
    })
  }
  const searchFunction = () => {
    setReload(!reload)
    setPageNumber(1)
  };

  const downloadExcel = async (fileName) => {
    const result = await transactionAPI?.downloadExcel({
      params: {
        ...headerObject,
        fileName: fileName
      }
    })
    if (result.status && result?.data) {
      var _url = window.URL.createObjectURL(result?.data);
      window.open(_url, "_blank").focus(); // window.open + focus
    }
  }

  const getTitleAndSecondaryTitle = () => {
    let title = t('SIDEMENU.report_stops_info')



    let secondaryTitle = ""

    return {
      title: title,
      secondaryTitle: secondaryTitle
    }
  }


  const getRowClassName = (params) => {


    // if (params?.row?.rowStyleClassNumber) {

    //   return 'highlighted-gray-row'
    // }
    // else {
    //   return null
    // }
  };
  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >

      {showStops ? (
        <Stops
          openForm={showStops}
          setOpenForm={setShowStops}
          data={selectedObject?.details}
        />
      ) : null}
      <Title
        title={getTitleAndSecondaryTitle()?.title}
        secondaryTitle={getTitleAndSecondaryTitle()?.secondaryTitle}
      />
      {showAlert ?
        <Alert sx={{
          fontFamily: 'Cairo',
          width: '100%'
        }} severity="warning">
          <AlertTitle sx={{
            fontFamily: 'Cairo'
          }}>تنبيه</AlertTitle>
          يجب اختيار  <strong>جميع الحقول</strong> للبحث عن البيانات
        </Alert>
        : null
      }
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '10px',
          marginBottom: '10px',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CustomeSelectField
          label={t("TRANSACTIONS.source_geofence")}
          haswidth={true}
          onInputChange={handleSearchTermSourceGeofenceChange}
          inputValue={searchTermSourceGeofence}
          loading={loadingSourceGeofences}
          loadingText={t('GLOBAL.searching')}
          value={filters?.sourceGeofence}
          list={sourceGeofences}
          error={false}
          message={""}
          readOnly={false}
          onChange={(e, v) => {
            setFilters({
              ...filters,
              sourceGeofence: v,
              source_geofence_id: v ? v?.id : ""
            })
          }}
          onClearClick={() => {
            setFilters({
              ...filters,
              sourceGeofence: null,
              source_geofence_id: ""
            })

          }}
        />
        <CustomeSelectField
          label={t("TRANSACTIONS.source_geofence")}
          haswidth={true}
          onInputChange={handleSearchTermDestinationGeofenceChange}
          inputValue={searchTermDestinationGeofence}
          loading={loadingDestinationGeofences}
          loadingText={t('GLOBAL.searching')}
          value={filters?.destinationGeofence}
          list={destinationGeofences}
          error={false}
          message={""}
          readOnly={false}
          onChange={(e, v) => {
            setFilters({
              ...filters,
              destinationGeofence: v,
              destination_geofence_id: v ? v?.id : ""
            })
          }}
          onClearClick={() => {
            setFilters({
              ...filters,
              destinationGeofence: null,
              destination_geofence_id: ""
            })

          }}
        />
        <CustomDateField
          label={t('TRANSACTIONS.report_date')}
          value={filters?.reportDate}
          error={false}
          message={""}
          haswidth={true}
          readOnly={false}
          minDate={null}
          maxDate={null}
          onChange={(e) => {
            setFilters({
              ...filters,
              reportDate: e,
              report_date: helpers.formatDateTime(e)
            })

          }}
          onClearClick={() => {
            setFilters({
              ...filters,
              reportDate: null,
              report_date: null
            })
          }}
        />

        <Button
          disabled={loading}
          variant="contained"
          // spacing={2}
          sx={{
            // flexGrow: 1,
            margin: 1,
            width: "80px !important",
            minWidth: "80px !important",
            maxWidth: "80px !important",
            backgroundColor: "#e7a52a",
            "&:hover": {
              //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
            },
            height: "35px",
            fontFamily: "Cairo-Bold",
          }}
          className="iconeFilterSearch"
          onClick={() => {
            loadData({ generateReport: true })
          }}
        >
          {t("GLOBAL.SEARCH_SEARCH_BUTTON")}
        </Button>
      </Box>
      <Grid
        dosntHaveSearch={true}
        rows={rows}
        multipleExports={rolesRedux?.export_transaction?.value ?
          [
            { icon: <SiMicrosoftexcel color="green" size={"25"} />, name: t('TRANSACTIONS.exportExcel'), key: 'report1', onClick: () => { exportFunction() } },
            { icon: <BsFilePdfFill color="red" size={"25"} />, name: t('TRANSACTIONS.exportPDF'), key: 'report4', onClick: () => { exportPDF() } },
          ] : []
        }
        fixHeight={'calc(100vh - 300px)'}
        getRowClassName={getRowClassName}
        hasExport={false}
        exportFunction={exportFunction}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={false}
        creatFunction={createFunction}

        hasColumnsHidShow={false}
        setColumns={setColumns}

        filterChilds={
          <Search
            filters={filters}
            setFilters={setFilters}
            loadingBranches={loadingBranches}
            branches={branches}
            transactionProductTypes={transactionProductTypes}
            loadingTransactionProductTypes={loadingTransactionProductTypes}
            showBySequence={
              {
                sourceGeofence: 1,
                destinationGeofence: 2,
                report_date: 3
              }
            }


          />
        }
      />

    </Box>
  );
}
