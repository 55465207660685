import { Box, FormControlLabel, Switch, Grid, Tooltip, IconButton } from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import CustomTextField from '../../../Components/CustomTextField';
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GeofencesForGroup from './GeofencesForGroup';
import { useSelector } from 'react-redux';
import ImportFormContainer from '../../Geofences/ImportForm/ImportFormContainer';

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector(state => state.themeData.gridtheme)
  const [openImportForm, setOpenImportForm] = useState(false)
  const [importedSelectedData, setImportedSelectedData] = useState([])
  const fileData = (data) => { 
    setImportedSelectedData(data?.map(item=>item[1]))
    setOpenImportForm(false)
  }
  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.9)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "21120",
        }}
        onClick={() => {
          props?.setOpen(false);
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>

      <Grid container spacing={2} sx={{
        marginTop: '10px'
      }}>
        <Grid xs={12} sm={6} md={4} lg={4} sx={{
          padding: '0px'
        }}>
          <CustomTextField
            label={t("GEOFENCE_GROUPS.name")}
            value={props?.object?.name?.value}
            error={props?.object?.name?.error}
            message={props?.object?.name?.message}
            haswidth={true}
            readOnly={false}
            focused={true}
            onChange={(e) => {

              props?.setObject({
                ...props?.object,
                name: {
                  ...props?.object?.name,
                  value: e?.target?.value,
                  error: false,
                  message: []
                }
              })

            }}
            onClearClick={() => {
              props?.setObject({
                ...props?.object,
                name: {
                  ...props?.object?.name,
                  value: "",
                  error: false,
                  message: []
                }
              })
            }}
          />
        </Grid>


        <Grid xs={12} sm={6} md={4} lg={4} sx={{
          padding: '0px'
        }}>
          <Box
            sx={{
              backgroundColor: '#fff',
              color: '#000',
              // borderRadius:'10px',
              height: '55px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              width: '93% !important',
              boxShadow: '0px 6px 20px 3px #00000014 !important',
              margin: 'auto',

              paddingLeft: '15px',
              "& .MuiTypography-root": {
                fontFamily: 'Cairo-Medium'
              }

            }}
          >
            <FormControlLabel control={<Switch checked={props?.object?.enabled?.value == 1} onChange={(e) => {

              props?.setObject({
                ...props?.object,
                enabled: {
                  ...props?.object?.enabled,
                  value: e.target.checked,
                  error: false,
                  message: []
                }
              })
            }} />} sx={{ fontFamily: 'Cairo-Medium' }} label={!props?.enabled?.value ? t('GEOFENCE_GROUPS.enabled') : t('GEOFENCE_GROUPS.disabled')} />

          </Box>
        </Grid>
        <Grid xs={12} sm={6} md={4} lg={4} sx={{
          padding: '0px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}>
          <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("GLOBAL.import_selected_data")}</span>}>
            <IconButton
              sx={{
                padding: "2px",
                marginRight: '10px',
                backgroundColor: gridtheme?.gridHeader,
                ":hover": {
                  backgroundColor: gridtheme?.gridHeader + "AA",
                }
              }}
              onClick={() => {
                setOpenImportForm(true)
              }}

            >
              <FileUploadIcon
                sx={{
                  fontSize: "40px",
                  color: gridtheme?.colorWhite
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <GeofencesForGroup
        listOfSelectedRows={props?.listOfSelectedRows}
        setListOfSelectedRows={props?.setListOfSelectedRows}
        importedSelectedData={importedSelectedData}
      />
      {openImportForm ? (
        <ImportFormContainer
          object={null}
          openForm={openImportForm}
          setOpenForm={setOpenImportForm}
          fileData={fileData}
        />
      ) : null}

    </Box >
  );
};

export default RenderContent