import React, { useState, useEffect } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDownGrid from "../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Typography,
  Tooltip
} from "@mui/material";

import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import routeAPI from "../../Network/Route";
import FormContainer from "./Form/FormContainer";
import Title from "./Title";
import helpers from "../../assets/js/helper";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { selectRoles } from "../../reduxStore/RolesReducer";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';
import swal from "sweetalert";

const cookies = new Cookies();

export default function RoutesPage() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);

  const [filters, setFilters] = useState({
    name: "",
    code: "",
    wialon_id: null,
    export_excel: false
  })
  const [reload, setReload] = useState(false)

  const [openForm, setOpenForm] = useState(false);
  const [selectedObject, setselectedObject] = useState(null);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const rolesRedux = useSelector(selectRoles)

  const columns = [
    { field: "index_column", headerName: "ت", minWidth: 50, maxWidth: 60 },
    { field: "id", headerName: "#", minWidth: 50, maxWidth: 60 },
    { field: "name", headerName: t("ROUTES.name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "code", headerName: t("ROUTES.code"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "source_geofence_name", headerName: t("ROUTES.source_geofence_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "destination_geofence_name", headerName: t("ROUTES.destination_geofence_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "routes_count", headerName: t("ROUTES.routes_count"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "note", headerName: t("ROUTES.note"), minWidth: 110, maxWidth: 150, flex: 2 },
    {
      field: "last_updated", headerName: t("ROUTES.last_updated"), minWidth: 110, maxWidth: 150, flex: 1,
      renderCell: (rows) => {

        
        return (
          <Typography
          sx={{
            fontFamily:'Cairo'
          }}
          >
            {rows?.row?.formatted_updated_at?rows?.row?.formatted_updated_at:rows?.row?.formatted_datetime}
          </Typography>
        )
      }
    },

    {
      field: "linked_to_wialon", headerName: t("ROUTES.linked_to_wialon"), minWidth: 50, maxWidth: 50, flex: 1,
      renderCell: (rows) => {

        if (rows?.row?.resource_id && rows?.row?.geofence_id) {
          return (
            <Box>
              <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("GEOFENCES.linked_to_wialon")}</span>}>
                <LinkIcon sx={{ fill: 'green' }} />
              </Tooltip>
            </Box>
          )
        }
        return (
          <Box>
            <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("GEOFENCES.not_linked_to_wialon")}</span>}>
              <LinkOffIcon sx={{ fill: 'red' }} />
            </Tooltip>
          </Box>
        )
      }
    },
    {
      headerName: t("ROUTES.actions"),
      field: "Actions",
      flex: 0.4,
      minWidth: 100,
      // hide: !rolesRedux?.update_route?.value,
      renderCell: (rows) => (
        rolesRedux?.update_route?.value ?
          <DropDownGrid>
            <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
              <ListItemIcon sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px 7px 0 0'
              }}>
                {rows?.row?.geofence_id ?
                  <LinkOffIcon style={{ fill: "#ff0000" }} /> :
                  <LinkIcon style={{ fill: "#17681b" }} />}
               </ListItemIcon>
              {rows?.row?.geofence_id ?
                t('ROUTES.unlink') : t('ROUTES.link')}
            </MenuItem>
            <MenuItem onClick={() => { updateEnableFunction(rows?.row) }} className="ActionIcons">
              <ListItemIcon sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px 7px 0 0'
              }}>
                {rows?.row?.enabled ?
                  <CancelIcon style={{ fill: "#ff0000" }} /> :
                  <CheckCircleIcon style={{ fill: "#17681b" }} />}


              </ListItemIcon>
              {rows?.row?.enabled ?
                t('ROUTES.disable') : t('ROUTES.enable')}
            </MenuItem>

          </DropDownGrid> : null
      ),
    },
  ]
  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }

  useEffect(() => {
    loadData()
  }, [pageNumber, pageSize, reload]);


  const loadData = async (searchParams) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        ...headerObject,
        ...(filters?.export_excel ? {} :
          {
            page: pageNumber,
            page_size: pageSize,
          }),
        search: "",
        ...searchParams,
        ...filters
      };

      setLoading(true);

      const result = await routeAPI.routes({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data?.data) {


        if (filters?.export_excel) {
          setFilters({
            ...filters,
            export_excel: false
          })
          downloadExcel(result?.data?.data)
        }
        else {
          if (result?.data?.data?.data?.length > 0) {
            setRows(result?.data?.data?.data);
            setRowsTotal(result?.data?.data?.total_records);
            setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
          }
          else {
            setRows([]);
            setRowsTotal(0);
            setPageCount(0);
          }
        }
      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };


  const createFunction = () => {
    setselectedObject(null);
    setOpenForm(true);
  };
  const updateFunction = (obj) => {
    if (obj?.geofence_id) {
      swal({
        title: t('GLOBAL.are_you_sure'),
        text: t('ROUTES.wialon_link_will_be_removed'),
        icon: 'warning',
        buttons: [t('GLOBAL.no'), t('GLOBAL.yes')],
        dangerMode: true,
      }).then(async (updateWithWialonLink) => {

        if (updateWithWialonLink) {
          unlinkObject(obj)
        } else {
          // alert('canceled')
        }
      })
    }
    else {
      setselectedObject(obj)
      setOpenForm(true)
    }

  }

  const unlinkObject = async (object) => {
    let readyToSendObject = {}
    if (object?.geofence_id) {

      readyToSendObject["resource_id"] = null;
      readyToSendObject["geofence_id"] = null;
      readyToSendObject["update_wialon_link"] = 1;
    }

    let params = {
      ...headerObject
    };

    let result = null;
    if (object && object?.id) {
      readyToSendObject["id"] = object?.id;

      try {
        result = await routeAPI.editRoute({
          data: readyToSendObject,
          params: params,
          id: object?.id,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (result?.status && result?.data?.status) {



          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));

          loadData();

        } else {


          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }

      } catch (error) {


        toast.error(error.message);
      }
    }
  }

  const updateEnableFunction = (obj) => {

    swal({
      title: t('GLOBAL.are_you_sure'),
      text: obj?.enabled ? t('ROUTES.route_will_be_disabled') : t('ROUTES.route_will_be_enabled'),
      icon: 'warning',
      buttons: [t('GLOBAL.no'), t('GLOBAL.yes')],
      dangerMode: true,
    }).then(async (updateWithWialonLink) => {

      if (updateWithWialonLink) {
        updateEnableRoute(obj)
      } else {
        // alert('canceled')
      }
    })


  }
  const updateEnableRoute = async (route) => {
    let readyToSendObject = {}
    readyToSendObject["enabled"] = route?.enabled == 1 ? 0 : 1;


    let params = {
      username: cookies.get("user_name"),
      user_id: cookies.get("user_id"),
      account_name: cookies.get("account_name"),
      account_id: cookies.get("account_id"),
      sid: cookies.get("sid"),
      baseurl: cookies.get("baseUrl"),
      id: cookies.get("id"),
    };

    let result = null;
    if (route && route?.id) {
      readyToSendObject["id"] = route?.id;

      try {
        result = await routeAPI.editRoute({
          data: readyToSendObject,
          params: params,
          id: route?.id,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (result?.status && result?.data?.status) {



          toast.success(t("NETWORK_MESSAGES.infoUpdatedSuccessfully"));

          loadData();

        } else {


          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }

      } catch (error) {


        toast.error(error.message);
      }
    }
  }
  const clearFilterFunction = () => {
    setFilters({
      name: "",
      code: "",
      wialon_id: null,
      export_excel: false
    })
  }
  const searchFunction = () => {
    setReload(!reload)
    setPageNumber(1)
  };
  const exportFunction = () => {

    setFilters({
      ...filters,
      export_excel: true
    })
    setReload(!reload)
  }
  const downloadExcel = async (fileName) => {
    const result = await routeAPI?.downloadExcel({
      params: {
        ...headerObject,
        fileName: fileName
      }
    })
    if (result.status && result?.data) {
      var _url = window.URL.createObjectURL(result?.data);
      window.open(_url, "_blank").focus(); // window.open + focus
    }
  }
  const getRowClassName = (params) => {
    if (params?.row?.enabled === 0) {
      return 'highlighted-disabled-row'
    }
    return ''
  };
  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >

      {openForm ? (
        <FormContainer
          object={selectedObject}
          openForm={openForm}
          setOpenForm={setOpenForm}
          loadData={loadData}
        />
      ) : null}

      <Title
        createFunction={createFunction}
        openFilterColumn={openFilterColumn}
      />
      <Grid
        rows={rows}
        hasExport={rolesRedux?.export_route?.value}
        exportFunction={exportFunction}
        getRowClassName={getRowClassName}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={false}
        creatFunction={createFunction}


        filterChilds={
          <Search
            filters={filters}
            setFilters={setFilters}
          />
        }
      />

    </Box>
  );
}
