import callAPI from '../ApiConfig'


const dashboard = async (props) => {


    return await callAPI({
        route: "transactions/dashboard",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }
    })

}




const dashboardAPI = {
    dashboard: dashboard,
}
export default dashboardAPI