
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import masterObject, {
  objectMerge
} from "../../../Errors/Masters/Master";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import geofenceGroupAPI from "../../../Network/GeofenceGroup";
import RenderFooter from "./RenderFooter";
import RenderContent from "./RenderContent";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};


export default function FormContainer(props) {
  const [t] = useTranslation("common");
  const [listOfSelectedRows, setListOfSelectedRows] = useState([])
  const [loading, setLoading] = useState(false);
  
  const [geofenceGroup, setGeofenceGroup] = useState(masterObject);
  const navigate = useNavigate();

  const submit = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {

        let checkErrorObject = {
          ...geofenceGroup
        }
        let hasError = false
        let readyToSendObject = {}
        if (geofenceGroup?.name?.value && geofenceGroup?.name?.value?.trim()?.length > 0) {
          let name = geofenceGroup?.name?.value?.trim()
          readyToSendObject["name"] = name?.replace(/\\|\/|\'/g, "");
          readyToSendObject["code"] = name?.replace(/\\|\/|\'/g, "");
        }
        else {
          hasError = true
          checkErrorObject['name']['error'] = true
          checkErrorObject['name']['message'] = [t('GLOBAL.mandatory_field')]
        }
        // if (geofenceGroup?.code?.value && geofenceGroup?.code?.value?.trim()?.length > 0) {
        //   let code = geofenceGroup?.code?.value?.trim()
        //   readyToSendObject["code"] = code?.replace(/\\|\/|\'/g, "");
        // }
        // else {
        //   hasError = true
        //   checkErrorObject['code']['error'] = true
        //   checkErrorObject['code']['message'] = [t('GLOBAL.mandatory_field')]
        // }

        if (listOfSelectedRows?.length > 0) {
          readyToSendObject["geofence_ids"] = listOfSelectedRows
        }
        else {
          hasError = true
          swal({
            title: t('GLOBAL.error'),
            text: t('GEOFENCE_GROUPS.empty_geofences_list'),
            icon: 'warning',

            dangerMode: true,
          })

        }


        let enabled = geofenceGroup?.enabled?.value ? 1 : 0
        readyToSendObject["enabled"] = enabled;



        if (hasError) {
          setGeofenceGroup({
            ...checkErrorObject
          })
          return
        }



        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
          id: cookies.get("id"),
        };

        let result = null;
        if (props?.object && props?.object?.id) {
          readyToSendObject["id"] = props?.object?.id;

          result = await geofenceGroupAPI.editGeofenceGroup({
            data: readyToSendObject,
            params: params,
            id: props?.object?.id,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        } else {

          result = await geofenceGroupAPI.addGeofenceGroup({
            data: readyToSendObject,
            params: params,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        }

        if (result?.status && result?.data?.status) {

          setLoading(false);

          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));
          clearForm();
          props.loadData();
          props.setOpenForm(false);
        } else {

          setLoading(false);

          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {
            if (result?.data?.data?.message?.text) {
              toast.error(result?.data?.data?.message?.text)
            }
            else {
              toast.error(result?.data?.data?.message)
            }

          }
        }
        setLoading(false);
      } catch (error) {

        setLoading(false);
        toast.error(error.message);
      }
    } else {

      // navigate('/redirect')
    }
  };

  const clearForm = () => {
    setGeofenceGroup(masterObject)
  };
  useEffect(() => {
    if (!props?.object) {
      clearForm();

    } else {
      loadingGroupGeofences(props?.object?.id)


    }
  }, [props?.openForm, props?.object]);


  const loadingGroupGeofences = async (id) => {
    
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        username: cookies.get("user_name") ? cookies.get("user_name") : null,
        user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
        account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
        account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
        authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
        sid: cookies.get("sid") ? cookies.get("sid") : null,
        baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
        id: cookies.get("id") ? cookies.get("id") : null,

      };

      setLoading(true);

      const result = await geofenceGroupAPI.geofenceGroup({
        params: object,
        data: {
          id: id
        },
      });
      setLoading(false);

      if (result.status && result?.data?.status) {
        setListOfSelectedRows(result?.data?.data?.geofence_ids)
        setGeofenceGroup(objectMerge(result?.data?.data))
      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };
  
  
  return (
    <PopupForm
      open={props.openForm}
      setOpen={props.setOpenForm}
      title={
        props?.object?.id
          ? t("GEOFENCE_GROUPS.update_title")
          : t("GEOFENCE_GROUPS.add_title")
      }
      isFullScreen={false}
      content={
        <RenderContent
          object={geofenceGroup}
          setObject={(value) => {
            setGeofenceGroup(value)
          }}
          setListOfSelectedRows={setListOfSelectedRows}
          listOfSelectedRows={listOfSelectedRows}
          open={props.openForm}
          setOpen={props.setOpenForm}
          isFullScreen={false}
        />
      }
      footer={
        <RenderFooter
          open={props.openForm}
          setOpen={props.setOpenForm}
          submit={submit}
          clearForm={clearForm}
        />
      }
    />
  );
}
