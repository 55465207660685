
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next"
import mainUnitObject, {
  checkErrors,
  objectMerge,
  resetErrors,
  resetObject,
} from "../../../Errors/MainUnits/MainUnit";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import mainUnitApi from "../../../Network/MainUnit";
import RenderFooter from "./RenderFooter";
import RenderContent from "./RenderContent";
const label = { inputProps: { "aria-label": "Switch demo" } };

const cookies = new Cookies();
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};


export default function FormContainer(props) {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [unit, setUnit] = useState(mainUnitObject);
  const navigate = useNavigate();

  const submitAndRefresh = () => { 
    submit(true)  
  }
  const submit = async (refreshFromWialon) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {


        let checkErrorObject = {
          ...unit
        }
        let hasError = false
        let readyToSendObject = {}
        if (unit?.receipt_number?.value  && unit?.receipt_number?.value?.trim()?.length>0) {
          let receipt_number = unit?.receipt_number?.value?.trim()
          readyToSendObject["receipt_number"] = receipt_number?.replace(/\\|\/|\'/g, "");
        }
        else{
          hasError=true
          checkErrorObject['receipt_number']['error']=true
          checkErrorObject['receipt_number']['message']=[t('GLOBAL.mandatory_field')]
        }
        if (unit?.numbering?.value  && unit?.numbering?.value?.trim()?.length>0) {
          let numbering = unit?.numbering?.value?.trim()
          readyToSendObject["numbering"] = numbering?.replace(/\\|\/|\'/g, "");
        }
        else{
          hasError=true
          checkErrorObject['numbering']['error']=true
          checkErrorObject['numbering']['message']=[t('GLOBAL.mandatory_field')]
        }

        // if (unit?.plate_number?.value  && unit?.plate_number?.value?.trim()?.length>0) {
        // let plate_number = unit?.plate_number?.value?.trim()
        // readyToSendObject["plate_number"] = plate_number?.replace(/\\|\/|\'/g, "");
        // }
        // else{
        //   hasError=true
        //   checkErrorObject['plate_number']['error']=true
        //   checkErrorObject['plate_number']['message']=[t('GLOBAL.mandatory_field')]
        // }


        if (unit?.plate_letter_id?.value?.id !== null) {
          readyToSendObject["plate_letter_id"] = unit?.plate_letter_id?.value?.id;
        }
        if (unit?.plate_province_id?.value?.id !== null) {
          readyToSendObject["plate_province_id"] = unit?.plate_province_id?.value?.id;
        }
        if (unit?.plate_type_id?.value?.id !== null) {
          readyToSendObject["plate_type_id"] = unit?.plate_type_id?.value?.id;
        }
        if (unit?.main_account_id?.value?.id !== null) {
          readyToSendObject["main_account_id"] = unit?.main_account_id?.value?.id;
        }
        if (unit?.tag_id?.value?.id !== null) {
          readyToSendObject["tag_id"] = unit?.tag_id?.value?.id;
        }
        let is_subscribed = unit?.is_subscribed?.value ? 1 : 0
        readyToSendObject["is_subscribed"] = is_subscribed;

        let is_installed = unit?.is_installed?.value ? 1 : 0
        readyToSendObject["is_installed"] = is_installed;
        let enabled = unit?.enabled?.value ? 1 : 0
        readyToSendObject["enabled"] = enabled;

        if (unit?.installation_date?.value && unit?.installation_date?.value_formated?.length > 0) {

          readyToSendObject["installation_date"] = unit?.installation_date?.value_formated;
        }
        // if (unit?.note?.value  && unit?.note?.value?.trim()?.length>0) {
        let note = unit?.note?.value?.trim()
        readyToSendObject["note"] = note?.replace(/\\|\/|\'/g, "");
        // }
        let tid = unit?.tid?.value?.trim()
        readyToSendObject["tid"] = tid?.replace(/\\|\/|\'/g, "");
        let epc = unit?.epc?.value?.trim()
        readyToSendObject["epc"] = epc?.replace(/\\|\/|\'/g, "");
        let company_name = unit?.company_name?.value?.trim()
        readyToSendObject["company_name"] = company_name?.replace(/\\|\/|\'/g, "");
        let driver_name = unit?.driver_name?.value?.trim()
        readyToSendObject["driver_name"] = driver_name?.replace(/\\|\/|\'/g, "");
        let plate_number = unit?.plate_number?.value?.trim()
        readyToSendObject["plate_number"] = plate_number?.replace(/\\|\/|\'/g, "");
        let plate_letter = unit?.plate_letter?.value?.trim()
        readyToSendObject["plate_letter"] = plate_letter?.replace(/\\|\/|\'/g, "");
        let plate_type = unit?.plate_type?.value?.trim()
        readyToSendObject["plate_type"] = plate_type?.replace(/\\|\/|\'/g, "");
        let plate_province = unit?.plate_province?.value?.trim()
        readyToSendObject["plate_province"] = plate_province?.replace(/\\|\/|\'/g, "");
        let name_and_type = unit?.name_and_type?.value?.trim()
        readyToSendObject["name_and_type"] = name_and_type?.replace(/\\|\/|\'/g, "");


        if (hasError) {
          setUnit({
            ...checkErrorObject
          })
          return
        }



        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
          id: cookies.get("id"),
        };

        let result = null;
        if (props?.object && props?.object?.id) {
          readyToSendObject["id"] = props?.object?.id;

          result = await mainUnitApi.editMainUnitTemp({
            data: readyToSendObject,
            params: params,
            id: props?.object?.id,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        } else {

          result = await mainUnitApi.addMainUnitTemp({
            data: readyToSendObject,
            params: params,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
        }

        if (result?.status && result?.data?.status) {

          setLoading(false);

          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));
          clearForm();
          if(refreshFromWialon)
          {
            props.loadAndRefreshData(unit);
          }
          else{
            props.loadData();
          }
          
          props.setOpenForm(false);
        } else {

          setLoading(false);

          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }
        setLoading(false);
      } catch (error) {

        setLoading(false);
        toast.error(error.message);
      }
    } else {

      // navigate('/redirect')
    }
  };

  const clearForm = () => {
    setUnit(mainUnitObject)
  };
  useEffect(() => {
    if (!props?.object) {
      clearForm();

    } else {

      setUnit(objectMerge(props?.object))
    }
  }, [props?.openForm, props?.object]);


  return (
    <PopupForm
      open={props.openForm}
      setOpen={props.setOpenForm}
      title={
        props?.object?.id
          ? t("UNITS.update_title")
          : t("UNITS.add_title")
      }
      isFullScreen={false}
      content={
        <RenderContent
          object={unit}
          setObject={(value) => {
            setUnit(value)
          }}
          open={props.openForm}
          setOpen={props.setOpenForm}
          isFullScreen={false}
          loadingPlateTypes={props?.loadingPlateTypes}
          loadingUsers={props?.loadingUsers}
          loadingPlateLetters={props?.loadingPlateLetters}
          loadingPlateProvinces={props?.loadingPlateProvinces}
          plateTypes={props?.plateTypes}
          plateProvinces={props?.plateProvinces}
          plateLetters={props?.plateLetters}
          users={props?.users}
        />
      }
      footer={
        <RenderFooter
          open={props.openForm}
          setOpen={props.setOpenForm}
          submit={submit}
          submitAndRefresh={submitAndRefresh}
          clearForm={clearForm}
        />
      }
    />
  );
}
