import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux'
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { useEffect } from 'react';
import { useState } from 'react';



const actions = [
  { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
];

export default function SpeedDialTooltipOpen(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const gridtheme = useSelector(state => state.themeData.gridtheme)
  const [topMargin, setTopMargin] = useState(props?.actions?.length ==2?128:182)
  

  console.log("props?.actions?.length",props?.actions?.length);
  
  
  return (

    <>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel={props?.label}
        sx={{
         
          marginRight: '10px',
          marginTop: topMargin+'px',
          '& .MuiSpeedDial-fab': {
            backgroundColor: gridtheme?.gridHeader,
            ":hover": {
              backgroundColor: gridtheme?.gridHeader + "AA",
            },
            padding: "2px",
            marginRight: '10px',
            height:'44px',
            width:'44px'
          },
          '& .MuiSpeedDialAction-staticTooltipLabel':{
            width:'300px',
            fontFamily:'Cairo'
          }

        }}
        icon={props?.icon}
        direction='down'
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {props?.actions.map((action) => (
          <SpeedDialAction
            key={action?.key}
            icon={action?.icon}
            tooltipTitle={action?.name}
            tooltipOpen

            onClick={action?.onClick}
          />
        ))}
      </SpeedDial>
    </>


  );
}
