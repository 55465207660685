import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StarIcon from "@mui/icons-material/Star";
import { BsFillCreditCardFill, BsPlus } from "react-icons/bs";
import { MdPhoneInTalk } from "react-icons/md";
import { IoDocumentTextSharp } from "react-icons/io5";
import Person2Icon from "@mui/icons-material/Person2";
import { useTranslation } from "react-i18next";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DeleteIcon from "@mui/icons-material/Delete";
import helpers from "../../assets/js/helper";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { toast } from "react-toastify";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import { useSelector } from "react-redux";
import { selectRoles } from "../../reduxStore/RolesReducer";
import CustomTabs from "./CustomTabs";
import CustomToggleStatus from "../CustomToggleStatus";
import CancelIcon from '@mui/icons-material/Cancel';
import CustomMultiStatusIcon from "../../Components/CustomMultiStatusIcon";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import VerifiedIcon from '@mui/icons-material/Verified';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DownloadIcon from '@mui/icons-material/Download';
import { viewExtra1ObjectKeys, viewExtra2ObjectKeys, viewExtra3ObjectKeys, viewObjectKeys } from "../../Errors/Transactions/Transaction";
import CustomTextField from "../CustomTextField";

const iconButtonSx = {
  backgroundColor: "#994D1E",
  boxShadow: "0px 3px 6px #00000029",
  width: "20px",
  height: "20px",
  "&:hover": {
    backgroundColor: "#994D1EA0",
  },
};
const primaryTextSX = {
  color: "#212121",
  fontFamily: "Cairo",
  fontSize: "13px",
  fontWeight: '600',
  direction: 'rtl',
  textAlign: 'center'
};

const secondaryTextSX = {
  color: "#777676",
  fontFamily: "Cairo-ExtraLight",
  fontSize: "12px",
  fontWeight: "600",
  direction: 'rtl',
  textAlign: 'center'
};

const buttonSX = {
  width: "164px",
  fontFamily: "Cairo",
  fontSize: "13px",
  backgroundColor: "#FFFFFF66",
  color: "#FFFFFF",
  marginLeft: "5px",
  border: "none",
};
const buttonHoverStyle = {
  backgroundColor: "#FFFFFF",
  color: "#994D1E",
};

const addButtonSX = {
  width: "max-content",
  fontFamily: "Cairo",
  fontSize: "13px",
  backgroundColor: "#FFFFFF66",
  color: "#994D1E",
  paddingLeft: "0px",
  paddingRight: "0px",
  border: "none",
};
const addButtonHoverStyle = {
  backgroundColor: "#FFFFFF",
  color: "#994D1EA0",
  border: "none",
};

export default function DataGridRow(props) {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const rolesRedux = useSelector(selectRoles)


  const handleClick = () => {
    props?.setClickedCollapsed(props?.row?.id);
    setOpen(!open);
    let arr = [];
    props?.rows &&
      props?.rows?.length > 0 &&
      props?.rows?.map((itm) => {
        if (itm?.id == props?.row?.id) {
          arr.push({
            ...itm,
            showCollapse: !open,
          });
        } else {
          arr.push({
            ...itm,
            showCollapse: false,
          });
        }
      });

    props?.setRows(arr);
    // if (!open) {
    //   props?.setCurrentUserOpen(props?.row?.id)
    // } else {
    //   props?.setCurrentUserOpen(null)
    //   props?.setCurrentTabOpen(1)
    // }

  };

  const [openDropDown, setOpenDropDown] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpenDropDown((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDropDown(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenDropDown(false);
    } else if (event.key === "Escape") {
      setOpenDropDown(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openDropDown);
  React.useEffect(() => {
    if (prevOpen.current === true && openDropDown === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openDropDown;
  }, [openDropDown]);

  const deleteFunction = async (obj) => {
    swal({
      title: "?هل انت متأكد",
      text: "لن تتمكن من التراجع عن هذا!",
      icon: "warning",
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {

        } catch (error) {
          toast.error(t(error?.message));
        }
      } else {
        // alert('canceled')
      }
    });
  };

  const getRowClassName = () => {
    if (props?.row?.enabled === 0) {
      return '#b1b0b0'
    }
    else {
      if (props?.row?.transaction_status_id === 2) {
        return '#bdd3f4'
      }
      else if (props?.row?.transaction_status_id === 3) {
        return '#f4bdbd'
      }
      else if (props?.row?.transaction_status_id === 4) {
        return '#bdf4c6'
      }
      else {
        return "#FFFFFF"
      }
    }



  };

  return (
    <Box
      sx={{
        display: "flex",
        direction: 'ltr',
        justifyContent: "space-between",
        alignItems: "stretch",
        flexDirection: "column",
        backgroundColor: open ? getRowClassName() + "A0" : getRowClassName(),
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid',
        borderBottomColor: open ? "#bdd3f4" : "#bdd3f4A0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "stretch",
          flexDirection: "row",
          paddingTop: '5px',
          paddingBottom: '5px'
        }}
      >
        <ListItemButton style={{
          padding: 0,
          width: '25px',
          maxWidth: '25px'

        }} onClick={handleClick}>
          <KeyboardArrowDown
            sx={{
              mr: -1,
              opacity: 1,
              transform: open ? "rotate(-180deg)" : "rotate(0)",
              transition: "0.2s",
              color: "#52575D",
              marginRight: '5px',
              width: "25px",
            }}
          />
        </ListItemButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: '5px',

            flex: 0.1,
          }}
        >
          <Typography variant="caption" sx={secondaryTextSX}>
            {props?.row?.index_column}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {/* <Typography variant="subtitle2" sx={primaryTextSX}>
              {props?.row && props?.row?.id}
            </Typography> */}
            <Typography variant="caption" sx={primaryTextSX}>
              {props?.row && props?.row?.transaction_number}
            </Typography>
            {/* <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("TRANSACTIONS.created_by_name")}
                </span>
              }
            >
              <Typography variant="caption" sx={secondaryTextSX}>
                {props?.row?.created_by_name}
              </Typography>
            </Tooltip> */}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ ...primaryTextSX, direction: "rtl" }}
          >
            {props?.row?.unit_transaction_type?props?.row?.unit_transaction_type:props?.row?.unit_name}
          </Typography>
          <Typography variant="caption" sx={secondaryTextSX}>
            {props?.row?.source_geofence_name}
            {" -> "}
            {props?.row?.destination_geofence_name ? props?.row?.destination_geofence_name : "لايوجد"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ ...primaryTextSX, direction: "rtl" }}
          >
            {props?.row?.product_type}
          </Typography>
          <Typography variant="caption" sx={secondaryTextSX}>
            {props?.row?.liters}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            flex: 1.5,
          }}
        >
          {(!props?.row?.routes_count) ?
            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("TRANSACTIONS.no_routes")}
                </span>
              }
            >
              <CancelIcon
                sx={{
                  fill: "#FF0000",
                  width: '24px'
                }}
              />
            </Tooltip> :


            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("TRANSACTIONS.routes_count")}
                </span>
              }
            >

              <IconButton
                onClick={() => { props?.showRoutesFunction(props?.row) }}
                sx={{
                  fontSize: '16px',
                  color: '#000000DE',
                  width: '24px'
                }}
              >

                {parseInt(props?.row?.routes_count)}

              </IconButton>

            </Tooltip>

          }
          <Box sx={{ width: '5px' }}></Box>
          <CustomToggleStatus
            trueOrFalse={props?.row?.is_monitored == 1}
            trueLabel={t("TRANSACTIONS.monitored")}
            falseLabel={t("TRANSACTIONS.not_monitored")}
            trueColor={"green"}
            falseColor={"red"}
          />
          <Box sx={{ width: '5px' }}></Box>
          <CustomMultiStatusIcon
            multiStatusArray={[

              {
                statusIsTrue: (
                  props?.row?.is_monitored == 1
                  &&
                  props?.row?.has_valid_routes == 0
                ),
                label: t("TRANSACTIONS.no_valid_routes"),
                icon: <CancelIcon
                  sx={{
                    fill: "orange"
                  }}
                />
              },
              {
                statusIsTrue: (
                  props?.row?.is_monitored == 1
                  &&
                  props?.row?.has_valid_routes == 1
                  &&
                  props?.row?.has_violation == 0
                  &&
                  props?.row?.is_arrived == 1
                ),
                label: t("TRANSACTIONS.doesnt_has_violation"),
                icon: <CheckCircleIcon
                  sx={{
                    fill: "green"
                  }}
                />
              },
              {
                statusIsTrue: (
                  props?.row?.is_monitored == 1
                  &&
                  props?.row?.has_valid_routes == 1
                  &&
                  props?.row?.has_violation == 1
                ),
                label: t("TRANSACTIONS.has_violation"),
                icon: <CancelIcon
                  sx={{
                    fill: "red"
                  }}
                />
              }

            ]}
          />
          <Box sx={{ width: '5px' }}></Box>
          {props?.row?.is_monitored == 0 ? <Box sx={{ width: '24px' }}></Box> :
            <CustomToggleStatus
              trueOrFalse={props?.row?.is_arrived == 1}
              trueLabel={t("TRANSACTIONS.is_arrived")}
              falseLabel={t("TRANSACTIONS.isnt_arrived")}
              trueColor={"green"}
              falseColor={(props?.row?.is_complete == 1
                && props?.row?.exceeded_allowed_time == 0
                && props?.row?.payload_not_unloaded == 0
                && props?.row?.payload_unloaded_incomplete == 0)? "orange" : "red"}
              
              ignorefalse={props?.row?.is_complete == 0}
            />}
          <Box sx={{ width: '5px' }}></Box>
          {props?.row?.is_monitored == 0 || (
            props?.row?.is_complete == 0
            &&
            props?.row?.exceeded_allowed_time == 0

          ) ? <Box sx={{ width: '24px' }}></Box> :

            <CustomMultiStatusIcon
              multiStatusArray={[
                {
                  statusIsTrue: (
                    props?.row?.is_complete == 1
                    && props?.row?.exceeded_allowed_time == 0
                    && props?.row?.payload_not_unloaded == 0
                    && props?.row?.payload_unloaded_incomplete == 0
                  ),
                  label: t("TRANSACTIONS.is_complete"),
                  icon: <CheckCircleIcon
                    sx={{
                      fill: "green"
                    }}
                  />
                },
                {
                  statusIsTrue: (
                    props?.row?.is_complete == 1
                    && props?.row?.exceeded_allowed_time == 0
                    && props?.row?.payload_not_unloaded == 1 // status 7
                    && props?.row?.payload_unloaded_incomplete == 0
                  ),
                  label: t("TRANSACTIONS.payload_not_unloaded"),
                  icon: <CancelIcon
                    sx={{
                      fill: "red"
                    }}
                  />
                }
                ,
                {
                  statusIsTrue: (
                    props?.row?.is_complete == 1
                    && props?.row?.exceeded_allowed_time == 0
                    
                    && props?.row?.payload_unloaded_incomplete == 1 // status 9
                  ),
                  label: t("TRANSACTIONS.payload_unloaded_incomplete"),
                  icon: <CancelIcon
                    sx={{
                      fill: "orange"
                    }}
                  />
                }
                ,
                {
                  statusIsTrue: (
                    props?.row?.exceeded_allowed_time == 1
                  ),
                  label: t("TRANSACTIONS.exceeded_allowed_time")+" "+props?.row?.note,
                  icon: <CancelIcon
                    sx={{
                      fill: "red"
                    }}
                  />
                }
              ]}
            />}
          <Box sx={{ width: '5px' }}></Box>
          {
            (props?.row?.stop_violation_counter == 0 && props?.row?.stop_out_violation_counter == 0) ? <Box sx={{ width: '24px' }}></Box> :

              <Tooltip
                title={
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("TRANSACTIONS.stop_violation_counter")}
                  </span>
                }
              >
                <IconButton
                  onClick={() => { props?.showStopsFunction(props?.row) }}
                  sx={{
                    fontSize: '16px',
                    color: '#000000DE'
                  }}
                >
                  {parseInt(props?.row?.stop_violation_counter) + parseInt(props?.row?.stop_out_violation_counter)}
                </IconButton>
              </Tooltip>

          }
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            flex: 1.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              flexDirection: "column",
              flex: 2,
              paddingRight: '10px',
              minWidth: '120px'
            }}
          >
            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("TRANSACTIONS.date_time")}
                </span>
              }
            >
              <Typography variant="subtitle2" sx={primaryTextSX}>



                {(props?.row?.formatted_datetime && props?.row?.formatted_datetime?.includes(':')) ? props?.row?.formatted_datetime?.replaceAll("-", "/").replace(/:\d+$/, '') : ""}

              </Typography>

            </Tooltip>
            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("TRANSACTIONS.out_from_source_at")}
                </span>
              }
            >
              <Typography variant="subtitle2" sx={primaryTextSX}>



                {(props?.row?.out_from_source_at && props?.row?.out_from_source_at?.includes(':')) ? props?.row?.out_from_source_at?.replaceAll("-", "/").replace(/:\d+$/, '') : ""}

              </Typography>
            </Tooltip>

            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("TRANSACTIONS.arrived_at")}
                </span>
              }
            >
              <Typography variant="subtitle2" sx={primaryTextSX}>



                {(props?.row?.arrived_at && props?.row?.arrived_at?.includes(':')) ? props?.row?.arrived_at?.replaceAll("-", "/").replace(/:\d+$/, '') : ""}

              </Typography>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("TRANSACTIONS.trip_duration_time_difference") + "-" + t("TRANSACTIONS.trip_duration_time_difference_tooltip")}
                </span>
              }
            >
              <Typography variant="caption" sx={{
                ...secondaryTextSX,

              }}>

                {props?.row && props?.row?.trip_duration_time_difference}

              </Typography>
            </Tooltip>
            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {t("TRANSACTIONS.trip_range_time_difference") + "-" + t("TRANSACTIONS.trip_range_time_difference_tooltip")}
                </span>
              }
            >
              <Typography variant="caption" sx={secondaryTextSX}>

                {props?.row && props?.row?.trip_range_time_difference}

              </Typography>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Tooltip
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("TRANSACTIONS.transaction_status_name")}
              </span>
            }
          >
            <Typography variant="caption" sx={primaryTextSX}>
              {props?.row && props?.row?.transaction_status_name}
            </Typography>
          </Tooltip>
          <Tooltip
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("TRANSACTIONS.audited_by_name")}
              </span>
            }
          >
            <Typography variant="caption" sx={secondaryTextSX}>
              {props?.row?.transaction_status_id == 1 ? "-" : props?.row?.audited_by_name}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Tooltip
            title={
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("TRANSACTIONS.unit_monitored_by_name_formatted")}
              </span>
            }
          >
            <Typography variant="caption" sx={primaryTextSX}>
              {props?.row && props?.row?.unit_monitored_by_name_formatted}
            </Typography>
          </Tooltip>
          
        </Box>

        <IconButton
          sx={{
            width: "70px",
            "&:hover": {
              borderRadius: "0px",
            },
          }}
          // onClick={() => {

          // }}
          ref={anchorRef}
          id="composition-button"
          aria-controls={openDropDown ? "composition-menu" : undefined}
          aria-expanded={openDropDown ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </IconButton>
        {/* master grid action popup  */}
        <Popper
          open={openDropDown}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{
            zIndex: 2342423,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper
                sx={{
                  backgroundColor: "#E3E9EF",
                  borderRadius: "0 !important",
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={openDropDown}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {rolesRedux?.view_transaction?.value ?
                      <MenuItem onClick={() => {
                        props?.viewFunction(props?.row)
                        setOpenDropDown(false);
                      }} className="ActionIcons">
                        <ListItemIcon sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '0px 7px 0 0'
                        }}>
                          <VisibilityIcon style={{ fill: "#17681b" }} />

                        </ListItemIcon>
                        {t('TRANSACTIONS.view_transaction')}
                      </MenuItem> : null}
                    {props?.row?.is_arrived == 1 && rolesRedux?.export_transaction_report?.value ?
                      <MenuItem onClick={() => {
                        props?.exportReport(props?.row)
                        setOpenDropDown(false);
                      }} className="ActionIcons">
                        <ListItemIcon sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '0px 7px 0 0'
                        }}>
                          <DownloadIcon style={{ fill: "#17681b" }} />

                        </ListItemIcon>
                        {t('GLOBAL.download_report')}
                      </MenuItem> : null}
                    {props?.row?.is_arrived == 1 && rolesRedux?.export_transaction_report?.value ?
                      <MenuItem onClick={() => {
                        props?.createReport(props?.row)
                        setOpenDropDown(false);
                      }} className="ActionIcons">
                        <ListItemIcon sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '0px 7px 0 0'
                        }}>
                          <NoteAddIcon style={{ fill: "#17681b" }} />

                        </ListItemIcon>
                        {t('GLOBAL.create_report')}
                      </MenuItem> : null}
                    {props?.showUpdate && props?.showUpdate(rolesRedux?.update_transaction?.value, props?.row) ?
                      <MenuItem onClick={() => {
                        props?.UpdateFunction(props?.row)
                        setOpenDropDown(false);
                      }} className="ActionIcons">
                        <ListItemIcon sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '0px 7px 0 0'
                        }}>
                          <EditIcon style={{ fill: "#17681b" }} />

                        </ListItemIcon>
                        {t('TRANSACTIONS.update_destination_geofence')}
                      </MenuItem> : null}
                    {rolesRedux?.add_transaction_audit?.value ?
                      <MenuItem onClick={() => {
                        props?.auditFunction(props?.row)
                        setOpenDropDown(false);
                      }} className="ActionIcons">
                        <ListItemIcon sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '0px 7px 0 0'
                        }}>
                          <VerifiedIcon style={{ fill: "#17681b" }} />

                        </ListItemIcon>
                        {t('TRANSACTIONS.audit')}
                      </MenuItem> : null}
                    {rolesRedux?.add_route?.value && props?.row?.destination_geofence_name ?
                      <MenuItem onClick={() => {
                        props?.createRouteFunction(props?.row)
                        setOpenDropDown(false);
                      }} className="ActionIcons">
                        <ListItemIcon sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '0px 7px 0 0'
                        }}>
                          <AddRoadIcon style={{ fill: "#17681b" }} />

                        </ListItemIcon>
                        {t('TRANSACTIONS.create_route')}
                      </MenuItem> : null}
                    {props?.row?.latitude && props?.row?.longitude ?
                      <MenuItem onClick={() => {
                        window.open(
                          `https://maps.google.com/?q=${props?.row?.latitude},${props?.row?.longitude}`,
                          "_blank"
                        );
                        setOpenDropDown(false);
                      }} className="ActionIcons">
                        <ListItemIcon sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '0px 7px 0 0'
                        }}>
                          <LocationOnIcon style={{ fill: "#17681b" }} />

                        </ListItemIcon>
                        {t('TRANSACTIONS.map_location')}
                      </MenuItem> : null}

                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      {
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box
            sx={{
              minHeight: "200px",
              background: "#FFFFFF",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                flex:1,
                display: "flex",
                flexDirection: "column",
              }}
            >

              {/* {viewObjectKeys?.length ? viewObjectKeys?.map(viewObjectKey => {
                  return <Grid xs={3} sm={3} md={3} lg={3} sx={{
                    padding: '0px'
                  }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: '#17681b',
                        borderRadius: '10px',
                        marginLeft: '10px',
                        marginTop: '10px',
                        minHeight: '75px',
                        flex: 1,
                      }}
                    >
                      <Typography variant="subtitle2" sx={secondaryTextSX}>
                        {t("TRANSACTIONS." + viewObjectKey)}
                      </Typography>
                      <Typography variant="caption" sx={primaryTextSX}>
                        {props?.row?.hasOwnProperty(viewObjectKey) ? props?.row[viewObjectKey] : ""}
                      </Typography>

                    </Box>


                  </Grid>
                }) : null} */}

              {viewExtra1ObjectKeys?.length ? viewExtra1ObjectKeys?.map(viewObjectKey => {
                return <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#f3f3f3',
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      padding: '5px',
                      backgroundColor: "#fff3cd",
                      height: '100%',
                      color: 'white'
                    }}
                  >


                    <Typography variant="subtitle2" sx={{
                      ...secondaryTextSX,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      padding: '5px',
                      
                      minHeight: '30px',
                      color: 'black'
                    }}>
                      {t("TRANSACTIONS." + viewObjectKey)}
                    </Typography>
                  </Box>


                  <Typography variant="caption" sx={{
                    ...primaryTextSX,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flex: 2,
                    padding: '5px',
                  }}>
                    {props?.row?.hasOwnProperty(viewObjectKey) && props?.row[viewObjectKey] ? props?.row[viewObjectKey] : "لايوجد"}
                  </Typography>

                </Box>


              }) : null}
            </Box>
            <Box
              sx={{
                flex:1,
                display: "flex",
                flexDirection: "column",
              }}
            >

              {/* {viewObjectKeys?.length ? viewObjectKeys?.map(viewObjectKey => {
                  return <Grid xs={3} sm={3} md={3} lg={3} sx={{
                    padding: '0px'
                  }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: '#17681b',
                        borderRadius: '10px',
                        marginLeft: '10px',
                        marginTop: '10px',
                        minHeight: '75px',
                        flex: 1,
                      }}
                    >
                      <Typography variant="subtitle2" sx={secondaryTextSX}>
                        {t("TRANSACTIONS." + viewObjectKey)}
                      </Typography>
                      <Typography variant="caption" sx={primaryTextSX}>
                        {props?.row?.hasOwnProperty(viewObjectKey) ? props?.row[viewObjectKey] : ""}
                      </Typography>

                    </Box>


                  </Grid>
                }) : null} */}

              {viewExtra2ObjectKeys?.length ? viewExtra2ObjectKeys?.map(viewObjectKey => {
                return <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#f3f3f3',
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      padding: '5px',
                      backgroundColor: "#fff3cd",
                      height: '100%',
                      
                    }}
                  >


                    <Typography variant="subtitle2" sx={{
                      ...secondaryTextSX,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      padding: '5px',
                     
                      minHeight: '30px',
                      color: 'black'
                    }}>
                      {t("TRANSACTIONS." + viewObjectKey)}
                    </Typography>
                  </Box>


                  <Typography variant="caption" sx={{
                    ...primaryTextSX,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flex: 2,
                    padding: '5px',
                  }}>
                    {props?.row?.hasOwnProperty(viewObjectKey) && props?.row[viewObjectKey] ? props?.row[viewObjectKey] : "لايوجد"}
                  </Typography>

                </Box>


              }) : null}
            </Box>
            <Box
              sx={{
                flex:1,
                display: "flex",
                flexDirection: "column",
              }}
            >

              {/* {viewObjectKeys?.length ? viewObjectKeys?.map(viewObjectKey => {
                  return <Grid xs={3} sm={3} md={3} lg={3} sx={{
                    padding: '0px'
                  }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: '#17681b',
                        borderRadius: '10px',
                        marginLeft: '10px',
                        marginTop: '10px',
                        minHeight: '75px',
                        flex: 1,
                      }}
                    >
                      <Typography variant="subtitle2" sx={secondaryTextSX}>
                        {t("TRANSACTIONS." + viewObjectKey)}
                      </Typography>
                      <Typography variant="caption" sx={primaryTextSX}>
                        {props?.row?.hasOwnProperty(viewObjectKey) ? props?.row[viewObjectKey] : ""}
                      </Typography>

                    </Box>


                  </Grid>
                }) : null} */}

              {viewExtra3ObjectKeys?.length ? viewExtra3ObjectKeys?.map(viewObjectKey => {
                return <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#f3f3f3',
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      padding: '5px',
                      backgroundColor: "#fff3cd",
                      height: '100%',
                      
                    }}
                  >


                    <Typography variant="subtitle2" sx={{
                      ...secondaryTextSX,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1,
                      padding: '5px',
                    
                      minHeight: '30px',
                      color: 'black'
                    }}>
                      {t("TRANSACTIONS." + viewObjectKey)}
                    </Typography>
                  </Box>


                  <Typography variant="caption" sx={{
                    ...primaryTextSX,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flex: 2,
                    padding: '5px',
                  }}>
                    {props?.row?.hasOwnProperty(viewObjectKey) && props?.row[viewObjectKey] ? props?.row[viewObjectKey] : "لايوجد"}
                  </Typography>

                </Box>


              }) : null}
            </Box>
          </Box>

        </Collapse>
      }
    </Box>
  );
}
