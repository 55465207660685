import { Box } from '@mui/material'
import React,{useState,useEffect} from 'react'
import CustomButton from './CustomButton'

const buttonSX = {
    width: '164px',
    fontFamily: 'Cairo',
    fontSize: '13px',
    backgroundColor: '#FFFFFF66',
    color: '#FFFFFF',
    marginLeft: '5px',
    border: 'none'
}
const buttonHoverStyle = {
    backgroundColor: '#FFFFFF',
    color: '#994D1E',
}
const selectedButtonSX = {
    width: '164px',
    fontFamily: 'Cairo',
    fontSize: '13px',
    backgroundColor: '#FFFFFF',
    color: '#994D1E',
    marginLeft: '5px',
    border: 'none'

}
const selectedButtonHoverStyle = {
    backgroundColor: '#FFFFFF66',
    color: '#FFFFFF',
}

function CustomTabs(props) {
    const [loading, setLoading] = useState(true)
    const [selectedTabContent, setSelectedTabContent] = useState(null)
    const [tabs, setTabs] = useState(props?.tabsObject?.tabs)
    const [isUpdate,setIsUpdate]=useState(false)
    const showContent = (tab) => { 
        setSelectedTabContent(tab?.content)  
        setIsUpdate(false)
        setTabs(
            tabs?.map(currentTab=>{
                return {
                    ...currentTab,
                    ...(currentTab?.id==tab?.id?{
                        selected:true
                    }:
                    {
                        selected:false
                    })
                }
            })
        )  
    }
    useEffect(()=>{
        
        // setTabs(props?.tabsObject?.tabs)
        setIsUpdate(true)
    },[props?.tabsObject?.tabs])
    useEffect(() => {
        // if(isUpdate){
        let defaultSelectedTabContent = null
      
        if(props?.tabsObject?.tabs?.length>0)
        {
          
            const defaultSelectedTabs = props?.tabsObject?.tabs?.filter(tab=>tab?.selected)
            if(defaultSelectedTabs?.length>0)
            {
                
                defaultSelectedTabContent = defaultSelectedTabs[0]?.content
            }
            else{
           
                setTabs(
                    tabs?.map(currentTab=>{
                        return {
                            ...currentTab,
                            ...((currentTab?.id==props?.currentTabOpen)&&!props?.customerFromUrl?{
                                selected:true
                            }:currentTab?.id==4&&props?.customerFromUrl?{
                                selected:true
                            }:
                            {
                                selected:false
                            })
                        }
                    })
                ) 
                props?.tabsObject?.tabs&&props?.tabsObject?.tabs?.length&&props?.tabsObject?.tabs?.map((itm)=>{
                   if(itm?.id==4&&props?.customerFromUrl||itm?.id==props?.currentTabOpen){
                      defaultSelectedTabContent = itm?.content
                   }
                })
                if(!defaultSelectedTabContent)
                defaultSelectedTabContent = props?.tabsObject?.tabs[0]?.content
            }
        }
        setSelectedTabContent(defaultSelectedTabContent)
    //    }
      return () => {
      }
    }, [])
    
    return (
        <Box>
            <Box
                sx={{
                    flex: 1
                }}
            >
                {
                    selectedTabContent
                }
            </Box>
            <Box
                sx={{
                    height: '50px',
                    background: '#994D1E',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
            >
                {tabs?.map((tab, index) => {
                    return (
                        <CustomButton
                            key={tab?.id ? tab?.id : index}
                            text={tab?.title}
                            sx={tab?.selected?selectedButtonSX: buttonSX}
                            buttonHoverStyle={tab?.selected?selectedButtonHoverStyle:buttonHoverStyle}
                             
                            startIcon={tab?.startIcon}
                            onClick={() => {
                                showContent(tab)
                                props?.setCurrentTabOpen(tab?.id)
                            }}
                        />
                    )
                })}



            </Box>
        </Box>
    )
}

export default CustomTabs