import "./App.css";
import React, { useEffect, useState } from "react";
import LandingPage from "./Pages/LandingPage";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import $ from "jquery";
import Cookies from "universal-cookie";
import SideMenu from "./SideMenu/SideMenu";
import { I18nextProvider } from "react-i18next";
import { CacheProvider } from "@emotion/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";
import Context from "./assets/js/context";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/test1.css";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { setUserToken } from "./reduxStore/UserReducer";
import { setscreenwidth } from "./reduxStore/SettingsReducer";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import ProviderRedirect from "./Components/ProviderRedirect";
import Profile from "./Pages/Profile/Profile";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { useNavigate } from "react-router-dom";
import SessionEnd from "./Pages/SessionEnd";
import SorryPage from "./Pages/SorryPage";
import PageNotFound from "./Pages/PageNotFound";
import Units from "./Pages/Units/Units";
import Geofences from "./Pages/Geofences/Geofences";
import Users from "./Pages/Users/Users";
import Activities from "./Pages/Activities/Activities";
import RoutesPage from "./Pages/Routes/RoutesPage";
import Transactions from "./Pages/Transactions/Transactions";
import { selectRoles, setRolesRedux } from "./reduxStore/RolesReducer";
import NotAuthorized from "./Pages/NotAuthorized";
import profileAPI from "./Network/Profile";
import Footer from "./SideMenu/Footer";
import UnitsTransactions from "./Pages/UnitsTransactions/UnitsTransactions";
import ReportByTransactionNumber from "./Pages/Reports/ReportByTransactionNumber";
import ReportByIsMonitored from "./Pages/Reports/ReportByIsMonitored";
import ReportBySourceGeofence from "./Pages/Reports/ReportBySourceGeofence";
import ReportByDestinationGeofence from "./Pages/Reports/ReportByDestinationGeofence";
import ReportByProductType from "./Pages/Reports/ReportByProductType";
import ReportBySourceAndProductType from "./Pages/Reports/ReportBySourceAndProductType";
import ReportByDestinationAndProductType from "./Pages/Reports/ReportByDestinationAndProductType";
import TestPage from "./Pages/TestPage";
import TransactionsCustom from "./Pages/Transactions/TransactionsCustom";
import ReportByAuditedPerUser from "./Pages/Reports/ReportByAuditedPerUser";
import ReportAuditedUnitsMonitoredByUser from "./Pages/Reports/ReportAuditedUnitsMonitoredByUser";
import MainUnits from "./Pages/MainUnits/MainUnits";
import MobileUsers from "./Pages/MobileUsers/MobileUsers";
import MainAccounts from "./Pages/MainAccounts/MainAccounts";
import Tags from "./Pages/Tags/Tags";
import MainUnitsTemp from "./Pages/MainUnits/MainUnitsTemp";
import TransactionsNoUnit from "./Pages/Transactions/TransactionsNoUnit";
import TransactionsPrivate from "./Pages/Transactions/TransactionsPrivate";
import ReportStopsInfo from "./Pages/Reports/ReportStopsInfo";
import ReportForMainUnits from "./Pages/Reports/ReportForMainUnits";
import Login from "./Pages/Login/Login";
import InstallationCheck from "./Pages/MainUnitsInstallationCheck/InstallationCheck";
import ReportMissingDestinations from "./Pages/Reports/ReportMissingDestinations";
import ReportMissingRoutes from "./Pages/Reports/ReportMissingRoutes";
import TransactionsFast from "./Pages/Transactions/TransactionsFast";
import UnitSections from "./Pages/UnitSections/UnitSections";
import UnitTypes from "./Pages/UnitTypes/UnitTypes";
import UnitTransactionsDetail from "./Pages/UnitsTransactions/UnitTransactionsDetail";
import UnitsPrivate from "./Pages/Units/UnitsPrivate";
import GeofenceGroups from "./Pages/GeofenceGroups/GeofenceGroups";
// const Dashboard = React.lazy(() => import('./Pages/Dashboard/Dashboard'))
const cookies = new Cookies();


const MINUTE_MS = 60000;
function App() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const interval = setInterval(() => {
      const baseUrl = cookies.get("baseUrl");
      const sid = cookies.get("sid");
      const authHash = cookies.get("authHash");

      if (baseUrl && baseUrl != "null" && sid && authHash) {

        $.ajax({
          type: "GET",
          enctype: "application/json",
          processData: !1,
          contentType: !1,
          crossDomain: true,
          dataType: "jsonp",
          url: baseUrl + "/avl_evts?sid=" + sid,
          success: function (data) {
            if (data?.tm) {

              cookies.set("active_session", "yes", { path: "/" });
            } else if (data?.error) {

              cookies.set("active_session", "no", { path: "/" });
              cookies.set("sid", null, { path: "/" });

            }

          },

        });
      } else {
        cookies.set("active_session", "no", { path: "/" });
      }
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const [state] = useState({
    data: [],
    ms: [],
    data1: [],
    Dash: [],
    check: "",
    che: "login",
    new: "",
    inprogress: "",
    closed: "",
    approved: "",
    rejected: "",
    archived: "",
    noti: "",
    desc: "",
    commenter: "",
    body: "",
    title: "",
    SiFilter: [],
  });
  const directions = useSelector((state) => state.settingsData.directions);
  const languageCode = useSelector((state) => state.settingsData.code);

  const Translate_ar = useSelector(
    (state) => state.translateData.translation_ar
  );
  const Translate_en = useSelector(
    (state) => state.translateData.translation_en
  );
  const rolesRedux = useSelector(selectRoles)
  const maintheme = useSelector((state) => state.themeData.maintheme);
  const dispatch = useDispatch();


  const [theme, setTheme] = useState(
    createTheme({
      primary: {
        main: '#17681b',
        darker: '#17681b',
      },
      customGreen: {
        main: '#17681b',
        darker: '#17681b',
      },
      direction: directions, // Both here and <body dir="rtl">
    })
  );

  try {
    const bodyWeb = document.querySelector("body");
    if (bodyWeb) {
      bodyWeb.style.background = maintheme?.background;
    }
    i18next.init({
      interpolation: { escapeValue: false }, // React already does escaping
      lng: languageCode,
      resources: {
        ar: {
          common: Translate_ar,
        },
        en: {
          common: Translate_ar,
        },
      },
    });
  } catch (error) {
    console.log(error?.message);
  }

  useEffect(() => {
    localStorage.setItem("direction", directions);
    if (directions === "rtl") {

      document.dir = "rtl";
      const htmlTag = document.querySelector("html");
      if (htmlTag) {
        htmlTag.lang = "ar";
      }
      cookies.set("language", "ar", { path: "/" });
    } else {
      // setCacheRtl(createCache({ key: 'css' }))
      document.dir = "ltr";
      cookies.set("language", "en", { path: "/" });
    }

    setTheme(
      createTheme({
        ...theme,
        direction: directions, // Both here and <body dir="rtl">
      })
    );
  }, [directions]);

  const isrtl = React.useMemo(() => {
    return createCache({
      key: directions === "rtl" ? "muirtl" : "muiltr",
      stylisPlugins: directions === "rtl" ? [rtlPlugin] : [],
    });
  }, [directions]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(setscreenwidth(window.innerWidth));
    });

    if (cookies.get('token', { path: "/" })) {
      initNotWialonUserInfo()
    }
    else {
      initUserProfileInfo();
    }



  }, []);
  const jwt = cookies.get("token");

  var headers = {
    jwt: jwt,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const initNotWialonUserInfo = async () => {

    if (cookies.get('token', { path: "/" })) {

      let data = null
      try {

        let params = {

          id: cookies.get('id')
        }
        setLoading(true)
        data = await profileAPI.Profile({
          params: params,
          headers: headers
        });
        if (data && data?.status && data?.data?.status) {

          if (data?.data?.data?.roles) {
            handleCheckIdExists(data?.data?.data?.roles)
          }


        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.log(e?.message)
      }
    } else {
      setLoading(false)
    }
  }
  const initUserProfileInfo = async () => {

    if (cookies.get('active_session', { path: "/" }) == 'yes' || cookies.get('active_session', { path: "/system" }) == 'yes') {

      let data = null
      try {
        let params = {
          username: cookies.get('user_name'),
          user_id: cookies.get('user_id'),
          account_name: cookies.get('account_name'),
          account_id: cookies.get('account_id'),
          sid: cookies.get('sid'),
          baseurl: cookies.get('baseUrl'),
          id: cookies.get('id')
        }
        setLoading(true)
        data = await profileAPI.Profile({
          params: params
        });
        if (data && data?.status && data?.data?.status) {

          if (data?.data?.data?.roles) {
            handleCheckIdExists(data?.data?.data?.roles)
          }


        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.log(e?.message)
      }
    } else {
      setLoading(false)
    }
  }
  const handleCheckIdExists = (roles) => {


    if (rolesRedux) {


      const updatedRoles = Object.entries(rolesRedux).reduce(
        (acc, [key, value]) => {
          if (roles?.includes(value?.id)) {
            acc[key] = { ...value, value: true };
          } else {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );


      dispatch(setRolesRedux(updatedRoles));
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <I18nextProvider i18n={i18next}>
        <CacheProvider value={isrtl}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ToastContainer
                containerId="container_toast_id"
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                transition={Slide}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Context.Provider
                value={{
                  value: state,
                  action: {},
                }}
              >
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/system" element={<SideMenu />}>
                    <Route
                      path={"dashboard"}
                      element={
                        <React.Suspense fallback={<LandingPage />}>
                          <ProviderRedirect>
                            <Dashboard />
                          </ProviderRedirect>
                        </React.Suspense>
                      }
                    />
                    {rolesRedux?.view_geofence?.value ?
                      <Route
                        path={"geofences"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <Geofences />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_route?.value ?
                      <Route
                        path={"routes"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <RoutesPage />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"transactions"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <Transactions key="transactions" />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"transactions_fast"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <TransactionsFast key="transactions_fast" />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"transactions_custom"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <TransactionsCustom key="transactions_custom" />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"transactions_private"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <TransactionsPrivate key="transactions_private" />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction_no_unit?.value ?
                      <Route
                        path={"transactions_custom_no_unit"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <TransactionsNoUnit key="transactions_custom_no_unit" noUnit={true} />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}

                    {rolesRedux?.view_user?.value ?
                      <Route
                        path={"users"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <Users />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_activity?.value ?
                      <Route
                        path={"activities"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <Activities />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}

                    <Route
                      path={"profile"}
                      element={
                        <React.Suspense fallback={<LandingPage />}>
                          <ProviderRedirect>
                            <Profile />
                          </ProviderRedirect>
                        </React.Suspense>
                      }
                    />
                    {rolesRedux?.view_unit?.value ?
                      <Route
                        path={"units"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <Units />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                      {rolesRedux?.view_unit?.value ?
                      <Route
                        path={"units_governmental"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <Units />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                      {rolesRedux?.view_unit?.value ?
                      <Route
                        path={"units_private"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <UnitsPrivate />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_unit?.value ?
                      <Route
                        path={"units_transactions"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <UnitsTransactions />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                      {rolesRedux?.view_unit?.value ?
                      <Route
                        path={"unit_transactions_detail"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <UnitTransactionsDetail />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"report_by_transaction_number"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportByTransactionNumber />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"report_stops_info"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportStopsInfo />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"report_by_is_monitored"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportByIsMonitored />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"report_by_source_geofence"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportBySourceGeofence />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"report_by_destination_geofence"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportByDestinationGeofence />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"report_by_product_type"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportByProductType />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"report_by_audited_per_user"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportByAuditedPerUser />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"report_audited_units_monitored_by_user"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportAuditedUnitsMonitoredByUser />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"report_by_source_and_product_type"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportBySourceAndProductType />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_transaction?.value ?
                      <Route
                        path={"report_by_destination_and_product_type"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportByDestinationAndProductType />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_main_unit?.value ?
                      <Route
                        path={"report_for_main_units"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportForMainUnits />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_main_unit?.value ?
                      <Route
                        path={"report_for_missing_destinations"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportMissingDestinations />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_main_unit?.value ?
                      <Route
                        path={"report_for_missing_routes"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <ReportMissingRoutes />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {/* {rolesRedux?.view_main_unit?.value ?
                      <Route
                        path={"main_units"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <MainUnits />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />} */}
                    {rolesRedux?.view_main_unit?.value ?
                      <Route
                        path={"main_units"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <MainUnitsTemp />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_mobile_user?.value ?
                      <Route
                        path={"mobile_users"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <MobileUsers />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_master?.value ?
                      <Route
                        path={"main_accounts"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <MainAccounts />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_master?.value && (cookies.get('id') == 46) ?
                      <Route
                        path={"tags"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <Tags />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_master?.value ?
                      <Route
                        path={"unit_sections"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <UnitSections />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                      {rolesRedux?.view_master?.value ?
                      <Route
                        path={"unit_types"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <UnitTypes />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                    {rolesRedux?.view_main_unit?.value ?
                      <Route
                        path={"main_units_installation_check"}
                        element={
                          <React.Suspense fallback={<Login />}>
                            {/* <ProviderRedirect> */}
                            <InstallationCheck />
                            {/* </ProviderRedirect> */}
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                      {rolesRedux?.view_geofence_group?.value ?
                      <Route
                        path={"geofence_groups"}
                        element={
                          <React.Suspense fallback={<LandingPage />}>
                            <ProviderRedirect>
                              <GeofenceGroups />
                            </ProviderRedirect>
                          </React.Suspense>
                        }
                      /> : <Route path="not_authorized" element={<NotAuthorized />} />}
                  </Route>
                  <Route path="/redirect" element={<SessionEnd />} />
                  <Route path="/sorry" element={<SorryPage />} />
                  {/* <Route path="/test" element={<TestPage />} /> */}
                  {loading ?
                    <Route path="*" element={<PageNotFound loading={true} />} />
                    :
                    <Route path="*" element={<PageNotFound loading={false} />} />
                  }

                </Routes>
              </Context.Provider>
            </BrowserRouter>
          </ThemeProvider>
        </CacheProvider>
      </I18nextProvider>
      <Footer />
    </Box>
  );
}

export default App;
