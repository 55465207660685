import { Box, Grid } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import helpers from '../../../assets/js/helper';
import CustomeSelectField from '../../../Components/CustomeSelectField';
import CustomTextField from '../../../Components/CustomTextField';
import CloseIcon from "@mui/icons-material/Close";
import { selectRoles } from '../../../reduxStore/RolesReducer';
import { useSelector } from 'react-redux';

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const rolesRedux = useSelector(selectRoles)


  return (
    <Box
      className="Container-fluid position-relative"
      sx={{
        "& .MuiFormHelperText-root": {
          fontFamily: "Cairo !important",
          color: "red",
        },
      }}
    >
      <Box
        sx={{
          width: "35px",
          height: "35px",
          borderRadius: "50%",
          position: "fixed",
          top: "15px",
          right: "20px",
          boxShadow: "0 0 10px -2px rgba(0,0,0,0.9)",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          zIndex: "21120",
        }}
        onClick={() => {
          props?.setOpen(false);
        }}
      >
        <CloseIcon
          sx={{
            fontSize: "30px",
            color: "#407ea4",
          }}
        />
      </Box>

      <Box className="Container-fluid position-relative" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{
          marginTop: '10px'
        }}>
          <Grid xs={12} sm={12} md={12} lg={12} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.name")}
              value={props?.object?.name?.value}
              error={props?.object?.name?.error}
              message={props?.object?.name?.message}
              haswidth={true}
              readOnly={true}
              focused={true}
              onChange={(e) => {

                props?.setObject({
                  ...props?.object,
                  name: {
                    ...props?.object?.name,
                    value: e?.target?.value,
                    error: false,
                    message: []
                  }
                })

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  name: {
                    ...props?.object?.name,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          {/* <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.plate_number")}
              value={props?.object?.plate_number?.value}
              error={props?.object?.plate_number?.error}
              message={props?.object?.plate_number?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {
                if (!isNaN(e?.target?.value)) {
                  props?.setObject({
                    ...props?.object,
                    plate_number: {
                      ...props?.object?.plate_number,
                      value: e?.target?.value,
                      error: false,
                      message: []
                    }
                  })
                }

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_number: {
                    ...props?.object?.plate_number,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid> */}
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.number_and_letter")}
              value={props?.object?.number_and_letter?.value}
              haswidth={true}
              readOnly={true}
              
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.plate_type")}
              value={props?.object?.type?.value}
              haswidth={true}
              readOnly={true}
              
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.plate_province")}
              value={props?.object?.province?.value}
              haswidth={true}
              readOnly={true}
              
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.vin")}
              value={props?.object?.vin?.value}
              haswidth={true}
              readOnly={true}
              
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomTextField
              label={t("UNITS.driver_name")}
              value={props?.object?.driver_name?.value}
              error={props?.object?.driver_name?.error}
              message={props?.object?.driver_name?.message}
              haswidth={true}
              readOnly={false}
              focused={true}
              onChange={(e) => {
         
                  props?.setObject({
                    ...props?.object,
                    driver_name: {
                      ...props?.object?.driver_name,
                      value: e?.target?.value,
                      error: false,
                      message: []
                    }
                  })
                

              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  driver_name: {
                    ...props?.object?.driver_name,
                    value: "",
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          {/* <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.plate_letter")}
              haswidth={true}
              value={props?.object?.plate_letter_id?.value}
              list={props?.plateLetters?.length ? props?.plateLetters : []}
              error={props?.object?.plate_letter_id?.error}
              message={props?.object?.plate_letter_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {


                props?.setObject({
                  ...props?.object,
                  plate_letter_id: {
                    ...props?.object?.plate_letter_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_letter_id: {
                    ...props?.object?.plate_letter_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.plate_province")}
              haswidth={true}
              value={props?.object?.plate_province_id?.value}
              list={props?.plateProvinces?.length ? props?.plateProvinces : []}
              error={props?.object?.plate_province_id?.error}
              message={props?.object?.plate_province_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {
                props?.setObject({
                  ...props?.object,
                  plate_province_id: {
                    ...props?.object?.plate_province_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_province_id: {
                    ...props?.object?.plate_province_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.plate_type")}
              haswidth={true}
              value={props?.object?.plate_type_id?.value}
              list={props?.plateTypes?.length ? props?.plateTypes : []}
              error={props?.object?.plate_type_id?.error}
              message={props?.object?.plate_type_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {


                props?.setObject({
                  ...props?.object,
                  plate_type_id: {
                    ...props?.object?.plate_type_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  plate_type_id: {
                    ...props?.object?.plate_type_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid> */}
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
            <CustomeSelectField
              label={t("UNITS.unit_type")}
              haswidth={true}
              value={props?.object?.unit_type_id?.value}
              list={props?.unitTypes?.length ? props?.unitTypes : []}
              error={props?.object?.unit_type_id?.error}
              message={props?.object?.unit_type_id?.message}
              readOnly={false}
              onChange={(e, newValue) => {


                props?.setObject({
                  ...props?.object,
                  unit_type_id: {
                    ...props?.object?.unit_type_id,
                    value: newValue,
                    error: false,
                    message: []
                  }
                })
              }}
              onClearClick={() => {
                props?.setObject({
                  ...props?.object,
                  unit_type_id: {
                    ...props?.object?.unit_type_id,
                    value: null,
                    error: false,
                    message: []
                  }
                })
              }}
            />
          </Grid>
          <Grid xs={6} sm={6} md={6} lg={6} sx={{
            padding: '0px'
          }}>
           <CustomeSelectField
                label={t("UNITS.unit_section")}
                haswidth={true}
                value={props?.object?.unit_section_id?.value}
                list={props?.unitSections?.length ? props?.unitSections : []}
                error={props?.object?.unit_section_id?.error}
                message={props?.object?.unit_section_id?.message}
                readOnly={false}
                onChange={(e, newValue) => {


                  props?.setObject({
                    ...props?.object,
                    unit_section_id: {
                      ...props?.object?.unit_section_id,
                      value: newValue,
                      error: false,
                      message: []
                    }
                  })
                }}
                onClearClick={() => {
                  props?.setObject({
                    ...props?.object,
                    unit_section_id: {
                      ...props?.object?.unit_section_id,
                      value: null,
                      error: false,
                      message: []
                    }
                  })
                }}
              />
          </Grid>
          {rolesRedux?.update_user_units?.value ?
            <Grid xs={6} sm={6} md={6} lg={6} sx={{
              padding: '0px'
            }}>
              <CustomeSelectField
                label={t("UNITS.monitored_by")}
                haswidth={true}
                value={props?.object?.monitored_by_id?.value}
                list={props?.users?.length ? props?.users : []}
                error={props?.object?.monitored_by_id?.error}
                message={props?.object?.monitored_by_id?.message}
                readOnly={false}
                onChange={(e, newValue) => {


                  props?.setObject({
                    ...props?.object,
                    monitored_by_id: {
                      ...props?.object?.monitored_by_id,
                      value: newValue,
                      error: false,
                      message: []
                    }
                  })
                }}
                onClearClick={() => {
                  props?.setObject({
                    ...props?.object,
                    monitored_by_id: {
                      ...props?.object?.monitored_by_id,
                      value: null,
                      error: false,
                      message: []
                    }
                  })
                }}
              />
            </Grid>
            : null}
        </Grid>
      </Box>
    </Box>
  );
};

export default RenderContent