import callAPI from '../ApiConfig'
import $ from "jquery";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const transactionAudits = async (props) => {

    return await callAPI({
        route: "transaction_audits/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addTransactionAudit = async (props) => {


    return await callAPI({
        route: "transaction_audits/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editTransactionAudit = async (props) => {

    return await callAPI({
        route: "transaction_audits/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteTransactionAudit = async (props) => {


    return await callAPI({
        route: "transaction_audits/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getTransactionAuditList = async (props) => {

    return await callAPI({
        route: "transaction_audits/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const transactionAuditAPI = {
    transactionAudits: transactionAudits,
    addTransactionAudit: addTransactionAudit,
    editTransactionAudit: editTransactionAudit,
    deleteTransactionAudit: deleteTransactionAudit,
    getTransactionAuditList: getTransactionAuditList
}
export default transactionAuditAPI